export {
    ALL_ROLES,
    PRIVILEGES,
    UserRole,
    PrivilegeLevel,
    PrivilegeLevels
};

enum UserRole {
    admin = 'Administrator',
    tmsAdmin = 'tms_Admin',
    tmsControlCenter = 'tms_ControlCenter',
    tmsTransportWorker = 'tms_TransportWorker',
    tmsPickingWorker = 'tms_pickingWorker',
    tmsMonitor = 'tms_Monitor',
    lvsAdmin = 'lvs_Admin',
    lvsControlCenter = 'lvs_ControlCenter',
    lvsStorageWorker = 'lvs_StorageWorker',
    lvsMonitor = 'lvs_Monitor',
}

enum PrivilegeLevels {
    TmsAccess = 'TmsAccess',
    LvsAccess = 'LvsAccess',
    BaseDataManagement = 'BaseDataManagement',
    OrderManagement = 'OrderManagement',
    OrderProcessing = 'OrderProcessing',
    OrderPicking = 'OrderPicking',
    ReadAccess = 'ReadAccess',
    UserManagement = 'UserManagement',
    RoleManagement = 'RoleManagement',
    Admin = 'Admin'
}

type PrivilegeLevel =
    'TmsAccess'
    | 'LvsAccess'
    | 'BaseDataManagement'
    | 'OrderManagement'
    | 'OrderProcessing'
    | 'OrderPicking'
    | 'ReadAccess'
    | 'UserManagement'
    | 'RoleManagement'
    | 'Admin';

const ALL_ROLES = Object.values(UserRole);

type Privileges = { [key in PrivilegeLevel]: UserRole[] };

const PRIVILEGES: Privileges = {
    Admin: [UserRole.admin],
    UserManagement: [UserRole.admin, UserRole.tmsAdmin, UserRole.lvsAdmin],
    RoleManagement: [UserRole.admin, UserRole.tmsAdmin, UserRole.lvsAdmin],

    BaseDataManagement: [UserRole.admin, UserRole.tmsAdmin, UserRole.tmsControlCenter, UserRole.tmsControlCenter, UserRole.lvsAdmin, UserRole.lvsControlCenter],

    OrderManagement: [UserRole.admin, UserRole.tmsAdmin, UserRole.tmsControlCenter, UserRole.lvsAdmin, UserRole.lvsControlCenter],
    OrderPicking: [UserRole.admin, UserRole.tmsControlCenter, UserRole.tmsAdmin, UserRole.tmsTransportWorker, UserRole.tmsPickingWorker],
    OrderProcessing: [UserRole.admin, UserRole.tmsAdmin, UserRole.tmsControlCenter, UserRole.tmsTransportWorker, UserRole.lvsAdmin, UserRole.lvsControlCenter, UserRole.lvsStorageWorker],

    ReadAccess: ALL_ROLES,
    LvsAccess: [UserRole.admin, UserRole.lvsAdmin, UserRole.lvsControlCenter, UserRole.lvsStorageWorker, UserRole.lvsMonitor],
    TmsAccess: [UserRole.admin, UserRole.tmsAdmin, UserRole.tmsControlCenter, UserRole.tmsTransportWorker, UserRole.tmsMonitor]
};


