import {Component} from '@angular/core';
import {DialogMessage} from '../../../../domain/enums';
import {TransportPosition} from '../../../../domain/tms-model';
import {TransportPositionFormGroup} from '../../../../domain/form-groups';
import {ViewNames} from '../../../../constants';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-transport-booking-dialog',
    templateUrl: './transport-booking-dialog.component.html',
    styleUrls: ['./transport-booking-dialog.component.scss']
})
export class TransportBookingDialogComponent {

    transportUpdateFormGroup = new TransportPositionFormGroup();
    displayStrings = ViewNames;

    constructor(
        private _dialogRef: MatDialogRef<TransportBookingDialogComponent, {
            message: DialogMessage,
            result: Partial<TransportPosition> | null
        }>,
    ) {
    }

    get updateIsValid(): boolean {
        return this.transportUpdateFormGroup.controls.bookingNumber.valid || this.transportUpdateFormGroup.controls.jobNumber.valid;
    }

    save() {
        if (this.updateIsValid) {
            const formValue = this.transportUpdateFormGroup.getRawValue();
            this._dialogRef.close({
                message: DialogMessage.CONFIRM,
                result: {bookingNumber: formValue.bookingNumber!, jobNumber: formValue.jobNumber!, orderedBy: formValue.orderedBy!}
            });
        }
    }

    onNoChanges() {
        this._dialogRef.close({message: DialogMessage.NO_CHANGES, result: null})
    }

}
