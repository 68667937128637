import {EXPORT_COLUMN_DECORATOR_KEY} from './key';
import {IllegalExportConfigError} from '../errors/parsing.errors';
import {ExportMap} from '../parsers/object-parser.interface';
import {IExportColumn} from '../interfaces/export-column.interface';
import {ExportColumn} from '../model/export-column.model';


/**
 * Stores the configuration of a column to export in the meta properties of the BASE-class also for inherited classes it will be the base class
 * @param {IExportColumn} config of the column for export
 * @decorator
 * @returns
 */
function exportColumn(config: Partial<IExportColumn>) {
    return function (target: Function | any, propertyName: string | undefined | symbol, parameterIndex?: number) {

        // catching classes that inherit from another class
        // redirecting to the actual wanted constructor
        if (!target?.name) {
            target = target.constructor;
        }

        const key = checkConfigIsLegal(target, propertyName, config);

        // store the key also in the config for easier access a better readability
        if (!config?.propertyKey) {
            config.propertyKey = key;
        }

        const exportColumns = getExistingConfig(target);

        // fall through for the index in priority order hardcoded => constructor-param => last inserted
        const targetIndex = config.index ?? parameterIndex ?? Object.keys(exportColumns).length;

        // checking the next free index
        const index = moveIfIndexIsUsed(targetIndex, exportColumns);

        // updating map of current typ
        exportColumns[key] = createExportConfig(config, key, index);

        // overriding all existing config with new set
        Reflect.defineMetadata(EXPORT_COLUMN_DECORATOR_KEY, exportColumns, target);
    }
}

function moveIfIndexIsUsed(index: number, inConfig: ExportMap<ExportColumn>): number {
    const colWithIndex: ExportColumn | undefined = Object.entries(inConfig).find(([_, c]) => c.index === index)?.[1];

    const indexIsUsed = colWithIndex?.index !== undefined;

    if (indexIsUsed && !colWithIndex!.enforceIndex) {
        colWithIndex!.index++;
        return index;
    }

    if (colWithIndex?.enforceIndex) {
        return index + 1;
    }

    return index;
}

function createExportConfig(config: Partial<IExportColumn>, key: string, index: number): ExportColumn {
    return new ExportColumn({...config, propertyKey: key, view: config.view!, index}, config.index !== undefined);
}

function getExistingConfig(target: Function): ExportMap<ExportColumn> {
    return Reflect.getMetadata(EXPORT_COLUMN_DECORATOR_KEY, target) ?? {};
}

function checkConfigIsLegal(target: Function, propertyName: string | symbol | undefined, config: Partial<IExportColumn>): string {
    const key = propertyName ?? config.propertyKey;

    // check if the key is missing. Happens often by usage of constructor params
    if (!key) {
        throw new IllegalExportConfigError(target, key, `No name was provided for a property in a exportable class. Check constructor parameters of ${target.name}`);
    }

    // check if there is any way to retrieve data for this property
    if (!config.view && !config.link && !config.destruct) {
        throw new IllegalExportConfigError(target, key, `Property is neither linked to an external entity nor is marked to destructure nor has an view property`);
    }

    // check if there are no conflicting configurations
    if (config.link && config.combine) {
        throw new IllegalExportConfigError(target, key, `Property can not be linked to the entity ${config.link.name} and combined with the property ${config.combine}`);
    }

    if (config.link && config.link === target) {
        throw new IllegalExportConfigError(target, key, 'Classes cannot link to them self for export');
    }

    return String(key);
}

export {
    exportColumn as Export,
    IExportColumn,
};
