import {FileImportEvent} from './import-event.model';
import {Component} from '@angular/core';

export {
  ImportPage
}

@Component({
  selector: '<import-pager></import-pager>',
  template: '',
  providers: [
    {
      provide: ImportPage
    }
  ]
})
class ImportPage<T> {
  initialEvent: FileImportEvent<T> | null = null;
}
