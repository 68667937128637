import {AfterContentChecked, Component, ElementRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-nullable-field',
  templateUrl: './nullable-field.component.html',
  styleUrls: ['./nullable-field.component.scss'],
})
export class NullableFieldComponent implements AfterContentChecked {

  noContent = true;

  /**
   * @returns the native element of the surrounding span
   */
  get contentSpanElement(): HTMLElement {
    return this.contentSpan?.nativeElement;
  }

  @ViewChild('spanElement', {static: true, read: ElementRef}) contentSpan!: ElementRef;

  ngAfterContentChecked() {
    this.noContent = !this.hasOwnContent(this.contentSpanElement) && !this.hasChildContent(this.contentSpanElement);
  }

  hasOwnContent(element: HTMLElement): boolean {
    return !!(element.innerHTML && element.innerHTML?.trim() !== '' && element.innerText?.trim() !== '');
  }

  hasChildContent(element: HTMLElement) {
    return element.children.length > 0 && !!Array.from(element.children).find(n => n.textContent !== '');
  }
}
