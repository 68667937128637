import {Inject, Injectable} from '@angular/core';
import {StorageArea, StorageAreaQuery, StorageBin} from '../../domain/lvs-model';
import {SignalRService} from '../../websocket';
import {StateService} from '../model/state/state.service';
import {mergeMap, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {EntityKey} from '../../domain/utility-types';
import {GOODS_RECEPTION_ID, Warehouse} from '../../domain/core-model';
import {PropertySearcher} from '../../search/model';
import {search} from '../../search/operators';
import {StorageBinService} from '../storage-bin/storage-bin.service';

@Injectable({
    providedIn: 'root'
})
export class StorageAreaService extends StateService<StorageArea, StorageAreaQuery> {

    constructor(
        @Inject('WarehouseSignalRService') private _warehouseWebsocket: SignalRService<StorageArea>,
        private _storageBins: StorageBinService,
    ) {
        super(
            {type: StorageArea, primaryKeys: ['storageAreaId', 'warehouseId'], webSocket: 'StorageAreaSignalRService'},
            {eagerLoading: true}
        );
        this.watchWarehouseWebsocket();
    }

    getByWarehouseId(warehouseKey: EntityKey<Warehouse>, query = ''): Observable<StorageArea[]> {
        return this.state.pipe(
            map((s) => s.filter((a: StorageArea) => a.warehouseId === warehouseKey.warehouseId)),
            mergeMap((areas: StorageArea[]) => {
                if (areas.length === 0) {
                    return this._api.search({warehouseId: warehouseKey.warehouseId});
                }
                return of(areas);
            }),
            search<StorageArea>(query)
        );
    }

    createGoodsReception(warehouseId: number, description: string) {
        return this.create({storageAreaId: GOODS_RECEPTION_ID, warehouseId, description})
            .pipe(
                switchMap(success => {
                    if (success) {
                        const storageBin = new StorageBin({
                            storageBinId: '000101',
                            storageAreaId: GOODS_RECEPTION_ID,
                            warehouseId,
                            isHighRack: false,
                            level: 1,
                            row: 1,
                            shelf: 1,
                        });
                        return this._storageBins.create(storageBin);
                    }
                    return of(false);
                })
            );
    }

    protected override searchProperty: PropertySearcher<StorageArea, StorageAreaQuery> = (searchList, key, query) => {
        if (key === 'warehouseName') {
            // TODO: Make description searchable
            return searchList.filter(s => s.warehouseObj?.warehouseName.toLocaleLowerCase().includes(query.toLowerCase()));
        }
        return undefined;
    };

    private watchWarehouseWebsocket(): void {
        this._warehouseWebsocket.getNewNotification()
            .subscribe((w) => {
                this._api.search({warehouseId: w.warehouseId})
                    .subscribe((partialState) => this.updateState(partialState, 'create', false));
            });
    }
}
