import {Inject, Injectable} from '@angular/core';
import {ExportableConfig} from '../decorators/exportable.decorator';
import {FileType} from '../model/export-filetpyes.enum';
import {EXPORT_CLASS} from '../model/export.providers';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import dayjs from 'dayjs';
import {ExportOptions, FileSaveOptions} from '../model/export-options.model';
import {PlainObjectParser} from '../parsers/plain-object.parser';
import {HeaderLineParser} from '../parsers/header-line.parser';
import {ExportMap, FlattenedStringMap, IObjectParser} from '../parsers/object-parser.interface';
import {OneToManyParser} from '../parsers/one-to-many.parser';
import {ExportableReader} from '../readers/exportable-reader';
import {ColumnReader} from '../readers/column.reader';

function parserFactory(exportable: ExportableConfig, columns: ExportMap): IObjectParser<FlattenedStringMap | FlattenedStringMap[]> {
    if (exportable.writingProperty) {
        return new OneToManyParser(exportable, columns);
    }
    return new PlainObjectParser(columns);
}

/**
 * Service to export data of entities either as Excel sheet or as csv.
 * Reads the configuration of a class marked with @{@link Exportable} and
 * based on the as @{@link Export} marked properties it creates an Excel output
 * representing the collected data
 *
 *
 * @see Exportable
 * @see Export
 */
@Injectable({
    providedIn: 'root'
})
export class ExportService<T extends { [key: string]: any }> {


    // configuration of the exportable columns associated with the injected class
    exportColumns!: ExportMap;

    // configuration of an exportable class
    private _exportableClass!: ExportableConfig;

    private readonly _exportableReader: ExportableReader;
    private readonly _columnReader: ColumnReader;

    private readonly _headerParser: HeaderLineParser;
    private readonly _bodyParser: IObjectParser<FlattenedStringMap | FlattenedStringMap[]>;


    private readonly _dateTemplate = 'DD/MM/YYYY-HH:mm';

    /**
     * Creates an export service which is capable of exporting instances of the provided class when this class
     * is marked with @Exportable
     * @param {Function} _exportableClassDescriptor the class itself (handled as its constructor)
     */
    constructor(
        @Inject(EXPORT_CLASS) private _exportableClassDescriptor: Function
    ) {
        if (!this._exportableClassDescriptor) {
            throw new Error('No class was provided for export-module');
        }

        this._exportableReader = new ExportableReader(this._exportableClassDescriptor);

        this._columnReader = new ColumnReader(this._exportableClassDescriptor);


        this._readConfigurations();

        this._headerParser = new HeaderLineParser(this.exportColumns);

        this._bodyParser = parserFactory(this._exportableReader.getExportableConfig()!, this._columnReader.getColumns()!);
    }

    /**
     * @returns the view descriptive name of a class
     */
    get exportableClassName(): string {
        return this._exportableClass?.name;
    }

    exportFromHtmlTable(document: Document) {
        const book = XLSX.utils.book_new();

        const page = XLSX.utils.table_to_sheet(document, {cellStyles: true})

        XLSX.utils.book_append_sheet(book, page, this.exportableClassName);

        XLSX.writeFile(book, 'test.xlsx', {cellStyles: true});
    }

    /**
     * Exports all given entities of a class marked as @Exportable according to its defined columns
     * @param {T} values the entities to be exported
     * @param {FileType} fileType the member of the {@link FileType} enum which represents the wanted file type
     * @param {ExportOptions} options
     */
    exportToFile<X extends T>(values: T[], fileType: FileType, options?: ExportOptions<X>): void {
        const baseHeaders = this._headerParser.produceHeaderLine()

        const headers = this._headerParser.insertDestructedHeaders(baseHeaders, values);

        const fileBody = this._bodyParser.parseObjectsAsReadable(values);

        // basic file name with ~ as marker to replace for better readability
        let fileName = `${this.exportableClassName}_~${dayjs().format(this._dateTemplate).toString()}`;

        // finishing the file_name either with the export id or just removing the ~
        fileName = fileName.replace('~', options?.exportId ? `${options.exportId}_` : '');

        // storing file to users system
        this._saveFile(headers, fileBody, fileName, {fileType: fileType, fileName});
    }

    /**
     * Reads all configurations of the injected class
     * @private
     */
    private _readConfigurations(): void {
        if (!this._exportableReader.isExportable()) {
            throw new Error(`The provided class ${this._exportableClassDescriptor} is not marked as @Exportable`);
        }

        this._exportableClass = this._exportableReader.getExportableConfig()!;

        if (!this._columnReader.hasColumns() && !this._exportableClass.usesCustomExport) {
            throw new Error(`The provided class ${this._exportableClassDescriptor} has no properties marked with @Export`);
        }

        this.exportColumns = this._columnReader.getColumns()!;
    }


    /**
     * Stores the given data into a file of the given filetype with the name.
     * Currently, supports only excel and csv
     * @param headerLine the column headers of the file
     * @param body the data associated to these columns (key => column)
     * @param fileName the name fo the file to be stored
     * @param {FileSaveOptions} options options to configure the output of the file export
     * @private
     */
    private _saveFile(headerLine: string[], body: FlattenedStringMap[], fileName: string, options: FileSaveOptions): void {
        const book = XLSX.utils.book_new();

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(body, {
            header: headerLine,
            sheetStubs: true,
            cellStyles: true
        });

        if (options.fileType === FileType.XLSX) {
            XLSX.utils.book_append_sheet(book, ws, this.exportableClassName);
            XLSX.writeFile(book, fileName + options.fileType, {cellStyles: true});
            return;
        }

        const csvContent = XLSX.utils.sheet_to_csv(ws);
        const data: Blob = new Blob([csvContent]);
        saveAs(data, options.fileName + options.fileType);
    }
}
