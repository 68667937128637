import {BaseEntity} from "../base-entity.model";

export class Setting extends BaseEntity {
  constructor(
    public readonly settingId: string,
    public values: string[]
  ) {
    super();
  }
}
