<div [matTooltip]="'ui.transports.tooltips.priority.' + priority | translate"
     [ngClass]="{
            'very-high': priority === PriorityLevel.VeryHighPriority,
            'high': priority === PriorityLevel.HighPriority,
            'medium': priority === PriorityLevel.MediumPriority,
            'low': priority === PriorityLevel.LowPriority,
            'very-low': priority === PriorityLevel.VeryLowPriority,
            'none': priority === PriorityLevel.NoPriority,
            'unknown': priority > PriorityLevel.VeryHighPriority || priority < PriorityLevel.NoPriority
        }"
     class="priority"
></div>
