import {Component, forwardRef, Optional, Self} from '@angular/core';
import {Article} from '../../domain/core-model';
import {BaseFormFieldComponent} from '../base-form-field/base-form-field.component';
import {NgControl} from '@angular/forms';
import {CustomControl} from '../model/custom-control.model';
import {ArticleService} from '../../entity-state/article-service/article.service';

@Component({
    selector: 'app-article-form-field',
    templateUrl: '../base-form-field/base-form-field.component.html',
    styleUrls: ['../base-form-field/base-form-field.component.scss'],
    providers: [
        {provide: BaseFormFieldComponent, useExisting: forwardRef(() => ArticleFormFieldComponent)},
        {provide: CustomControl, useExisting: forwardRef(() => ArticleFormFieldComponent)}
    ],
})
export class ArticleFormFieldComponent extends BaseFormFieldComponent<Article> {
    constructor(
        @Optional() @Self() public override ngControl: NgControl,
    ) {
        super(
            {providerType: ArticleService},
            {defaultLabel: 'Artikel', key: 'articleId', displayKeys: ['articleId', 'description']},
            ngControl
        );
    }
}
