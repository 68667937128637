import {Export, Exportable} from 'src/app/export';
import {TransportPosition} from './transport-position.model';
import {BaseEntity} from '../base-entity.model';

export {MaterialRequest, MaterialRequestIdDto}

@Exportable({name: 'Materialnachforderung', writingProperty: 'transportPositions'})
class MaterialRequest extends BaseEntity {

    static buildFromEagerLoad(value: MaterialRequest): MaterialRequest {
        value.transportPositions?.map(TransportPosition.buildFromEagerLoad);
        return value;
    }

    constructor(
        @Export({link: TransportPosition, listValue: true, propertyKey: 'transportPositions'})
        public transportPositions: Array<TransportPosition> = new Array<TransportPosition>(),
        @Export({view: 'Materialnachforderung', propertyKey: 'materialRequestId', index: 0})
        public materialRequestId: number | null = null,
        public isChecked: boolean = false,
    ) {
        super();
    }
}

// this type can have all properties of a materialRequest but always has to have an ID
type MaterialRequestIdDto = Partial<MaterialRequest> & { materialRequestId: number | null };
