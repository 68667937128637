import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {GOODS_RECEPTION_ID, GOODS_RECEPTION_PREFIX, Warehouse} from '../core-model';
import {FormValue} from '../utility-types';
import {CustomValidators} from '../../form-fields';
import {StorageArea, StorageBin, StorageBinBuilder} from '../lvs-model';

export {
  StorageAreaForm,
  StorageAreaFormGroup,
  StorageBinCollectionForm,
  StorageBinCollectionFormGroup,
  GoodsReceptionForm,
  GoodsReceptionFormGroup,
}

class StorageAreaFormGroup extends FormGroup<StorageAreaForm> {
  constructor() {
    super(new StorageAreaForm());
  }

  override patchValue(value: Partial<StorageArea>, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.patchValue({...value, warehouse: value.warehouseId}, options);

    if (this.controls.warehouse.valid) {
      this.controls.warehouse.disable();
    }
  }

  buildStorageArea(includeStorageBins = false): StorageArea | null {
    if (this.invalid) {
      return null;
    }
    const formValue = this.getRawValue();
    const warehouseId = typeof formValue.warehouse === 'number' ? formValue.warehouse : formValue.warehouse?.warehouseId;

    let storageBins = new Array<StorageBin>();

    if (includeStorageBins) {
      storageBins = this.buildStorageBins();
    }


    return new StorageArea(formValue.storageAreaId, formValue.description, storageBins, warehouseId);
  }

  private buildStorageBins(): Array<StorageBin> {
    const storageAreaId = this.getRawValue().storageAreaId;
    const storageBinTemplates = this.getRawValue().storageBins;
    let storageBins = new Array<StorageBin>();
    // initial last cluster has had no shelves
    let lastClusterLength = 0;
    for (const storageBinCollection of storageBinTemplates) {
      let {shelfCount, levelCount, rowCount, isHighRack} = storageBinCollection;
      storageBins = storageBins.concat(StorageBinBuilder.generateStorageBins({
        levelCount,
        rowCount,
        shelfCount,
        isHighRack,
        storageAreaId,
        clusterIndex: lastClusterLength,
      }));

      // determining the amount of shelves created for the last cluster
      lastClusterLength += shelfCount;
    }

    return storageBins;
  }
}

class StorageAreaForm {

  warehouse = new FormControl<Warehouse | number | null>(null, {
    nonNullable: true,
    validators: [Validators.required, CustomValidators.objectSelectedValidator]
  });
  storageAreaId = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required, Validators.minLength(5)]
  });
  description = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required, Validators.minLength(7)]
  });

  storageBins = new FormArray<FormGroup<StorageBinCollectionForm>>([new FormGroup(new StorageBinCollectionForm())]);

  constructor(value?: FormValue<StorageAreaFormGroup> & { warehouseRequired?: boolean }) {
    if (value?.warehouse || value?.warehouseRequired) {
      this.warehouse.patchValue(value.warehouse ?? null);
    }
    if (value?.storageAreaId) {
      this.storageAreaId.patchValue(value.storageAreaId);
    }
    if (value?.description) {
      this.description.patchValue(value.description);
    }
    if (value?.storageBins) {
      for (const [index, binValue] of value.storageBins.entries()) {
        this.storageBins.setControl(index, new StorageBinCollectionFormGroup(binValue));
      }
    }
  }
}

class StorageBinCollectionFormGroup extends FormGroup<StorageBinCollectionForm> {
  constructor(value?: FormValue<StorageBinCollectionFormGroup>) {
    super(new StorageBinCollectionForm(value));
  }
}

class StorageBinCollectionForm {

  shelfCount = new FormControl<number>(1, {
    nonNullable: true,
    validators: [Validators.required, Validators.min(1)]
  });
  rowCount = new FormControl<number>(1, {
    nonNullable: true,
    validators: [Validators.required, Validators.min(1), Validators.max(99)]
  });
  levelCount = new FormControl<number>(1, {
    nonNullable: true,
    validators: [Validators.required, Validators.min(1), Validators.max(99)]
  });
  isHighRack = new FormControl<boolean>(false, {nonNullable: true, validators: [Validators.required]});

  constructor(value?: FormValue<StorageBinCollectionFormGroup>) {
    if (value?.shelfCount) {
      this.shelfCount.patchValue(value.shelfCount);
    }
    if (value?.rowCount) {
      this.rowCount.patchValue(value.rowCount);
    }

    if (value?.levelCount) {
      this.levelCount.patchValue(value.levelCount);
    }

    if (value?.isHighRack) {
      this.isHighRack.patchValue(value.isHighRack);
    }
  }

}

class GoodsReceptionFormGroup extends FormGroup<GoodsReceptionForm> {
  constructor(value?: FormValue<StorageAreaFormGroup>) {
    super(new GoodsReceptionForm(value));
  }
}

class GoodsReceptionForm extends StorageAreaForm {
  constructor(value?: FormValue<StorageAreaFormGroup>) {
    super(value);
    this.storageAreaId.setValue(GOODS_RECEPTION_ID);

    if (!this.description.value.includes(GOODS_RECEPTION_PREFIX)) {
      this.description.setValue(GOODS_RECEPTION_PREFIX);
    }

    this.storageAreaId.disable()
    this.description.disable();
  }
}
