import {StorageBin} from './storage-bin.model';

export {
  StorageBinLabelBuilder
}

const BARCODE_DATA_DELIMITER = "::"
const DATA_MATRIX_ENCODING = "datamatrix";
const BAR_CODE_ENCODING = "code128";

const ENCODINGS = {
  fullLocationLabelEncoding: DATA_MATRIX_ENCODING,
  storageBinOnlyLabelEncoding: BAR_CODE_ENCODING
};

function buildFullLocationLabel(storageBins: StorageBin): string;
function buildFullLocationLabel(...storageBins: StorageBin[]): string[] | string {
  const labelCollection = storageBins.map(bin => `${bin.storageBinId}${BARCODE_DATA_DELIMITER}${bin.storageAreaId}${BARCODE_DATA_DELIMITER}${bin.warehouseId}`);
  return storageBins.length === 1 ? labelCollection[0] : labelCollection;
}

function buildStorageBinOnlyLabel(storageBins: StorageBin): string;
function buildStorageBinOnlyLabel(...storageBins: StorageBin[]): string[] | string {
  const labelCollection = storageBins.map(bin => `${bin.storageBinId}`);
  return storageBins.length === 1 ? labelCollection[0] : labelCollection;
}

function addLabels(...storageBins: StorageBin[]): StorageBin[] {
  return storageBins.map(s => {
    return {...s, binOnlyLocationData: buildStorageBinOnlyLabel(s), fullLocationLabelData: buildFullLocationLabel(s)};
  });
}


function addBarcodeDataToStorageBins(storageBin: StorageBin): StorageBin;
function addBarcodeDataToStorageBins(storageBin: StorageBin[]): StorageBin[];
function addBarcodeDataToStorageBins(storageBins: StorageBin[] | StorageBin): StorageBin | StorageBin[] {
  let storageBinList = Array.isArray(storageBins) ? storageBins : new Array(storageBins);
  storageBinList = addLabels(...storageBinList);
  return storageBinList.length === 1 ? storageBinList[0] : storageBinList;
}

const StorageBinLabelBuilder = {
  BARCODE_DATA_DELIMITER,
  ENCODINGS,
  buildFullLocationLabel,
  buildStorageBinOnlyLabel,
  addBarcodeDataToStorageBins
};
