import {Component, ElementRef, Input, NgZone, OnInit} from '@angular/core';
import {BaseButtonDirective} from '../base-button/base-button.directive';

@Component({
    selector: 'app-barcode-download-button',
    templateUrl: './barcode-download-button.component.html',
    styleUrls: ['./barcode-download-button.component.scss']
})
export class BarcodeDownloadButtonComponent extends BaseButtonDirective implements OnInit {

    @Input() type: 'code128' | 'data-matrix' = 'data-matrix';

    constructor(_el: ElementRef, _zone: NgZone) {
        super(_el, _zone);
    }
}
