import {MatPaginatorIntl} from '@angular/material/paginator';

export function GermanPaginator() {
    // This function controls the labeling of MatPaginator for items per page
    // For more see angular-material.module.ts
    const germanPaginator = new MatPaginatorIntl();
    germanPaginator.itemsPerPageLabel = 'Elemente pro Seite:';
    return germanPaginator;
}

