import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, EventEmitter, Input} from '@angular/core';
import {TableButtonEvent} from '..';
import {MatMenuModule} from '@angular/material/menu';

@Component({
    selector: 'app-table-menu-item',
    templateUrl: './table-menu-item.component.html',
    styleUrls: ['./table-menu-item.component.scss'],
    imports: [
        MatMenuModule
    ],
    standalone: true
})
export class TableMenuItemComponent<T> {

    @Input() callbackFn: (event: TableButtonEvent<T>) => any = (ev) => {/**/
    };

    @Input()
    set extending(value: any) {
        this._extending = coerceBooleanProperty(value);
    }

    get extending(): boolean {
        return this._extending;
    }

    clicked: EventEmitter<MouseEvent> = new EventEmitter();

    private _extending = false;
}
