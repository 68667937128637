import {Component, ElementRef, HostBinding, Inject, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {ControlValueAccessor, NgControl, UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {FocusMonitor} from '@angular/cdk/a11y';
import {Subject} from 'rxjs';
import {toEntries} from '../../domain/functions';
import {InputTypeEnum} from '../../domain/enums';
import {IPropertyView} from '../../domain/interfaces';
import {MAT_FORM_FIELD, MatFormField, MatFormFieldAppearance, MatFormFieldControl} from '@angular/material/form-field';

type Layout = 'column' | 'row';

@Component({
    selector: 'app-array-input',
    templateUrl: './array-input.component.html',
    styleUrls: ['./array-input.component.scss'],
    providers: [
        {provide: MatFormFieldControl, useExisting: ArrayInputComponent},
    ]
})
export class ArrayInputComponent implements ControlValueAccessor, OnInit, OnDestroy {

    static nextId = 0;
    @HostBinding() id = `array-input-${ArrayInputComponent.nextId++}`;
    formArray: UntypedFormArray;
    stateChanges: Subject<void> = new Subject<void>();
    focused = false;
    touched = false;
    controlType = 'array-input';
    readonly autofilled: boolean = false;
    @Input('aria-describedby') userAriaDescribedBy = '';

    constructor(
        private _fb: UntypedFormBuilder,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl) {

        this.formArray = this._fb.array([]);
        this.formArray.push(this._fb.control(''));

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    @Input()
    get value(): string[] {
        return this.formArray.value ? this.formArray.value : null;
    }

    set value(values: string[] | null) {
        for (const [index, value] of toEntries(values ? values : [])) {
            if (this.formArray.at(index)) {
                this.formArray.at(index).setValue(value);
            } else {
                this.formArray.push(this._fb.control(value));
            }
        }
        this.stateChanges.next();
    }

    get errorState(): boolean {
        return this.formArray.invalid && this.touched;
    }

    get empty(): boolean {
        return !this.formArray.value;
    }

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    private _placeholder = '';

    @Input()
    get placeholder() {
        return this._placeholder;
    }

    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    private _required = false;

    @Input()
    get required() {
        return this._required;
    }

    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    private _disabled = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.formArray.disable() : this.formArray.enable();
        this.stateChanges.next();
    }

    private _maxAmount = 5;

    get maxAmount(): number {
        return this._maxAmount;
    }

    private _inputType: InputTypeEnum = InputTypeEnum.Text;

    get inputType(): InputTypeEnum {
        return this._inputType;
    }

    private _label = '';

    get label(): string {
        return this._label;
    }

    private _appearance: MatFormFieldAppearance = 'fill';

    @Input()
    get appearance(): MatFormFieldAppearance {
        return this._appearance;
    }

    set appearance(val: MatFormFieldAppearance) {
        this._appearance = val;
    }

    private _layout: Layout = 'row';

    get layout(): Layout {
        return this._layout;
    }

    @Input()
    set layout(val: Layout) {
        this._layout = val;
    }

    private _property!: IPropertyView;

    get property(): IPropertyView {
        return this._property;
    }

    @Input()
    set property(prop: IPropertyView) {
        this._property = prop;
        this.readConfig();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (_: any) => {
    };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {
    };

    getProperty() {
        return this._property;
    }

    ngOnInit(): void {
        if (this.property.input!.selectionInputType === InputTypeEnum.AsyncSelection) {
            this.formArray.valueChanges.subscribe(v => this._handleInput());
        }
        this.readConfig();
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const controlElement = this._elementRef.nativeElement
            .querySelector('.array-container')!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this._elementRef?.nativeElement?.querySelector('input')?.focus();
        }
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.touched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(values: string[]): void {
        this.value = values;
    }

    _handleInput(): void {
        this.onChange(this.value);
    }

    onAdd(): void {
        if (!this.maxAmount || this.formArray.length < this.maxAmount) {
            this.formArray.push(this._fb.control(''));
            this.stateChanges.next();
        }
    }

    onRemove(): void {
        if (this.formArray.length > 0) {
            this.formArray.removeAt(this.formArray.length - 1);
            this.stateChanges.next();
        }
    }

    private readConfig(): void {
        if (this._property.input?.maxArrayLength) {
            this._maxAmount = this._property.input!.maxArrayLength;
        }

        if (this._property.input?.selectionInputType) {
            this._inputType = this._property.input!.selectionInputType;
        }

        this._label = this._property.view;
    }
}
