import {Component, Input} from '@angular/core';
import {BaseButtonDirective} from '../base-button/base-button.directive';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'app-square-button',
  templateUrl: './square-button.component.html',
  styleUrls: ['./square-button.component.scss']
})
export class SquareButtonComponent extends BaseButtonDirective {

  @Input() color: ThemePalette = 'primary';
}
