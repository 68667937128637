import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {TransportTask} from '../model/transport-task.model';
import {TransportPositionService} from '../../../../entity-state/transport-position/transport-position.service';
import {TransportTaskQuery} from '../model/transport-task.query';

export function filterTransportTasksByInitiator$(
    transportPositionState: TransportPositionService,
    query: NonNullable<TransportTaskQuery['initiator']>,
    searchList: TransportTask[]
): Observable<TransportTask[]> {
    const transportPositionQuery = typeof query === 'number' ? {initiator: query} : {initiatorName: query};

    return transportPositionState.search(transportPositionQuery).pipe(
        map((initiators) => initiators.map(i => i.transportTaskId)),
        map((transportTaskIdsInInitiatorScope) =>
            (searchList.filter((t) => transportTaskIdsInInitiatorScope.includes(t.transportTaskId)))
        )
    );
}
