export {
    ButtonTexts
}

const defaultButtons = {
    save: `Speichern`,
    abort: `Abbrechen`,
    import: `Importieren`,
}




const ButtonTexts = {
    ...defaultButtons
};