import {Observable} from 'rxjs';
import {TransportTask} from '../model/transport-task.model';
import {map} from 'rxjs/operators';
import {TransportPositionService} from '../../../../entity-state/transport-position/transport-position.service';
import {TransportTaskQuery} from '../model/transport-task.query';

export function filterTransportTasksByHandlingUnit$(
    transportPositionState: TransportPositionService,
    query: NonNullable<TransportTaskQuery['handlingUnitId']>,
    searchList: TransportTask[]
): Observable<TransportTask[]> {
    return transportPositionState.search({handlingUnitId: query}).pipe(
        map((positions) => positions.map(p => p.transportTaskId)),
        map((taskIdsInHandlingUnitScope) =>
            (searchList.filter(t => taskIdsInHandlingUnitScope.includes(t.transportTaskId)))
        )
    );
}
