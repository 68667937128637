export {
    FileType,
    MIMEType,
    FileSelection
}

/**
 * File representations that maybe will be supported in the future
 */
enum FileType {
    CSV = '.csv',
    XLSX = '.xlsx',
    XML = '.xml',
    PDF = '.pdf'
}

/**
 * Possible mime types for exported files
 */
enum MIMEType {
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    CSV = 'text/csv',
    XML = 'application/xml',
    JSON = 'application/json',
    PDF = 'application/pdf'
}

/**
 * File representations currently supported by the ExportModule
 */
const FileSelection: { view: string, value: FileType }[] = [
    {
        view: 'csv',
        value: FileType.CSV
    },
    {
        view: 'Excel',
        value: FileType.XLSX
    }
];