import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

type Type = Function;

@Injectable({
    providedIn: 'root'
})
export class ConfigurationsApiService {

    private readonly _backendAddress = environment.coreUrl;

    constructor(
        private _http: HttpClient
    ) {
    }

    getAzureMapsSupscription(): Observable<string> {
        return this._http.get(`${this._backendAddress}/Configurations/GetAzureMapsSubscription`, {responseType: 'text'});
    }

    /**
     * Returs a dictionary according to the in {@link type} defined entitity holding all datatypes for the properties of the entity
     * @param {string} typeName the name of the type all properties shall be fetched for
     * @param {'core' | 'lvs' | 'tms'} module
     */
    getPropertyDictionary(typeName: string, module: 'core' | 'tms' | 'lvs' = 'core'): Observable<{
        key: string,
        value: string
    }[] | null> {
        const backendAddress = module !== 'core' ? module === 'tms' ? environment.tmsUrl : environment.lvsUrl : this._backendAddress;
        const requestUrl = `${backendAddress}/${typeName}/GetPropertyDictionary`;
        return this._http.get<{ key: string, value: string }[]>(requestUrl)
            .pipe(
                catchError((err) => of(null)),
                tap((dictionaryList) => dictionaryList?.forEach(entry => entry.key = entry.key[0].toLowerCase() + entry.key.slice(1)))
            );
    }
}
