export {
    ToolTips
}

const defaultToolTips = {
    edit: `Bearbeiten`,
    delete: `Löschen`,
    abort: `Abbrechen`,
    restore: `Wiederherstellen`
}

const importTips = {
    toggleImportable: `Als importierbar makieren`
}



const ToolTips = {
    ...defaultToolTips,
    importTips
};