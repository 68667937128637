<div class="centered" [class.welcome]="!(isAuthenticated | async)">
  <div class="navigation-helper" *ngIf="isAuthenticated | async; else welcomePage" data-cy="dashboard-navigation">
    <app-navigation-card *ngFor="let parentRoute of routes" [route]="parentRoute"></app-navigation-card>
  </div>

  <ng-template #welcomePage>
    <div class="welcome-header row justify-center">
      <div class="column justify-center center-alignment">
        <h2>Willkommen</h2>
        <button mat-raised-button color="primary" routerLink="login">Weiter zur Anmeldung</button>
      </div>
    </div>
  </ng-template>
</div>
