import {BaseEntity} from '../../domain';
import {connect, firstValueFrom, merge, Observable, of, ReplaySubject} from 'rxjs';
import {EntityKey} from '../../domain/utility-types';
import {KeyBuilder} from './key-builder';
import {debounceTime, map, takeUntil} from 'rxjs/operators';

export {
    IStateLoader,
    StateStorage
}

interface IStateLoader<T> {
    get refreshFn(): Observable<T[]>;

    get prefetchFn(): Observable<T[]> | undefined;
}

type PrefetchResult<T> = {
    pre: T[] | null
};
type FullFetchResult<T> = {
    loaded: T[]
};

class StateStorage<T extends BaseEntity> {

    private readonly _stateDebounceTime = 300;

    private _stateSubject = new ReplaySubject<T[]>(1);

    private _prefetchDone = new ReplaySubject<void>(1);
    private _fullyInitialized = new ReplaySubject<void>(1);

    constructor(
        private readonly _loader: IStateLoader<T>,
        private readonly _keyBuilder: KeyBuilder<T>
    ) {
        this._init();
    }

    get state(): Observable<T[]> {
        return this._prefetchDone.pipe(
            connect(() => this._stateSubject.asObservable()
                .pipe(
                    debounceTime(this._stateDebounceTime)
                )),
        );
    }

    private get prefetchFn(): Observable<PrefetchResult<T>> {
        const preFetch = this._loader.prefetchFn;

        if (!preFetch) {
            return of({pre: null});
        }

        return preFetch.pipe(
            map((v: T[] | null) => {
                return {pre: v}
            })
        );
    }

    private get refreshFn(): Observable<FullFetchResult<T>> {
        return this._loader.refreshFn.pipe(
            map((v) => {
                return {loaded: v}
            })
        );
    }

    refresh() {
        this.refreshFn.subscribe((s) => {
            this._stateSubject.next(s.loaded)
        });
    }

    get(entityKey: EntityKey<T>) {
        return this.state
            .pipe(
                map((s) => s.find(e => this._keyBuilder.isSameKey(e, entityKey as EntityKey<T>)))
            );
    }

    async remove(value: T | EntityKey<T> | T[] | EntityKey<T>[] | (EntityKey<T> | T)[]): Promise<void> {
        const current = await firstValueFrom(this._stateSubject);

        const entityList = Array.isArray(value) ? value : [value];

        for (const entity of entityList) {
            const index = current.findIndex(e => this._keyBuilder.isSameKey(e, entity));

            // only entities in state can be removed;
            if (index < 0) {
                continue;
            }

            current.splice(index, 1);
        }

        this._stateSubject.next(current);
    }


    async add(value: T | T[]): Promise<void> {
        const current = await firstValueFrom(this._stateSubject);

        const entityList = Array.isArray(value) ? value : [value];

        const allEntitiesToAdd = entityList.filter(nextEntity => {
            const index = current.findIndex(e => this._keyBuilder.isSameKey(nextEntity, e));

            // only entities not in state get added
            return index === -1;
        });

        if (allEntitiesToAdd.length === 0) {
            return
        }

        this._stateSubject.next([...allEntitiesToAdd, ...current]);
    }

    async replace(value: T | T[]): Promise<void> {
        let current = await firstValueFrom(this._stateSubject);

        const entityList = Array.isArray(value) ? value : [value];

        for (const entity of entityList) {
            const index = current.findIndex(e => this._keyBuilder.isSameKey(e, entity));

            // skip entities not in state
            if (index === -1) {
                continue;
            }

            current = [...current.slice(0, index), entity, ...current.slice(index + 1)];
        }

        this._stateSubject.next(current);
    }

    close() {
        this._prefetchDone.complete();
        this._fullyInitialized.complete();
        this._stateSubject.complete();
    }

    private _init() {
        merge(this.prefetchFn, this.refreshFn)
            .pipe(takeUntil(this._fullyInitialized))
            .subscribe((nextState) => {
                const isPrefetch = 'pre' in nextState;

                const nextDataSet = isPrefetch ? nextState.pre : nextState.loaded;

                if (!nextDataSet) {
                    return;
                }

                this._publishInitialChanges(nextDataSet, !isPrefetch);
            });

    }

    private _publishInitialChanges(changes: T[], isFullState: boolean) {
        this._stateSubject.next(changes);

        this._prefetchDone.next();

        if (!isFullState) {
            return;
        }

        this._fullyInitialized.next();
    }
}

