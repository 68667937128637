export {
    Languages,
    TRANSLATION_MODULES
}

enum Languages {
    deDE = 'de-DE',
    enUS = 'en-US'
}

const TRANSLATION_MODULES: string[] = [
    'ics-core',
    'ics-authentication',
    'ics-theme',
    'article-groups',
    'articles',
    'external-api',
    'handling-units',
    'initiator',
    'inventory',
    'lock',
    'material-requests',
    'routing',
    'storage-areas',
    'storage-bins',
    'super-tasks',
    'transport-positions',
    'transport-tasks',
    'user-feedback',
    'warehouses',
    'system-logs',
    'ics-cdk-messenger',
    'ics-ng-messenger'
];
