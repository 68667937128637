import {HttpErrorResponse} from '@angular/common/http';

export {
  IHttpResult,
  ServerResponse
};

class IHttpResult<T = any> {

  isError: boolean;
  err: HttpErrorResponse | string | null = null;
  responseValue: T | null;
  statusCode: number | null;
  index?: number | null;

  constructor(
    isError: boolean = false,
    err: HttpErrorResponse | string | null = null,
    responseValue?: T | null,
    statusCode?: number,
    index?: number,
  ) {
    this.isError = isError;
    this.err = err;
    this.responseValue = responseValue ?? null;
    this.statusCode = statusCode ?? null;
    this.index = index;
  }
}

class ServerResponse<T> {
  constructor(
    public asyncState: any | null = null,
    public creationOptions: number = 0,
    public id: number,
    public isCanceled: boolean = false,
    public isCompletedSuccessFully: boolean,
    public isFaulted: boolean,
    public result: T,
    public status: number
  ) {
  }
}
