import {Pipe, PipeTransform} from '@angular/core';
import {Article, ArticleId} from '../../../../../domain/core-model';
import {Observable, startWith} from 'rxjs';
import {ArticleService} from '../../../../../entity-state/article-service/article.service';
import {map} from 'rxjs/operators';

@Pipe({
    name: 'article',
    standalone: true
})
export class ArticlePipe implements PipeTransform {

    constructor(
        private _articles: ArticleService
    ) {
    }

    transform<X extends keyof Omit<Article, 'articleId'>>(articleId: ArticleId, key: X): Observable<Article[X] | '--' | null> {
        return this._articles.getById({articleId}, true).pipe(
            startWith(null),
            map(article => {
                if (!article) {
                    return '--';
                }

                return article[key] ?? '--';
            })
        );

    }

}
