import {NgModule} from "@angular/core";
import {SharedModule} from "../shared";
import {ArrayInputComponent} from "./array-input/array-input.component";
import {AsyncSelectComponent} from "./async-select/async-select.component";
import {DateInputComponent} from "./date-input/date-input.component";
import {GenericDialogComponent} from "./generic-dialog/generic-dialog.component";
import {GenericFormComponent} from "./generic-form/generic-form.component";


@NgModule({
    declarations: [
        ArrayInputComponent,
        AsyncSelectComponent,
        DateInputComponent,
        GenericDialogComponent,
        GenericFormComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        ArrayInputComponent,
        AsyncSelectComponent,
        DateInputComponent,
        GenericDialogComponent,
        GenericFormComponent
    ]
})
export class GenericComponentsModule {
}
