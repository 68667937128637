export {ApiEndpoints, API_ENDPOINTS};

/**
 * Collects all commonly supported api-endpoints.
 * Can be used with boolean to configure active and inactive endpoints
 */
interface ApiEndpoints<T = string | boolean> {
  readonly get: T;
  readonly getPage: T;
  readonly getById: T;
  readonly search: T;
  readonly createNewEntity: T;
  readonly createNewEntities: T;
  readonly updateEntity: T;
  readonly updateEntities: T;
  readonly deleteEntity: T;
  readonly deleteEntities: T;


  readonly getEager: T;
  readonly searchEager: T;
  readonly searchNested: T;
  readonly getPropertyDictionary: T;
  readonly lock: T;
  readonly releaseLock: T;
}

/**
 * Names of all endpoints
 */
const API_ENDPOINTS: ApiEndpoints<string> = {
  createNewEntities: 'CreateNewEntities',
  deleteEntities: 'DeleteEntities',
  updateEntities: 'UpdateEntities',
  get: 'Get',
  getPage: 'GetPage',
  getById: 'GetById',
  search: 'Search',
  createNewEntity: 'CreateNewEntity',
  updateEntity: 'updateEntity',
  deleteEntity: 'DeleteEntity',

  getEager: 'GetEager',
  searchEager: 'SearchEager',
  searchNested: 'SearchNested',
  getPropertyDictionary: 'GetPropertyDictionary',

  lock: 'Lock',
  releaseLock: 'ReleaseLock',
};
