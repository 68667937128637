import {Article} from '../core-model';
import {ArticleGroup} from '../tms-model';
import {BaseEntity} from '../base-entity.model';

export {StorageBinArticle};

class StorageBinArticle extends BaseEntity {
  static buildFromEagerLoad(storageBinArticle: StorageBinArticle): StorageBinArticle {
    storageBinArticle.articleGroupName = storageBinArticle?.articleGroupObj?.name;
    storageBinArticle.articleDescription = storageBinArticle?.articleObj?.description ?? undefined;
    storageBinArticle.articleAdditionalFields = storageBinArticle?.articleObj?.additionalFields;
    return storageBinArticle;
  }

  public articleGroupId?: number;

  // for displaying
  public articleGroupName?: string;
  public articleDescription?: string;
  public articleAdditionalFields?: {[key: string]: string};

  // for eager loading endpoint
  public articleObj?: Article;
  public articleGroupObj?: ArticleGroup;

  constructor(
    public articleId: string,
    public articleCount: number,
    public storageBinId: string,
    public warehouseId: number,
    public storageAreaId: string
  ) {
    super();
  }
}
