import {Injectable} from '@angular/core';
import {Warehouse} from '../../../domain/core-model';
import {ContainerApiService} from '../../../api/container-api/container-api.service';
import {StateService} from '../../model/state/state.service';
import {StorageArea, StorageBin} from '../../../domain/lvs-model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { IHttpResult } from 'src/app/api/interfaces/http-result.interface';

@Injectable({
    providedIn: 'root'
})
export class ContainerService extends StateService<Warehouse> {

    constructor(
        protected override _api: ContainerApiService,
    ) {
        super(
            {
                api: ContainerApiService,
                type: Warehouse,
                primaryKeys: ['warehouseId'],
                webSocket: 'ContainerSignalRService'
            },
            {eagerLoading: false, prefetching: true}
        );
    }

    createFullContainer(fullContainer: {
        warehouse: Warehouse,
        storageAreas: StorageArea[],
        storageBins: StorageBin[]
    }): Observable<IHttpResult<Warehouse>> {
        return this._api.createFull(fullContainer)
    }
}
