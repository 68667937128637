<app-page>
    <h2 *ngIf="!dialogRef">
        {{'ui.logs.title.system.logs' | translate}}
    </h2>

    <h2 mat-dialog-title *ngIf="dialogRef">
        {{'ui.logs.title.local.logs' | translate}}
    </h2>


    <app-log-filter *ngIf="filtersActive" (filter)="searchLogs($event)"></app-log-filter>

    <div *ngIf="logs" id="log-window">
        <app-animated-ellipsis *ngIf="loading">
            <span>{{'ui.logs.pending' | translate}}</span>
        </app-animated-ellipsis>
        <p *ngIf="(!logs || logs?.length <= 0) && !loading">Keine Logs gefunden...</p>
        <cdk-virtual-scroll-viewport itemSize="10" class="terminal-viewport">
            <p *cdkVirtualFor="let log of logs" class="log-line">
                <span>>></span>
                <span>&nbsp;[{{log.createdAt |localizedDate}}]&nbsp;</span>
                <app-log-level [logLevel]="log?.logLevel"></app-log-level>
                <span>::&nbsp;{{ log?.logType }}&nbsp;::&nbsp;</span>
                <span>::&nbsp;{{ log?.source }}&nbsp;::&nbsp;</span>
                <span>&nbsp;{{ log?.content }}&nbsp;</span>
            </p>
        </cdk-virtual-scroll-viewport>
    </div>
</app-page>


