<h2 mat-dialog-title>{{ title }} {{ action }}</h2>

<mat-dialog-content class="content">
  <app-generic-form [properties]="properties" [entity]="entity" [purpose]="purpose" [appearance]="'fill'" [layout]="'column'" (value)="onSubmit($event)" #form></app-generic-form>
</mat-dialog-content>

<mat-dialog-actions *ngIf="purpose !== 'display'" align="end">
  <button mat-raised-button color="primary" (click)="form.submitForm()" [disabled]="!(form.valid | async)">Speichern</button>
  <button mat-raised-button color="warn" (click)="onAbort()">Abbrechen</button>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="purpose === 'display'" align="end">
  <button mat-raised-button color="warn" (click)="close()">Schließen</button>
</mat-dialog-actions>
