<mat-card>
    <mat-card-header>
        <mat-card-title>{{'ui.routing.' + route.id | translate}}</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content>
        <mat-nav-list>

            <mat-list-item *ngFor="let childRoute of route.children" [routerLink]="childRoute.link">

                <app-navigation-label #itemLabelRef [route]="childRoute" inset matListItemTitle></app-navigation-label>


                <div matListItemMeta>
                    <ng-container *ngIf="itemLabelRef.incompleteEntityCount$|async as count; else loadingTemplateRef">
                        <app-broken-imports-button *ngIf="count > 0"
                                                   [route]="childRoute.link!"></app-broken-imports-button>
                    </ng-container>

                    <ng-template #loadingTemplateRef>
                        <app-small-loading-shade *ngIf="itemLabelRef.hasCount"></app-small-loading-shade>
                    </ng-template>
                </div>


            </mat-list-item>

        </mat-nav-list>

    </mat-card-content>

</mat-card>
