import {Component, OnInit} from '@angular/core';
import {IcsIdentityAuthenticationService} from 'ics-identity-authentication';
import {Observable} from 'rxjs';
import {APP_ROUTES} from "../../app.routes";
import {NavigationCardComponent} from "../../navigation";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [
    NavigationCardComponent,
    AsyncPipe,
    NgForOf,
    MatButtonModule,
    RouterLink,
    NgIf

  ]
})
export class DashboardComponent implements OnInit {

  isAuthenticated: Observable<boolean> | undefined;
  routes = APP_ROUTES;


  constructor(
    private _authentication: IcsIdentityAuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.isAuthenticated = this._authentication.isAuthenticated;
  }

}
