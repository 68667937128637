import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Article, HandlingUnit, HandlingUnitArticle} from '../core-model';
import {CustomValidators} from '../../form-fields';

export {
    HandlingUnitArticleFormGroup
}


class HandlingUnitArticleFormGroup extends FormGroup<HandlingUnitArticleForm> {
    constructor(handlingUnit: HandlingUnit) {
        super(new HandlingUnitArticleForm(handlingUnit));
    }

    override patchValue(value: Partial<HandlingUnitArticle>, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
        const patcher = {
            articleObj: value.articleId!,
            handlingUnitId: value.handlingUnitId!,
            articleCount: value.articleCount!
        };
        super.patchValue(patcher as any, options);
    }

    createHandlingUnitArticle(): HandlingUnitArticle | null {
        if (this.invalid) {
            return null;
        }
        const formValue = this.getRawValue();
        return new HandlingUnitArticle(
            formValue.articleObj!.articleId,
            formValue.articleCount,
            formValue.handlingUnitId
        );
    }
}

class HandlingUnitArticleForm {

    handlingUnitId = new FormControl<string>({value: '', disabled: true}, {
        nonNullable: true,
        validators: [Validators.required]
    });

    articleObj = new FormControl<Article | null>(null, {
        nonNullable: true,
        validators: [Validators.required, CustomValidators.objectSelectedValidator]
    });

    articleCount = new FormControl<number>(1, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(0.1)]
    });

    constructor(handlingUnit: HandlingUnit) {
        this.handlingUnitId.patchValue(handlingUnit.handlingUnitId);
    }


}
