import 'reflect-metadata';
import {NamedColumns} from '../model/column-definitions-provider.interface';

const COLUMN_DECORATOR_KEY = Symbol('@Column');

type ParsableDataType = string | number | boolean | Date | 'internal';

type ParsableDataTypeRepresentation = 'string' | 'number' | 'boolean' | 'date' | 'internal' | 'object';

type PropertyValues = { view: string, value: any }[];

// eslint-disable-next-line @typescript-eslint/ban-types
type Class = Function;

/**
 * Configuration for a property of an {@link Importable} Class.
 * Configures how the name of the Property is shown to the user and
 * which datatype is expected to be imported.
 *
 *
 * @see Importable
 */
interface Column {
    /**
     * Name of the property displayed to the user
     */
    view?: string;

    /**
     * Datatype to be checked by import
     */
    dataType: ParsableDataTypeRepresentation;

    /**
     * Whether he imported value has to be a list or not
     */
    listValue?: boolean;

    /**
     * Whether missing of the property is marking the import as failed or not
     */
    required?: boolean;

    /**
     * Name of the property. Has to be provided when using constructor-parameters
     */
    propertyKey?: string;

    /**
     * Defines the map/enum that holds all possible values for this property
     */
    propertyValues?: PropertyValues;

    /**
     * Defines that the marked property has to be restructered from the imported object into its own object
     * the basic assumption is that all leaving properties that are not provided shall be imported into
     * the restructuring object,
     *
     * use dataType: 'object' for properties of this type
     */
    restructure?: boolean;
}

/**
 * Checks if the given Target is already the prototyp of a Class or is still the constructor
 * @internal
 * @param {Function | any} target is a Function in case of the constructor else expected to be the Prototype
 * @returns the protoype of a class
 */
function getMetaTarget(target: Class| any): any {
    return typeof target === 'function' ? target.prototype : target;
}


/**
 * Marks an Class-Propery as Column of an {@link Importable} class.
 * This column will be interpreted as one column of an excel or csv sheet and the imported value of the property
 * will be checked against the given config.
 *
 *
 * @decorator
 * @param {Column} config
 * @returns {ParameterDecorator | PropertyDecorator}
 */
function importColumn(config: Column) {
    return function (target: Class | any, propertyKey: string | symbol, parameterIndex?: number) {
        const metaTarget = getMetaTarget(target);
        const configName = propertyKey ?? config?.propertyKey;

        if (!config?.propertyKey) {
            config.propertyKey = configName as string;
        }

        if (!configName) {
            throw new Error('No propertyName was Provided for @Column decorator. Check for propertyName in config of constructor-parameter');
        }

        if (config?.dataType === 'internal' && !config?.propertyValues) {
            throw new Error(`No values where provided fpr internal value of property ${config.propertyKey} for ${target?.name}`);
        }

        if (!config.view && !config.restructure) {
            throw new Error(`The property ${String(configName)} of the class ${target?.name} has neither a view attribute nor is it marked as restructurable`);
        }

        const newConfig = { [configName]: config };
        const existingConfig = metaTarget[COLUMN_DECORATOR_KEY];

        if (existingConfig) {
            metaTarget[COLUMN_DECORATOR_KEY] = { ...existingConfig, ...newConfig };
        } else {
            metaTarget[COLUMN_DECORATOR_KEY] = { ...newConfig };
        }
    }
}


/**
 * Checks if the given class has importable columns
 *
 * @external
 * @param {Function | any} target Function in case of constructor else prototype
 * @returns {boolean} wheter columns was found or not
 */
function hasColumns(target: Class | any): boolean {
    const metaTarget = getMetaTarget(target);
    return !!(metaTarget?.[COLUMN_DECORATOR_KEY]);
}

/**
 * Returns the importable columns of a class. If a class has no
 * importable colums it returns null.
 *
 * @external
 * @param {Function | any} target Function in case of constructor else prototype
 * @returns {Column | null}
 */
function getColumns(target: Class | any): { [key: string]: Column } | null {
    const metaTarget = getMetaTarget(target);
    return metaTarget?.[COLUMN_DECORATOR_KEY] ?? null;
}

/**
 * Checks whether the given class has the given importable column.
 *
 * @external
 * @param {string} columnName the name of the searched column
 * @param {Function | any} target Function in case of constructor else prototype
 * @returns {boolean} wheter the searched column was found or not
 */
function getColumn(target: Class | any, columnName: string) {
    const metaTarget = getMetaTarget(target);
    return metaTarget?.[COLUMN_DECORATOR_KEY]?.[columnName] ?? null;
}

/**
 * Returns the importable column with the given name from the given class.
 * If there is no importable columns with this name it returns null.
 *
 * @external
 * @param {string} columnName name of the column the configuration of is searched
 * @param {Function | any} target Function in case of constructor else prototype
 * @returns {Column | null}
 */
function hasColumn(target: Class | any, columnName: string): boolean {
    const metaTarget = getMetaTarget(target);
    return !!(metaTarget?.[COLUMN_DECORATOR_KEY]?.[columnName]);
}

function getColumnNames(target: Class | any): NamedColumns {
    const metaTarget = getMetaTarget(target);
    const columns = getColumns(metaTarget);
    const namedColumns: NamedColumns = {};
    if (columns) {
      Object.keys(columns).forEach(k => namedColumns[k] = columns[k].view!);
    }
    return namedColumns;
}

// adding context to the reading functions
const ColumnReader = {
    getColumnNames,
    hasColumn,
    hasColumns,
    getColumn,
    getColumns
};


export {
    importColumn as Import,
    Column as ColumnConfig,
    ParsableDataType,
    PropertyValues,
    ColumnReader
};
