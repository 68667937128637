import {Component, Host, Input, OnInit, Optional} from '@angular/core';
import {AbstractControl, FormGroupDirective} from '@angular/forms';

@Component({
    selector: 'app-form-errors',
    templateUrl: './form-errors.component.html',
    styleUrls: ['./form-errors.component.scss']
})
export class FormErrorsComponent implements OnInit {

    @Input() control?: AbstractControl<any, any> | null;

    @Input() controlName?: string;

    @Input() entityName?: string;

    constructor(
        @Host() @Optional() private _parentForm?: FormGroupDirective,
    ) {
    }

    ngOnInit() {
        if (!this.control) {
            if (this.controlName && this._parentForm) {
                const control = this._parentForm.form.get(this.controlName);
                if (control) {
                    this.control = control;
                }
            }
        }
    }
}
