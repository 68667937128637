<div class="row justify-center">
  <h2 mat-dialog-title [ngStyle]="{ color: color }">{{ title }}</h2>
</div>


<mat-dialog-content *ngIf="!!message" class="column center-alignment">
  <ng-container *ngIf="messageLines; else plain">
    <p *ngFor="let line of messageLines">{{line}}</p>
  </ng-container>

  <ng-template #plain>
    <p [innerHTML]="message"></p>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="center" *ngIf="submitButton; else defaultButtons">
  <button mat-raised-button color="warn" (click)="onConfirm()">{{ submitButton }}</button>
  <button mat-raised-button color="primary"
          (click)="closeDialog()">{{ abortButton ? abortButton : "Abbrechen" }}</button>
</mat-dialog-actions>

<ng-template #defaultButtons>
  <mat-dialog-actions align="center">
    <button mat-raised-button color="warn"
            (click)="onConfirm()">{{ title === "Warnung" ? "Weiter" : "Bestätigen" }}</button>
    <button mat-raised-button color="primary" mat-dialog-close>Abbrechen</button>
  </mat-dialog-actions>
</ng-template>
