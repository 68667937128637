import {Injectable} from '@angular/core';
import {Vehicletype} from "../../domain/core-model";
import {StateService} from '../model/state/state.service';


@Injectable({
    providedIn: 'root'
})
export class VehicletypeService extends StateService<Vehicletype> {

    constructor() {
        super({webSocket: 'VehicleTypeSignalRService', type: Vehicletype, primaryKeys: ['vehicletypeId']});
    }
}
