import {StateService} from '../model/state/state.service';
import {Injectable} from '@angular/core';
import {
    ArticleGroup,
    ImportedTransportPosition,
    TransportGroup,
    TransportGroups,
    TransportPosition,
    TransportPositionObjectQuery,
    TransportPositionState
} from '../../domain/tms-model';
import {ArticleGroupService} from '../article-group-service/article-group.service';
import {DateTimeService} from '../../core';
import {ArticleGroupApiService} from '../../api/article-group-api/article-group-api.service';
import {SuperTaskApiService} from '../../api/super-task/super-task-api.service';
import {SignalRMaterialRequestService} from '../../websocket';
import {WarehouseService} from '../warehouse/warehouse-service/warehouse.service';
import {TransportPositionApiService} from '../../api/transport-position-api/transport-position-api.service';
import {combineLatest, concat, noop, Observable, of, switchMap, take} from 'rxjs';
import {DeepPartial, PropertySearcher} from '../../search/model';
import {searchAnd} from '../../search/operators';
import {map, mergeMap, takeWhile, tap, toArray} from 'rxjs/operators';
import {isEmpty} from '../../domain/functions';
import {EntityKey} from '../../domain/utility-types';
import {IHttpResult} from '../../api/interfaces/http-result.interface';
import {IcsUserFeedbackService} from 'ics-core';
import {TransportTaskId} from '../../modules/tms/transport-task';


@Injectable({
    providedIn: 'root',
})
export class TransportPositionService extends StateService<TransportPosition, TransportPositionObjectQuery> {

    constructor(
        private _articleGroups: ArticleGroupService,
        private _dateTime: DateTimeService,
        private _articleGroupApi: ArticleGroupApiService,
        private _superTaskApi: SuperTaskApiService,
        private _materialRequestSocket: SignalRMaterialRequestService,
        private _warehouses: WarehouseService,
        private _userFeedback: IcsUserFeedbackService,
        protected override _api: TransportPositionApiService
    ) {
        super(
            {
                type: TransportPosition,
                primaryKeys: ['transportPositionId'],
                webSocket: 'TransportPositionSignalRService',
                api: _api
            }, {
                eagerLoading: true,
                prefetching: true
            });
        this.watchMaterialRequestCreation();
    }

    override get prefetchFn(): Observable<TransportPosition[]> {
        return this._api.getExceptStatusEager(TransportPositionState.Finished);
    }

    public getGrouped(query: [DeepPartial<TransportPositionObjectQuery>, string]): Observable<TransportGroup[]> {
        let [objQuery, stringQuery] = query;

        return this.state.pipe(
            searchAnd(stringQuery),
            searchAnd<TransportPosition, DeepPartial<TransportPositionObjectQuery>>(objQuery, this.searchProperty),
            map((s) => new TransportGroups(s)),
            map(s => {
                if (isEmpty(objQuery)) {
                    return s;
                }
                return s.filter(t => t.positions.every(p => p.transportState !== TransportPositionState.ToBeChecked));
            }),
            map((groups) => groups.sort((g: TransportGroup) => !!g.summary.articleGroupId ? -1 : 1)),
        );

    }

    override update(...entities: TransportPosition[]): Observable<boolean> {

        if (entities.some((tp) => {
            return !tp.articleSourceId && tp.transportState > TransportPositionState.ToBeChecked
        })) {
            return this._userFeedback.show({
                type: 'error',
                message: 'ui.material.requests.tooltips.check.button.disabled'
            }).pipe(map(() => {
                return false
            }));
        }

        return super.update(...entities.map(TransportPosition.removeEagerLoadedObjects));
    }

    public override delete(transportPositions: EntityKey<TransportPosition>): void;

    public override delete(transportPositions: TransportPosition[]): Observable<boolean>;

    public override delete(transportPositions: EntityKey<TransportPosition>[]): Observable<boolean>;

    public override delete(transportPositions: TransportPosition[] | EntityKey<TransportPosition>[] | EntityKey<TransportPosition>): void | Observable<boolean> {
        if (Array.isArray(transportPositions)) {
            return this._confirm.askForConfirmation({
                title: 'ui.transports.confirmations.delete.multiple.title',
                message: 'ui.transports.confirmations.delete.multiple.message',
            }).pipe(
                switchMap(() => this._api.deleteCollected(transportPositions)),
                toArray(),
                map((res: boolean[]) => res.includes(false))
            )
        }

        return super.delete(transportPositions);
    }

    /**
     * Access-point for re-grouping positions. Opens a dialog to the user to decide about the new
     * grouping of transport-positions.
     * Starts internal regrouping after user confirmed new grouping
     * @param positions
     * @param articleGroup
     */
    reGroupPositions(positions: TransportPosition[], articleGroup: ArticleGroup | null): Observable<boolean> {
        const calls: Observable<any>[] = [];
        if (articleGroup && !articleGroup?.articleGroupId) {
            calls.push(this._articleGroups.createAndGet(articleGroup)
                .pipe(
                    tap((a) => positions.forEach(p => p.articleGroupId = a?.articleGroupId))
                ));
        }
        calls.push(this.update(...positions));
        return concat(...calls).pipe(
            map(res => !!res),
            toArray(),
            map(res => res.includes(false))
        );
    }

    /**
     * Imports a given et of transport-positions into the system. Checks if date-time is handled correctly.
     * In case article-groups are given it checks which of them need to be created in first place and creates them.
     * Same is for the creation of super-tasks
     * @param {TransportPosition[]} positionsToImport
     * @returns {Observable<IHttpResult<TransportPosition> | null>} returns null if the user says no to the import of article-groups
     * else the concatenation of all import-http-calls
     */
    public importTransportPositions(positionsToImport: TransportPosition[]): Observable<IHttpResult<TransportPosition> | null> {
        // cleaning up data for not messing up http-req
        const importData = positionsToImport.map(tp => {
            if (tp.superTaskId && !tp.superTaskDestination) {
                // tasks without super-destination cannot be created
                delete tp.superTaskId;
            }
            // dates always shit
            tp.toBeDoneBy = tp.toBeDoneBy ? this._dateTime.toServerTimeUTC(tp.toBeDoneBy) as string : undefined;
            return tp;
        });

        const newGroups = importData.filter(p => !p?.articleGroupId && p?.articleGroupName);
        const newSubTasks = importData.filter(p => isNaN(Number(p.superTaskId)) || (p?.superTaskId ?? -1) < 0 || p.superTaskDestination);
        if (newGroups.length > 0 || newSubTasks.length > 0) {
            return this.informUserAboutNewDataCreated(importData, newGroups, newSubTasks);
        } else {
            return this._api.importEntities(positionsToImport);
        }
    }

    fillMissingInternalData(data: ImportedTransportPosition[]) {
        return combineLatest([
            this._articleGroups.state,
            this._warehouses.state
        ]).pipe(
            take(1),
            map(([articleGroups, warehouses]) => {
                return data.map(p => {
                    let articleGroupObj: ArticleGroup | null = null;
                    if (p.articleGroupName) {
                        articleGroupObj = articleGroups.find(a => a.name.trim() === p.articleGroupName!.trim()) ?? new ArticleGroup(p.articleGroupName!);
                    }
                    const articleSourceId = warehouses.find(w => w.warehouseName.trim() === String(p.articleSourceId).trim())?.warehouseId! ?? null;
                    const destination = warehouses.find(w => w.warehouseName.trim() === String(p.destination).trim())?.warehouseId! ?? null;

                    return {
                        ...p,
                        articleSourceId,
                        destination,
                        articleGroupObj,
                        articleGroupId: articleGroupObj?.articleGroupId
                    };
                });
            })
        );
    }

    getTaskPositions$(transportTaskId: TransportTaskId, query?: TransportPositionObjectQuery) {
        const source$ = query ? this.search(query) : this.state;


        return source$.pipe(
            map(positions => positions.filter(p => p.transportTaskId === transportTaskId))
        );
    }

    protected override searchProperty: PropertySearcher<TransportPosition, TransportPositionObjectQuery> = (searchList, key, query) => {
        if (key === 'transportState' && Array.isArray(query)) {
            return searchList.filter(p => query.includes(p.transportState));
        }

        if (key === 'articleSourceName') {
            return searchList.filter(p => p.sourceWarehouseObj?.warehouseName.toLowerCase().includes(query.toLowerCase()));
        }

        if (key === 'destinationName') {
            return searchList.filter(p => p.destinationWarehouseObj?.warehouseName.toLowerCase().includes(query.toLowerCase()));
        }

        return undefined;
    };


    /**
     * Checks whether a transport-position shall be marked as incomplete or not
     *
     * transport-positions that have to be checked and are not part of a material request also shall be incomplete
     * @param p
     * @protected
     */
    protected override isIncomplete(p: TransportPosition): boolean {
        return p.isComplete === false || (p.transportState === TransportPositionState.ToBeChecked && !p.materialRequestId);
    }

    /**
     * Takes all unknown groups from a list of storage and creates http calls for their creation.
     * Sets the article-group-id to the mapped transport-position after server response
     * @param newGroups
     * @returns
     */
    private createNewGroupsForTransportPositions(newGroups: TransportPosition[]): Observable<IHttpResult<ArticleGroup | null>>[] {
        // using reduce to collect all groups mapped to their transports
        return newGroups.reduce((calls, current) => {
            // checking if group with this name is already planned
            const callGroup = calls?.find(v => v.articleGroup.name === current?.articleGroupName)
            if (!callGroup) {
                calls.push({
                    articleGroup: current.articleGroupObj ?? new ArticleGroup(current.articleGroupName!),
                    positions: [current]
                });
            } else {
                callGroup.positions.push(current);
            }
            return calls;
        }, new Array<{
            articleGroup: ArticleGroup,
            positions: TransportPosition[]
        }>())// mapping the collected groupings to a http-call, which uses the rxjs-pipe to set the article-group-id for all position in the collected set
            .map(group => this._articleGroupApi.create(group.articleGroup, false)
                .pipe(
                    tap((res) => {
                        if (res.responseValue) {
                            group.positions.forEach(p => {
                                p.articleGroupId = res.responseValue!.articleGroupId!
                                delete p.articleGroupObj;
                            })
                        }
                    })));
    }

    /**
     * Takes a set of transport-positions and checks for which of them a super-task is needed for completion. Therefore, creates a http-call for a super-task
     * holding all positions with the same {@link TransportPosition.superTaskDestination}.
     * Uses the http-response to set the super-task-id in the given transport-positions
     * @param newInterimTransports
     * @returns
     */
    private createNewSuperTasksForTransportPositions(newInterimTransports: TransportPosition[]): Observable<any>[] {
        const callGroups = newInterimTransports
            .filter(t => t.superTaskDestination)
            .reduce((callGroups, current) => {
                // checking if super-tasks with this destination is already planned
                const callGroup = callGroups?.find(v => v.destination === current?.superTaskDestination);
                if (!callGroup) {
                    callGroups.push({destination: current.superTaskDestination!, positions: [current]});
                } else {
                    callGroup.positions.push(current);
                }
                return callGroups;
            }, new Array<{
                destination: string,
                positions: TransportPosition[]
            }>());

        return callGroups.map(c => {
            return this._warehouses.search({warehouseName: c.destination})
                .pipe(
                    take(1),
                    switchMap(w => {
                        const [warehouse] = w;
                        if (warehouse) {
                            return this._superTaskApi.create({destinationWarehouseId: w[0]!.warehouseId!}, false)
                        }

                        return of(noop());

                    }),
                    tap(res => {
                        if (typeof res !== 'object' || !res.responseValue) {
                            return
                        }
                        c.positions.forEach(p => p.superTaskId = res.responseValue?.superTaskId!);
                    })
                );
        });
    }

    private informUserAboutNewDataCreated(importData: TransportPosition[], newGroups: TransportPosition[], subTasks: TransportPosition[]): Observable<IHttpResult<TransportPosition> | null> {
        const groupCreationCalls = this.createNewGroupsForTransportPositions(newGroups);
        const superTaskCalls = this.createNewSuperTasksForTransportPositions(subTasks);

        return this._confirm.askForConfirmation({
            title: 'ui.transports.confirmations.import.data.changes.title',
            message: 'ui.transports.confirmations.import.data.changes.message',
        }).pipe(
            switchMap(() => {
                return this._api.importEntities(importData.map(TransportPosition.removeEagerLoadedObjects), ...groupCreationCalls, ...superTaskCalls);
            })
        );
    }

    private watchMaterialRequestCreation(): void {
        this._materialRequestSocket.getNewNotification()
            .pipe(
                takeWhile(() => this._materialRequestSocket.isConnected),
                mergeMap((mr) => this._api.searchEager({materialRequestId: mr.materialRequestId})),
                map((tps) => tps.map(TransportPosition.buildFromEagerLoad))
            )
            .subscribe((materialRequestTransports) => this.updateState(materialRequestTransports, 'create', false));
    }
}
