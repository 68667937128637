<h2 mat-dialog-title>{{ title }}</h2>

<mat-dialog-content>
  {{ additionalHeader }}+
  <ng-container *ngIf="info">
    <p *ngFor="let inf of info">{{ inf }}</p>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" mat-dialog-close="">Okay</button>
</mat-dialog-actions>
