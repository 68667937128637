import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatCardModule} from '@angular/material/card';

@Component({
    selector: 'app-warning-card',
    standalone: true,
    imports: [CommonModule, MatCardModule],
    templateUrl: './warning-card.component.html',
    styleUrls: ['./warning-card.component.scss']
})
export class WarningCardComponent {

}
