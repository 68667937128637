import {EventEmitter, Injectable} from '@angular/core';
import {Setting} from '../../domain/core-model';
import {Observable} from 'rxjs';
import {IcsIdentityAuthenticationService} from 'ics-identity-authentication';
import {SignalREvents} from '../model/signal-r-events.enum';
import {SignalRService} from "../base-service/signal-r.service";
import {WebAppSettings} from "../../settings";
import {environment} from "../../../environments/environment";
import {HandlingUnitApiService} from '../../api/handling-unit-api/handling-unit-api.service';
import { LockService } from '../../lock/lock-service/lock.service';


@Injectable({
  providedIn: 'root'
})
export class SignalRSettingService extends SignalRService<Setting> {

  get huIdPatternChange(): Observable<RegExp> {
    return this._huIdPatternEvent.asObservable();
  }

  private readonly _huIdPatternEvent: EventEmitter<RegExp> = new EventEmitter<RegExp>();
  private readonly _patternSettings: string[] = [WebAppSettings.huIdAllowedSigns, WebAppSettings.huIdSuffix,
    WebAppSettings.huIdAllowedSigns, WebAppSettings.huIdPrefix, WebAppSettings.huIdMinLength, WebAppSettings.huIdMaxLength];

  constructor(
    authService: IcsIdentityAuthenticationService,
    lock: LockService,
    private _handlingUnitApi: HandlingUnitApiService
  ) {
    super(authService, environment.coreUrl + '/SettingHub', Setting, lock);
  }

  protected override connect() {
    this.connection.on(SignalREvents.RCVUPDATED, (result: Setting | null | undefined) => {
      if (result && this._patternSettings.includes(result.settingId)) {
        this._handlingUnitApi.getHandlingUnitIdPattern().subscribe((pattern) => {
          this._huIdPatternEvent.emit(new RegExp(pattern));
        });
      }
    });
    super.connect();
  }
}
