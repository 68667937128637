import {Pipe, PipeTransform} from '@angular/core';
import {DateTimeService} from '../../core';
import dayjs from 'dayjs';

@Pipe({
    name: 'localizedDate',
    standalone: true
})
export class LocalizedDatePipe implements PipeTransform {

    constructor(
        private _dateTime: DateTimeService,
    ) {
    }

    transform(value: string | null | undefined, format?: string): string {
        if (!value) {
            return '';
        }

        const dateString = this._dateTime.fromServerTimeUTC(value) as string ?? value;
        const isInvalid = !dateString || dateString === "Invalid Date";

        if (isInvalid) {
            return '??'
        }

        return dayjs(dateString).format(format ?? 'DD.MM.YYYY HH:mm');

    }

}
