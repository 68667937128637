import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    ViewChild
} from '@angular/core';
import {ControlValueAccessor, NgControl, UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {FocusMonitor} from '@angular/cdk/a11y';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {map, startWith, tap} from 'rxjs/operators';
import {IPropertyView} from '../../domain/interfaces';
import {MAT_FORM_FIELD, MatFormField, MatFormFieldAppearance, MatFormFieldControl} from '@angular/material/form-field';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';


@Component({
    selector: 'app-async-select',
    templateUrl: './async-select.component.html',
    styleUrls: ['./async-select.component.scss'],
    providers: [
        {provide: MatFormFieldControl, useExisting: AsyncSelectComponent},
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsyncSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {

    static nextId = 0;
    @HostBinding() id = `async-select-${AsyncSelectComponent.nextId++}`;
    @ViewChild(MatAutocompleteTrigger, {static: true}) autoCompleteTrigger!: MatAutocompleteTrigger;
    stateChanges = new Subject<void>();
    control: UntypedFormControl;
    focused = false;
    touched = false;
    controlType = 'async-select';
    filteredOptions!: Observable<any[]>;
    optionsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly autofilled: boolean = false;
    @Input('aria-describedby') userAriaDescribedBy = ''
    private _options: any[] = [];

    constructor(
        private _fb: UntypedFormBuilder,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl) {
        this.control = this._fb.control('');

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    @Input()
    get value(): string {
        if (this.control.value) {
            return this.control.value[this.propertyConfig.key]
                ? this.control.value[this.propertyConfig.key] : this.control.value[this.propertyConfig.input!.altSelectionName!];
        }
        return this.control.value;
    }

    set value(val: string | null) {
        this.control.setValue(this._options ? this.checkValue(val) : val);
        this.stateChanges.next();
    }

    get errorState(): boolean {
        return (this.control.invalid || !this.autoCompleteTrigger.activeOption) && this.touched;
    }

    get empty(): boolean {
        return !this.control.value;
    }

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    private _placeholder = '';

    @Input()
    get placeholder() {
        return this._placeholder;
    }

    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    private _required = false;

    @Input()
    get required() {
        return this._required;
    }

    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    private _disabled = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.control.disable() : this.control.enable();
        this.stateChanges.next();
    }

    private _optionResolver!: Observable<any[]>;

    @Input()
    get optionResolver(): Observable<any[]> {
        return this._optionResolver;
    }

    set optionResolver(res: Observable<any[]>) {
        this._optionResolver = res;
    }

    private _propertyConfig!: IPropertyView;

    @Input()
    get propertyConfig(): IPropertyView {
        return this._propertyConfig;
    }

    set propertyConfig(prop) {
        this._propertyConfig = prop;
    }

    private _appearance: MatFormFieldAppearance = 'fill';

    @Input()
    get appearance(): MatFormFieldAppearance {
        return this._appearance;
    }

    set appearance(val: MatFormFieldAppearance) {
        this._appearance = val;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (_: any) => {
    };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {
    };

    ngOnInit(): void {
        this.loadOptions();
    }

    displayConfig(val: any) {
        if (!val) {
            return '';
        }

        // checking if multiple properties are configured for display
        const multipleDisplay = this.propertyConfig.input!.displayMultipleProperties;

        // default key of entity-type
        const key = this.propertyConfig.key;

        // alternative key if default is wrong name
        const altKey = this.propertyConfig.input!.altSelectionName!;

        // alt key for better reading
        const altDisplay = this.propertyConfig.input!.altSelectionDisplay!;

        // display origin key value and altDisplay
        const displayBoth = !!this.propertyConfig.input!.displayBoth;

        if (multipleDisplay && val[multipleDisplay.firstDisplay] && val[multipleDisplay.secondDisplay]) {
            return `${val[multipleDisplay.firstDisplay]}, ${val[multipleDisplay.secondDisplay]}`;
        } else if (displayBoth) {
            return `${val[key] ? val[key] : val[altKey]}, ${val[altDisplay]}`;
        } else if (altDisplay) {
            return val[altDisplay];
        } else if (altKey) {
            return val[altKey];
        } else {
            return val[key];
        }
    }

    _filter(desc: string): any[] {
        const filter = String(desc).toLowerCase();
        if (!filter || filter === '') {
            return this._options;
        }

        const propName = this.propertyConfig.input!.altSelectionName ? this.propertyConfig.input!.altSelectionName : this.propertyConfig.key;
        const altDisplay = this.propertyConfig.input!.altSelectionDisplay;

        const multiDisplay = this.propertyConfig.input!.displayMultipleProperties;

        if (multiDisplay) {
            return this._options.filter((o: any) => {
                const firstVal: string = typeof o[multiDisplay.firstDisplay] === 'number' ? String(o[multiDisplay.firstDisplay]) : o[multiDisplay.firstDisplay];
                const secondVal: string = typeof o[multiDisplay.secondDisplay] === 'number' ? String(o[multiDisplay.secondDisplay]) : o[multiDisplay.secondDisplay];
                return firstVal.toLowerCase().indexOf(filter) === 0 || secondVal.toLowerCase().indexOf(filter) === 0;
            });
        }

        if (altDisplay) {
            return this._options.filter(o => {
                const val = typeof o[propName] === 'number' ? String(o[propName]) : o[propName];
                const altVal = typeof o[altDisplay] === 'number' ? String(o[altDisplay]) : o[altDisplay];
                return altVal ? val.toLowerCase().indexOf(filter) === 0 || altVal?.toLowerCase().indexOf(filter) === 0 : val.toLowerCase().indexOf(filter) === 0;
            });
        }

        return this._options.filter(o => {
            const val = typeof o[propName] === 'number' ? String(o[propName]) : o[propName];
            return val.toLowerCase().indexOf(filter) === 0;
        });
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            if (!this.control.touched) {
                // this.autoCompleteTrigger.closePanel();
            }
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const controlElement = this._elementRef.nativeElement
            .querySelector('.async-select-input')!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this._elementRef.nativeElement.querySelector('input')?.focus();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.touched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(val: string): void {
        this.value = val;
    }

    _handleInput(): void {
        this.onChange(this.value);
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
    }

    private loadOptions(): void {
        this.optionsLoading.next(true);
        this.optionResolver
            .pipe(
                tap((values) => this._options = values),
                tap(() => this.optionsLoading.next(false)),
                tap(() => this.value = this.control.value)
            ).subscribe(() => {
            this.filteredOptions = this.control.valueChanges
                .pipe(
                    startWith(''),
                    // checking that value is neither null or undefined
                    map(val => !val ? '' : val),
                    // mapping input tu itself or to the needed property if its JSON
                    map(val => typeof val === 'string' ? val : this.propertyConfig.input?.altSelectionName ? val[this.propertyConfig.input.altSelectionName] : val[this.propertyConfig.key]),
                    map(val => this._filter(val))
                );
        });
    }

    private checkValue(val: any): any {
        const find = this.propertyConfig.input!.altSelectionName ? this.propertyConfig.input!.altSelectionName : this.propertyConfig.key;
        return this._options.find(p => p[find] === val);
    }

}
