import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'trim',
    standalone: true
})
export class TrimPipe implements PipeTransform {

    transform(value: string): string | undefined | null {
        return value?.trim();
    }

}
