import {inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';
import {IcsConfirmationService} from 'ics-core';

export const transportChangesGuard: CanActivateFn = () => {

    return inject(IcsConfirmationService).askForConfirmation({
        title: 'ui.transport.tasks.confirmations.changes.title',
        message: 'ui.transport.tasks.confirmations.changes.message',
    });

};
