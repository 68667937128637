import {ISelectionProperty} from '../interfaces';

export enum ReadyDescription {
  Ready = 'Bereit',
  NotReady = 'Nicht Bereit'
}

export const readySelection: ISelectionProperty[] = [
  {
    view: ReadyDescription.Ready,
    value: true
  },
  {
    view: ReadyDescription.NotReady,
    value: false
  }
];
