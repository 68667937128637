import {Observable, switchMap, take, zip} from 'rxjs';
import {Article} from '../../domain/core-model';
import {Injectable} from '@angular/core';
import {StateService} from '../model/state/state.service';
import {ArticleApiService} from '../../api/article-api/article-api.service';
import {delay, map} from 'rxjs/operators';
import {IHttpResult} from '../../api/interfaces/http-result.interface';
import {IcsUserFeedbackService} from 'ics-core';

@Injectable({
    providedIn: 'root'
})

export class ArticleService extends StateService<Article> {

    constructor(
        private _articleApi: ArticleApiService,
        private _userFeedback: IcsUserFeedbackService
    ) {
        super(
            {type: Article, primaryKeys: ['articleId'], webSocket: 'ArticleSignalRService', api: ArticleApiService},
            {prefetching: true}
        );
    }

    importArticles(articles: Article[]): Observable<(Article | null)[]> {
        // // ensure all articleNumbers are strings
        articles = articles.map(a => {
            return {...a, articleId: String(a.articleId)};
        });
        return this.state
            .pipe(
                take(1),
                map(s => this._separateUpdateAndDelete(s, articles)),
                switchMap((batches) => {
                    let concatenation: Array<Observable<IHttpResult<Article[] | null>>> = [];

                    // only make a create call if needed
                    if (batches.create.length > 0) {
                        let articleCreateBatches = new Array<Article[]>();
                        for (let i = 0; i <= batches.create.length; i += 400) {
                            articleCreateBatches.push(batches.create.slice(i, i + 400));
                        }
                        concatenation = concatenation.concat(articleCreateBatches.map(b => this._articleApi.createNewEntities(b).pipe(delay(500))));
                    }

                    // only update the needed articles
                    if (batches.update.length > 0) {
                        let articleUpdateBatches = new Array<Article[]>();
                        for (let i = 0; i <= batches.update.length; i += 400) {
                            articleUpdateBatches.push(batches.update.slice(i, i + 400));
                        }
                        concatenation = concatenation.concat(articleUpdateBatches.map(b => this._articleApi.updateEntities(b).pipe(delay(500))));
                    }
                    return zip(...concatenation);
                }),
                this._userFeedback.insert(res => {
                    const type = res.length === articles.length ? 'success' : 'error';

                    const allError = res.every(r => r.isError);

                    const appendix = type === 'error' ? `.${allError ? 'full' : 'partial'}` : '';

                    return {
                        type: type,
                        message: `ui.user.feedback.${type}.article.import${appendix}`
                    };
                }),
                map((res) => res.reduce((acc, curr) => curr.responseValue ? acc.concat(curr.responseValue) : [...acc, null], new Array<Article | null>()))
            );

    }

    private _separateUpdateAndDelete(state: Article[], imports: Article[]): { update: Article[], create: Article[] } {
        return imports.reduce((acc, curr) => {
            const existing = state?.find(s => s.articleId === curr.articleId);
            if (existing) {
                acc.update.push({...existing, ...curr});
            } else {
                acc.create.push(curr);
            }
            return acc;
        }, {create: new Array<Article>(), update: new Array<Article>()});

    }
}
