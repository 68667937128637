import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'listIndex',
    standalone: true
})
export class ListIndexPipe implements PipeTransform {

    transform<T>(value: T[] | undefined, index: number | 'first' | 'last'): T | undefined {

        if (!value) {
            return undefined;
        }

        if (index === 'first') {
            return value[0];
        }

        if (index === 'last') {
            return value[value.length - 1];
        }

        return value[index];
    }

}
