<app-base-table [data]="data" [columns]="columns" [columnNames]="columnNames"
                [refreshing]="refreshing" [privilegeLevel]="privilegeLevel ?? 'OrderManagement'"
                [blockedSelectionIndexes]="blockedSelectionIndexes"
                [disabled]="disabled"
                [usePagination]="usePagination" [selectionTooltip]="selectionTooltip"
                [noRefresh]="noRefresh"
                [blockSelection]="blockSelection" matSort
                matSortActive="transportPositionId"
                matSortDirection="desc"
                matSortStart="desc" data-cy="transport-position-table">

    <ng-container matColumnDef="transportPositionId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let position; let i = index">{{ position?.transportPositionId ?? i }}</td>
    </ng-container>

    <ng-container matColumnDef="priorityLevel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.priorityLevel }}</th>
        <td mat-cell *matCellDef="let position" data-cy="priority-level-col">
            <app-priority [priorityLevel]="position.priorityLevel"></app-priority>
        </td>
    </ng-container>

    <ng-container matColumnDef="transportState">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let position">
            <app-transport-position-state [state]="position.transportState"></app-transport-position-state>
        </td>
    </ng-container>

    <ng-container matColumnDef="transportListId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TransportListe</th>
        <td mat-cell *matCellDef="let position">
            <app-nullable-field>{{ position.transportListId }}</app-nullable-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="bookingNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.bookingNumber }}</th>
        <td mat-cell *matCellDef="let position">
            <app-nullable-field>{{ position.bookingNumber }}</app-nullable-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="jobNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.jobNumber }}</th>
        <td mat-cell *matCellDef="let position">
            <app-nullable-field>{{ position.jobNumber }}</app-nullable-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="articleGroupId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.articleGroupName }}</th>
        <td mat-cell *matCellDef="let position" data-cy="article-group-id-col">
            <ng-container [ngTemplateOutlet]="articleGroupColumn" [ngTemplateOutletContext]="{position}"></ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="articleGroupName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.articleGroupName }}</th>
        <td mat-cell *matCellDef="let position" data-cy="article-group-name-col">
            <ng-container [ngTemplateOutlet]="articleGroupColumn" [ngTemplateOutletContext]="{position}"></ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="articleId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.articleId }}</th>
        <td mat-cell *matCellDef="let position">
            <app-nullable-field>{{ position.articleId }}</app-nullable-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="articleDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.articleDescription }}</th>
        <td mat-cell *matCellDef="let position" style="max-width: 10em; padding-right: 0.5em">
            <app-nullable-field appFitText [maxFontSize]="14" [minFontSize]="12">

                <!-- for eager-loaded positions                -->
                <ng-container *ngIf="position.articleObj; else asyncArticleTemplate">
                    {{ position.articleObj?.description }}
                </ng-container>

                <ng-template #asyncArticleTemplate>
                    {{ position.articleId |article:'description' |async }}
                </ng-template>

            </app-nullable-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="articleWeight">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.articleWeight }}</th>
        <td mat-cell *matCellDef="let position">{{ position?.articleWeight | weight: "g":"kg" }}</td>
    </ng-container>

    <ng-container matColumnDef="articleCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.articleCount }}</th>
        <td mat-cell *matCellDef="let position">{{ position.articleCount }}</td>
    </ng-container>

    <ng-container matColumnDef="articleSourceId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.articleSourceId }}</th>
        <td mat-cell *matCellDef="let position">
            <ng-container [ngTemplateOutlet]="warehouseColumn"
                          [ngTemplateOutletContext]="{warehouse: position?.sourceWarehouseObj, warehouseId: position?.articleSourceId}"></ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="destination">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.destination }}</th>
        <td mat-cell *matCellDef="let position">
            <ng-container [ngTemplateOutlet]="warehouseColumn"
                          [ngTemplateOutletContext]="{warehouse: position?.destinationWarehouseObj, warehouseId: position?.destination}"></ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="handlingUnitId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Handlingunit</th>
        <td mat-cell *matCellDef="let position">
            <app-nullable-field>{{ position.handlingUnitId }}</app-nullable-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="orderedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.orderedBy }}</th>
        <td mat-cell *matCellDef="let position">
            <app-nullable-field>
                {{ position?.orderedBy|localizedDate }}
            </app-nullable-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="toBeDoneBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.toBeDoneBy }}</th>
        <td mat-cell *matCellDef="let position">
            <app-nullable-field>
                {{ position?.toBeDoneBy|localizedDate }}
            </app-nullable-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="isComplete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.isComplete }}</th>
        <td mat-cell *matCellDef="let position">
            <app-boolean-field [value]="position.isComplete"></app-boolean-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.operator }}</th>
        <td mat-cell *matCellDef="let position" data-cy="operator-col">
            <app-nullable-field>{{ position.userObj | userName }}</app-nullable-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="superTaskDestination">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.superTaskDestination }}</th>
        <td mat-cell *matCellDef="let position">
            <app-nullable-field>{{ position?.superTaskDestination }}</app-nullable-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="initiator">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnNames.initiator }}</th>
        <td mat-cell *matCellDef="let position">
            <app-nullable-field>{{ position?.initiatorObj?.name }}</app-nullable-field>
        </td>
    </ng-container>
</app-base-table>


<ng-template let-position="position" #articleGroupColumn>
    <app-nullable-field>

        <ng-container *ngIf="position.articleGroupObj; else asyncValue">
            {{ position.articleGroupObj | articleGroup }}
        </ng-container>
        <ng-template #asyncValue>
            <ng-container *ngIf="!noAsync; else pureValue">
                {{ position.articleGroupId |articleGroup |async }}
            </ng-container>
        </ng-template>

        <ng-template #pureValue>
            {{ position.articleGroupName ?? position.articleGroupId }}
        </ng-template>


    </app-nullable-field>
</ng-template>


<ng-template let-warehouse="warehouse" let-warehouseId="warehouseId" #warehouseColumn>
    <app-nullable-field>

        <ng-container *ngIf="warehouse; else asyncWarehouse">
            {{ warehouse | warehouse }}
        </ng-container>

        <ng-template #asyncWarehouse>
            <ng-container *ngIf="!noAsync; else pureValue">
                {{ warehouseId | warehouse | async }}
            </ng-container>
        </ng-template>

        <ng-template #pureValue>
            {{ warehouseId }}
        </ng-template>

    </app-nullable-field>
</ng-template>
