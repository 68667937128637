export {WebAppSettings, defaultSettings};

enum WebAppSettings {
  globalArticleFields = 'web-article-fields-global',
  additionalArticleFieldsMinLength = 'web-article-field-min-length',
  additionalArticleFieldsMaxAmount = 'web-article-fields-max-amount',
  possibleArticleTableColumns = 'web-possible-article-table-columns',
  selectedArticleTableColumns = 'web-selected-article-table-columns',
  huIdMaxLength = 'hu_id_max_length',
  huIdMinLength = 'hu_id_min_length',
  huIdPrefix = 'hu_id_prefix',
  huIdSuffix = 'hu_id_suffix',
  huIdAllowedSigns = 'hu_id_allowed_signs',
  materialRequestEmailList = 'material-request-email-list',
  warehouseRelationTableContentKey = 'web-warehouse-relation-table-content-key',
  externalJsonSettingPrefix = 'EXTERNAL_JSON_SETTING_FOR_',
  preferImportColumnNaming = 'web-import-column-naming-preferred',
  materialRequestGroupSuffix = 'web-material-request-group-suffix',
  materialRequestArticleFilters = 'web-material-request-article-filters'
}

/**
 * The namings of all import settings-service for both import ot find and settings-service to change.
 * !!! These have to match the pattern <entity-name>-import-order and <entity-name>-column-names
 */
export enum ImportSettings {
  transportPositionImportOrder = 'transport-position-import-order',
  transportPositionColumnNames = 'transport-position-column-names',
  articleImportOrder = 'article-import-order',
  articleColumnNames = 'article-column-names',
  importRangeSymbols = 'import-range-symbols'
}


const defaultSettings: { [key: string]: string[] } = {
  [WebAppSettings.globalArticleFields]: [],
  [WebAppSettings.additionalArticleFieldsMaxAmount]: ['5'],
  [WebAppSettings.additionalArticleFieldsMinLength]: ['3'],
  [WebAppSettings.possibleArticleTableColumns]: ['articleId', 'description', 'articleWeight', 'lengthInMillimetres', 'widthInMillimetres', 'heightInMillimetres'],
  [WebAppSettings.selectedArticleTableColumns]: ['articleId', 'description', 'articleWeight', 'lengthInMillimetres', 'widthInMillimetres', 'heightInMillimetres'],
  [WebAppSettings.huIdMinLength]: ['5'],
  [WebAppSettings.huIdMaxLength]: ['15'],
  [WebAppSettings.huIdPrefix]: [''],
  [WebAppSettings.huIdSuffix]: [''],
  [WebAppSettings.huIdAllowedSigns]: [''],
  [WebAppSettings.materialRequestEmailList]: [],
  [WebAppSettings.warehouseRelationTableContentKey]: ['warehouseName'],
  [ImportSettings.importRangeSymbols]: ['START', 'END'],
  [WebAppSettings.preferImportColumnNaming]: [String(false)],
  [WebAppSettings.materialRequestGroupSuffix]: ['-NF'],
  [WebAppSettings.materialRequestArticleFilters]: []
};
