import {EntitySettings} from './entity-settings.interface';
import {SettingsMap} from '../settings-map.model';

export {
  WarehouseSettings
}

class WarehouseSettings extends EntitySettings {

  warehouseUIProperty: 'warehouseName' | 'description' = 'warehouseName';

  constructor(settings: SettingsMap) {
    super();
    if (settings['web-warehouse-relation-table-content-key']) {
      const storedPropertyKey = settings['web-warehouse-relation-table-content-key'][0];
      this.warehouseUIProperty = storedPropertyKey === 'description' ? 'description' : 'warehouseName';
    }
  }

  toMap(): Partial<SettingsMap> {
    return {
      'web-warehouse-relation-table-content-key': [JSON.stringify(this.warehouseUIProperty)]
    }
  }
}
