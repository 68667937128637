import {ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {ExportService} from './export-service/export.service';
import {EXPORT_CLASS} from './model/export.providers';

const staticProviders: Provider[] = [
    ExportService,
];

/**
 * Allows tot create excel or csv exports of an entity marked as @Exportable
 * for usage information look at {@link https://abmsoft.atlassian.net/wiki/spaces/PCKTMS/pages/3787292677/Export+Module confluence}
 */
@NgModule({
    imports: [],
})
export class ExportModule {

    public static forClass(classType: Function): ModuleWithProviders<ExportModule> {
        return {
            ngModule: ExportModule,
            providers: [
                ...staticProviders,
                {
                    provide: EXPORT_CLASS,
                    useValue: classType
                }
            ]
        };
    }
}

