import {FlattenedStringMap, IHeaderLineParser, IObjectParser, StringMap} from './object-parser.interface';
import {IExportColumn} from '../interfaces/export-column.interface';
import {PlainObjectParser} from './plain-object.parser';
import {ColumnReader} from '../readers/column.reader';
import {ExportParserError} from '../errors/parsing.errors';
import {HeaderLineParser} from './header-line.parser';

export {
    LinkObjectParser
}


class LinkObjectParser implements IObjectParser, IHeaderLineParser {

    private readonly _readerMap = new Map<string, ColumnReader>();
    private readonly _linkMap = new Map<string, PlainObjectParser>();

    constructor(
        private _links: IExportColumn[]
    ) {
        this._checkDuplicateLinks();

        for (const link of _links) {
            const reader = new ColumnReader(link.link!);
            this._readerMap.set(link.propertyKey, reader);
            this._linkMap.set(link.propertyKey, new PlainObjectParser(reader.getExternColumns()));
        }
    }

    /**
     * @inheritDoc
     *
     * Produces objects from child objects that properties are marked with @Export({link: true})
     * accordingly to the linked class
     */
    parseObjectsAsReadable(input: StringMap): FlattenedStringMap;
    parseObjectsAsReadable(input: StringMap[]): FlattenedStringMap[];
    parseObjectsAsReadable(input: StringMap | StringMap[]): FlattenedStringMap | FlattenedStringMap[] {
        if (Array.isArray(input)) {
            return input.map(v => this._parseSingle(v));
        }

        return this._parseSingle(input);
    }

    /**
     * @inheritDoc
     */
    produceHeaderLine(forProperty: string) {
        const target = this._links.find(c => c.propertyKey === forProperty);

        if (!target) {
            return [];
        }

        const reader = this._readerMap.get(target.propertyKey);

        if (!reader) {
            return [];
        }

        const parser = new HeaderLineParser(reader.getExternColumns());
        const duplicate = this._hasDuplicate(target);

        if (duplicate) {
            return parser.produceHeaderLine().map(h => `${h}-${target.view}`);
        }

        return parser.produceHeaderLine();
    }

    private _parseSingle(value: StringMap): FlattenedStringMap {
        return this._links.reduce((acc: FlattenedStringMap, curr) => {
            const parser = this._linkMap.get(curr.propertyKey);

            if (!parser) {
                return {};
            }

            const target = value?.[curr.propertyKey] ?? {};

            const parsed = parser.parseObjectsAsReadable(target as StringMap);

            if (parsed) {
                return {...acc, ...this._checkSuffix(parsed, curr)};
            }

            return acc;

        }, {});
    }

    private _checkDuplicateLinks() {
        const links = this._links.map(c => c.link!);

        const set = new Set(links);

        if (set.size !== links.length) {
            console.warn('Detected to properties linking the same class. Properties will be suffixed');
        }

        for (const col of this._links) {
            const duplicate = this._hasDuplicate(col);

            if (duplicate && !(duplicate.view || col.view)) {
                throw new ExportParserError(LinkObjectParser, 'Cannot parse to objects that link the same class but at least on property lacks a view definition.');
            }
        }
    }

    private _hasDuplicate(col: IExportColumn) {
        return this._links.find(c => c.link === col.link && c.propertyKey !== col.propertyKey);
    }

    private _checkSuffix(value: FlattenedStringMap, column: IExportColumn): FlattenedStringMap {
        const duplicate = this._hasDuplicate(column);

        if (!duplicate) {
            return value;
        }

        return Object.entries(value).reduce((acc: FlattenedStringMap, [key, prop]) => {
            return {...acc, [`${key}-${column.view}`]: prop};
        }, {});
    }

}
