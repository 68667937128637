import {Type} from '@angular/core';
import {StateService} from '../state/state.service';
import {StateAccess, StateConfig, StateOptions} from '../state/state.service.options';
import {BaseEntity} from "../../../domain";

export {StateEagerLoadingOptions, StateEagerLoadingConfig}

class StateEagerLoadingConfig<T extends BaseEntity> extends StateConfig<T> {
  constructor(
    stateManagementAccessor: StateAccess<T>,
    stateManagementOptions: StateOptions<T>,
    public stateEagerLoadingOptions: StateEagerLoadingOptions<T>
  ) {
    super(stateManagementAccessor, stateManagementOptions);
  }
}


type EagerLoadingServiceReference<T extends BaseEntity> = Type<StateService<T>> | StateService<T>;

class StateEagerLoadingOptions<T extends BaseEntity> {
  eagerLoadingReferences = new Array<EagerLoadingOption<T, any>>();
  eagerLoadingReferenceMap: Map<keyof T, EagerLoadingOption<T, any>>;

  constructor(...referenceOptions: EagerLoadingOption<T, any>[]) {
    this.eagerLoadingReferences = referenceOptions
    this.eagerLoadingReferenceMap = new Map(referenceOptions.map(e => {
      return [e.eagerProperty, e];
    }));
  }
}

type EagerLoadingProperty<T extends {
  [key: string | number | symbol]: any
}, X> = { [K in keyof T]: K extends X ? K : never }[keyof T];

class EagerLoadingOption<T extends BaseEntity, X extends BaseEntity> {
  constructor(
    public eagerLoadedType: Type<X>,
    public serviceReference: EagerLoadingServiceReference<X>,
    public eagerProperty: EagerLoadingProperty<T, X>,
    public writingProperty: keyof T,
    public eagerQueryBuilder?: (e: T) => Partial<T>
  ) {
  }
}
