import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {EntityKey} from 'src/app/domain/utility-types';
import {LockEntry} from '../model/lock-entry.model';
import {LockMap} from '../model/lock-map.model';

@Injectable({
  providedIn: 'root'
})
export class LockService {

  constructor(
    _injector: Injector
  ) {
  }

  private _statusChanges = new Subject<void>();

  get statusChanges(): Observable<void> {
    return this._statusChanges.asObservable();
  }

  private _locker = new Map<Function, BehaviorSubject<LockMap<any>>>();

  get locker() {
    return this._locker;
  }

  public getLocker<T extends { [key: string]: any }>(func: new () => T): Observable<LockMap<any>> {
    return this.getLocalLock(func).asObservable();
  }

  public addLock<T extends { [key: string]: any }>(func: new() => T, ...value: LockEntry<T>[]) {
    const localLock = this.getLocalLock(func);
    value.forEach(element => {
      localLock.getValue().set(element.key, element.value)
    });
    localLock.next(localLock.getValue());
  }

  public releaseLock<T extends { [key: string]: any }>(func: new() => T, key: EntityKey<T>) {
    const localLock = this.getLocalLock(func);
    localLock.getValue().delete(key);
    localLock.next(localLock.getValue());
  }

  private getLocalLock<T extends { [key: string]: any }>(func: new () => T) {
    const existing = this._locker.get(func);
    if (existing) {
      return existing;
    }
    const newEntry = this._locker.set(func, new BehaviorSubject(new LockMap())).get(func)!;
    this._statusChanges.next();
    return newEntry
  }
}
