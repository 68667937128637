<mat-form-field [appearance]="appearance" [color]="color" subscriptSizing="fixed" class="date-range-input">

    <mat-label>{{ label ?? ('ui.common.labels.date.range.input' |translate) }}</mat-label>

    <mat-date-range-input [formGroup]="control" [rangePicker]="picker">
        <input formControlName="start" matStartDate placeholder="Start">
        <input formControlName="end" matEndDate placeholder="Ende">
    </mat-date-range-input>

    <mat-hint class="date-range-input__hint-tools">
        <button (click)="selectRange( 'week')" [color]="color" class="date-range-input__quick-selector"
                mat-raised-button>
            <span>{{ 'ui.common.labels.date.range.current.week' |translate }}</span>
        </button>

        <button (click)="selectRange('month')" [color]="color" class="date-range-input__quick-selector"
                mat-raised-button>
            <span>{{ 'ui.common.labels.date.range.current.month' |translate }}</span>
        </button>
    </mat-hint>

    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
    <mat-date-range-picker #picker [startAt]="currentDate"></mat-date-range-picker>
</mat-form-field>
