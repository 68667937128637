import {Export} from "src/app/export";
import {BaseEntity} from '../base-entity.model';

export type InitiatorId = Initiator['initiatorId'];

export class Initiator extends BaseEntity {

    // this is a constructor
    constructor(
        public readonly initiatorId?: number,
        @Export({propertyKey: 'name', view: 'Auftraggeber', extern: true})
        public name?: string,
        public email?: string,
        public phonenumber?: string,
        public isActive?: boolean,
        public company?: string
    ) {
        super();
    }
}
