import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    Host,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    QueryList,
    ViewChild
} from '@angular/core';
import {MatSort, MatSortModule, Sort} from '@angular/material/sort';
import {ThemedPaginatorComponent} from 'src/app/table/themed-paginator/themed-paginator.component';
import {EventTable} from '../model/event-table.model';
import {IndexedSelectionModel} from '../model/indexed-selection-model';
import {InjectTableContentDirective} from '../inject-table-content/inject-table-content.directive';
import {PrivilegeLevel} from 'src/app/roles';
import {ActionColumn, TableColumns, userActionColumns} from '../model/table-columns.model';
import {TableMenuItemComponent} from '../table-menu-item/table-menu-item.component';
import {takeUntil} from 'rxjs/operators';
import {merge, Subject} from 'rxjs';
import {isDataSource} from '@angular/cdk/collections';
import {DisplayNames} from '../../domain/utility-types';
import {TableSortService} from '../table-sort/table-sort.service';
import {ActivatedRoute} from '@angular/router';
import {
    MatColumnDef,
    MatFooterRowDef,
    MatHeaderRowDef,
    MatRowDef,
    MatTable,
    MatTableDataSource,
    MatTableModule
} from "@angular/material/table";
import {TableButtonEvent} from '../model/button-events.model';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DisableUnlessPrivilegedDirective} from 'ics-core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {TranslateModule} from '@ngx-translate/core';
import {JsonPipe, NgForOf, NgIf} from '@angular/common';
import {AddButtonComponent} from '../../buttons';
import {PageEvent} from '@angular/material/paginator';

@Component({
    selector: 'app-base-table',
    templateUrl: './base-table.component.html',
    styleUrls: ['./base-table.component.scss'],
    outputs: BaseTableComponent.OUTPUTS,
    inputs: [...BaseTableComponent.BASE_INPUTS, ...BaseTableComponent.T_INPUTS],
    imports: [
        MatSortModule,
        MatTableModule,
        MatCheckboxModule,
        MatTooltipModule,
        DisableUnlessPrivilegedDirective,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
        MatMenuModule,
        ThemedPaginatorComponent,
        TranslateModule,
        NgForOf,
        NgIf,
        AddButtonComponent,
        JsonPipe
    ],
    standalone: true
})
export class BaseTableComponent<T = any> extends EventTable<T> implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {

    static readonly BASE_INPUTS = ['data', 'disabled', 'stickyHeader', 'noRefresh', 'refreshing'];
    static readonly OUTPUTS = ['selectionChange', 'delete', 'edit', 'details', 'refresh', 'pageChange'];
    static readonly T_INPUTS = ['columnNames', 'columns'];

    // inner own mat sort
    @ViewChild(MatSort, {static: true}) sort!: MatSort;

    // the paginator to connect with the table
    @ViewChild(ThemedPaginatorComponent) paginator!: ThemedPaginatorComponent;

    // the table itself
    @ViewChild(MatTable, {static: true}) table!: MatTable<any>;

    // children of ng-content to connect with the table
    @ContentChildren(MatColumnDef, {descendants: true}) columnContainers!: QueryList<MatColumnDef>;

    // watch for directives to get injected content that is collected in a component
    @ContentChildren(InjectTableContentDirective, {descendants: true}) inheritedColumns!: QueryList<InjectTableContentDirective>;

    // watch for injected menu items to react to events and check extending or overriding
    @ContentChildren(TableMenuItemComponent) extendedMenuItems!: QueryList<TableMenuItemComponent<T>>;

    @ContentChildren(MatFooterRowDef) injectedFooterRows!: QueryList<MatFooterRowDef>;

    // watching the span surrounding the external menu content to check if there is external content
    @ViewChild('menuContent', {read: ElementRef}) menuContent!: ElementRef;
    /**
     * Input for overriding the refreshing value to show the refresh bar on demand
     */
    @Input() refreshing = true;
    /**
     * controls the height of the table and if the content in overflow is scrollable
     */
    @Input() height = 35;
    /**
     * Defines all columns displayed by the table
     */
    @Input() columns: TableColumns<T> | string[] = [];
    @Input() columnNames?: Partial<DisplayNames<T>>;
    /**
     * Defines which privileges are needed to access the actions of the table
     */
    @Input() privilegeLevel?: PrivilegeLevel;
    /**
     * Disables all access-points to the selection to avoid user interaction in bad states
     */
    @Input() blockSelection?: boolean;
    /**
     * Disables the selection for the values at the given index
     */
    @Input() blockedSelectionIndexes?: number[];
    /**
     * A function that returns the tooltip that is show when the user hovers over the selection
     */
    @Input() selectionTooltip?: (value: T) => string;
    // data management sources for the table
    tableDataSource: MatTableDataSource<T> = new MatTableDataSource<T>([]);
    selection: IndexedSelectionModel = new IndexedSelectionModel();
    // watching hovered table row for menu content
    hoveredTableRowIndex = -1;
    protected _firstDataSetReceived = false;
    // handle for subscriptions
    protected _subscriptions: Subject<void> = new Subject();

    // flag for control key hold to make multiple select possible
    private _multipleSelectKeyPressed = false;
    // flag to disable refresh button completely
    private _disableRefresh?: boolean;
    private _columnsAdded: Set<MatColumnDef> = new Set();

    constructor(
        private _el: ElementRef,
        protected _changeDetector: ChangeDetectorRef,
        protected _tableSort: TableSortService,
        protected _activeRoute: ActivatedRoute,
        @Optional() @Host() private _sort?: MatSort,
    ) {
        super();
    }

    get data(): any[] | null | undefined {
        if (this._firstDataSetReceived) {
            return this.tableDataSource.data;
        }
        return null;
    }

    /**
     * The data displayed in the table
     */
    @Input()
    set data(value: any[] | null | undefined) {
        // updating datasource if no values are passed datasource gets set to empty
        if (value && Array.isArray(value)) {
            this.tableDataSource.data = value ?? [];
            this._firstDataSetReceived = true;
            this.refreshing = false;
        }
    }

    get noRefresh(): boolean | undefined {
        return this._disableRefresh;
    }

    /**
     * Decides whether the refresh option is shown to the user in the paginator or not
     */
    @Input()
    set noRefresh(value: any) {
        this._disableRefresh = coerceBooleanProperty(value);
    }

    /**
     * @returns whether a height is provided or not and converts the height to rem
     */
    get maxHeight(): string {
        return this.height ? `${this.height}rem` : '35rem';
    }

    /**
     * returns all column names that are not associated with actions
     */
    get dataColumnNames(): string[] {
        return (this.columns as string[])?.filter((c) => !userActionColumns.includes(c as ActionColumn)) ?? [];
    }

    /**
     * @returns all column definitions that got injected by {@see InjectTableContent} directives
     */
    get inheritedColumnDefs(): MatColumnDef[] {
        return this.inheritedColumns.reduce((acc, curr) => {
            if (!curr?.injectedColumns) {
                return acc;
            }
            return [...acc, ...curr?.injectedColumns]
        }, new Array<MatColumnDef>());
    }

    /**
     * @return whether there is external content injected into the menu
     */
    get hasExternalMenuContent(): boolean {
        return !!(this.menuContent?.nativeElement?.innerHTML || this.menuContent?.nativeElement?.children?.length > 0) && !this.extendedMenuItems?.get(0)?.extending;
    }

    get selectableValues(): T[] {
        if (!this.paginator) {
            return this.tableDataSource.data;
        }

        // reading current page
        const {pageSize, pageIndex} = this.paginator.materialPaginator;

        // going forward to the page selected
        const startIndex = pageIndex * pageSize;

        // adding the selected page
        const endIndex = startIndex + pageSize;

        const selectable = this.tableDataSource.data.slice(startIndex, endIndex);

        if (this.blockedSelectionIndexes) {
            return selectable.filter((d, i) => !this.blockedSelectionIndexes?.includes(i));
        }
        return selectable;
    }

    // flag for disabling table
    private _disabled = false;

    /**
     * if the selection is disabled
     */
    get disabled(): boolean {
        return this._disabled;
    }

    /**
     * disables the selection
     */
    @Input()
    set disabled(value: any) {
        this._disabled = coerceBooleanProperty(value);
    }

    // control which stores whether pagination is used or not
    private _usePagination = true;

    /**
     * Disables or enables the pagination on false values the table will not have a paginator
     */
    @Input()
    get usePagination(): boolean {
        return this._usePagination;
    }

    set usePagination(value: any) {
        this._usePagination = coerceBooleanProperty(value);
    }

    // controlling mat-tables layout like this because its first accessible AfterViewInit
    private _fixedLayout?: boolean;

    get fixedLayout(): boolean | undefined {
        return this._fixedLayout;
    }

    /**
     * Decides whether the mat-tables fixed-layout shall be used or not
     */
    @Input()
    set fixedLayout(value: any) {
        this._fixedLayout = coerceBooleanProperty(value);
    }

    ngOnInit(): void {
        this.rebuildSort();

        // watching for sort clear commands
        this._tableSort.listenToClear(this._activeRoute).pipe(takeUntil(this._subscriptions)).subscribe(() => {
            this.sort.sort({id: '', start: '', disableClear: false});
        });

        // changing how the datasource accesses the data props to sort
        // as known failure of mat-table
        this.tableDataSource.sortingDataAccessor = (data: any, id: string) => this._tableSort.findSortValue(data, id);
    }

    /**
     * In this lifecycle hook the table gets build by connecting the
     * columns and the table
     */
    ngAfterContentInit(): void {
        // detaching from change detection to avoid mat table creating header row before injected rows got set
        // better to do in ContentInit to have a good run in Init
        this._changeDetector.detach();

        // connecting the columns that got injected by ng-content
        this.connectColumnDefinitions();

        // watching all events of the extended menu items to use their callback on click
        this.watchMenuItemEvents();

        this.addRowDefs(...this.injectedFooterRows.toArray());
    }

    /**
     * Reads and adds the columns injected by {@see InjectTableContent} directive.
     * Starts a new run of change detection to commit the changes to the table
     */
    ngAfterViewInit(): void {
        // connecting the columns that got injected by InjectTableContent directive
        this.connectColumnDefinitions();

        // reattaching the change detection after all injected columns got connected
        this._changeDetector.reattach();

        // fixes the error of inputs not really readable/written
        this._changeDetector.detectChanges();

        this.checkTableRelations();

        this.watchContentColumnChanges();
    }

    /**
     * Listens to the windows keydown event to check if the user has pressed the [shift] key.
     * Used for connected select
     * @param event
     */
    @HostListener('document:keydown.Shift', ['$event'])
    checkControlKeyPressed(event: KeyboardEvent) {
        this._multipleSelectKeyPressed = event?.shiftKey;
    }

    /**
     * Listens to the windows keyup event to check if the user has released the [shift] key.
     * Used for connected select
     * @param event
     */
    @HostListener('document:keyup.Shift', ['$event'])
    checkControlKeyLeft(event: KeyboardEvent) {
        this._multipleSelectKeyPressed = event?.ctrlKey;
    }

    /**
     * Whether the number of selected elements matches the total number of rows.
     * */
    isAllSelected(): boolean {
        const numSelected = this.selection.size
        const numRows = this.selectableValues.length;
        if (numRows <= 0) {
            return false;
        }
        return numSelected === numRows;
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection.
     */
    masterToggle(clear = false): void {
        if (this.isAllSelected() || clear) {
            this.selection.clear();
            this.selectionChange.emit({selected: []});
            return;
        }

        this.selectableValues.forEach((p, i) => {
            if (!this.blockedSelectionIndexes?.includes(i)) {
                this.selection.select({index: i, selected: p});
            }
        });

        this.selectionChange.emit({selected: this.selection.selected});
    }

    /**
     * returns whether a row is selected or not
     * @param i
     * @returns
     */
    isSelected(i: number): boolean {
        return this.selection.isSelected(i);
    }

    /**
     * adds the position with the index to the selection
     */
    selectPosition(selected: any, index: number): void {
        // checking if user has shift key pressed
        if (this._multipleSelectKeyPressed) {
            const startingIndex = this.selection?.lastIndex;
            // have to iterate because selection is index based and slice would destroy index of data
            for (let i = startingIndex; i <= index; i++) {
                this.selection.select({selected: this.tableDataSource.data[i], index: i});
            }
        } else {
            // need to make a deselect if a position is selected to toggle correctly
            this.selection.isSelected(index) ? this.selection.deselect(index) : this.selection.select({
                index,
                selected
            });
        }
        this.selectionChange.emit({selected: this.selection.selected});
    }

    /**
     * Removes the value associated with the index from the selection
     * @param {number} index to be removed
     */
    deselectPosition(index: number): void {
        this.selection.deselect(index);
        this.selectionChange.emit({selected: this.selection.selected});
    }

    clearSelection(): void {
        this.selection.clear();
        this.selectionChange.emit({selected: this.selection.selected});
    }

    /**
     * sorts the data of the table by the given column
     * @param {Sort} sort the sorting event
     */
    onSort(sort: Sort): void {
        event?.preventDefault();
        event?.stopPropagation();
        const column = sort.active;
        const data = this.tableDataSource.data;
        this.tableDataSource.data = data.sort((a: any, b: any) => compare(a[column], b[column], sort.direction === 'asc'));
        this.saveSort();
    }

    /**
     * Uses super class to emit event
     * and adds the refresh indicator to the default behavior
     */
    override onRefresh(): void {
        this.refreshing = true;
        super.onRefresh();
    }

    /**
     * checks whether a column is provided via <ng-content></ng-content> or not.
     * @param {string} col the name of the column
     * @returns true if the column was found in ng-content
     */
    columnIsProvided(col: string): boolean {
        const providedColumns = [...this.columnContainers, ...this.inheritedColumnDefs, ...this._columnsAdded].map(c => c.name);
        return providedColumns.findIndex(c => c === col) >= 0;
    }

    /**
     * provides a very basic search for data in the table
     * @param query
     */
    search(query: string): void {
        this.tableDataSource.filter = query.toLowerCase().trim();
    }

    connectMatTable(table: MatTable<T>): void {
        this.table = table;
    }

    addColumnDefs(...defs: MatColumnDef[]): void {
        defs.forEach(d => {
            this._columnsAdded.add(d);
            this.table?.addColumnDef(d)
        });
    }

    addRowDefs(...rows: (MatRowDef<any> | MatHeaderRowDef | MatFooterRowDef)[]): void {
        rows.forEach(r => {
            if (r instanceof MatFooterRowDef) {
                this.table.addFooterRowDef(r);
            } else if (r instanceof MatHeaderRowDef) {
                this.table.addHeaderRowDef(r);
            } else {
                this.table.addRowDef(r);
            }
        });
    }

    ngOnDestroy(): void {
        if (isDataSource(this.tableDataSource)) {
            this.tableDataSource.disconnect();
        }
        this._subscriptions.next();
        this._subscriptions.complete();
    }

    detailsOrEdit(value: T, i: number) {
        if (this.details.observed) {
            this.details.emit(new TableButtonEvent(value, i, 'details'));
            return;
        }

        this.edit.emit(new TableButtonEvent(value, i, 'edit'));
    }

    override onPageChange(event: PageEvent) {
        this.masterToggle(true);
        super.onPageChange(event);
    }

    private saveSort() {
        if (this.sort.active || this._sort?.active) {
            const accessedSort = this.sort.active ? this.sort : this._sort!;
            const isParentSort = !this.sort.active;
            this._tableSort.saveState(this._activeRoute, accessedSort.direction, accessedSort.active, isParentSort);
        }
    }

    private rebuildSort() {
        const state = this._tableSort.loadState(this._activeRoute);

        if (!state) {
            return
        }
        const sort = state?.isParent ? this._sort : this.sort;

        sort?.sort({id: state?.header, start: state?.direction, disableClear: false});
    }

    /**
     * connects the paginator to the table
     */
    private checkTableRelations(): void {
        if (this._usePagination) {
            if (this.tableDataSource && this.paginator && !this.tableDataSource.paginator) {
                this.tableDataSource.paginator = this.paginator.materialPaginator;
            }
        }

        if (this.tableDataSource && this._sort && !this.tableDataSource.sort) {
            this.tableDataSource.sort = this._sort;
            // binding sort events to prevent usage of default sort
            this._sort.sortChange.pipe(takeUntil(this._subscriptions)).subscribe((sort) => this.onSort(sort));
        }
    }

    /**
     * Watches over changes in the column-definitions provided by ng-content.
     * When something in content changes it connects the new column definition to the mat-table
     * @private
     */
    private watchContentColumnChanges() {
        merge(
            this.columnContainers.changes,
            this.inheritedColumns.changes
        ).pipe(takeUntil(this._subscriptions)).subscribe(() => this.connectColumnDefinitions());
    }

    /**
     * Connects the provided MatColumnsDefs from the <ng-content></ng-content> with the
     * material table in this component
     */
    private connectColumnDefinitions(): void {
        const columnsToConnect = this.filterDuplicateColumns(...this.columnContainers, ...this.inheritedColumnDefs);
        for (const col of columnsToConnect) {
            this.table.addColumnDef(col);
        }
    }

    /**
     * Removes duplicate columns from the table to allow overriding of
     * raw columns. Always uses the last defined MatColumnDef for a column.
     * @returns the remaining list of MatColumnDefs that can remain in the table
     */
    private filterDuplicateColumns(...containers: MatColumnDef[]): MatColumnDef[] {
        return containers.reduce((acc, curr) => {
            const existingIndex = acc.findIndex(c => c.name === curr.name);
            if (existingIndex < 0) {
                acc.push(curr);
            }
            return acc;
        }, new Array<MatColumnDef>());
    }

    private watchMenuItemEvents(): void {
        this.extendedMenuItems.forEach(menuItem => {
            menuItem.clicked.pipe(takeUntil(this._subscriptions)).subscribe(() => {
                if (menuItem.callbackFn) {
                    const rowIndex = this.hoveredTableRowIndex;
                    const rowValue = this.tableDataSource.data[this.hoveredTableRowIndex];
                    menuItem.callbackFn({index: rowIndex, value: rowValue, type: 'unknown'});
                }
            })
        });
    }
}


/**
 * Decides whether a value [a] should be placed before or after a value [b] in a sorting
 * @param a first value to be compared
 * @param b value to be compared to [a]
 * @param isAsc decides the sorting order; true for ascending false for descending
 * @return 1 if [a] is bigger in sorting of [isAsc] order otherwise false
 */
function compare(a: string | number, b: string | number, isAsc: boolean): number {
    if (a === b) {
        return 0;
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
