import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {View, ViewChangedEvent} from '../model/view-change.model';
import {ChildRoutes} from '../../constants';
import {ActivatedRoute} from '@angular/router';
import {IconRegistry} from '../../core/icon-service/icon-registry.constants';


@Component({
  selector: 'app-view-toggle',
  templateUrl: './view-toggle.component.html',
  styleUrls: ['./view-toggle.component.scss']
})
export class ViewToggleComponent implements OnInit {

  private static ASSOCIATED_ICONS: Map<string, string> = new Map([[ChildRoutes.Group, 'dynamic_feed'], [ChildRoutes.Table, 'view_list'], [ChildRoutes.List, 'view_list']]);

  @Input()
  set views(value: Array<View>) {
    this._views = value.map((v) => this.associateIcon(v));
  }

  get views(): Array<View> {
    return this._views;
  }

  @Output('viewChanged') viewChangeEmitter = new EventEmitter<ViewChangedEvent<any>>();

  get firstView(): View | null {
    return this.views[0];
  }

  @Input() initialView: string | undefined;

  private _views = new Array<View>();

  constructor(
    private _activeRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    if (!this.initialView) {
      this.initialView = this._activeRoute.firstChild?.snapshot.routeConfig?.path;
    }
  }

  emitViewChange(view: View<string>) {
    this.viewChangeEmitter.emit(new ViewChangedEvent(view.name, view.name));
  }

  private associateIcon(view: View): View {
    const associatedIcon = ViewToggleComponent.ASSOCIATED_ICONS.get(view.name);
    if (associatedIcon && !view.icon) {
      return new View(view.name, view.displayValue, associatedIcon);
    }

    if (IconRegistry.find(i => i.iconName === view.icon)) {
      view.svgIcon = true;
    }

    return view;
  }
}
