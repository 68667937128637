import {BaseEntity} from '../base-entity.model';
import {StorageBin} from './storage-bin.model';
import {Warehouse} from '../core-model';

export {
  StorageArea,
  StorageAreaQuery
}

class StorageArea extends BaseEntity {
  constructor(
    public storageAreaId: string,
    public description: string,
    public storageBins?: Array<StorageBin>,
    public warehouseId: number | null = null,
    public warehouseObj?: Warehouse,
  ) {
    super();
  }
}

type StorageAreaQuery = Partial<StorageArea> & { warehouseName?: string };
