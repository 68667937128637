import {Routes} from '@angular/router';
import {ChildRoutes, RouteParams} from '../../../constants';
import {IcsUnsavedChangesGuard, PrivilegeGuard} from 'ics-core';
import {PrivilegeLevels} from '../../../roles';
import {LockGuard} from '../../../lock/lock-guard/lock.guard';
import {importProvidersFrom} from '@angular/core';
import {LockModule} from '../../../lock/lock.module';
import {transportChangesGuard} from './guards/transport-task-changes.guard';
import {TransportTask} from './model/transport-task.model';
import {ExportModule} from '../../../export';

export enum TransportTaskRoutes {
    Positions = 'positions',
}

export const routes: Routes = [
    {
        path: '', // this empty route is basically just for easier provider management
        providers: [
            importProvidersFrom(
                LockModule.forID(TransportTask, 'transportTaskId'),
                ExportModule.forClass(TransportTask)
            )
        ],
        children: [
            {
                path: ChildRoutes.List,
                loadComponent: () => import('./pages/list/transport-task-list.component').then(c => c.TransportTaskListComponent)
            },
            {
                path: ChildRoutes.History,
                loadComponent: () => import('./pages/list/transport-task-list.component').then(c => c.TransportTaskListComponent)
            },
            {
                path: `${ChildRoutes.Edit}/:${RouteParams.EntityId}`,
                loadComponent: () => import('./pages/edit/transport-task-edit.component').then(c => c.TransportTaskEditComponent),
                canActivate: [PrivilegeGuard, transportChangesGuard, LockGuard],
                canDeactivate: [IcsUnsavedChangesGuard, LockGuard],
                data: {privilegeLevel: PrivilegeLevels.OrderManagement}
            },
            {
                path: `${ChildRoutes.Details}/:${RouteParams.EntityId}`,
                loadComponent: () => import('./pages/details/transport-task-details.component').then(c => c.TransportTaskDetailsComponent)
            },
            {
                path: `${TransportTaskRoutes.Positions}/:${RouteParams.EntityId}`,
                loadComponent: () => import('./pages/positions/transport-task-positions.component').then(c => c.TransportTaskPositionsComponent),
            },
            {
                path: '',
                redirectTo: ChildRoutes.List,
                pathMatch: 'full'
            },
            {
                path: '**',
                redirectTo: ChildRoutes.List
            }
        ]
    }
];
