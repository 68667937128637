<div [appCanBeDisabled]="disabled" class="row" data-cy="save-btn">
    <mat-spinner *ngIf="saving" [diameter]="20" color="accent"></mat-spinner>
    <div class="col">

        <button (click)="onClick($event)" [disabled]="disabled" color="accent" data-cy="save-button"
                mat-raised-button>
            <span>{{ label ?? ('ui.common.labels.save' | translate) }}</span>
        </button>

        <span *ngIf="!disabled && !suppressShortcut" class="tip row">
          <strong>{{'ui.common.labels.tipp' |translate}}</strong>
          <span>{{'ui.common.short.cuts.save'|translate}}</span>
        </span>

    </div>
</div>

