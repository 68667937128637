import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormArray,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {ExistingEntityCheck} from '../domain/utility-types';

export {entitySelectedValidator, CustomValidationErrors, phoneNumberValidator, CustomValidators};

type ComplexFormModelValidatorFn<T extends AbstractControl> = (control: T) => ValidationErrors | null;

enum CustomValidationErrors {
  NoEntitySelected = 'noEntitySelected',
  InvalidPhoneNumber = 'invalidPhoneNumber',
  EntityAlreadyExists = 'entityAlreadyExists',
  DuplicateInputError = 'duplicatedInput'
}

const noDuplicateInputValidator = <T>(controlName?: string): ComplexFormModelValidatorFn<UntypedFormArray> => {
  return (formArray) => {
    const checkValues: any[] = formArray.value.map((v: any) => controlName ? v[controlName] : v).filter((v: any) => !!v);
    const controlSet = new Set([...checkValues]);
    return checkValues.length !== controlSet.size ? {[CustomValidationErrors.DuplicateInputError]: true} : null;
  };
};

const entityAlreadyExistsValidator = <T>(callback: ExistingEntityCheck<T>): AsyncValidatorFn => {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return callback(control.value).pipe(take(1), map((exists) => {
      return exists ? {entityAlreadyExists: true} : null;
    }));
  };
}

function objectSelectedValidator(control: AbstractControl): ValidationErrors | null {
  const isRequired = control.hasValidator(Validators.required);
  const value = control.value;

  if (!isRequired && (value === null || value === undefined)) {
    return null;
  }

  return typeof value === 'object' ? null : {[CustomValidationErrors.NoEntitySelected]: true};
}

function entitySelectedValidator<T>(entityOptions: T[], primaryKey: keyof T): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let selectedEntity: T | undefined;
    if (control.value) {
      selectedEntity = entityOptions?.find((e: T) => {
        const selectValue = typeof control.value === 'string' || typeof control.value === 'number' ? control.value : control.value?.[primaryKey];
        const primaryKeyValue = String(e[primaryKey]);
        return primaryKeyValue.toLowerCase() === selectValue?.toString()?.toLowerCase();
      });
    }
    return selectedEntity ? null : {[CustomValidationErrors.NoEntitySelected]: true};
  };
}

function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const phoneRegEx = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im);
    return !control.value || control.value?.match(phoneRegEx) ? null : {[CustomValidationErrors.InvalidPhoneNumber]: true};
  }
}


const CustomValidators = {
  entityAlreadyExistsValidator,
  objectSelectedValidator,
  noDuplicateInputValidator,
  entitySelectedValidator,
  phoneNumberValidator
}
