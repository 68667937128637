import {EntityKey} from "src/app/domain/utility-types"

export {LockMap}

class LockMap<T extends { [key: string]: any }> extends Map<EntityKey<T>, string> {

  first(): [EntityKey<any>, string] | null {
    return this.entries().next().value;
  }

  override set(key: EntityKey<T>, value: string) {
    const existing = this.findEntry(key);
    existing ? existing[1] = value : super.set(key, value);
    return this;
  }

  override delete(key: EntityKey<T>): boolean {
    const existing = this.findEntry(key);
    if (existing) {
      const result = super.delete(existing[0]);
      return result;
    }
    return false;
  }

  override has(key: { [key: string]: any }): boolean {
    return !!this.findEntry(key);
  }

  findEntry(key: { [key: string]: any }): [EntityKey<any>, string] | null {
    const entries = this.entries();
    for (const [internalKey, value] of entries) {
      if (this.isSameKey(internalKey, key)) {
        return [internalKey, value];
      }
    }
    return null;
  }


  private isSameKey(key1: any, key2: any): boolean {
    for (const key in key1) {
      if (key1[key] != key2[key]) {
        return false;
      }
    }
    return true;
  }
}
