import {Component, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import {CustomControl} from '../model/custom-control.model';
import {MatDateRangeInput} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatDateFormats} from '@angular/material/core';
import 'dayjs/locale/de.js';
import {SharedModule} from '../../shared';
import {currentLanguageFactoryFor} from './dayjs-date-adapter/lanuage.factory';
import {addDateFormats} from './dayjs-date-adapter/date.formats';
import {addDayjsAdapter} from './dayjs-date-adapter/dayjs-adapter';

dayjs.extend(weekday);

export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';

const MY_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: DEFAULT_DATE_FORMAT,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    }
};

export type DateRange = { start: dayjs.Dayjs, end: dayjs.Dayjs } | { start: null, end: null };


@Component({
    selector: 'app-date-range-input',
    templateUrl: './date-range-input.component.html',
    styleUrls: ['./date-range-input.component.scss'],
    inputs: ['color', 'appearance'],
    providers: [
        {provide: CustomControl, useExisting: forwardRef(() => DateRangeInputComponent)},
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateRangeInputComponent), multi: true},
        currentLanguageFactoryFor(MAT_DATE_LOCALE),
        addDateFormats(),
        addDayjsAdapter(),
    ],
    standalone: true,
    imports: [
        SharedModule,
    ]
})
export class DateRangeInputComponent extends CustomControl<{
    start: dayjs.Dayjs,
    end: dayjs.Dayjs
}> implements ControlValueAccessor {

    @ViewChild(MatDateRangeInput, {static: true}) dateRangeInput!: MatDateRangeInput<dayjs.Dayjs>;

    @Input() label?: string;

    control = new FormGroup({
        start: new FormControl<dayjs.Dayjs | null>(null),
        end: new FormControl<dayjs.Dayjs | null>(null)
    });

    currentDate = dayjs();


    onChange = (_: any) => {
    };

    onTouched = () => {
    };


    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(value: Partial<DateRange> | undefined): void {
        if (!value) {
            this.control.patchValue({start: undefined, end: undefined});
            return;
        }

        const {start, end} = value;

        if (!start || !end) {
            return;
        }

        this.control.setValue({start, end});

        this.onChange({start, end});
    }

    selectRange(range: 'week' | 'month') {
        // saving value in form field state
        this.writeValue({start: this._getStartOf(range), end: this._getEndOf(range)});
    }

    private _getStartOf(range: 'week' | 'month') {
        if (range === 'month') {
            return dayjs().startOf('month');
        }

        // subtract the weekend
        return dayjs().startOf('week');
    }

    private _getEndOf(range: 'week' | 'month') {

        if (range === 'month') {
            return dayjs().endOf('month');
        }

        // subtract the weekend
        return dayjs().endOf('week').subtract(2, 'days');
    }
}
