export const appName = 'Pck-TMS';

interface IAssetPaths {
  readonly icsLogo: string;
  readonly pckLogo: string;
  readonly liebherrLogo: string;
  readonly tlsLogo: string;
}

export const AssetPaths: IAssetPaths = {
  icsLogo: '../assets/logos/ics-group-logo.png',
  pckLogo: '../assets/logos/pck-logo.png',
  liebherrLogo: '../assets/logos/liebherr-logo.svg',
  tlsLogo: '../assets/logos/tls-logo.svg'
};

interface IModuleTitles {
  readonly articles: string;
  readonly handlingunits: string;
  readonly warehouses: string;
  readonly storageareas: string;
  readonly storagebins: string;
  readonly transporttasks: string;
  readonly transportlists: string;
  readonly transportlistpositions: string;
  readonly articlegroups: string;
  readonly transporthistory: string;
  readonly users: string;
  readonly transportpositions: string;
  readonly initiators: string;
  readonly mobileappdownload: string;
  readonly materialrequests: string;
  readonly articlerequisitions: string;
  readonly inventory: string;
  readonly supertasks: string;
  readonly vehicles: string;
  readonly vehicletypes: string;
  readonly systemsettings: string;
}

export const ModuleTitles: IModuleTitles = {
  articles: 'Artikel',
  warehouses: 'Quelle/Ziel',
  handlingunits: 'Handlingunits',
  storageareas: 'Lagerbereiche',
  storagebins: 'Lagerplätze',
  transportlists: 'Transportlisten',
  transporttasks: 'Transportaufträge',
  transportlistpositions: 'Transportlisten-Positionen',
  articlegroups: 'Artikelgruppen',
  transporthistory: 'Transporthistorie',
  users: 'Benutzer',
  transportpositions: 'Transporte',
  initiators: 'Auftraggeber',
  mobileappdownload: 'Mobile App Download',
  articlerequisitions: 'Entnahmen',
  materialrequests: 'Materialnachforderungen',
  inventory: 'Bestandsübersicht',
  supertasks: 'Mehrstufige Transporte',
  vehicles: 'Fahrzeuge',
  vehicletypes: 'Fahrzeugtypen',
  systemsettings: 'Systemeinstellungen'
};

export const DialogTitles: IModuleTitles = {
  articles: 'Artikel',
  warehouses: 'Quelle/Ziel',
  handlingunits: 'Handlingunit',
  storageareas: 'Lagerbereich',
  storagebins: 'Lagerplatz',
  transportlists: 'Transportliste',
  transporttasks: 'Transportauftrag',
  transportlistpositions: 'Transportlisten-Position',
  articlegroups: 'Artikelgruppe',
  transporthistory: '',
  users: 'Benutzer',
  transportpositions: 'Transport',
  initiators: 'Auftraggeber',
  mobileappdownload: 'Mobile App Download',
  articlerequisitions: 'Entnahme',
  materialrequests: 'Materialnachforderung',
  inventory: 'Bestand',
  supertasks: 'Mehrstufiger Transport',
  vehicles: 'Fahrzeug',
  vehicletypes: 'Fahrzeugtyp',
  systemsettings: 'Systemeinstellung'
};
