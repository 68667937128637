import {
    StateEagerLoadingConfig,
    StateEagerLoadingOptions
} from '../model/eager-loaded-state/eager-loaded-state.options';
import {HandlingUnit, Warehouse} from '../../domain/core-model';
import {StateAccess, StateOptions} from '../model/state/state.service.options';
import {HandlingUnitApiService} from '../../api/handling-unit-api/handling-unit-api.service';
import {WarehouseService} from '../warehouse/warehouse-service/warehouse.service';


export {
    HandlingUnitServiceConfig
}

const HandlingUnitServiceConfig = new StateEagerLoadingConfig<HandlingUnit>(
    new StateAccess(HandlingUnit, ['handlingUnitId'], HandlingUnitApiService, 'HandlingUnitSignalRService'),
    new StateOptions({eagerLoading: false}),
    new StateEagerLoadingOptions({
        eagerLoadedType: Warehouse,
        eagerProperty: 'warehouseId',
        writingProperty: 'warehouseObj',
        serviceReference: WarehouseService as any
    })
);
