import {AbstractControl} from '@angular/forms';
import {Component, Input} from '@angular/core';
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {ThemePalette} from "@angular/material/core";

export {
  CustomControl
}

@Component({
  selector: 'app-custom-form-field',
  template: '<span style="color: : red">Use only subclasses of this</span>',
  styles: [],
})
abstract class CustomControl<T> {

  public abstract control: AbstractControl;

  private _appearance: MatFormFieldAppearance = 'outline';

  get appearance(): MatFormFieldAppearance {
    return this._appearance;
  }

  @Input()
  set appearance(value: MatFormFieldAppearance) {
    this._appearance = value;
  };

  private _color: ThemePalette = 'accent';

  get color() {
    return this._color;
  }

  @Input()
  set color(value: ThemePalette) {
    this._color = value;
  }
}
