import {Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2} from '@angular/core';

/**
 * Allows the element this directive is used on to react to a file drag and drop event.
 * Emits all dropped files on-drop.
 * Highlights the host as drag an drop area.
 *
 * Needs an hidden input element to work
 * i.e. <input hidden type="file" [accept]="acceptedFileTypes" (change)="uploadFile($event.target.files)" #fileInput />
 */
@Directive({
  selector: '[appUpload]'
})
export class UploadDirective {

  // emitter for communicating files top host
  @Output() fileDropped = new EventEmitter<any>();

  /**
   * Access to the native element of the host
   */
  get element(): HTMLElement {
    return this.el.nativeElement;
  }

  /**
   *
   * @param {ElementRef} el Reference to the host element
   */
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  /**
   * Listens to the dragover event of the host and reacts with highlighting the border
   * @param event
   */
  @HostListener('dragover', ['$event']) public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.showBorderHighlight(true);
  }

  /**
   * Listens to the dragleave event of the host and reacts with end of highlight
   * @param event
   */
  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.showBorderHighlight(false);

  }

  /**
   * Listens to the drop event of the host and emits the file dropped
   */
  @HostListener('drop', ['$event']) public onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }

    this.showBorderHighlight(false);
  }


  /**
   * highlights the drag an drop area by using a dashed border
   * @param {boolean} visible whether the border of the host is highlighted or not
   * @private
   */
  private showBorderHighlight(visible: boolean): void {
    this.element.style.outline = visible ? "2px dashed lightgrey" : "unset";
  }
}
