import {AfterContentInit, Component, ContentChildren, OnDestroy, QueryList} from '@angular/core';
import {CustomFormFieldComponent} from '../../../form-fields/custom-form-field.component';
import {startWith, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CustomControl} from '../../../form-fields/model/custom-control.model';
import {ThemePalette} from '@angular/material/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormField, MatFormFieldAppearance} from "@angular/material/form-field";


@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill', color: 'accent'}}
    ]
})
export class FormComponent implements AfterContentInit, OnDestroy {

    @ContentChildren(MatFormField, {descendants: true}) formFieldListRef!: QueryList<MatFormField>;
    @ContentChildren(CustomControl, {descendants: true}) customControlListRef!: QueryList<CustomControl<any>>;
    @ContentChildren(CustomFormFieldComponent, {descendants: true}) legacyControlListRef!: QueryList<CustomFormFieldComponent>;

    private readonly _appearance: MatFormFieldAppearance = 'fill';
    private readonly _color: ThemePalette = 'accent';
    private readonly _subscriptions = new Subject<void>();

    constructor() {

    }

    ngAfterContentInit() {
        this.watchQueryList(this.formFieldListRef);
        this.watchQueryList(this.customControlListRef);
        this.watchQueryList(this.legacyControlListRef);
    }

    ngOnDestroy() {
        this._subscriptions.next();
        this._subscriptions.complete();
    }

    private watchQueryList<X extends {
        appearance: MatFormFieldAppearance,
        color: ThemePalette
    }>(queryList: QueryList<X>) {
        queryList.changes
            .pipe(
                startWith(queryList.toArray()),
                takeUntil(this._subscriptions)
            )
            .subscribe(v => this.adjustStyles(...v));
    }

    private adjustStyles<X extends { appearance: MatFormFieldAppearance, color: ThemePalette }>(...values: X[]) {
        values.forEach(v => {
            v.appearance = this._appearance;
            v.color = this._color;
        });
    }


}
