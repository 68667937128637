import {Directive, ElementRef, Input, QueryList} from "@angular/core";
import {TableContentInjector} from "../model/column-injector.interface";
import {MatColumnDef} from "@angular/material/table";

declare interface NG {
    getComponent<T>(element: ElementRef): T | null;
}

@Directive({
    selector: '[appInjectTableContent]',
    standalone: true
})
export class InjectTableContentDirective {

    @Input('appInjectTableContent') component: TableContentInjector | null = null;

    get injectedColumns(): QueryList<MatColumnDef> {
        if (this.component) {
            return this.component?.columnDefinitions;
        }
        return new QueryList<MatColumnDef>();
    }
}
