<ng-container *ngIf="columnProperties">
    <div (cdkDropListDropped)="onDrop($event)" cdkDropList cdkDropListAutoScrollStep="10"
         cdkDropListOrientation="horizontal"
         id="column-drag-list">
        <mat-card *ngFor="let column of columnProperties" [cdkDragDisabled]="property?.editing" [id]="column" cdkDrag
                  cdkDragBoundary="#column-drag-list" style="padding: 0.5%">
            <app-import-property
                #property
                (descriptionChange)="onDescriptionChange($event)"
                [columnKey]="column"
                [columnName]="columnNames?.[column] ?? columnDefinitions?.[column]?.view ?? column"
                [columnValues]="columnDefinitions?.[column]?.propertyValues"
                [required]="columnDefinitions?.[column]?.required"
                [restructuringProperty]="columnDefinitions[column]?.restructure"
            ></app-import-property>


        </mat-card>
    </div>
</ng-container>
