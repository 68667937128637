import {Component, Inject, Injector, OnInit, Type} from '@angular/core';
import {WEBSOCKET_MARKER} from '../websocket.module';
import {SignalRService} from '../base-service/signal-r.service';
import {environment} from '../../../environments/environment';
import {EnvironmentType} from '../../../environments/environment.interface';

@Component({
  selector: 'app-websocket-connection-indicator',
  templateUrl: './websocket-connection-indicator.component.html',
  styleUrls: ['./websocket-connection-indicator.component.scss']
})
export class WebsocketConnectionIndicatorComponent implements OnInit {

  get isAllowedToStop() {
    return environment.environmentType === EnvironmentType.LOCAL
  }

  get featureIsEnabled() {
    return false;
    //return environment.environmentType !== EnvironmentType.PRODUCTION;
  }

  webSocket!: SignalRService<any>;

  constructor(
    private _injector: Injector,
    @Inject(WEBSOCKET_MARKER) private _marker: string | Type<SignalRService<any>>
  ) {
    this.webSocket = this._injector.get<SignalRService<any>>(_marker as any);
  }

  ngOnInit(): void {

  }


  reconnectWebsocket() {
    this.webSocket.rebuildConnection();
  }

  endWebsocketConnection() {
    this.webSocket.disconnect();
  }
}
