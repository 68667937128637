import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Directive, ElementRef, HostListener, Input} from '@angular/core';

export class CanBeDisabled {
    private _disabled = false;

    get disabled() {
        return this._disabled;
    }

    set disabled(value: any) {
        this._disabled = coerceBooleanProperty(value);
    }

}


@Directive({
    selector: '[appCanBeDisabled]',
    host: {
        '[attr.disabled]': 'disabled || null',
        '[disabled]': "disabled || null"
    },
    standalone: true
})
export class CanBeDisabledDirective extends CanBeDisabled {

    constructor(
        private _elementRef: ElementRef
    ) {
        super();
    }

    @Input('appCanBeDisabled')
    override set disabled(value: any) {
        super.disabled = value;

        super.disabled ? this._blockEvents() : this._releaseEvents();
    }

    private get _element() {
        return this._elementRef.nativeElement as HTMLElement;
    }

    private get _matButtonWrapper(): Element | null | undefined {
        return this._element?.querySelector('.mat-button-wrapper');
    }

    @HostListener('click', ['$event'])
    prevent(event: Event) {
        if (!super.disabled) {
            return;
        }
        this._preventDisabledEvents(event);
    }

    private _preventDisabledEvents = (event: Event): void => {
        // A disabled button shouldn't apply any actions
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
    };

    private _releaseEvents() {
        this._matButtonWrapper?.removeEventListener('click', this._preventDisabledEvents);
    }

    private _blockEvents() {
        this._matButtonWrapper?.addEventListener('click', this._preventDisabledEvents);
    }
}

