import {Component, Input, OnInit} from '@angular/core';
import {RouterLink} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
    selector: 'app-un-lock-button',
    templateUrl: './un-lock-button.component.html',
    styleUrls: ['./un-lock-button.component.scss'],
    imports: [
        RouterLink,
        MatIconModule,
        MatButtonModule,
        TranslateModule,
        MatTooltipModule
    ],
    standalone: true
})
export class UnLockButtonComponent implements OnInit {

    @Input() routerLink = '../locker';

    constructor() {
    }

    ngOnInit(): void {
    }
}
