<span [appCanBeDisabled]="disabled">
  <button [disabled]="disabled" color="warn" data-cy="delete-button"
          mat-raised-button matTooltip="{{'ui.common.labels.delete' | translate}}">
    <span>
      <mat-icon>delete</mat-icon>
    </span>

  </button>
</span>

