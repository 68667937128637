import {InjectionToken} from "@angular/core";

type Class = Function;

/**
 * Proides a class to the {@link ExportService}
 *
 * @see ExportService
 */
const EXPORT_CLASS = new InjectionToken<Class>('ExportClassProvider');

export {
    EXPORT_CLASS
}
