import {IcsIdentityAuthenticationService} from 'ics-identity-authentication';
import {combineLatest, Observable, switchMap} from 'rxjs';
import {IcsCdkMessengerAuthentication, LogFn} from '@ics-cdk/messenger';
import {delay, first, map} from 'rxjs/operators';
import {isDevMode} from '@angular/core';

export {messengerLogging, messengerAuthenticationFactory}

function messengerAuthenticationFactory(auth: IcsIdentityAuthenticationService): Observable<IcsCdkMessengerAuthentication | null> {
    return auth.isAuthenticated.pipe(
        delay(50), // fixes stuck on login
        switchMap(() => combineLatest([auth.user, auth.token])),
        map(([user, token]) => {

            const id = user?.sub;

            if (!id || !token) {
                return null;
            }

            return {userId: id, accessToken: token};
        }),
        first((user) => user !== null),
    );
}

const messengerLogging: LogFn = (level, message) => {

    // ERROR
    if (level === 3) {
        console.error(["MESSENGER"], ...message);
    }

    //INFORMATION
    if (!isDevMode() || level <= 1) {
        return
    }

    console.log(["MESSENGER"], ...message);
}
