import {InjectionToken, Provider} from '@angular/core';

export {
    DayJsDateAdapterOptions,
    MAT_DAYJS_DATE_ADAPTER_OPTIONS,
    useDayJsAdapterOptions
};

interface DayJsDateAdapterOptions {
    /**
     * Turns the use of utc dates on or off.
     * Changing this will change how Angular Material components like DatePicker output dates.
     * {@default false}
     */
    useUtc?: boolean;
}

/** InjectionToken for Dayjs date adapter to configure options. */
const MAT_DAYJS_DATE_ADAPTER_OPTIONS =
    new InjectionToken<DayJsDateAdapterOptions>(
        'MAT_DAYJS_DATE_ADAPTER_OPTIONS',
        {
            providedIn: 'root',
            factory: dayJsAdapterDefaultOptions
        }
    );

function dayJsAdapterDefaultOptions(): DayJsDateAdapterOptions {
    return {
        useUtc: false
    };
}

function useDayJsAdapterOptions(value: DayJsDateAdapterOptions): Provider {
    return {
        provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS,
        useValue: value
    };
}
