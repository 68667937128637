import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms'
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {isArray, sanitizeFormValue} from 'src/app/domain/functions';
import {LoggingService} from '../logging-service/logging.service';
import {LogEntryQuery, LogLevelOptions} from '../model/logg-entry.model';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {NgForOf} from '@angular/common';
import {MatInputModule} from '@angular/material/input';

@Component({
    selector: 'app-log-filter',
    templateUrl: './log-filter.component.html',
    styleUrls: ['./log-filter.component.scss'],
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgForOf,
        MatInputModule
    ],
    standalone: true
})
export class LogFilterComponent implements OnInit, OnDestroy {

    @Output() filter: EventEmitter<LogEntryQuery> = new EventEmitter();

    loggingSourceOptions: string[] = [];
    loggingTypeOptions: string[] = [];
    logLevels = LogLevelOptions;
    filterForm!: UntypedFormGroup;

    private _subscriptions: Subject<void> = new Subject();

    constructor(
        private _fb: UntypedFormBuilder,
        private _logger: LoggingService
    ) {
    }

    ngOnInit(): void {
        this.filterForm = this._fb.group({
            source: [],
            logType: [],
            logLevel: [],
            content: []
        });

        // connecting to state of all source options
        this._logger
            .getLoggingSources({global: true})
            .pipe(takeUntil(this._subscriptions))
            .subscribe((sources) => this.loggingSourceOptions = sources);

        // connecting to state of all logtypes
        this._logger
            .getLoggingTypes({global: true})
            .pipe(takeUntil(this._subscriptions))
            .subscribe((types) => this.loggingTypeOptions = types);

        // generating component output
        this.filterForm.valueChanges
            .pipe(
                takeUntil(this._subscriptions),
                distinctUntilChanged(),
                debounceTime(30),
                map((value) => sanitizeFormValue(value))
            )
            .subscribe(value => this.filter.emit(this.removeEmptyArray(value)));
    }

    ngOnDestroy(): void {
        // ending all pending subscriptions
        this._subscriptions.next();
        this._subscriptions.complete();
    }

    private removeEmptyArray(value: any) {
        for (const key in value) {
            if (value[key] && isArray(value[key])) {
                const arrayValue = (value[key] as any[]);
                if (arrayValue.length === 0) {
                    delete value[key];
                }
            }
        }
        return value;
    }
}
