import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Self
} from '@angular/core';
import {ControlValueAccessor, NgControl, UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {FocusMonitor} from '@angular/cdk/a11y';
import {Subject} from 'rxjs';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {MAT_FORM_FIELD, MatFormField, MatFormFieldAppearance, MatFormFieldControl} from '@angular/material/form-field';
import {ThemePalette} from '@angular/material/core';

type Format = 'date-time' | 'date-time-offset';

@Component({
    selector: 'app-date-input',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.scss'],
    providers: [
        {provide: MatFormFieldControl, useExisting: DateInputComponent},
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateInputComponent implements ControlValueAccessor, OnInit, OnDestroy {

    static nextId = 0;
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    static ngAcceptInputType_disabled: BooleanInput;
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    static ngAcceptInputType_required: BooleanInput;
    dateControl: UntypedFormControl;
    stateChanges = new Subject<void>();
    focused = false;
    touched = false;
    controlType = 'date-input';
    startDate = new Date();
    @HostBinding() id = `date-input-${DateInputComponent.nextId++}`;
    @Input('aria-describedby') userAriaDescribedBy = '';
    readonly autofilled: boolean = false;

    constructor(
        private _fb: UntypedFormBuilder,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl
    ) {
        this.dateControl = this._fb.control('');

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    get empty() {
        return !this.dateControl.value || this.dateControl.value === '';
    }

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input()
    get value(): string | Date {
        return this.dateControl.value ? this._format === 'date-time-offset' ? new Date(this.dateControl.value).toISOString() : this.dateControl.value : null;
    }

    set value(val: string | Date) {
        const dateValue: Date = typeof val === 'string' ? new Date(val) : val;
        const trueDate = new Date(new Date(dateValue).toString() + ' UTC');
        this.dateControl.setValue(val ? trueDate : '');
    }

    get errorState(): boolean {
        return this.dateControl.invalid && this.touched;
    }

    private _placeholder = '';

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }

    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    private _required = false;

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    private _disabled = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.dateControl.disable() : this.dateControl.enable();
        this.stateChanges.next();
    }

    private _label = '';

    get label(): string {
        return this._label;
    }

    @Input()
    set label(val: string) {
        this._label = val;
    }

    private _appearance: MatFormFieldAppearance = 'fill';

    get appearance(): MatFormFieldAppearance {
        return this._appearance;
    }

    @Input()
    set appearance(appearance: MatFormFieldAppearance) {
        this._appearance = appearance;
    }

    private _color: ThemePalette = 'primary';

    get color(): ThemePalette {
        return this._color;
    }

    @Input()
    set color(color: ThemePalette) {
        this._color = color;
    }

    private _format: Format = 'date-time-offset';

    @Input()
    get format(): Format {
        return this._format;
    }

    set format(value: Format) {
        this._format = value;
    }

    ngOnInit() {
        this.dateControl.valueChanges.subscribe(v => this._handleInput());
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (_: any) => {
    };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {
    };

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const controlElement = this._elementRef.nativeElement
            .querySelector('.date-input-container')!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    _handleInput(): void {
        this.onChange(this.value);
    }

    writeValue(val: string | Date | null): void {
        if (val) {
            this.value = val;
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this._elementRef.nativeElement.querySelector('input')?.focus();
        }
    }
}
