import {IExportColumn} from '../decorators/export.decorator';
import {ColumnReader} from '../readers/column.reader';

export {
    ExportValidator
}

class ExportValidator {

    validateColumnCanBeExported(column: IExportColumn, value?: string | number | object | null): boolean {

        // linked columns can be exported because these will be handled at the next level
        if (column.link) {
            return this._checkLinkedColumnWillBeExported(column);
        }

        // linked columns can be exported because these will be handled at the next level
        if (column.destruct) {
            return true;
        }

        // columns with a view can be exported because they can be '--'
        if (column.view) {
            return true;
        }

        console.warn(`Skipping column ${column.propertyKey} on export because it has neither a value or a defined readable view`)
        return !!value;
    }

    validateColumnCanBeExportedWithoutValue(column: IExportColumn) {
        return column.link || column.destruct;
    }

    private _checkLinkedColumnWillBeExported(column: IExportColumn) {
        if (!column.link) {
            return false;
        }

        const reader = new ColumnReader(column.link);

        if (!reader.getExternColumns()) {
            throw new Error(`Cannot export column ${column.propertyKey} because the linked class ${column.link} has no extern properties.`);
        }

        return true;
    }
}
