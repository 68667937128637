import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseApiService} from '../base-api-service/base-api.service';
import {SubTaskCreate, SuperTask} from 'src/app/domain/tms-model/supertask.model';
import {IHttpResult} from "../interfaces/http-result.interface";
import {catchError, map} from "rxjs/operators";
import {IcsUserFeedbackService} from 'ics-core';
import { BuildFromEagerLoadCallback } from 'src/app/domain/utility-types';

@Injectable({
    providedIn: 'root'
})
export class SuperTaskApiService extends BaseApiService<SuperTask> {

    constructor(http: HttpClient, userFeedback: IcsUserFeedbackService) {
        super('SuperTask', 'tms', http, userFeedback);
    }

    createSubTasks(subTaskDto: SubTaskCreate): Observable<IHttpResult> {
        return this._http.post(this.buildEndpointUrl('CreateTransportTasks'), subTaskDto, {observe: "response"})
            .pipe(
                catchError(this.handleHttpErrorResult),
                map(this.buildHttpResult),
                this._userFeedback.insert((v) => {
                    const type = v.isError ? 'error' : 'success';
                    return {
                        type,
                        message: `ui.user.feedback.${type}.supertasks.create.sub.tasks`
                    }
                })
            )
    }
    
    override getEager<X extends SuperTask = SuperTask>(buildFromEagerLoadCallback?: BuildFromEagerLoadCallback<SuperTask, X> | undefined): Observable<(SuperTask | X)[]> {
        return super.getEager(SuperTask.buildFromEagerLoad);
    }
}
