import {inject, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {IcsIdentityAuthenticationModule, IcsIdentityAuthenticationService} from 'ics-identity-authentication';

import {LoggingService} from "./logging";
import {EntityStateModule} from './entity-state/entity-state.module';
import {
    BuildMultiTranslationProvider,
    IcsAppModule,
    IcsNavModule,
    IcsRolesModule,
    IcsStorageModule,
    NeedsPrivilegeDirective
} from 'ics-core';
import {ApiConfigurations, AppWebSockets} from './constants';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ImportModule} from './import';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared';
import {CoreModule} from './core/core.module';
import {LockModule} from './lock/lock.module';
import {ApiModule} from './api/api.module';
import {SettingsService} from './settings';
import {WebsocketModule} from './websocket';
import {IcsThemeModule, IcsThemeSettingComponent} from 'ics-theme';
import {TranslateModule} from "@ngx-translate/core";
import {Languages, TRANSLATION_MODULES} from "./i18n";
import {HistoryHandleComponent} from "./components";
import {NavigationLabelComponent} from "./navigation";
import {RouteNamesService} from "./core";
import {ALL_ROLES, PRIVILEGES, RoleService} from './roles';
import {UnLockButtonComponent} from './lock/un-lock-button/un-lock-button.component';
import {addIcsMessenger, IcsMessengerToolsComponent} from '@ics-angular/messenger';
import {withAuthentication, withHubConnection, withLogger} from '@ics-cdk/messenger';
import {messengerAuthenticationFactory, messengerLogging} from './messenger.factory';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HistoryHandleComponent,
        NavigationLabelComponent,
        IcsThemeSettingComponent,
        CoreModule,
        EntityStateModule,
        LockModule,
        SharedModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        IcsNavModule,
        ApiModule.forApis(ApiConfigurations),
        WebsocketModule.forRoot(AppWebSockets),
        IcsAppModule.forRoot({
            version: environment.version,
            routingTitles: RouteNamesService
        }),
        IcsIdentityAuthenticationModule.withSettings(environment.userManagerSettings),
        IcsThemeModule.forConfig({
            defaultTheme: 'ics-dark-blue-light-green'
        }),
        IcsStorageModule.forRoot({
            application: 'ICS-TLS',
            relativeTo: {
                access: RoleService,
                use: 'currentUserId$'
            }
        }),
        IcsRolesModule.forRoot({
            provider: RoleService,
            roles: ALL_ROLES,
            privileges: PRIVILEGES
        }),
        TranslateModule.forRoot({
            defaultLanguage: Languages.deDE,
            loader: BuildMultiTranslationProvider(TRANSLATION_MODULES),
            useDefaultLang: true,
        }),
        ImportModule.forRoot({
            columns: SettingsService,
            logging: LoggingService
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        AppRoutingModule,
        NeedsPrivilegeDirective,
        UnLockButtonComponent,
        IcsMessengerToolsComponent
    ],
    providers: [
        addIcsMessenger(
            withAuthentication(() => messengerAuthenticationFactory(inject(IcsIdentityAuthenticationService))),
            withHubConnection(environment.messenger),
            withLogger(messengerLogging)
        )
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}


