import {PriorityLevel, TransportPosition} from '../../../../domain/tms-model';
import {User, Vehicle, Vehicletype, Warehouse} from "../../../../domain/core-model";
import {BaseEntity} from '../../../../domain';
import {TransportTaskState} from './transport-task-state.model';
import {Exportable} from '../../../../export';

// IMPORTANT DO NOT SHORTEN

export {TransportTaskId, TransportTask, TransportTaskStatusChange}

type TransportTaskId = NonNullable<TransportTask['transportTaskId']>;

// TODO: Make this implement the DTO interface

@Exportable({
    name: 'transport-auftraege',
    usesCustomExport: true
})
class TransportTask extends BaseEntity {

    // defined by backend
    latitude: number | null = null;
    longitude: number | null = null;
    gpsImgId: number | null = null;
    dateFinished: string | null = null;

    jobNumber?: string;

    bookingNumber?: string;

    // eager loaded stuff
    sourceWarehouseObj?: Warehouse | null = null;
    userObj?: User | null = null;
    vehicleObj?: Vehicletype | null = null;

    // PCKTMS-324 added movement
    statusChanges: TransportTaskStatusChange[] = [];

    transportPositions: TransportPosition[] = [];

    constructor(
        public priorityLevel: PriorityLevel,
        public sourceWarehouseId: number,
        public operator: string | null,
        public isReady: boolean,
        public state: TransportTaskState = TransportTaskState.Created,
        public usedVehicleId: string | null = null,
        public readonly transportTaskId?: number,
    ) {
        super();
    }
}

type TransportTaskStatusChange = {
    id: number;
    timeStamp: string;
    userId: string;
    newState: TransportTaskState;
    usedVehicleId: Vehicle | null
};
