import {Inject, Injectable, Injector, Optional, Type} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {combineLatest, from, map, mergeMap, Observable, of, switchMap, take} from 'rxjs';
import {ChildRoutes, FeatureModuleRoutes, RouteParams} from 'src/app/constants';
import {LockService} from '../lock-service/lock.service';
import {IcsIdentityAuthenticationService} from 'ics-identity-authentication';
import {EntityLockedSnackbarComponent} from '../entity-locked-snackbar/entity-locked-snackbar.component';
import {LOCK_ID, LOCK_TYPE} from '../lock.module';
import {EntityKey} from '../../domain/utility-types';
import {BaseApiService} from '../../api/base-api-service/base-api.service';
import {IcsUserFeedbackService} from 'ics-core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class LockGuard {

    constructor(
        private _locks: LockService,
        private _auth: IcsIdentityAuthenticationService,
        private _userFeedback: IcsUserFeedbackService,
        private _router: Router,
        private _injector: Injector,
        private _snackbar: MatSnackBar,
        @Inject(LOCK_ID) @Optional() private _lockId: string,
        @Inject(LOCK_TYPE) @Optional() private _type: Type<any>
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._redirectIfLocked(state, route);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._redirectIfLocked(state, childRoute);
    }

    canDeactivate(
        component: any,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._unlockEntity(currentRoute);
    }

    private _redirectIfLocked(state: RouterStateSnapshot, route: ActivatedRouteSnapshot) {
        return this._lockIsOwnedByUser(route)
            .pipe(
                mergeMap((owner) => {
                    if (owner === null) {
                        return this._lockEntity(state, route);
                    }
                    return from(this._blockEditPage(state.url, owner, this._getNextEntityModuleRoute(route)));
                })
            );
    }

    private _blockEditPage(url: string, userId: string, module: string | undefined) {
        return this._snackbar.openFromComponent(EntityLockedSnackbarComponent, {
            data: {userId, module},
            panelClass: ['ics-error-feedback'],
            duration: undefined,
        }).afterOpened().pipe(
            switchMap(() => {
                return this._router.navigateByUrl(url.replace(ChildRoutes.Edit, ChildRoutes.Details).replace(ChildRoutes.Check, ChildRoutes.Details));
            })
        );
    }

    private _lockEntity(state: RouterStateSnapshot, route: ActivatedRouteSnapshot) {
        const config = this._getConfig(route);
        const api = this._injector.get<BaseApiService<any>>(config.type);
        if (api) {
            return api.lock(config.key).pipe(
                mergeMap((v) => v === null ? of(true) : from(this._blockEditPage(state.url, v, this._getNextEntityModuleRoute(route))))
            );
        }
        return of(true);
    }

    private _unlockEntity(route: ActivatedRouteSnapshot): Observable<boolean> {
        const config = this._getConfig(route);
        const api = this._injector.get<BaseApiService<any>>(config.type);
        return this._lockIsOwnedByUser(route)
            .pipe(
                mergeMap((owner) => {
                    if (owner !== null) {
                        return of(true);
                    }
                    return api.releaseLock(config.key).pipe(
                        switchMap((res) => {
                            if (!res) {
                                this._userFeedback.show({
                                    type: 'error',
                                    message: 'ui.user.feedback.lock.could.not.be.released'
                                })
                            }
                            return of(res);
                        }));
                })
            );
    }

    private _getNextEntityModuleRoute(route: ActivatedRouteSnapshot): string | undefined {
        const pseudoModuleName = route.pathFromRoot[1].routeConfig?.path;

        if (!pseudoModuleName) {
            return undefined;
        }

        const featureRoutes = (FeatureModuleRoutes as any)[pseudoModuleName];

        if (!featureRoutes) {
            return undefined;
        }

        const featureRoute = route.pathFromRoot.slice(1).reverse().find(r => Object.values(featureRoutes).includes(r.routeConfig?.path));

        if (featureRoute?.routeConfig) {
            return featureRoute.routeConfig.path!;
        }

        return undefined;
    }

    private _getConfig(route: ActivatedRouteSnapshot): { type: Type<any>, key: EntityKey<any> } {
        return {
            type: this._type,
            key: this._lockId ? {[this._lockId]: route.params[RouteParams.EntityId]} : route.params
        }
    }

    private _lockIsOwnedByUser(route: ActivatedRouteSnapshot): Observable<string | null> {
        const config = this._getConfig(route);
        return combineLatest([this._locks.getLocker(config.type), this._auth.user]).pipe(
            take(1),
            map(([locker, user]) => {
                const entry = locker.findEntry(config.key);

                if (!entry) {
                    return null;
                }

                return user!.sub === entry?.[1] ? null : entry[1];
            })
        );
    }
}
