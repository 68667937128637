import {ISelectionProperty} from '../interfaces';

export enum FileType {
  CSV = '.csv',
  XLSX = '.xlsx',
  XML = '.xml',
  PDF = '.pdf'
}

export enum MIMEType {
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV = 'text/csv',
  XML = 'application/xml',
  JSON = 'application/json',
  PDF = 'application/pdf'
}

export const FileSelection: ISelectionProperty[] = [
  {
    view: 'csv',
    value: FileType.CSV
  },
  {
    view: 'Excel',
    value: FileType.XLSX
  }
];


