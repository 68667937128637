import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {TranslateModule} from '@ngx-translate/core';
import {AsyncPipe} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {SharedModule} from '../../shared';
import {take} from 'rxjs';
import {delay, tap} from 'rxjs/operators';
import {UserNamePipe} from '../../pipes';


@Component({
    selector: 'app-entity-locked-snackbar',
    templateUrl: './entity-locked-snackbar.component.html',
    styleUrls: ['./entity-locked-snackbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [
        TranslateModule,
        AsyncPipe,
        MatIconModule,
        SharedModule
    ],
    providers: [UserNamePipe],
    standalone: true
})
export class EntityLockedSnackbarComponent implements OnInit {

    userName: string | null | undefined;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: { userId: string, module: string },
        private _snackbarRef: MatSnackBarRef<EntityLockedSnackbarComponent>,
        private _userName: UserNamePipe
    ) {
    }

    ngOnInit(): void {
        this._userName.transform(this.data.userId).pipe(
            take(1),
            tap((v) => this.userName = v),
            delay(5000)
        ).subscribe(() => this._snackbarRef.dismiss());
    }

}
