import { Injectable } from '@angular/core';
import { SubTaskCreate, SuperTask, SuperTaskQuery, TransportPosition } from '../../domain/tms-model';
import { combineLatest, concat, Observable, switchMap } from 'rxjs';
import { map, toArray } from 'rxjs/operators';
import { HandlingUnit } from "../../domain/core-model";
import { SuperTaskApiService } from "../../api/super-task/super-task-api.service";
import { StateService } from '../model/state/state.service';
import { HandlingUnitApiService } from '../../api/handling-unit-api/handling-unit-api.service';
import { IHttpResult } from '../../api/interfaces/http-result.interface';
import { TransportPositionApiService } from '../../api/transport-position-api/transport-position-api.service';
import { DeepPartial, PropertySearcher } from '../../search/model';
import { SearchUtil } from '../../search/util';
import { WarehouseService } from '../warehouse/warehouse-service/warehouse.service';
import { TransportPositionService } from '../transport-position/transport-position.service';


@Injectable({
    providedIn: 'root'
})
export class SuperTaskService extends StateService<SuperTask, SuperTaskQuery> {

    constructor(
        private _warehouseService: WarehouseService,
        private _transportPositionApi: TransportPositionApiService,
        private _transportPositionService: TransportPositionService,
        private _handlingUnitApi: HandlingUnitApiService,
        protected override _api: SuperTaskApiService,
    ) {
        super(
            {
                api: SuperTaskApiService,
                type: SuperTask,
                primaryKeys: ['superTaskId'],
                webSocket: 'SuperTaskSignalRService'
            },
            { prefetching: true, eagerLoading: true });
    }

    override get state() {
        //return super.state.pipe(
        return combineLatest([super.state, this._transportPositionService.state]).pipe(
            map(([superTasks, transportPositions]) => {
                superTasks.forEach(st => {
                    st.transportPositions = transportPositions.filter(tp => tp.superTaskId === st.superTaskId);
                })
                return superTasks;
            }),
            map(t => t.sort((a, b) => a.superTaskId! > b.superTaskId! ? -1 : 1))
        );
    }

    /**
     * Loads all TransportPositions associated with the given SuperTask
     * @param {SuperTask} superTask
     */
    getTransportPositionsOfSuperTask(superTask: SuperTask): Observable<TransportPosition[]> {
        return this._transportPositionApi.searchEager({ superTaskId: superTask.superTaskId! }, TransportPosition.buildFromEagerLoad);
    }

    /**
     * Returns all eager-loaded HandlingUnits that are associated with a TransportPosition of a SuperTask
     * @param {TransportPosition[]} transports
     */
    getHandlingUnitOfSuperTaskPositions(transports: TransportPosition[]): Observable<HandlingUnit[]> {
        const query: Partial<HandlingUnit> = { handlingUnitId: transports.find(t => t.handlingUnitId)?.handlingUnitId ?? undefined };
        return this._handlingUnitApi.searchEager(query);
    }

    /**
     * Updates a SuperTask to the passed state. Also updates the TransportPositions
     * if passed. For Positions without ID it creates a new one.
     * @param {SuperTask} value that holds the update
     * @param {TransportPosition[] | undefined} positions that hold all position updates
     */
    updateSuperTask(value: SuperTask, positions?: TransportPosition[]): Observable<boolean> {
        let calls: Observable<IHttpResult<TransportPosition | null> | IHttpResult<SuperTask | null>>[] = [];
        if (positions) {
            calls = calls.concat(positions
                .map(p => TransportPosition.removeEagerLoadedObjects(p))
                .map(p => p.transportPositionId ? this._transportPositionApi.update(p, false) : this._transportPositionApi.create(p, false)));
        }

        delete value.destinationWarehouseObj;
        delete value.transportPositions;

        calls.push(this._api.update(value));

        return concat(...calls)
            .pipe(
                toArray(),
                map((results: IHttpResult[]) => !results.find(r => r.isError))
            );
    }

    /**
     * Deletes the association between a SuperTask and a TransportPoosition
     * @param {TransportPosition} position
     */
    removePositionFromTask(position: TransportPosition): void {
        this._confirm.askForConfirmation({
            title: 'ui.super.tasks.confirmations.remove.position.title',
            message: 'ui.super.tasks.confirmations.remove.position.message',
        }).pipe(
            switchMap(() => this._transportPositionApi.update({ ...position, superTaskId: undefined }))
        ).subscribe();
    }

    /**
     * Uses API-Service to create a List of TransportTasks for all HandlingUnits in the
     * associated and finished TransportPositions
     * @param {SubTaskCreate} subTaskDto
     */
    createSubTasks(subTaskDto: SubTaskCreate): Observable<boolean> {
        return this._api.createSubTasks(subTaskDto)
            .pipe(
                map((res: IHttpResult<any>) => !res.isError)
            )
    }

    protected override searchProperty: PropertySearcher<SuperTask, SuperTaskQuery> = (searchList, key, query) => {
        if (key === 'transportPositions') {
            const transportQuery = query?.[0];
            const withTransports = searchList.filter(s => !!s.transportPositions?.length);
            return withTransports.filter(s => SearchUtil.search(s.transportPositions!, transportQuery).length > 0);
        }

        if (key === 'articleSourceName') {
            return this._searchByWarehouse(searchList, query);
        }

        if (key === 'destinationWarehouseName') {
            return searchList.filter(s => s.destinationWarehouseObj?.warehouseName.toLowerCase().includes(query.toLowerCase()))
        }

        if (key === 'bookingNumber') {
            const withTransports = searchList.filter(s => !!s.transportPositions?.length);
            return withTransports.filter(s => SearchUtil.search(s.transportPositions!, { [key]: query }).length > 0);
        }

        if (key === 'articleGroupId') {
            const withTransports = searchList.filter(s => !!s.transportPositions?.length);
            const objQuery = typeof query === 'string' ? {articleGroupObj: {name: query}} : { [key]: query }
            return withTransports.filter(s => SearchUtil.search(s.transportPositions!, objQuery).length > 0);
        }

        return undefined;
    };

    private _searchByWarehouse(searchList: SuperTask[], warehouseName: string): Observable<SuperTask[]> {
        return this._warehouseService.search({ warehouseName }).pipe(
            map(warehouses => warehouses.map(w => w.warehouseId!)),
            map(w => {
                return searchList.filter(s => s.transportPositions?.some(t => w.includes(t.articleSourceId!)))
            })
        );
    }
}
