import {NamedColumns} from "./column-definitions-provider.interface";
import {ViewChangedEvent} from '../../buttons/model/view-change.model';

export {
  ImportEvent,
  FileImportEvent,
  ImportOrderChangedEvent,
  ImportNamingChangedEvent,
  ImportViewChangeEvent,
  ImportView
};

/**
 * Represents one imported object.
 * The start property is used as flag to show the start of an import and tell how many objects will be imported
 * Categ orises the import by its quality
 */
class ImportEvent<T> {
  constructor(value: ImportEvent<T>) {
    Object.bind(this, value);
  }

  /**
   * Marker event for import start. Info about amount of imported objects
   */
  start?: number;

  /**
   * An object that fits all configuration
   */
  complete?: T;

  /**
   * An Object that is parsable but has missing required attributs
   */
  inComplete?: T

  /**
   * An Object that was not parsable becuse of missing properties or bad ordering
   */
  corrupted?: T;
}

/**
 * Represents all imports of an single file.
 * All imported entities get collected representing their state of health.
 * Imports a categorized in three classes [Complete, InComplete, corrupted]
 */
class FileImportEvent<T = any> {

  get firstAvailableDatSet(): Array<T> {
    if (this.complete.length > 0) {
      return this.complete;
    }

    if (this.inComplete && this.inComplete.length > 0) {
      return this.inComplete;
    }

    if (this.corrupted && this.corrupted.length > 0) {
      return this.corrupted;
    }
    return this.complete;
  }

  complete: T[] = new Array<T>();
  inComplete?: T[] = undefined;
  corrupted?: T[] = undefined

  constructor(value: Partial<FileImportEvent>) {
    if (value.complete) {
      this.complete = value.complete;
    }
    if (value.inComplete) {
      this.inComplete = value.inComplete;
    }

    if (value.corrupted) {
      this.corrupted = value.corrupted;
    }
  }
}

class ImportOrderChangedEvent {
  public id: string;
  public oldImportOrder: string[];
  public newImportOrder: string[];

  constructor(value: ImportOrderChangedEvent) {
    this.id = value.id;
    this.oldImportOrder = value.oldImportOrder;
    this.newImportOrder = value.newImportOrder;
  }
}

class ImportNamingChangedEvent {
  public id: string;
  public oldImportNames: NamedColumns;
  public newImportNames: NamedColumns;
  public changed: { old: string, new: string };

  constructor(value: ImportNamingChangedEvent) {
    this.id = value.id;
    this.oldImportNames = value.oldImportNames;
    this.newImportNames = value.newImportNames;
    this.changed = value.changed;
  }
}

type ImportView = 'complete' | 'inComplete' | 'corrupted';

class ImportViewChangeEvent<T> {
  constructor(
    public source: ViewChangedEvent<ImportView>,
    public associatedData: T[]
  ) {
  }
}
