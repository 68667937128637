import {InjectionToken, ModuleWithProviders, NgModule, Type} from '@angular/core';
import {LockGuard} from './lock-guard/lock.guard';
import {LockRouterModule} from './lock-router/lock-router.module';

export const LOCK_TYPE = new InjectionToken('LOCK_TYPE');
export const LOCK_ID = new InjectionToken('LOCK_ID');

@NgModule({
    imports: [
        LockRouterModule,
    ]
})
export class LockModule {

    static forID<T>(type: Type<T>, id?: keyof T): ModuleWithProviders<LockModule> {
        return {
            ngModule: LockModule,
            providers: [
                {
                    provide: LOCK_TYPE,
                    useValue: type,
                },
                {
                    provide: LOCK_ID,
                    useValue: id
                },
                LockGuard
            ]
        }
    }
}
