export {
  NavigationRoute
}

class NavigationRoute {
  constructor(
    public route: string,
    public title: string
  ) {
  }
}
