import {Component, forwardRef, Optional, Self} from '@angular/core';
import {Warehouse} from '../../domain/core-model';
import {BaseFormFieldComponent} from '../base-form-field/base-form-field.component';
import {CustomControl} from '../model/custom-control.model';
import {NgControl} from '@angular/forms';
import {BaseFormFieldProviders} from '../model/base-form-field-config.model';
import {WarehouseService} from '../../entity-state/warehouse/warehouse-service/warehouse.service';

@Component({
    selector: 'app-warehouse-form-field',
    templateUrl: '../base-form-field/base-form-field.component.html',
    styleUrls: ['../base-form-field/base-form-field.component.scss'],
    providers: [
        {provide: BaseFormFieldComponent, useExisting: forwardRef(() => WarehouseFormFieldComponent)},
        {provide: CustomControl, useExisting: forwardRef(() => WarehouseFormFieldComponent)},
        {provide: 'DefaultLabel', useValue: 'Quelle/Ziel'},
    ],
})
export class WarehouseFormFieldComponent extends BaseFormFieldComponent<Warehouse> {

    constructor(
        @Optional() @Self() public override ngControl: NgControl,
    ) {
        super(
            new BaseFormFieldProviders<Warehouse>({providerType: WarehouseService}),
            {key: 'warehouseId', displayKeys: ['warehouseName', 'description'], defaultLabel: 'Quelle/Ziel'},
            ngControl
        );
    }

    override get selectedEntity(): Warehouse | null {
        const controlValue = this.control.value;
        if (typeof controlValue === 'number') {
            return this.autoCompleteOptions?.find(s => s.warehouseId === controlValue) ?? null;
        }
        return null;
    }

}
