import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {
    AnimatedEllipsisComponent,
    BooleanFieldComponent,
    CenteredLoadingShadeComponent,
    ClickableFieldComponent,
    ConfirmDialogComponent,
    FloatingButtonBarComponent,
    FormColumnComponent,
    FormComponent,
    FormErrorsComponent,
    FormRowComponent,
    FormRowControlsComponent,
    ImagePlaceHolderComponent,
    InfoDialogComponent,
    NoDataSourceFoundWarningComponent,
    NullableFieldComponent,
    PageComponent,
    SubmitRowComponent,
    SubPageComponent
} from './components';
import {CommonModule} from '@angular/common';
import {ControlShortCutDirective, DragAndDropDirective, FitTextDirective} from './directives';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularMaterialModule} from './angular-material';
import {DisclaimerComponent} from './components/disclaimer/disclaimer.component';
import {HoverToggleDirective} from './directives/hover-toggle/hover-toggle.directive';
import {ButtonsModule} from '../buttons';
import {SmallLoadingShadeComponent} from './components/small-loading-shade/small-loading-shade.component';
import {TranslateModule} from '@ngx-translate/core';

const AngularModules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule
];

const ChildModules = [
    AngularMaterialModule,
    ButtonsModule
];

const Components = [
    CenteredLoadingShadeComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    FormErrorsComponent,
    ImagePlaceHolderComponent,
    NullableFieldComponent,
    FloatingButtonBarComponent,
    FormRowComponent,
    FormColumnComponent,
    SubmitRowComponent,
    FormRowControlsComponent,
    ClickableFieldComponent,
    BooleanFieldComponent,
    DisclaimerComponent,
    PageComponent,
    SubPageComponent,
    FormComponent,
    SmallLoadingShadeComponent,
    AnimatedEllipsisComponent,
    NoDataSourceFoundWarningComponent
];

const Directives = [
    DragAndDropDirective,
    FitTextDirective,
    ControlShortCutDirective,
    HoverToggleDirective,
];


@NgModule({
    declarations: [
        ...Components,
        ...Directives,
    ],
    imports: [
        ...ChildModules,
        ...AngularModules,
    ],
    exports: [
        ...ChildModules,
        ...AngularModules,
        ...Components,
        ...Directives,
    ]
})
export class SharedModule {
}
