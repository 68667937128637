<div class="transport-position-state-select-container" [attr.aria-labelledby]="_formField?.getLabelId()" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
  <mat-form-field [appearance]="appearance" [color]="color">
    <mat-label>{{ label ?? "Status" }}</mat-label>
    <mat-select
      [multiple]="multiple"
      [required]="required"
      [formControl]="control"
      class="transport-position-state-select-element"
      [aria-label]="label ?? 'Status'"
      (selectionChange)="_handleInput()"
      (valueChange)="checkUserReset($event)"
      color="primary"
    >
      <ng-container *ngIf="!required && !multiple">
        <mat-option [value]="null">
          <span>--</span>
        </mat-option>
      </ng-container>

      <mat-option class="select-option" [value]="possibleStates.Created" [disabled]="(!disableProcessBlocking && purpose === 'edit' && value > possibleStates.Released) || hasHuAssigned">
        <span>{{ stateDescriptions.Created }}</span>
      </mat-option>

      <mat-option [value]="possibleStates.ToBeChecked" [disabled]="(!disableProcessBlocking && value > possibleStates.Released) || hasHuAssigned">
        <span>{{ stateDescriptions.ToBeChecked }}</span>
      </mat-option>

      <mat-option [value]="possibleStates.Released" [disabled]="(!disableProcessBlocking && value > possibleStates.InProgress) || hasHuAssigned">
        <span>{{ stateDescriptions.Released }}</span>
      </mat-option>

      <mat-option [value]="possibleStates.InProgress" [disabled]="(!disableProcessBlocking && value > possibleStates.Picked) || hasHuAssigned">
        <span>{{ stateDescriptions.InProgress }}</span>
      </mat-option>

      <mat-option [value]="possibleStates.Picked" [disabled]="!disableProcessBlocking && value > possibleStates.ReadyForTransport">
        <span>{{ stateDescriptions.Picked }}</span>
      </mat-option>

      <mat-option [value]="possibleStates.ReadyForTransport" [disabled]="!disableProcessBlocking && value > possibleStates.InTransport">
        <span>{{ stateDescriptions.ReadyForTransport }}</span>
      </mat-option>

      <mat-option [value]="possibleStates.InTransport" [disabled]="!disableProcessBlocking && value === possibleStates.Finished">
        <span>{{ stateDescriptions.InTransport }}</span>
      </mat-option>

      <mat-option [value]="possibleStates.Finished">
        <span>{{ stateDescriptions.Finished }}</span>
      </mat-option>
    </mat-select>

    <mat-progress-spinner mode="indeterminate" *ngIf="!userAccessLoaded" color="accent" diameter="16" matSuffix></mat-progress-spinner>

    <mat-error>
      <app-form-errors [control]="control" [entityName]="label ?? 'Status'"></app-form-errors>
    </mat-error>
  </mat-form-field>
</div>
