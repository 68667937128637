import {NgModule} from '@angular/core';
import {InitiatorFormFieldComponent} from "./initiator-form-field/initiator-form-field.component";
import {CustomFormFieldComponent} from "./custom-form-field.component";
import {HandlingUnitFormFieldComponent} from "./handling-unit-form-field/handling-unit-form-field.component";
import {WarehouseFormFieldComponent} from "./warehouse-form-field/warehouse-form-field.component";
import {VehicletypeFormFieldComponent} from "./vehicletype-form-field/vehicletype-form-field.component";
import {ArrayFormFieldComponent} from "./array-form-field/array-form-field.component";
import {ArticleGroupFormFieldComponent} from "./article-group-form-field/article-group-form-field.component";
import {
    TransportTaskStateFormFieldComponent
} from "./transport-task-state-form-field/transport-task-state-form-field.component";
import {
    TransportPositionStateFormFieldComponent
} from "./transport-position-state-form-field/transport-position-state-form-field.component";
import {SharedModule} from "../shared";
import {StorageBinFormFieldComponent} from "./storage-bin-form-field/storage-bin-form-field.component";
import {PriorityLevelFormFieldComponent} from "./priority-level-form-field/priority-level-form-field.component";
import {StorageAreaFormFieldComponent} from "./storage-area-form-field/storage-area-form-field.component";
import {UserFormFieldComponent} from "./user-form-field/user-form-field.component";
import {DateTimeFormFieldComponent} from "./date-time-form-field/date-time-form-field.component";
import {ArticleFormFieldComponent} from "./article-form-field/article-form-field.component";
import {BaseFormFieldComponent} from './base-form-field/base-form-field.component';
import {BaseFormFieldOptionComponent} from './base-form-field-option/base-form-field-option.component';
import {TranslateModule} from "@ngx-translate/core";
import {
    PriorityComponent,
    TransportPositionStateComponent,
    TransportTaskStateComponent
} from '../modules/tms/components';
import {VehicleFormFieldComponent} from './vehicle-form-field/vehicle-form-field.component';


@NgModule({
    declarations: [
        BaseFormFieldComponent,
        CustomFormFieldComponent,
        ArticleFormFieldComponent,
        HandlingUnitFormFieldComponent,
        UserFormFieldComponent,
        WarehouseFormFieldComponent,
        StorageAreaFormFieldComponent,
        ArticleGroupFormFieldComponent,
        InitiatorFormFieldComponent,
        StorageBinFormFieldComponent,
        TransportPositionStateFormFieldComponent,
        PriorityLevelFormFieldComponent,
        DateTimeFormFieldComponent,
        ArrayFormFieldComponent,
        TransportTaskStateFormFieldComponent,
        VehicletypeFormFieldComponent,
        BaseFormFieldOptionComponent,
        VehicleFormFieldComponent
    ],
    imports: [
        SharedModule,
        TranslateModule,
        PriorityComponent,
        TransportPositionStateComponent,
        TransportTaskStateComponent
    ],
    exports: [
        ArticleFormFieldComponent,
        HandlingUnitFormFieldComponent,
        UserFormFieldComponent,
        WarehouseFormFieldComponent,
        StorageAreaFormFieldComponent,
        ArticleGroupFormFieldComponent,
        InitiatorFormFieldComponent,
        StorageBinFormFieldComponent,
        TransportPositionStateFormFieldComponent,
        PriorityLevelFormFieldComponent,
        DateTimeFormFieldComponent,
        ArrayFormFieldComponent,
        TransportTaskStateFormFieldComponent,
        VehicletypeFormFieldComponent,
        VehicleFormFieldComponent
    ]
})
export class FormFieldsModule {
}
