import {Injectable} from '@angular/core';
import sha256 from 'crypto-js/sha256';
import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8'

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private static AES_KEY = 'oA6Mauwr@3q@ruLj*yWE%r5DtqmT%E8%vTDUCjyC';

  constructor() {
  }

  get(key: string) {
    let storageItem = localStorage.getItem(this.hashKey(key));
    if (storageItem) {
      storageItem = this.decryptData(storageItem);
      try {
        return JSON.parse(storageItem);
      } catch (err) {
        return null;
      }
    }
    return null;
  }

  set<X extends object>(key: string, value: X) {
    const storageValue = this.encryptData(JSON.stringify(value));
    localStorage.setItem(this.hashKey(key), storageValue);
  }

  remove(key: string) {
    localStorage.removeItem(this.hashKey(key));
    return !!this.get(key);
  }

  clear() {
    localStorage.clear();
  }

  private hashKey(key: string) {
    return sha256(key).toString();
  }

  private encryptData(data: string) {
    return AES.encrypt(data, StorageService.AES_KEY).toString();
  }

  private decryptData(data: string) {
    return AES.decrypt(data, StorageService.AES_KEY).toString(enc);
  }
}
