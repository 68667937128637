import {NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';
import {PseudoModuleRoutes} from 'src/app/constants';
import {PrivilegeLevels} from "src/app/roles";
import {IcsIdentityAuthenticationGuard} from 'ics-identity-authentication';
import {PrivilegeGuard} from 'ics-core';

const appRoutes: Route[] = [
    {
        path: '',
        loadComponent: () => import('./components/dashboard/dashboard.component').then(c => c.DashboardComponent)
    },
    {
        path: '',
        loadChildren: () => import('@ics-angular/messenger').then(r => r.ROUTES),
        canActivate: [IcsIdentityAuthenticationGuard]
    },
    {
        path: PseudoModuleRoutes.core,
        loadChildren: () => import('./modules/management-core/management-core.module').then(m => m.ManagementCoreModule),
        canActivate: [IcsIdentityAuthenticationGuard, PrivilegeGuard],
        data: {privilegeLevel: PrivilegeLevels.ReadAccess}
    },
    {
        path: PseudoModuleRoutes.tms,
        loadChildren: () => import('./modules/tms/tms.module').then(m => m.TMSModule),
        canActivate: [IcsIdentityAuthenticationGuard, PrivilegeGuard],
        data: {privilegeLevel: PrivilegeLevels.TmsAccess}
    },
    {
        path: PseudoModuleRoutes.lvs,
        loadChildren: () => import('./modules/lvs/lvs.module').then(m => m.LVSModule),
        canActivate: [IcsIdentityAuthenticationGuard, PrivilegeGuard],
        data: {privilegeLevel: PrivilegeLevels.LvsAccess}
    },
    {
        path: PseudoModuleRoutes.admin,
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
        canActivate: [IcsIdentityAuthenticationGuard, PrivilegeGuard],
        canActivateChild: [PrivilegeGuard],
        data: {privilegeLevel: PrivilegeLevels.Admin}
    },
    {
        path: '**',
        loadComponent: () => import('./components/page-not-found/page-not-found.component').then(c => c.PageNotFoundComponent)
    }
];


@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot(appRoutes, {useHash: true})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
