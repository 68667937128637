import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AppInfo, AppReleaseInfo} from '../../domain/core-model';

type AppAccessDeniedResult = { message: string, statusCode: number, code: string };

@Injectable({
  providedIn: 'root'
})
export class MobileAppApiService {

  readonly backendIp = environment.coreUrl;

  constructor(private http: HttpClient) {
  }

  getLatestApp(): Observable<AppInfo | null> {
    return this.getApp();
  }
  
  getApp(id?: number): Observable<AppInfo | null> {
    let params = new HttpParams();
    if (id) {
      params = params.append('id', id);
    }

    return this.http.get<AppInfo>(this.backendIp + '/app/GetApp', {params})
      .pipe(
        catchError(err => of(null)),
        map(res => this._checkAccessDeniedResult(res))
      )
  }

  getReleases(): Observable<AppReleaseInfo[]> {
    return this.http.get<AppReleaseInfo[]>(this.backendIp + '/app/GetReleases')
      .pipe(
        catchError(err => of(new Array<AppReleaseInfo>())),
        map(res => this._checkAccessDeniedResult(res))
      )
  }

  private _checkAccessDeniedResult(result: any | AppAccessDeniedResult | null) {
    if (!result) {
      return null;
    }

    return 'statusCode' in result && result.statusCode === 401 ? null : result;
  }
}
