import {Component, OnInit} from '@angular/core';
import {TableSortService} from '../table-sort/table-sort.service';
import {ActivatedRoute} from '@angular/router';
import {FilterService} from '../../filter/filter-service/filter.service';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-paginator-menu',
    templateUrl: './paginator-menu.component.html',
    styleUrls: ['./paginator-menu.component.scss'],
    imports: [
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule
    ],
    standalone: true
})
export class PaginatorMenuComponent implements OnInit {

    constructor(
        private _activeRoute: ActivatedRoute,
        private _tableSort: TableSortService,
        private _filterState: FilterService
    ) {
    }

    ngOnInit(): void {
    }

    removeSortState() {
        this._tableSort.clearStorage(this._activeRoute);
    }

    removeFilterState() {
        this._filterState.clearStorage();
    }
}
