<div (keydown.escape)="clear()" class="base-input-container">
    <mat-form-field [appearance]="appearance"
                    [color]="color"
                    [style]="style" data-cy="entity-form-field">

        <mat-label>
            <div #contentLabel class="content-label-area">
                <ng-content select="[label]"></ng-content>
            </div>

            <ng-container *ngIf="contentLabel?.children?.length === 0">

                <ng-container *ngIf="label; else defaultLabelTemplate">
                    {{label|async}}
                </ng-container>

                <ng-template #defaultLabelTemplate>
                    {{behaviorConfig.defaultLabel}}
                </ng-template>

            </ng-container>

        </mat-label>

        <input [formControl]="control" [matAutocomplete]="entityComplete"
               [required]="required" [tabindex]="tabindex"
               class="base-input-element" data-cy="entity-input" matInput type="text"/>

        <div class="no-wrap-suffix" matTextSuffix>
            <ng-content select="[suffix]"></ng-content>
        </div>

        <mat-progress-spinner *ngIf="!autoCompleteOptions || !autoCompleteLoaded" color="accent" diameter="16"
                              matTextSuffix mode="indeterminate"></mat-progress-spinner>

        <button (click)="clear()" *ngIf="control.value && clearable" mat-icon-button matIconSuffix>
            <mat-icon>close</mat-icon>
        </button>

        <cdk-virtual-scroll-viewport [itemSize]="10" [maxBufferPx]="400" [minBufferPx]="200">
            <mat-autocomplete #entityComplete (optionSelected)="selectionChange.emit(control.value)"
                              [displayWith]="displayWithKeys.bind(this)"
                              autoActiveFirstOption>

                <ng-container *cdkVirtualFor="let option of filteredOptions$ | async; last as isLast">

                    <ng-container *ngIf="!isBlocked(option)">
                        <mat-option [value]="option">

                            <app-base-form-field-option [displayKeys]="displayKeys" [fallbackKey]="fallbackKey"
                                                        [value]="option"></app-base-form-field-option>
                        </mat-option>

                        <mat-divider *ngIf="!isLast"></mat-divider>
                    </ng-container>

                </ng-container>

            </mat-autocomplete>
        </cdk-virtual-scroll-viewport>

        <mat-error>
            <ng-content select="[errors]"></ng-content>
            <app-form-errors [control]="control"
                             [entityName]="contentLabel.textContent.length > 0 ? contentLabel.textContent : behaviorConfig.defaultLabel"></app-form-errors>
        </mat-error>
    </mat-form-field>
</div>



