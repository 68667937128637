import {Component} from '@angular/core';
import {BaseButtonDirective} from '../base-button/base-button.directive';

@Component({
  selector: 'app-settings-button',
  templateUrl: './settings-button.component.html',
  styleUrls: ['./settings-button.component.scss']
})
export class SettingsButtonComponent extends BaseButtonDirective {
}
