import {Injectable, TemplateRef} from '@angular/core';
import {DialogPurpose} from '../../domain/enums';
import {IManagementDialogCreation, ITypeConfig, ManagementDialogData} from '../../domain/interfaces';
import {ComponentType} from '@angular/cdk/overlay';
import {GenericDialogComponent} from '../../generic-components';
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(private dialog: MatDialog) {
    }

    createGenericManagementDialog<T>(purpose: DialogPurpose, typeConfig: ITypeConfig<T>, entity?: T, leadTitle?: string):
        IManagementDialogCreation {
        const data: ManagementDialogData<T> = {purpose, typeConfig, entity, leadTitle};
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            width: '40%',
            // height: '90%',
            data,
            autoFocus: false
        });
        return {dialogRef, submit: dialogRef.componentInstance.submit};
    }


    /**
     * Makes MatDialogs open function accessible through the service
     * @param component that should be shown in the dialog
     * @param config Configuration of MatDialog check MatDialog Documentation
     * @return MatDialogRef<T> Reference to the MatDialog
     */
    showDialog<T, X = any>(component: ComponentType<T> | TemplateRef<any>, config?: MatDialogConfig): MatDialogRef<T, X> {
        return this.dialog.open(component, config);
    }
}
