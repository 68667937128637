import {SettingsMap} from '../settings-map.model';
import {EntitySettings} from './entity-settings.interface';

export {
  GeneralSettings
}

class GeneralSettings extends EntitySettings {
  importRangeSymbols: [string, string] = ['START', 'END'];
  preferExcelColumnNaming = false;

  constructor(settings: SettingsMap) {
    super();
    if (settings['import-range-symbols']) {
      this.importRangeSymbols = [settings['import-range-symbols'][0], settings['import-range-symbols'][1]];
    }

    if (settings['web-import-column-naming-preferred']) {
      this.preferExcelColumnNaming = JSON.parse(settings['web-import-column-naming-preferred'][0], value => new Boolean(value));
    }
  }

  toMap(): Partial<SettingsMap> {
    return {
      'import-range-symbols': this.importRangeSymbols,
      'web-import-column-naming-preferred': [JSON.stringify(this.preferExcelColumnNaming)]
    }
  }
}
