import {EventEmitter, Injectable} from '@angular/core';
import dayjs from 'dayjs';
import {ActivatedRoute} from '@angular/router';
import {SortDirection} from '@angular/material/sort';
import {filter} from 'rxjs/operators';
import {StorageService} from "../../core";

type SortStorageEntry = { direction: SortDirection, header: string, isParent: boolean }

@Injectable({
  providedIn: 'root'
})
export class TableSortService {

  protected static HEADER_SEPARATOR = ".";
  protected static STORAGE_KEY = "TABLE_SORT";


  private readonly _sortState: Map<string, SortStorageEntry>;
  private readonly _clearedEvent = new EventEmitter<string>();


  constructor(
    private _storage: StorageService
  ) {
    const storageEntry = this._storage.get(TableSortService.STORAGE_KEY);
    this._sortState = new Map(Array.isArray(storageEntry) ? storageEntry : []);
  }

  listenToClear(activeRoute: ActivatedRoute) {
    return this._clearedEvent.asObservable().pipe(filter(key => key === this.getStateKey(activeRoute)));
  }

  hasState(activeRoute: ActivatedRoute) {
    return this._sortState.has(this.getStateKey(activeRoute));
  }

  saveState(activeRoute: ActivatedRoute, direction: SortDirection, header: string, isParent: boolean) {
    this._sortState.set(this.getStateKey(activeRoute), {direction, header, isParent});
    this.updateStorage();
  }

  loadState(activeRoute: ActivatedRoute) {
    return this._sortState.get(this.getStateKey(activeRoute));
  }

  clearStorage(key?: ActivatedRoute) {
    if (!key) {
      this._storage.remove(TableSortService.STORAGE_KEY);
      return;
    }

    this._sortState.delete(this.getStateKey(key));
    this.updateStorage();
    this._clearedEvent.emit(this.getStateKey(key));
    return;
  }


  findSortValue(data: any, sortHeaderId: string) {
    const value = this.getSortHeaderData(data, sortHeaderId);

    if (typeof value === 'string') {
      // accessing dates as real dates
      if (dayjs(value).isValid()) {
        return dayjs(value)
      }
      // accessing strings as their lowercase version
      return value?.toLowerCase();
    }
    return value;
  }

  protected getSortHeaderData(value: any, sortHeaderId: string) {
    let sortHeaders = [sortHeaderId];

    if (sortHeaderId.includes(TableSortService.HEADER_SEPARATOR)) {
      sortHeaders = sortHeaderId.split(TableSortService.HEADER_SEPARATOR);
    }

    let data: any = value;
    for (const header of sortHeaders) {
      data = data[header];
    }

    // end of searching in bad cases
    if (!data || typeof data === 'object') {
      return '';
    }

    return isNaN(Number(data)) ? data : Number(data);
  }

  private getStateKey(paths: ActivatedRoute): string {
    return paths.snapshot.pathFromRoot.reduce((acc, curr) => curr.routeConfig?.path ? acc + `/${curr.routeConfig?.path}` : acc, '');
  }

  private updateStorage() {
    this._storage.set(TableSortService.STORAGE_KEY, this._sortState);
  }
}
