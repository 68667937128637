import {Component, ElementRef, NgZone, OnInit} from '@angular/core';
import {BaseButtonDirective} from '../base-button/base-button.directive';

@Component({
    selector: 'app-unassign-button',
    templateUrl: './unassign-button.component.html',
    styleUrls: ['./unassign-button.component.scss']
})
export class UnassignButtonComponent extends BaseButtonDirective implements OnInit {

    constructor(_el: ElementRef, _zone: NgZone) {
        super(_el, _zone);
    }

}
