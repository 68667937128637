<mat-button-toggle-group *ngIf="firstView" [value]="initialView ?? firstView.name">
  <mat-button-toggle *ngFor="let view of views" [value]="view.name" (click)="emitViewChange(view)">
    <ng-container *ngIf="!view.icon" [ngTemplateOutlet]="raw"
                  [ngTemplateOutletContext]="{view: view}"></ng-container>

    <ng-container *ngIf="view.svgIcon" [ngTemplateOutlet]="svgIcon"
                  [ngTemplateOutletContext]="{name: view.icon}"></ng-container>

    <ng-container *ngIf="!view.svgIcon" [ngTemplateOutlet]="materialIcon"
                  [ngTemplateOutletContext]="{name: view.icon}"></ng-container>
  </mat-button-toggle>
</mat-button-toggle-group>


<ng-template #materialIcon let-name="name">
  <mat-icon>{{name}}</mat-icon>
</ng-template>

<ng-template #svgIcon let-name="name">
  <mat-icon [svgIcon]="name"></mat-icon>
</ng-template>

<ng-template #raw let-view="view">
  <span>{{view.displayValue}}</span>
</ng-template>
