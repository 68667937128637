export {
    ImportRangeSymbolsMissingError,
    ClassNotImportableError,
    NoImportableColumnsError,
    ImportError
}

type ImportError = ImportRangeSymbolsMissingError | ClassNotImportableError | NoImportableColumnsError;

// eslint-disable-next-line @typescript-eslint/ban-types
type Class = Function;

class ImportRangeSymbolsMissingError extends Error {

    get rangeSymbols(): { start: string, end: string } {
        return this._rangeSymbols;
    }

    private readonly _rangeSymbols: { start: string, end: string };

    constructor(message: string, symbols: { start: string, end: string }) {
        super(message);
        this.name = 'ImportRangeSymbolsMissing';
        this._rangeSymbols = symbols;
    }
}

class ClassNotImportableError extends Error {

    get className(): string {
        return this._className.toString();
    }

    private _className: Class;

    constructor(message: string, className: Class) {
        super(message);
        this.name = 'ClassNotImportableError';
        this._className = className;
    }
}

class NoImportableColumnsError extends Error {

    get className(): string {
        return this._className.toString();
    }

  // eslint-disable-next-line @typescript-eslint/ban-types
    private _className: Function;

    constructor(message: string, className: Class) {
        super(message);
        this.name = 'NoImportableColumnsError';
        this._className = className;
    }
}

