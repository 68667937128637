import {EntityKey} from '../domain/utility-types';
import {BaseEntity} from "../domain";

export {
    PseudoModuleRoutes,
    FeatureModuleRoutes,
    ChildRoutes,
    RouteParams,
    RouteDataParams,
    RouteQueryParams,
    StateParams,
    NavigationQueryParams,
    RouteQuery
}

export interface PseudoModules {
    readonly admin: string;
    readonly core: string;
    readonly tms: string;
    readonly lvs: string;
}

const PseudoModuleRoutes: PseudoModules = {
    admin: 'admin',
    core: 'core',
    lvs: 'lvs',
    tms: 'tms'
};

interface CoreRoutes {
    readonly article: string;
    readonly warehouse: string;
    readonly handlingUnit: string;
    readonly vehicle: string;
    readonly vehicleType: string;
}

interface TmsRoutes {
    readonly transportPosition: string;
    readonly superTask: string;
    readonly transportTask: string;
    readonly initiator: string;
    readonly materialRequest: string;
    readonly transportHistory: string;
}

interface LvsRoutes {
    readonly storageArea: string;
    readonly storageBin: string;
    readonly requisition: string;
    readonly inventory: string;
}

interface AdminRoutes {
    readonly user: string;
    readonly systemSettings: string;
    readonly systemLogs: string;
    readonly mobileAppDownload: string;
}

interface Modules {
    readonly admin: AdminRoutes;
    readonly core: CoreRoutes;
    readonly lvs: LvsRoutes;
    readonly tms: TmsRoutes;
}

const AdminRoutes: AdminRoutes = {
    systemLogs: 'system-log',
    systemSettings: 'system-setting',
    user: 'user',
    mobileAppDownload: 'mobile-app-download'
}

const CoreRoutes: CoreRoutes = {
    article: 'article',
    handlingUnit: 'handling-unit',
    vehicle: 'vehicle',
    vehicleType: 'vehicle-type',
    warehouse: 'warehouse'
}

const LvsRoutes: LvsRoutes = {
    inventory: 'inventory',
    requisition: 'requisition',
    storageArea: 'storage-area',
    storageBin: 'storage-bin'
}


const TmsRoutes: TmsRoutes = {
    initiator: 'initiator',
    materialRequest: 'material-request',
    superTask: 'super-task',
    transportPosition: 'transport-position',
    transportTask: 'transport-task',
    transportHistory: 'transport-task/history'
}

const FeatureModuleRoutes: Modules = {
    admin: AdminRoutes,
    core: CoreRoutes,
    lvs: LvsRoutes,
    tms: TmsRoutes
}

enum ChildRoutes {
    Start = 'start',
    Dashboard = 'dashboard',
    Table = 'table',
    List = 'list',
    Details = 'details',
    Edit = 'edit',
    General = 'general',
    Master = 'master',
    History = 'history',
    Create = 'create',
    Group = 'group',
    Import = 'import',
    Check = 'check',
    Developer = 'developer',
    ExternalApi = 'external-api',
    Survey = 'survey',
    Monitor = 'monitor'
}

enum RouteParams {
    EntityId = 'id'
}

enum RouteDataParams {
    Privileges = 'privileges',
    Redirect = 'redirect',
    Type = 'type'
}

enum RouteQueryParams {
    ReturnTo = 'returnTo',
    Submitted = 'submitted',
    InComplete = 'inComplete',
    PanelId = 'panelId',
    Preserve = 'preserve',
    Inactive = 'inactive'
}

type NavigationQueryParams = Partial<{ [key in RouteQueryParams]: any }>;

enum StateParams {
    Entities = 'entities',
    Submitted = 'submitted',
}

type EntityQueryParam<T extends BaseEntity> = keyof EntityKey<T>;

type CombinedQueryNames<T extends BaseEntity = never> = T extends never ? RouteQueryParams : RouteQueryParams | EntityQueryParam<T>;

type RouteQuery<T extends BaseEntity = never> = { [key in CombinedQueryNames<T>]?: string | number };
