import {Export} from "src/app/export";
import {StorageBin} from "./storage-bin.model";
import {BaseEntity} from "../base-entity.model";


export class ArticleGroupRequisitionStorageBin extends BaseEntity {
  constructor(
    public articleGroupRequisitionId: number,
    @Export({propertyKey: 'storageBinId', view: 'Lagerplatz', extern: true,})
    public storageBinId: string,
    @Export({propertyKey: 'storageAreaId', view: 'Lagerbereich', extern: true})
    public storageAreaId: string,
    @Export({propertyKey: 'warehouseId', view: 'Quelle', extern: true})
    public warehouseId: number,
    //Nav Properties
    public storageBinObj?: StorageBin
  ) {
    super();
  }
}
