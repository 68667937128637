<div
  [attr.aria-labelledby]="formField?.getLabelId()"
  (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)"
  class="date-input-container">
  <mat-form-field [appearance]="appearance" [color]="color" style="width: 85%">
    <mat-label>{{label}}</mat-label>
    <input matInput [formControl]="dateControl" [matDatepicker]="picker" (input)="_handleInput()" aria-label="Date Input" [required]="required"/>
    <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker [startAt]="startDate" #picker></mat-datepicker>
  </mat-form-field>
</div>


