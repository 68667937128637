import {EntityType} from '../enums';

export class Audit<T> {
  constructor(
    public auditId: number,
    public userId: string,
    public entityType: EntityType,
    public idOfChangedEntity?: string,
    public dateTime?: string,
    public oldValues?: string,
    public newValues?: string,
  ) {
  }

}
