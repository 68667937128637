export enum DialogPurpose {
  EDIT = 'edit',
  CREATE = 'add',
  DELETE = 'delete',
  IMPORT = 'import',
  DISPLAY = 'display'
}

export enum DialogMessage {
  CLOSE = 'close',
  SUCCESS = 'success',
  FAIL = 'fail',
  ABORT = 'abort',
  CONFIRM = 'confirm',
  NO_CHANGES = 'noChanges'
}

export enum DialogTitle {
  EDIT = 'Bearbeiten',
  CREATE = 'Hinzufügen',
  DELETE = 'Löschen',
  IMPORT = 'Importieren',
  WARN = 'Warnung'
}

export enum InfoHeader {
  ERRORS = 'Fehlerinformationen',
  ERROR = 'Fehler'
}

export enum DialogWarnings {
  BLOCK_TRANSPORT_TASK = 'Transportauträge sollten bevorzugt durch Scannen in der mobilen App erstellt werden'
}
