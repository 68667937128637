import {Type} from '@angular/core';
import {FlattenedStringMap, IObjectParser} from '../parsers/object-parser.interface';

export {
    ExportConfigError,
    IllegalExportConfigError,
    ExportParserError,
    ParsingError
}

class ParsingError extends Error {
    constructor(reason: string, property: string, value: any) {
        super(`Could not parse property ${property} because ${value} violated ${reason}`);

        Object.setPrototypeOf(this, ParsingError);
    }
}

class ExportParserError extends Error {

    constructor(parser: Type<IObjectParser<FlattenedStringMap | FlattenedStringMap[]>>, reason: string) {
        super(
            `${parser.name} an error Happened which made the parsing of objects in the current export impossible.
               Reason for this error was ${reason};
            `
        );

        Object.setPrototypeOf(this, ExportParserError);
    }
}

class ExportConfigError extends Error {

    constructor(from: Function, forType: Function, reason: string) {
        super(
            `${from.name} could not load config for type ${forType.name} because ${reason}`
        );

        Object.setPrototypeOf(this, ExportConfigError.prototype);
    }
}

class IllegalExportConfigError extends Error {

    constructor(target: Function, property: string | symbol | undefined, reason: string) {
        super(
            `Illegal export config was provided for the property ${String(property) ?? 'UNKNOWN'} of class ${target?.name ?? 'UNKNOWN'}.
             Reason for this error was: ${reason}.
            `
        );
        Object.setPrototypeOf(this, IllegalExportConfigError.prototype);
    }
}
