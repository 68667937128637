import {BaseTableComponent} from './base-table.component';
import {EventEmitter} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';

export {
  TableMapper
}

const mapOutputs = <X extends BaseTableComponent>(baseTableRef: BaseTableComponent<any>, tableExtension: X, outputs: Array<string>): void => {
  for (const output of outputs) {
    const tableOutputKey = output as keyof BaseTableComponent;
    const baseOutput = baseTableRef[tableOutputKey];
    const subOutput = tableExtension[tableOutputKey];
    if (subOutput && baseOutput) {
      if (subOutput instanceof EventEmitter && baseOutput instanceof EventEmitter) {
        const destroyHandle: Subject<void> = (tableExtension as any)._subscriptions!;
        (baseOutput as EventEmitter<any>).pipe(
          takeUntil(destroyHandle)
        ).subscribe((event) => subOutput.emit(event));
      }
    }
  }
};

const TableMapper = {
  mapOutputs
};
