import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appHoverToggle]',
  exportAs: 'hoverToggle'
})
export class HoverToggleDirective {

  @Output() hoverChange: EventEmitter<{ hoverState: boolean }> = new EventEmitter();

  hovered: boolean = false;

  constructor() { }

  @HostListener('mouseover', (['$event']))
  onHover() {
    this.setState(true);
  }

  @HostListener('mouseleave', (['$event']))
  onHoverEnd() {
    this.setState(false);
  }


  private setState(state: boolean) {
    setTimeout(() => {
      this.hovered = state;
      this.hoverChange.emit({ hoverState: this.hovered });
    }, 20);
  }
}
