import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: 'locker',
        loadComponent: () => import('../un-lock-panel/un-lock-panel.component').then(c => c.UnLockPanelComponent)
    }
];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forChild(routes)
    ]
})
export class LockRouterModule {
}
