import {Pipe, PipeTransform} from '@angular/core';

type WeightUnit = 'mg' | 'g' | 'kg' | 't';

const WEIGHT_FACTOR = 1_000;

@Pipe({
    name: 'weight',
    standalone: true
})
export class WeightPipe implements PipeTransform {

    transform(weight: number, sourceUnit: WeightUnit, destinationUnit: WeightUnit, amount = 1): number | null {
        if (weight === null || weight === undefined) {
            return null;
        }

        if (weight === 0) {
            return 0;
        }

        // indexing weight units for factorization
        const indexedWeightUnits = ['mg', 'g', 'kg', 't'];

        // reading index of source and destination to get distance for factor
        const sourceIndex = indexedWeightUnits.findIndex(s => s === sourceUnit);
        const destinationIndex = indexedWeightUnits.findIndex(s => s === destinationUnit);

        // factor is distance * 1000 because each UNIT a step of 1000 up or down
        const factor = Math.pow(WEIGHT_FACTOR, destinationIndex - sourceIndex);

        // if destination index is bigger go up in unit and multiply
        // amount can always be multiplied because default is
        return (destinationIndex > sourceIndex ? weight / factor : weight * factor) * amount;
    }

}
