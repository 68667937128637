import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, skipWhile, switchMap, take} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {IcsIdentityAuthenticationService} from 'ics-identity-authentication';
import {UserRole} from '../model/user-role.enum';
import {UserApiService} from '../../api/user/user-api.service';
import {IRoleProvider} from 'ics-core';
import {environment} from '../../../environments/environment';
import {EnvironmentType} from '../../../environments/environment.interface';

@Injectable({
    providedIn: 'platform'
})
export class RoleService implements IRoleProvider {

    private _userRoles = new ReplaySubject<UserRole[]>(1);

    constructor(
        private _auth: IcsIdentityAuthenticationService,
        private _userApi: UserApiService
    ) {
        this._initRoles();
    }

    get currentUserId$() {
        return this._auth.user.pipe(
            map(u => u?.sub ?? 'not_authenticated_user'),
            distinctUntilChanged(),
        );
    }

    /**
     * Deprecated use only for development testing
     * @deprecated
     * @param roles
     */
    public enforceUserRole(roles: UserRole[]): void {
        if (environment.environmentType !== EnvironmentType.DEVELOPMENT) {
            return;
        }

        this._userRoles.next([]);

        setTimeout(() => this._userRoles.next(roles), 200);
    }

    public getUserRoles(): Observable<UserRole[]> {
        return this._userRoles.asObservable();
    }

    private _initRoles(): void {
        this._auth.isAuthenticated.pipe(
            skipWhile(isAuthenticated => !isAuthenticated),
            distinctUntilChanged(),
            switchMap(() => this._userApi.getUserRoles()),
            take(1)
        ).subscribe((userRoles) => this._userRoles.next(userRoles));
    }
}
