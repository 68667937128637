import {User, Warehouse} from '../core-model';
import {ArticleGroup} from './article-group.model';
import {Initiator} from './inittiator.model';
import {TransportPositionState} from './transport-position-state.model';
import {PriorityLevel} from './priority-level.model';
import {TransportPosition} from './transport-position.model';
import {DialogMessage} from '../enums';

export {
  TransportGroupSummary,
  TransportGroup,
  TransportGroups,
  TransportGroupUpdateEvent
}

type TransportGroupSummaryType = Pick<TransportPosition, 'articleGroupName' | 'articleGroupId' | 'operator' | 'userName'
  | 'toBeDoneBy' | 'destination' | 'destinationWarehouseObj' | 'initiator' | 'priorityLevel'
  | 'articleSourceId' | 'transportState'>;

class TransportGroupSummary {

  get articleGroupId(): number | null | undefined {
    return typeof this.articleGroup === 'object' ? this.articleGroup?.articleGroupId : this.articleGroup;
  }

  get userId(): string | null | undefined {
    return typeof this.operator === 'object' ? this.operator?.userId : this.operator;
  }

  get articleSourceId(): number | null | undefined {
    return typeof this.articleSource === 'object' ? this.articleSource?.warehouseId : this.articleSource;
  }

  get destination(): number | null | undefined {
    return typeof this.destinationWarehouse === 'object' ? this.destinationWarehouse?.warehouseId : this.destinationWarehouse;
  }

  get initiatorId(): number | null {
    return typeof this.initiator === 'object' ? this.initiator?.initiatorId! : this.initiator;
  }

  public articleSource: number | Warehouse | null = null;
  public destinationWarehouse: number | Warehouse | null = null;
  public articleGroup: ArticleGroup | number | null = null;
  public operator: User | string | null = null;
  public initiator: Initiator | number | null = null;
  public transportState = TransportPositionState.Created
  public toBeDoneBy?: string | null;
  public priorityLevel? = PriorityLevel.LowPriority;
  public createdOn: TransportPosition | Partial<TransportPosition>;


  constructor(value: TransportPosition | Partial<TransportPosition>) {
    this.createdOn = value;
    this.articleSource = value.sourceWarehouseObj ?? value.articleSourceId ?? null;
    this.destinationWarehouse = value.destinationWarehouseObj ?? value.destination ?? null;
    this.articleGroup = value.articleGroupObj ?? value.articleGroupId ?? null;
    this.operator = value.userObj ?? value.operator ?? null;
    this.initiator = value.initiatorObj ?? value.initiator ?? null;
    this.toBeDoneBy = value.toBeDoneBy;
    this.priorityLevel = value.priorityLevel;

    if (value.transportState) {
      this.transportState = value.transportState;
    }
  }

  toTransportPositionUpdate(): Partial<TransportPosition> {
    const result: Partial<TransportPosition> = {
      toBeDoneBy: this.toBeDoneBy,
      transportState: this.transportState,
      priorityLevel: this.priorityLevel
    };

    if (this.articleSourceId) {
      result.articleSourceId = this.articleSourceId;
      result.sourceWarehouseObj = typeof this.articleSourceId === 'object' ? this.articleSourceId : null;
    }

    if (this.destination) {
      result.destination = this.destination;
      result.destinationWarehouseObj = typeof this.destinationWarehouse === 'object' ? this.destinationWarehouse : null;
    }

    if (this.userId) {
      result.operator = this.userId;
      result.userObj = typeof this.operator === 'string' ? null : this.operator;
    }

    if (this.initiatorId) {
      result.initiator = this.initiatorId;
      result.initiatorObj = typeof this.initiator === 'object' ? this.initiator : null;
    }

    if (this.articleGroupId) {
      result.articleGroupId = this.articleGroupId;
    }

    return result;
  }
}

class TransportGroup {
  constructor(
    public summary: TransportGroupSummary,
    public positions: TransportPosition[]
  ) {
  }

}

class TransportGroups extends Array<TransportGroup> {

  static groupPositions(positions: TransportPosition[]): TransportGroup[] {
    const groups: TransportGroup[] = [];
    if (positions) {
      // added this line to reduce elements in expandable list
      for (const pos of positions) {
        const groupId: number | undefined = pos.articleGroupId ?? undefined;
        // is now dependent to groupId because more safe
        const existing = groups.find((g: TransportGroup) => g.summary.articleGroupId === groupId);

        if (existing) {
          existing.positions.push(pos);
        } else {
          groups.push(new TransportGroup(new TransportGroupSummary(pos), [pos]));
        }
      }
    }
    return groups;
  }

  constructor(transportPositions: TransportPosition[]) {
    super(...TransportGroups.groupPositions(transportPositions));
  }
}

class TransportGroupUpdateEvent {
  constructor(
    public message: DialogMessage,
    public transportGroupSummary: TransportGroupSummary | null,
    public transportGroupUpdate: Partial<TransportPosition>
  ) {
  }
}
