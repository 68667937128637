import {CdkDragDrop, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ColumnConfig} from '../decorators/column.decorator';
import {ImportService} from '../import-service/import.service';
import {
  IMPORT_DEFINITIONS,
  ImportDefinitionsProvider,
  NamedColumns
} from '../model/column-definitions-provider.interface';
import {ImportNamingChangedEvent, ImportOrderChangedEvent} from '../model/import-event.model';

@Component({
  selector: 'app-import-property-order',
  templateUrl: './import-property-order.component.html',
  styleUrls: ['./import-property-order.component.scss']
})
export class ImportPropertyOrderComponent implements OnInit, OnDestroy {

  /**
   * Defines the current order of the columns
   */
  @Input('columnOrder')
  set columnProperties(value: string[]) {
    this._baseColumns = value;
    this._orderableColumns = value.filter((c) => !this.columnDefinitions?.[c]?.restructure);
  }

  get columnProperties(): string[] {
    return this._orderableColumns;
  }

  /**
   * The id's of the columns of the entity mapped to the user-defined names;
   * Stored in the outer system. Default is used per {@link @Column} decorator.
   */
  @Input() columnNames: NamedColumns = {};


  /**
   * Emits every time the order of the column gets changed
   * emits the whole new order
   */
  @Output() columnOrderChange: EventEmitter<string[]> = new EventEmitter();

  /**
   * Emits an event that holds more information about the state change
   * informs about the old and the new state and also holds the id of the changed columns
   */
  @Output() orderChange: EventEmitter<ImportOrderChangedEvent> = new EventEmitter();

  /**
   * Event emitted when user changes the naming of one column
   */
  @Output() columnNameChange: EventEmitter<ImportNamingChangedEvent> = new EventEmitter();


  // stores all info about the columns to order, like their name or if they are required
  columnDefinitions: { [key: string]: ColumnConfig } = {}

  private _orderableColumns: string[] = [];
  // watching drag and drop list div to make it scroll on mouse wheeel
  @ViewChild(CdkDropList, {read: ElementRef}) dragAndDropList!: ElementRef;

  private _baseColumns: string[] = [];

  private _orderedProperties: string[] = [];
  private _subscriptions: Subject<void> = new Subject();

  /**
   * Creates a new Import-Property-Order-Component that allows the user to adjust the
   * order of the imported columns
   * @param {ImportService} _import allows to retrieve information about the possible columns
   * @param {ImportDefinitionsProvider} _columnOrder optional allows the component to retrieve the order of the columns directly from the provider instead via '@Input'
   */
  constructor(
    private _import: ImportService,
    @Inject(IMPORT_DEFINITIONS) @Optional() private _columnOrder?: ImportDefinitionsProvider
  ) {
    // reads all config of the readable columns
    this.columnDefinitions = this._import.importableColumns;
  }

  ngOnInit(): void {
    // starts watching for updates of the column order
    this.watchColumnOrder();
  }

  /**
   * Listens to mouse wheel interactions with this element and shifts the
   *  drag and drop list to the left in range of scroll wheel change
   * @param event
   */
  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    if (this.dragAndDropList) {
      this.dragAndDropList.nativeElement.scrollLeft += event.deltaY;
    }
  }

  /**
   * Implements the change of Drag&Drop by moving the selected element to the
   * given position in array.
   * After that emits the new order to the parent component
   * @param {CdkDragDrop<string[]>} event the Drag&Drop Event
   */
  onDrop(event: CdkDragDrop<string[]>) {
    const oldOrderState = [...new Set([...this._orderableColumns, ...this._baseColumns])];
    moveItemInArray(this._orderableColumns, event.previousIndex, event.currentIndex);

    // emitting the event of order change
    // adding all not order-able columns from the base-set at the end of the emitted array
    this.columnOrderChange.emit([...new Set([...this._orderableColumns, ...this._baseColumns])]);

    // emitting the new directly commutable event
    this.orderChange.emit(new ImportOrderChangedEvent({
      id: this._import.classId,
      oldImportOrder: oldOrderState,
      newImportOrder: [...new Set([...this._orderableColumns, ...this._baseColumns])]
    }));
  }

  /**
   * Updates the current local model to the user changed state and emits an event to the outter system
   * to store the new values
   * @param newValue
   */
  onDescriptionChange(newValue: NamedColumns): void {
    const changedKey = Object.keys(newValue)[0];
    // storing updated state
    const changedNameState = {...this.columnNames, ...newValue}
    // emitting new state info so outer system can store this
    this.columnNameChange.emit(new ImportNamingChangedEvent({
      id: this._import.columnNamesId,
      oldImportNames: this.columnNames,
      newImportNames: changedNameState,
      changed: {
        old: this.columnNames[changedKey], new: changedNameState[changedKey]
      }
    }));
    // writing new state
    this.columnNames = changedNameState;
  }

  /**
   * Ends possible subscription to {@link ImportDefinitionsProvider}
   */
  ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  /**
   * Builds subscription to optional ColumnOrderProvides to retrieve the latest
   * order of columns.
   * This can be used to provide the order and names of the columns without property-binding
   * @private
   */
  private watchColumnOrder(): void {
    if (this._columnOrder) {
      // somehow this needs to separate subscriptions because these does not auto-end

      // subscription for the order of the columns
      this._columnOrder?.getColumnsInOrder(this._import.classId)
        .pipe(takeUntil(this._subscriptions))
        .subscribe((result) => this.columnProperties = result);

      // subscription for the namings of the columns
      this._columnOrder?.getColumnNames(this._import.columnNamesId)
        .pipe(takeUntil(this._subscriptions))
        .subscribe((result) => {
          this.columnNames = result
        });
    }
  }
}
