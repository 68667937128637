import {Article, Warehouse} from 'src/app/domain/core-model';
import {Export, Exportable} from 'src/app/export';
import {ArticleGroup} from '../../../../domain/tms-model';
import {BaseEntity} from '../../../../domain';

export {Inventory, InventoryQuery};

@Exportable({name: 'Bestand'})
class Inventory extends BaseEntity {

    static buildFromEagerLoad(inventory: Inventory): Inventory {
        inventory.articleGroupName = inventory?.articleGroupObj?.name;
        inventory.articleDescription = inventory?.articleObj?.description ?? undefined;
        inventory.articleAdditionalFields = inventory?.articleObj?.additionalFields;
        return inventory;
    }

    @Export({propertyKey: 'warehouseObj', view: 'Quelle', link: Warehouse})
    warehouseObj?: Warehouse | null = null

    @Export({propertyKey: 'storageAreaId', view: 'Lagerbereich'})
    storageAreaId?: string;

    @Export({propertyKey: 'storageBinId', view: 'Lagerplatz'})
    storageBinId?: string;

    @Export({link: ArticleGroup, propertyKey: 'articleGroupObj'})
    articleGroupObj?: ArticleGroup;

    @Export({propertyKey: 'handlingUnitId', view: 'Handlingunit'})
    handlingUnitId?: string;

    @Export({propertyKey: 'bookingNumber', view: 'Buchungsnummer'})
    bookingNumber?: string;

    @Export({propertyKey: 'jobNumber', view: 'Auftragsnummer'})
    jobNumber?: string;

    @Export({propertyKey: 'articleCount', view: 'Anzahl'})
    articleCount!: number;

    @Export({link: Article, propertyKey: 'articleObj'})
    articleObj?: Article;

    @Export({propertyKey: 'articleAdditionalFields', destruct: true})
    articleAdditionalFields?: { [key: string]: string };

    // meta
    @Export({view: 'Letzte Bewegung'})
    override lastModified?: string;


    // gets exported via the linked article
    articleId!: string;
    articleDescription?: string;

    // gets exported via the linked articleGroup
    articleGroupId?: number;
    articleGroupName?: string;

    // gets exported via the linked warehouse
    warehouseId?: number;

    constructor(value?: Inventory) {
        super();

        Object.assign(this, value);
    }
}

type InventoryQuery = Partial<Inventory> & { lastModified?: string, unit?: string, warehouseName?: string };
