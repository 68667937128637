import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Article} from '../core-model';
import {CustomValidators} from '../../form-fields';
import {StorageBin, StorageBinArticle} from '../lvs-model';
import {EntityKey} from '../utility-types';

export {
    StorageBinArticleFormGroup
}

class StorageBinArticleFormGroup extends FormGroup<StorageBinArticleForm> {
    constructor(storageBinKey: EntityKey<StorageBin>) {
        super(new StorageBinArticleForm(storageBinKey));
    }

    override patchValue(value: Partial<StorageBinArticle>, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
        value.articleId ? this.controls.articleObj.disable() : this.controls.articleObj.enable();
        super.patchValue(value, options);
    }

    createStorageBinArticle(): StorageBinArticle | null {
        if (this.invalid) {
            return null;
        }

        const formValue = this.getRawValue();

        return new StorageBinArticle(formValue.articleObj!.articleId, formValue.articleCount, formValue.storageBinId, formValue.warehouseId!, formValue.storageAreaId);
    }
}

class StorageBinArticleForm {

    storageBinId = new FormControl<string>({value: '', disabled: true}, {
        nonNullable: true,
        validators: [Validators.required]
    });
    warehouseId = new FormControl<number | null>({value: null, disabled: true}, {
        nonNullable: true,
        validators: [Validators.required]
    });
    storageAreaId = new FormControl<string>({value: '', disabled: true}, {
        nonNullable: true,
        validators: [Validators.required]
    });

    articleObj = new FormControl<Article | null>(null,
        {
            nonNullable: true,
            validators: [Validators.required, CustomValidators.objectSelectedValidator]
        });

    articleCount = new FormControl<number>(1, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(0.1)]
    });

    constructor(storageBinKey: EntityKey<StorageBin>) {
        this.storageBinId.patchValue(storageBinKey.storageBinId!);
        this.warehouseId.patchValue(storageBinKey.warehouseId!);
        this.storageAreaId.patchValue(storageBinKey.storageAreaId!);
    }


}
