<button mat-icon-button [disabled]="buttonDisabled">
    <app-icon-stack>
        <mat-icon *ngIf="type === 'data-matrix'; else code128">qr_code_2</mat-icon>

        <ng-template #code128>
            <mat-icon svgIcon="barcode"></mat-icon>
        </ng-template>
        <mat-icon bottom-right>file_download_outline</mat-icon>
    </app-icon-stack>
</button>
