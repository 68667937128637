import {FormArray, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {GoodsReceptionFormGroup, StorageAreaForm} from './storage-area-form-group.model';
import {FormValue} from '../utility-types';
import {GOODS_RECEPTION_ID, WarehouseTypes} from '../core-model';
import {CustomValidators} from '../../form-fields';

export {
    WarehouseForm,
    WarehouseFormGroup,
}

class WarehouseFormGroup extends FormGroup<WarehouseForm> {
    constructor(value?: FormValue<WarehouseFormGroup>) {
        super(new WarehouseForm(value));
    }

}

class WarehouseForm {
    warehouseName = new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(5)]
    });
    description = new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(10)]
    });
    warehouseType = new FormControl<WarehouseTypes>(WarehouseTypes.Warehouse, {
        nonNullable: true,
        validators: [Validators.required]
    });
    isInterimStorage = new FormControl<boolean>(false, {nonNullable: true, validators: [Validators.required]});
    createGoodsReception = new FormControl<boolean>(true, {nonNullable: true, validators: [Validators.required]});
    storageAreas = new FormArray([new GoodsReceptionFormGroup()], {
        validators: [CustomValidators.noDuplicateInputValidator('storageAreaId') as ValidatorFn]
    });

    constructor(value?: FormValue<WarehouseFormGroup>) {
        this.storageAreas.controls.forEach(c => c.controls.warehouse.removeValidators(Validators.required));
        if (value?.warehouseName) {
            this.warehouseName.patchValue(value.warehouseName);
        }

        if (value?.description) {
            this.description.patchValue(value.description);
        }

        if (value?.warehouseType) {
            this.warehouseType.patchValue(value.warehouseType);
        }

        if (value?.isInterimStorage) {
            this.isInterimStorage.patchValue(value.isInterimStorage);
        }

        if (value?.createGoodsReception) {
            this.createGoodsReception.patchValue(value.createGoodsReception);
        }

        if (!value?.storageAreas) {
            return;
        }

        // removing unnecessary controls
        if (value.storageAreas.length !== this.storageAreas.length) {
            this.storageAreas.controls = this.storageAreas.controls.slice(0, value.storageAreas.length);
        }

        for (const [index, areaValue] of value.storageAreas.entries()) {
            if (areaValue.storageAreaId === GOODS_RECEPTION_ID) {
                this.storageAreas.setControl(index, new GoodsReceptionFormGroup(areaValue));
                continue;
            }

            this.storageAreas.setControl(index, new FormGroup(new StorageAreaForm(areaValue)));
        }

    }
}
