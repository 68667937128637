import {EXPORT_COLUMN_DECORATOR_KEY} from '../decorators/key';
import {IExportColumn} from '../decorators/export.decorator';
import {ExportMap} from '../parsers/object-parser.interface';
import {ExportConfigError} from '../errors/parsing.errors';
import {ExportColumn} from '../model/export-column.model';

export {
    ColumnReader
}

class ColumnReader {

    private readonly _config: ExportMap<ExportColumn>;

    constructor(private readonly _class: Function) {
        this._config = Reflect.getMetadata(EXPORT_COLUMN_DECORATOR_KEY, this._class);

        if (!this._config) {
            throw new ExportConfigError(ColumnReader, this._class, 'Class has no columns marked as @Export');
        }
    }

    hasColumns(forKey?: string): boolean {
        if (forKey) {
            return !!this._config?.[forKey];
        }

        return !!this._config;
    }

    getColumns(): ExportMap | null;
    getColumns(forKey: string): IExportColumn | undefined;
    getColumns(forKey?: string): IExportColumn | undefined | ExportMap<IExportColumn> | null {

        if (forKey) {
            return this._config?.[forKey]?.asIExportColumn;
        }

        if (!this._config) {
            return null;
        }

        return this._convertToMap(Object.entries(this._config));
    }


    /**
     * Loads all columns that are linke to the provided property via the {link} attribute
     * of the @Export decorator
     * @param forProperty
     */
    getLinkedColumns(forProperty: string): ExportMap | null {
        const col = this._config?.[forProperty];

        if (!col?.link) {
            return null;
        }

        const targetReader = new ColumnReader(col.link);

        return targetReader.getExternColumns()
    }

    hasExternColumns() {
        const externColumns = this.getExternColumns();
        return externColumns && Object.keys(externColumns).length > 0;
    }

    getExternColumns(): ExportMap {
        return this._convertToMap(Object.entries(this._config).filter(([_, c]) => c.extern));
    }

    private _convertToMap(entries: Array<[key: string, col: ExportColumn]>): ExportMap {
        return entries.reduce((acc: ExportMap, [key, col]) => {
            return {...acc, [key]: col.asIExportColumn};
        }, {});
    }

}
