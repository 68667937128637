import {ITypeConfig} from '../interfaces';
import {InputTypeEnum} from '../enums';
import {Validators} from '@angular/forms';
import {Export, Exportable} from 'src/app/export';
import {StorageArea} from '../lvs-model';
import {BaseEntity} from '../base-entity.model';

export const GOODS_RECEPTION_ID = 'GOODS_RECEPTION';

export const GOODS_RECEPTION_PREFIX = 'WE-';

export type WarehouseId = Warehouse['warehouseId'];

@Exportable({name: 'Quelle/Ziel'})
export class Warehouse extends BaseEntity {
    constructor(
        @Export({view: 'Lagername', propertyKey: 'warehouseName', extern: true})
        public warehouseName: string,
        @Export({view: 'Beschreibung', propertyKey: 'description'})
        public description: string,
        @Export({view: 'Zwischenlager', propertyKey: 'isInterimStorage'})
        public isInterimStorage: boolean,
        @Export({view: 'Typ', propertyKey: 'warehouseType'})
        public warehouseType: WarehouseTypes,
        public storageAreas?: Array<StorageArea>,
        public warehouseId: number | null = null
    ) {
        super();
    }
}

export enum WarehouseTypes {
    Warehouse = 'W',
    Container = 'C',
    Inactive = 'D',
}

export const WarehouseConfig: ITypeConfig<Warehouse> = {
    properties: [
        {
            key: 'warehouseId',
            view: 'ID',
            keyProperty: true,
        },
        {
            key: 'warehouseName',
            view: 'Lagername',
            input: {
                required: true,
                isSettable: true,
                inputType: InputTypeEnum.Text,
                validators: [Validators.maxLength(50)],
            },
        },
        {
            key: 'description',
            view: 'Beschreibung',
            input: {
                required: true,
                inputType: InputTypeEnum.Text,
                isSettable: true,
                validators: [Validators.maxLength(50)],
            },
        },
        {
            key: 'isInterimStorage',
            view: 'Zwischenlager',
            input: {
                required: true,
                inputType: InputTypeEnum.Selection,
                selectionValues: [
                    {value: false, view: 'Nein'},
                    {value: true, view: 'Ja'},
                ],
                isSettable: true,
            },
        },
        {
            key: 'warehouseType',
            view: 'Typ',
        },
    ],
    keyNames: ['warehouseId', 'warehouseName', 'description', 'warehouseType', 'isInterimStorage'],
};
