import {Injectable} from '@angular/core';
import {ArticleGroup} from "../../domain/tms-model";
import {Observable, switchMap, take} from "rxjs";
import {map} from "rxjs/operators";
import {StateService} from '../model/state/state.service';
import {ArticleGroupApiService} from '../../api/article-group-api/article-group-api.service';

@Injectable({
    providedIn: 'root'
})
export class ArticleGroupService extends StateService<ArticleGroup> {

    constructor() {
        super({
            type: ArticleGroup,
            api: ArticleGroupApiService,
            webSocket: 'ArticleGroupSignalRService',
            primaryKeys: ['articleGroupId']
        });
    }

    findNameStartsWith(name: ArticleGroup['name'], sensitivity: 'un-sensitive' | 'sensitive' = 'sensitive') {
        const filter = sensitivity === 'un-sensitive' ? name.toLowerCase() : name;
        return this.state.pipe(
            map((groups) => groups.filter(({name}) => {
                const currentName = sensitivity === 'un-sensitive' ? name.toLowerCase() : name;
                return currentName.startsWith(filter);
            }))
        );
    }

    findByName(articleGroupName: string): Observable<ArticleGroup[]> {
        return this.state.pipe(
            map((groups) => groups.filter(g => g.name.toLowerCase() === articleGroupName.toLowerCase()))
        );
    }

    close(articleGroupId: number | number[]): Observable<boolean> {
        const groupList = Array.isArray(articleGroupId) ? articleGroupId : [articleGroupId];

        const idSet = new Set(groupList);

        return this.storage.state.pipe(
            take(1),
            map(state => {
                return state.filter(g => idSet.has(g.articleGroupId!))
            }),
            switchMap((foundArticleGroups: ArticleGroup[]) => {
                const toClose = foundArticleGroups.map(g => {
                    return {articleGroupId: g.articleGroupId, isOpen: false, name: g.name};
                })
                return this.update(...toClose);
            })
        );
    }
}
