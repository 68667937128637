import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {API_ENDPOINTS, ApiEndpoints,} from '../config/api-config.interface';
import {concat, Observable, of, switchMap} from 'rxjs';
import {catchError, delay, filter, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {StatusCodes} from 'http-status-codes';
import {IHttpResult, ServerResponse} from '../interfaces/http-result.interface';
import {BuildFromEagerLoadCallback, EntityKey} from "../../domain/utility-types";
import {BaseEntity} from "../../domain";
import {IcsUserFeedbackService} from 'ics-core';

type PrimaryKey = { [key: string]: string | number };

@Injectable({
    providedIn: 'root'
})
/**
 * This service provides the basic methods that are supported by every entity
 * or calls that can be supported by every entity like getComplete.
 *
 * For special http-calls extend this class in a new api-provider and add your method
 * in the manner of call-> error-handling -> mapping to {@link IHttpResult}
 *
 * All methods of this service that ar not GET return IHttpResult for
 * clean Return values
 *
 * @TypeParam T Defines the Base-Type of entity that is supported by the service
 *
 * @see {@link IHttpResult}
 * @see {@link ApiServiceFactory}
 */
export class BaseApiService<T extends BaseEntity> {

    protected readonly _apiRoute: string;

    /**
     * Creates a Basic API-Service.
     * USE {@link ApiServiceFactory.createService} to create Providers
     * @param _entityRoute Route of the managed entity on backend
     * @param _module determines in which super-module the entity is stored and with which backend-service communicates
     * @param _http HTTP-Client for Communication
     * @param _userFeedback SnackbarService for UserFeedback (can be disabled by every call)
     * @param apiConfig Config for not basic calls like getComplete. When an endpoint is set to true in config method will try request else throws error;
     */
    constructor(@Inject('entityRoute') protected _entityRoute: string,
                @Inject('module') private _module: 'core' | 'tms' | 'lvs',
                protected _http: HttpClient,
                protected _userFeedback: IcsUserFeedbackService) {

        // creating api-route for all calls for the current entity
        this._apiRoute = `${this.readModuleUrl()}/${this._entityRoute}`;
    }

    /**
     * Fetches all entities from the corresponding endpoint
     * in case of an error it returns an empty array
     * @returns Observable<T[]>
     */
    getAll(): Observable<T[]> {
        return this._http.get<T[]>(this.buildEndpointUrl(API_ENDPOINTS.get))
            .pipe(
                catchError((err: HttpErrorResponse) => this.handleHttpGetError(err) as Observable<T[]>)
            );
    }

    /**
     * Returns all values that are determined to be at a page of {@link pageIndex} with pages of
     * the size of {@link pageSize}
     * @param {number} pageIndex the index of the page determined by the size of the pages
     * @param {number} pageSize the size of pages selected
     * @returns {Observable<T[]>}
     */
    getPage(pageIndex: number = 0, pageSize: number = 0): Observable<T[]> {
        return this._http.get<T[]>(this.buildEndpointUrl(API_ENDPOINTS.getPage), {
            params: this.createIdParams({
                pageIndex,
                pageSize
            })
        })
            .pipe(
                catchError((err) => this.handleHttpGetError(err) as Observable<T[]>)
            );
    }

    lock(keyValue: EntityKey<T>): Observable<string | null> {
        return this._http.get<string | null>(
            this.buildEndpointUrl(API_ENDPOINTS.lock),
            {
                params: this.createIdParams(keyValue)
            }).pipe(
            catchError((err: HttpErrorResponse) => {

                return of(err.error);
            })
        );
    }

    releaseLock(keyValue: EntityKey<T>): Observable<boolean> {
        return this._http.get<HttpResponse<any>>(this.buildEndpointUrl(API_ENDPOINTS.releaseLock),
            {
                params: this.createIdParams(keyValue),
                observe: 'response'
            })
            .pipe(
                catchError((err: HttpErrorResponse) => of(err.status === StatusCodes.UNAUTHORIZED)),
                map(res => typeof res === 'boolean' ? res : res.status === StatusCodes.OK)
            );
    }

    /**
     * Creates a new entity of <T>.
     *  In all cases returns a {@link IHttpResult}. If an error happens the responseValue will be null.
     * @returns IHttpResult
     * @param value the entity to be created
     * @param userFeedback set to false disables userFeedBack and call is silent for user
     */
    create(value: T, userFeedback = true): Observable<IHttpResult<T | null>> {
        return this._http.post<T>(this.buildEndpointUrl(API_ENDPOINTS.createNewEntity), value, {observe: 'response'})
            .pipe(
                catchError(this.handleHttpErrorResult),
                map(this.buildHttpResult),
                switchMap((res) => {
                    if (userFeedback === true) {
                        const type = res.isError ? 'error' : 'success';
                        return this._userFeedback.show({
                            type: type,
                            message: `ui.user.feedback.${type}.${this._entityRoute.toLowerCase()}.create`
                        }).pipe(
                            map(() => res)
                        );
                    }

                    return of(res);
                })
            );
    }

    /**
     *
     */
    createNewEntities(entities: Array<T>, userFeedback = false): Observable<IHttpResult<T[] | null>> {
        return this._http.post<T[]>(this.buildEndpointUrl(API_ENDPOINTS.createNewEntities), entities, {observe: 'response'})
            .pipe(
                catchError(this.handleHttpErrorResult),
                map((res) => this.buildHttpResult<T[]>(res)),
                switchMap((res) => {
                    if (userFeedback === true) {
                        const type = res.isError ? 'error' : 'success';
                        return this._userFeedback.show({
                            type: type,
                            message: `ui.user.feedback.${type}.${this._entityRoute.toLowerCase()}.create.multiple`
                        }).pipe(
                            map(() => res)
                        );
                    }

                    return of(res);
                })
            );
    }

    /**
     * Deletes the entity related to the given key-value.
     * Returns a boolean whether deletion was successful or not.
     * Parses the Http-Response to {@link IHttpResult} for user feedback
     * @param keyValue The name of the entities primary key mapped to the value
     * @param userFeedback set to false disables userFeedBack and call is silent for user
     */
    delete(keyValue: EntityKey<T>, userFeedback = true, raw = false): Observable<boolean> {
        // TODO: Replace ANY
        return this._http.delete<any>(this.buildEndpointUrl(API_ENDPOINTS.deleteEntity), {
            params: this.createIdParams(keyValue),
            observe: 'response'
        }).pipe(
            catchError((err) => this.handleHttpErrorResult(err) as Observable<any>),
            map((res: HttpResponse<T>) => {
                return this.buildHttpResult(res);
            }),
            switchMap((res) => {
                if (userFeedback === true) {
                    const type = res.isError ? 'error' : 'success';
                    return this._userFeedback.show({
                        type: type,
                        message: `ui.user.feedback.${type}.${this._entityRoute.toLowerCase()}.delete`
                    }).pipe(
                        map(() => !res.isError)
                    );
                }

                return of(!res.isError);
            })
        );
    }

    deleteCollected(primaryKeys: EntityKey<T> []): Observable<boolean> {
        return concat(...primaryKeys.map(e => this.delete(e, false, true))).pipe(delay(5));
    }

    /**
     *  Sends an update request for an entity of <T>.
     *  In all cases returns a {@link IHttpResult}. If an error happens the responseValue will be null.
     * @returns IHttpResult
     * @param value the updated entity
     * @param userFeedback set to false disables userFeedBack and call is silent for user
     */
    update(value: T, userFeedback = true): Observable<IHttpResult<T | null>> {
        return this._http.put<ServerResponse<T>>(this.buildEndpointUrl(API_ENDPOINTS.updateEntity), value, {observe: 'response'})
            .pipe(
                catchError(this.handleHttpErrorResult),
                map(this.buildHttpResult),
                switchMap((res) => {
                    if (userFeedback === true) {
                        const type = res.isError ? 'error' : 'success';
                        return this._userFeedback.show({
                            type: type,
                            message: `ui.user.feedback.${type}.${this._entityRoute.toLowerCase()}.update`
                        }).pipe(
                            map(() => res)
                        );
                    }

                    return of(res);
                })
            );
    }

    updateEntities(entities: Array<T>, userFeedback = false): Observable<IHttpResult<T[] | null>> {
        return this._http.put<T[]>(this.buildEndpointUrl(API_ENDPOINTS.updateEntities), entities, {observe: 'response'})
            .pipe(
                catchError(this.handleHttpErrorResult),
                map((res) => this.buildHttpResult<T[]>(res)),
                switchMap((res) => {
                    if (userFeedback === true) {
                        const type = res.isError ? 'error' : 'success';
                        return this._userFeedback.show({
                            type: type,
                            message: `ui.user.feedback.${type}.${this._entityRoute.toLowerCase()}.update.multiple`
                        }).pipe(
                            map(() => res)
                        );
                    }

                    return of(res);
                })
            );
    }

    /**
     * Reruns an Observable containing the searched entity.
     * In case of error returns null
     * @param entityId the primary-key name mapped to the pk-value
     */
    getById(entityId: PrimaryKey): Observable<T | null> {
        return this._http.post<T>(this.buildEndpointUrl(API_ENDPOINTS.getById), {...entityId})
            .pipe(
                catchError(err => of(null))
            );
    }

    /**
     * Requests all entities from the server that match the given query
     * @TypeParam X allows an extension for incoming queries. So there can be another type of EntityQuery. Default is T.
     * @TypeParam Q allows overriding incoming query type. Default is T.
     * @param query
     */
    search<X = T, Q = T>(query: Partial<Q>): Observable<X[]> {
        return this._http.post<X[]>(this.buildEndpointUrl(API_ENDPOINTS.search), query)
            .pipe(
                catchError(err => this.handleHttpGetError(err) as Observable<[]>)
            );
    }

    /**
     * Returns all Entities of a CompleteDTO. Only works if endpoint is configured in {@link ApiEndpoints}.
     * @TypeParam X allows to use another class for CompleteDTO. Default is T.
     * @TypeParam Q allows overriding the default query type of T
     * @throws Error if the endpoint is set to false/undefined/null in config
     * @param query The properties that requested entities have to contain
     *
     * @see {@link ApiEndpoints}
     * @see {@link BaseApiService}
     */
    searchNested<X = T, Q = Partial<X>>(query: Q): Observable<T[]> {
        return this._http.post<T[]>(this.buildEndpointUrl(API_ENDPOINTS.searchNested), query)
            .pipe(
                catchError(err => this.handleHttpGetError(err) as Observable<[]>)
            );
    }

    /**
     * Returns all entities of a type, with eager loaded relation attributes. I.e. for a related warehouse the warehouse
     * object will also be included.
     * @TypeParam X allows to use another class for CompleteDTO. Default is T. This has to be returned by {@link buildFromEagerLoadCallback}
     * @throws Error if the endpoint is set to false/undefined/null in config
     * @param buildFromEagerLoadCallback Allows to change the incoming values in every manner.
     *
     * @see {@link searchEager}
     * @see {@link ApiEndpoints}
     * @see {@link BaseApiService}
     */
    getEager<X extends T = T>(buildFromEagerLoadCallback?: BuildFromEagerLoadCallback<T, X>): Observable<(X | T)[]> {
        return this._http.get<X[]>(this.buildEndpointUrl(API_ENDPOINTS.getEager))
            .pipe(
                catchError(err => this.handleHttpGetError(err) as Observable<[]>),
                map((values: T[]) => buildFromEagerLoadCallback ? values?.map(v => buildFromEagerLoadCallback(v)) : values)
            );
    }

    /**
     * Returns all entities of a type, that match the given query, with eager loaded relation attributes. I.e. for a related warehouse the warehouse
     * object will also be included.
     * @TypeParam Q allows overriding the type of the query.
     * @TypeParam X allows to use another class for CompleteDTO. Default is T. This has to be returned by {@link buildFromEagerLoadCallback}
     * @throws Error if the endpoint is set to false/undefined/null in config
     * @param query the properties every entity has to contain
     * @param buildFromEagerLoadCallback Allows to change the incoming values in every manner.
     *
     * @see {@link getEager}
     * @see {@link ApiEndpoints}
     * @see {@link BaseApiService}
     */
    searchEager<Q extends T = T, X extends T = T>(query: Partial<Q>, buildFromEagerLoadCallback?: BuildFromEagerLoadCallback<T, X>): Observable<T[]> {
        return this._http.post<T[]>(this.buildEndpointUrl(API_ENDPOINTS.searchEager), query)
            .pipe(
                catchError(err => this.handleHttpGetError(err) as Observable<[]>),
                map((values: T[]) => buildFromEagerLoadCallback ? values.map(v => buildFromEagerLoadCallback(v)) : values),
            );
    }

    /**
     * Creates a Http-Call-chain which imports all given entities. Every entity will be sent to backend with a delay of 5ms.
     * Info aboout failed imports is stored in the {@link IHttpResult}.
     *
     * Executes the preparationCalls before the actual importing calls and filters them from the final result.
     *
     * @returns {Observable<IHttpResult<T>>} Emits the result of every entity-creation call with a delay of 5ms
     *
     * Does not support user-feedback
     *
     * @param entities to be imported to the backend
     * @param preparationCalls api-calls to be done before the actual importing
     *
     * @see {@link IHttpListResult}
     */
    importEntities(entities: T[], ...preparationCalls: Observable<any>[]): Observable<IHttpResult<T>> {
        let index = 0;
        return concat(
            ...preparationCalls,
            ...entities.map(e => this.create(e, false).pipe(map((res: IHttpResult) => {
                res.index = index++;
                return res;
            }))))
            .pipe(
                delay(5),
                filter((v: any, i: number) => i >= preparationCalls?.length) // removing all preparation calls from the final output
            );
    }

    /**
     * Builds the Url to a given endpoint for the supported entity. I.e. /article/GetArticleSmall
     * Should be used in subclasses.
     * @param endpoint
     * @protected
     */
    protected buildEndpointUrl(endpoint: string): string {
        return `${this._apiRoute}/${endpoint}`;
    }

    /**
     * Creates params of primary key values. Supports also multiply key pks.
     * @param idValue
     * @protected
     */
    protected createIdParams(idValue: any): HttpParams {
        let params = new HttpParams();
        for (const key of Object.keys(idValue)) {
            const paramValue = idValue[key];
            // expicit checking to avoid false on values as 0
            if (paramValue !== undefined && paramValue !== null) {
                params = params.append(key, paramValue);
            }
        }
        return params;
    }

    /**
     * Takes an HttpError and maps it to [] or null for single values.
     * USE only with GET
     * @param httpError
     * @param isArray whether the expected result is an array value or not
     * @protected
     */
    protected handleHttpGetError<X = T>(httpError: HttpErrorResponse, isArray = true): Observable<X[]> | Observable<null> {
        if (isArray) {
            return of(new Array<X>());
        } else {
            return of(null);
        }
    }

    /**
     * Takes an error and maps it to {@link IHttpResult} for better handling.
     * @param httpError
     * @protected
     *
     * @see {@link IHttpResult}
     */
    protected handleHttpErrorResult(httpError: HttpErrorResponse): Observable<IHttpResult<null>> {
        return of(new IHttpResult(
            true,
            httpError.message ?? httpError.error,
            null,
            httpError.status
        ));
    }


    /**
     * Uses an HttpResponse to accumulate important infos in a simpler manner and creates an IHttpResult
     * @param response either the HttpResponse or an IHttpResult in case of an error
     * @protected
     */
    protected buildHttpResult<X = T>(response: HttpResponse<X | ServerResponse<X>> | IHttpResult<null>): IHttpResult<X | null> {
        console.debug('Building Result => ', {response});
        if (response instanceof HttpResponse) {
            let resultingEntity: X | null = null;
            if ((response.body as ServerResponse<X>)?.result) {
                console.debug('Building response from ServerResult => ', {body: response?.body});
                resultingEntity = (response.body as ServerResponse<X>).result;
            } else {
                resultingEntity = response.body as X;
            }
            console.debug('Result entity => ', {resultingEntity});
            return new IHttpResult(!(StatusCodes.OK || StatusCodes.ACCEPTED || StatusCodes.NO_CONTENT || StatusCodes.CREATED), null, resultingEntity, response.status);
        }
        // there might have been an error but no matter what
        // this is already a IHttpResult
        return response;
    }

    /**
     * Checks with tms-api shall be used
     * @private
     */
    protected readModuleUrl(): string {
        switch (this._module) {
            case 'lvs':
                return environment.lvsUrl;
            case 'tms':
                return environment.tmsUrl;
            default:
                return environment.coreUrl;
        }
    }
}

