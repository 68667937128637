import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from "../../../../environments/environment";
import {EnvironmentType} from "../../../../environments/environment.interface";

/**
 * Controls if a site can be access by checking current environment.
 * Therefore pages secured by this can only be access in a DEVELOPMENT environment
 */
@Injectable({
    providedIn: 'root'
})
export class DevelopmentGuard {
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return isDevMode() || environment.environmentType === EnvironmentType.DEVELOPMENT;
    }

}
