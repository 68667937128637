<ics-app>
    <ics-app-logo [src]="assetPaths.tlsLogo" routerLink="" toolbar-logo></ics-app-logo>

    <ng-container toolbar-buttons-start>
        <app-history-handle></app-history-handle>

        <ng-container *needsPrivilege="'Admin'">
            <app-un-lock-button routerLink="/admin/locker-panel"></app-un-lock-button>

            <app-app-download-button></app-app-download-button>
        </ng-container>
    </ng-container>

    <ng-container toolbar-buttons-end>
        <ics-msg-tools></ics-msg-tools>
        <ics-identity-authentication-menu></ics-identity-authentication-menu>
    </ng-container>

    <ng-container main-nav>
        <ics-nav *needsPrivilege="'ReadAccess'" [items]="routes" data-cy="side-navigation">
            <ng-container *ngFor="let route of routes">

                <app-navigation-label *icsNavLabel="route.id" [route]="route" inset>
                </app-navigation-label>

                <ng-container *ngIf="route.children">

                    <ng-container *ngFor="let child of route.children">
                        <app-navigation-label *icsNavLabel="child.id" [route]="child" inset>
                        </app-navigation-label>
                    </ng-container>

                </ng-container>

            </ng-container>
        </ics-nav>
    </ng-container>

    <div class="main-content-area" main-content [class.main-content-area--with-padding]="!(isMessengerRoute$|async)">
        <!-- Module content of route is displayed here -->
        <router-outlet></router-outlet>
    </div>

    <ng-container settings>
        <ics-theme-setting></ics-theme-setting>
    </ng-container>
</ics-app>
