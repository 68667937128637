import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

/**
 * Allows to create simple keyboard shortcuts in combination with [ctrl].
 * Emits an event when [ctr] + [key} is pressed.
 * Default for key is ENTER
 */
@Directive({
  selector: '[appControlShortCut]'
})
export class ControlShortCutDirective {

  @Input('appControlShortCut')
  set shortCutKey(value: string) {
    if (value) {
      this._shortCutKey = value;
    }

  }

  @Output() controlShortCut: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  private _shortCutKey: string = 'enter';

  constructor() {
  }

  @HostListener('keydown', ['$event']) checkShortCut(event: KeyboardEvent) {
    if (event.ctrlKey && event.key.toLowerCase() === this._shortCutKey.toLowerCase()) {
      this.controlShortCut.emit(event);
    }
  }

}
