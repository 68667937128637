import {Component, Input, OnInit} from '@angular/core';
import {AppRoute} from '../../app.routes';
import {NavigationLabelComponent} from "../navigation-label/navigation-label.component";
import {SharedModule} from "../../shared";
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-navigation-card',
    templateUrl: './navigation-card.component.html',
    styleUrls: ['./navigation-card.component.scss'],
    standalone: true,
    imports: [
        NavigationLabelComponent,
        SharedModule,
        TranslateModule
    ]
})
export class NavigationCardComponent implements OnInit {

    @Input() route!: AppRoute;

    constructor() {
    }

    ngOnInit(): void {
    }

}
