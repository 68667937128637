<ng-container *ngIf="incompleteEntityCount$|async as count; else defaultTemplateRef">

  <span [class.inset-badge]="_inset" [matBadgeOverlap]="false" [matBadge]="count > 0 ? count : undefined"
        matBadgeSize="medium">
    <ng-container [ngTemplateOutlet]="defaultTemplateRef"></ng-container>
  </span>
</ng-container>


<ng-template #defaultTemplateRef>
    <span>{{translationString|translate}}</span>
</ng-template>
