import {Export, Exportable} from 'src/app/export';
import {Import, Importable} from 'src/app/import';
import {ImportSettings} from 'src/app/settings/model/settings.enum';
import {ViewNames} from 'src/app/constants';
import {sanitizeFormValue} from '../functions';
import {BaseEntity} from '../base-entity.model';

export {
    Article,
    ArticleQuery,
    ArticleId
}

type ArticleId = Article['articleId'];

@Importable({
    id: ImportSettings.articleImportOrder,
    columnNamesId: ImportSettings.articleColumnNames,
    allowHeaderLine: true
})
@Exportable({name: 'Artikel'})
class Article extends BaseEntity {
    constructor(
        @Import({propertyKey: 'articleId', view: ViewNames.article.articleId, dataType: 'string', required: true})
        @Export({propertyKey: 'articleId', extern: true, view: ViewNames.article.articleId})
        public readonly articleId: string,
        @Import({propertyKey: 'description', view: ViewNames.article.description, dataType: 'string', required: true})
        @Export({propertyKey: 'description', view: ViewNames.article.description, extern: true})
        public description: string,
        @Import({
            propertyKey: 'articleWeight',
            view: ViewNames.article.articleWeight,
            dataType: 'number',
            required: true
        })
        @Export({propertyKey: 'articleWeight', view: ViewNames.article.articleWeight, extern: true})
        public articleWeight: number,
        @Import({propertyKey: 'lengthInMillimetres', view: ViewNames.article.lengthInMillimetres, dataType: 'number'})
        @Export({propertyKey: 'lengthInMillimetres', view: ViewNames.article.lengthInMillimetres})
        public lengthInMillimetres?: number | null,
        @Import({propertyKey: 'widthInMillimetres', view: ViewNames.article.widthInMillimetres, dataType: 'number'})
        @Export({propertyKey: 'widthInMillimetres', view: ViewNames.article.widthInMillimetres})
        public widthInMillimetres?: number | null,
        @Import({propertyKey: 'heightInMillimetres', view: ViewNames.article.heightInMillimetres, dataType: 'number'})
        @Export({propertyKey: 'heightInMillimetres', view: ViewNames.article.heightInMillimetres})
        public heightInMillimetres?: number | null,
        @Import({
            propertyKey: 'transportDescription',
            view: ViewNames.article.transportDescription,
            listValue: true,
            dataType: 'string'
        })
        @Export({propertyKey: 'transportDescription', view: ViewNames.article.transportDescription, listValue: true})
        public transportDescription?: string[] | null,
        @Import({propertyKey: 'additionalFields', dataType: 'string', restructure: true})
        @Export({propertyKey: 'additionalFields', destruct: true})
        public additionalFields?: { [key: string]: any }
    ) {
        super();
    }
}

interface IArticleQuery extends Partial<Article> {
    // @ts-ignore
    [key: Uppercase<string>]: string;
}

class ArticleQuery implements IArticleQuery {

    // @ts-ignore
    [key: Uppercase<string>]: string;

    static buildQuery(value: Partial<Article> | ArticleQuery): ArticleQuery {
        // all user defined properties
        const additionalFieldNames = Object.keys(value).filter(k => k.charAt(0).toUpperCase() === k.charAt(0));
        const additionalFields: any = additionalFieldNames.reduce((acc, curr) => {
            const additionalValue = (value as any)[curr];
            delete (value as any)[curr];
            return {...acc, [curr]: additionalValue};
        }, {} as any);
        return sanitizeFormValue({...value, additionalFields});
    }
}

