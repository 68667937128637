import {BaseEntity} from '../../domain';
import {ISignalRService} from './signal-r-service.interface';
import {StateStorage} from '../../entity-state/model/state-storage';
import {collectForTime} from '../../entity-state/model/collect.operator';

export class WebsocketConnector {

    constructor() {
    }

    connect<T extends BaseEntity>(socket: ISignalRService<T>, storage: StateStorage<T>) {
        socket.getNewNotification()
            .pipe(collectForTime(300))
            .subscribe(collected => this._handleAction('add', collected, storage));

        socket.getUpdatedNotification()
            .pipe(collectForTime(300))
            .subscribe((collected) => this._handleAction('update', collected, storage));

        socket.getDeletedNotification()
            .pipe(collectForTime(300))
            .subscribe((collected) => this._handleAction('delete', collected, storage));
    }

    private _handleAction<T extends BaseEntity>(action: 'add' | 'update' | 'delete', event: T | T[], storage: StateStorage<T>) {
        console.debug(`[Websocket-connector] ${action} entity from storage as order from websocket`, {action, event});

        if (action === 'add') {
            storage.add(event);
            return;
        }

        if (action === 'delete') {
            storage.remove(event);
            return;
        }

        if (action === 'update') {
            storage.replace(event);
            return;
        }
    }

}
