import {Export} from 'src/app/export';
import {BaseEntity} from '../base-entity.model';

export type ArticleGroupId = ArticleGroup['articleGroupId'];
export type ArticleGroupName = ArticleGroup['name'];

export class ArticleGroup extends BaseEntity {
    constructor(
        @Export({view: 'Artikelgruppe', propertyKey: 'name', extern: true})
        public name: string,
        public articleGroupId?: number,
        public isOpen?: boolean | null
    ) {
        super();
    }
}
