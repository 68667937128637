import {PrivilegeLevels} from "./roles";
import {ChildRoutes, FeatureModuleRoutes, PseudoModuleRoutes} from './constants';
import {Type} from '@angular/core';
import {IcsNavigationItem} from 'ics-core';
import {ArticleService} from "./entity-state/article-service/article.service";
import {IState} from "./entity-state/model/state.interface";
import {MaterialRequestService} from "./entity-state/material-request-service/material-request.service";
import {TransportPositionService} from './entity-state/transport-position/transport-position.service';

export {AppRoute, APP_ROUTES};


class AppRoute extends IcsNavigationItem {
    stateProvider?: Type<IState<any>>;
    override children?: AppRoute[];
}

const coreRoutes: AppRoute[] = [
    {
        id: PseudoModuleRoutes.core,
        privilege: PrivilegeLevels.ReadAccess,
        children: [
            {
                id: FeatureModuleRoutes.core.article,
                stateProvider: ArticleService
            },
            {
                id: FeatureModuleRoutes.core.warehouse,
            },
            {
                id: FeatureModuleRoutes.core.handlingUnit
            },
            {
                id: FeatureModuleRoutes.core.vehicle
            },
            {
                id: FeatureModuleRoutes.core.vehicleType
            }
        ]
    }
];

const lvsRoutes: AppRoute[] = [
    {
        id: PseudoModuleRoutes.lvs,
        privilege: PrivilegeLevels.LvsAccess,
        children: [
            {
                id: FeatureModuleRoutes.lvs.storageArea,

            },
            {
                id: FeatureModuleRoutes.lvs.storageBin,

            },
            {
                id: FeatureModuleRoutes.lvs.requisition,

            },
            {
                id: FeatureModuleRoutes.lvs.inventory,

            }
        ]
    }
];

const tmsRoutes: AppRoute[] = [
    {
        id: PseudoModuleRoutes.tms,
        privilege: PrivilegeLevels.TmsAccess,
        children: [
            {
                id: FeatureModuleRoutes.tms.transportPosition,
                stateProvider: TransportPositionService
            },
            {
                id: FeatureModuleRoutes.tms.superTask,
            },
            {
                id: `${FeatureModuleRoutes.tms.transportTask}/${ChildRoutes.List}`,
            },
            {
                id: `${FeatureModuleRoutes.tms.transportTask}/${ChildRoutes.History}`,
            },
            {
                id: FeatureModuleRoutes.tms.initiator,
            },
            {
                id: FeatureModuleRoutes.tms.materialRequest,
                stateProvider: MaterialRequestService
            }
        ]
    }
];

const adminRoutes: AppRoute[] = [
    {
        id: PseudoModuleRoutes.admin,
        privilege: PrivilegeLevels.Admin,
        children: [
            {
                id: FeatureModuleRoutes.admin.user
            },
            {
                id: FeatureModuleRoutes.admin.systemSettings
            },
            {
                id: FeatureModuleRoutes.admin.systemLogs
            }
        ]
    }
];


const APP_ROUTES = [...coreRoutes, ...lvsRoutes, ...tmsRoutes, ...adminRoutes].map(v => {
    v.id = v.id;

    if (v.children) {
        v.children.forEach(c => c.link = `${v.id}/${c.id}`);
    }

    return v;
});
