import {Component} from '@angular/core';
import {CanBeDisabled} from '../model/can-be-disabled';

@Component({
  selector: 'app-unassign-handling-unit-button',
  templateUrl: './unassign-handling-unit-button.component.html',
  styleUrls: ['./unassign-handling-unit-button.component.scss'],
  inputs: ['disabled']
})
export class UnassignHandlingUnitButtonComponent extends CanBeDisabled {

  constructor() {
    super();
  }

  get toolTip() {
    return `ui.transports.tooltips.menu.bar.release.handling.unit${this.disabled ? '.blocked' : ''}`;
  }

}
