<div class="row small-spacing" role="group" [formGroup]="filterForm">
    <mat-form-field appearance="outline" color="accent">
        <mat-label>Quellen</mat-label>
        <mat-select formControlName="source" multiple>
            <mat-option *ngFor="let source of loggingSourceOptions" [value]="source">{{ source }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
        <mat-label>Typ</mat-label>
        <mat-select formControlName="logType" multiple>
            <mat-option *ngFor="let type of loggingTypeOptions" [value]="type">{{ type }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
        <mat-label>Level</mat-label>
        <mat-select formControlName="logLevel" multiple>
            <mat-option *ngFor="let level of logLevels" [value]="level.value">{{ level.view }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
        <mat-label>Inhalt</mat-label>
        <input matInput formControlName="content" type="text"/>
    </mat-form-field>
</div>
