import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';

type FileRepresentation = { fileName: string, dataUrl?: string | ArrayBuffer };

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  /**
   * Joins the by the import accepted files into one string as html property
   * @returns
   */
  get acceptedFileTypes(): string {
    return this._acceptedMimeTypes.join(', ');
  }


  /**
   * @return the earliest imported file
   */
  get currentFile(): FileRepresentation {
    return this.files?.[0];
  }

  // watching file-input to open it on click
  @ViewChild('fileInput', { static: true }) fileInput!: ElementRef;
  // emitter the uploaded file
  @Output() file: EventEmitter<string> = new EventEmitter();

  // all uploaded files
  files: FileRepresentation[] = [];

  // all suported file types. Currently Excel and CSV
  private _acceptedMimeTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'text/csv'
  ];

  /**
   * Opens the File-Input (i.e. Windows explorer) by click on the central component
   */
  openFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  /**
   * reads each file and stores it mapped to it name
   * @param files the files from the File-Input
   */
  uploadFile(files: File[]): void {
    for (const file of files) {
      const reader = new FileReader();
      reader.addEventListener('load', (readerEvent) => {
        insertAtIndex(this.files, 0, { fileName: file.name, dataUrl: String(reader.result) });
        // this.files.push({ fileName: file.name, dataUrl: reader.result });
        this.file.emit(reader.result as string);
      });
      reader.readAsBinaryString(file);
    }

  }

  /**
   * removes the file at the given index
   * @param i
   */
  deleteAttachment(i: number) {
    this.files.splice(i, 1);
  }

  /**
   * Clears all files from the state of the component
   */
  clear(): void {
    this.files = [];
  }
}

/**
 * Inserts an Object at the given Index into the array
 * @param arr outgoing array to be extended
 * @param newValues values to be inserted into the array
 * @param index index at which [newValue] is inserted
 * @return a copy of the original Array with [newValue] at [index]
 */
function insertAtIndex<T>(arr: T[], index: number, ...newValues: T[]): T[] {
  arr.join();
  for (const value of newValues) {
    arr.splice(index, 0, value);
  }
  return arr;
}
