import {BaseEntity} from '../../domain';
import {EntityKey} from '../../domain/utility-types';

export class KeyBuilder<T extends BaseEntity> {

    constructor(private readonly _keys: (keyof EntityKey<T>)[]) {
    }

    buildPrimaryKey(entity: T) {
        return this._keys.reduce((acc, curr) => {
            return {...acc, [curr]: entity[curr]};
        }, {} as EntityKey<T>);
    }

    isSameKey(first: EntityKey<T> | T, second: EntityKey<T> | T) {
        for (const key of this._keys) {
            if (first[key] !== second[key]) {
                return false;
            }
        }
        return true;
    }
}
