import {ModuleWithProviders, NgModule, Type} from '@angular/core';
import {ImportService} from './import-service/import.service';
import {ImportComponent} from './import/import.component';
import {ImportPropertyOrderComponent} from './import-property-order/import-property-order.component';
import {FileUploadComponent} from './file-upload/file-upload.component';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {UploadDirective} from './upload-directive/upload.directive';
import {MatRippleModule} from '@angular/material/core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ImportPropertyComponent} from './import-property/import-property.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UnsavedImportsDialogComponent} from './unsaved-imports-dialog/unsaved-imports-dialog.component';
import {ImportFinishedGuard} from './import-finished-guard/import-finished.guard';
import {MatExpansionModule} from '@angular/material/expansion';
import {IMPORT_LOGGING, ImportLoggingProvider} from './model/import-logging-provider.interface';
import {IMPORT_DEFINITIONS, ImportDefinitionsProvider} from './model/column-definitions-provider.interface';
import {IMPORT_CLASS} from './model/import-class.provider';
import {ImportViewToggleComponent} from './import-view-toggle/import-view-toggle.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {ImportFabComponent} from './import-fab/import-fab.component';
import {RouterModule} from '@angular/router';
import {ButtonsModule} from '../buttons';
import {ImportPage} from './model/import-page.model';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatCardModule} from "@angular/material/card";
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTooltipModule} from "@angular/material/tooltip";
import {A11yModule} from '@angular/cdk/a11y';

interface ImportRootConfig<T extends { content: string }> {
    logging?: Type<ImportLoggingProvider<T>>,
    columns?: Type<ImportDefinitionsProvider>
}


@NgModule({
    declarations: [
        ImportComponent,
        ImportPropertyOrderComponent,
        FileUploadComponent,
        UploadDirective,
        ImportPropertyComponent,
        UnsavedImportsDialogComponent,
        ImportViewToggleComponent,
        ImportFabComponent,
        ImportPage
    ],
    imports: [
        CommonModule,
        RouterModule,
        ButtonsModule,
        MatCardModule,
        MatIconModule,
        MatProgressBarModule,
        MatRippleModule,
        MatListModule,
        MatButtonModule,
        DragDropModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        MatDialogModule,
        MatButtonModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatTooltipModule,
        A11yModule,
    ],
    providers: [
        ImportService,
        ImportFinishedGuard
    ],
    exports: [
        ImportComponent,
        ImportPropertyOrderComponent,
        FileUploadComponent,
        ImportFabComponent,
        ImportViewToggleComponent
    ]
})
/**
 * Allows to import data for entities marked with {@link Importable @Importable} decorator and properties marked with {@link Column @Column}
 * @see {@link https://abmsoft.atlassian.net/wiki/spaces/PCKTMS/pages/3776872449/Import+Module Confluence Documentation}
 */
export class ImportModule {

    static forRoot<T extends { content: string }>(config: ImportRootConfig<T>): ModuleWithProviders<ImportModule> {
        return {
            ngModule: ImportModule,
            providers: [
                {
                    provide: IMPORT_DEFINITIONS,
                    useExisting: config.columns
                },
                {
                    provide: IMPORT_LOGGING,
                    useExisting: config.logging
                }
            ]
        };
    }

    static forClass<T extends object>(importClass: Type<T>): ModuleWithProviders<ImportModule> {
        return {
            ngModule: ImportModule,
            providers: [
                {
                    provide: IMPORT_CLASS,
                    useValue: importClass // using useValue for Usage with classes that have contructor params
                }
            ]
        };
    }

}
