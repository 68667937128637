import {PriorityLevel, PriorityLevelSelection} from './priority-level.model';
import {TransportPositionState} from './transport-position-state.model';
import {ArticleGroup} from './article-group.model';
import {Article, HandlingUnit, User, Vehicle, Warehouse} from '../core-model';
import {Initiator} from './inittiator.model';
import {SuperTask} from './supertask.model';
import {Import, Importable} from 'src/app/import';
import {ImportSettings} from 'src/app/settings/model/settings.enum';
import {Export, Exportable} from 'src/app/export';
import {ViewNames} from 'src/app/constants/display-names/display-name.constants';
import {BaseEntity} from '../base-entity.model';
import {DeepPartial} from '../../search/model';
import {TransportTask, TransportTaskId} from '../../modules/tms/transport-task';

export {
    TransportPosition,
    TransportPositionQuery,
    ImportedTransportPosition,
    StatusChange,
    TransportPositionObjectQuery
};

@Exportable({name: 'Transporte'})
@Importable({
    id: ImportSettings.transportPositionImportOrder,
    columnNamesId: ImportSettings.transportPositionColumnNames,
})
class TransportPosition extends BaseEntity {
    @Export({propertyKey: 'articleObj', link: Article, extern: true})
    public articleObj?: Article | null;
    @Export({propertyKey: 'initiatorObj', link: Initiator, extern: true})
    public initiatorObj?: Initiator | null = null;
    @Export({propertyKey: 'userObj', link: User, extern: true})
    public userObj?: User | null = null;
    // relational imports
    @Import({
        propertyKey: 'superTaskDestination',
        view: ViewNames.transportPosition.superTaskDestination!,
        dataType: 'string'
    })
    // synthetic property for importing super-tasks
    public superTaskDestination?: string;
    // using this for import for stricter typing and better group-checking in service
    @Import({propertyKey: 'articleGroupName', view: ViewNames.transportPosition.articleGroupName!, dataType: 'string'})
    public articleGroupName?: string;
    @Export({propertyKey: 'articleGroupObj', link: ArticleGroup, extern: true})
    public articleGroupObj?: ArticleGroup | null;
    // eager loaded
    @Export({view: ViewNames.transportPosition.destination, extern: true, link: Warehouse})
    public destinationWarehouseObj?: Warehouse | null = null;
    @Export({view: ViewNames.transportPosition.articleSourceId, extern: true, link: Warehouse})
    public sourceWarehouseObj?: Warehouse | null = null;
    public superTaskObj?: SuperTask | null = null;
    // just for displaying in the Table
    public sourceWarehouseDescription?: string;
    public destinationWarehouseDescription?: string;
    public initiatorName?: string;
    public articleDescription?: string;
    public articleWeight?: number;
    public userName?: string;
    public transportListId?: number | null;
    public materialRequestId?: number | null;
    public statusChanges?: StatusChange[];
    public superTaskId?: number | null;
    public handlingUnitId?: string | null;
    public handlingUnitObj?: HandlingUnit | null;

    // PCKTMS-324 added trasnport-task relation
    transportTaskId?: TransportTaskId;
    transportTaskObj?: TransportTask;

    constructor(
        @Import({
            propertyKey: 'priorityLevel',
            view: ViewNames.transportPosition.priorityLevel!,
            dataType: 'internal',
            propertyValues: PriorityLevelSelection as any
        })
        public priorityLevel?: PriorityLevel,
        @Import({
            propertyKey: 'articleId',
            view: ViewNames.transportPosition.articleId!,
            dataType: 'string',
            required: true
        })
        public articleId?: string,
        @Export({propertyKey: 'articleCount', view: ViewNames.transportPosition.articleCount!, extern: true, index: 1})
        @Import({
            propertyKey: 'articleCount',
            view: ViewNames.transportPosition.articleCount!,
            dataType: 'number',
            required: true
        })
        public articleCount?: number,
        @Import({
            propertyKey: 'articleSourceId',
            view: ViewNames.transportPosition.articleSourceId!,
            dataType: 'string',
        })
        public articleSourceId?: number,
        @Import({propertyKey: 'destination', view: ViewNames.transportPosition.destination!, dataType: 'string'})
        public destination?: number,
        @Export({propertyKey: 'toBeDoneBy', view: ViewNames.transportPosition.toBeDoneBy!, extern: true, index: 10})
        @Import({propertyKey: 'toBeDoneBy', view: ViewNames.transportPosition.toBeDoneBy!, dataType: 'date'})
        public toBeDoneBy?: string | null,
        @Export({propertyKey: 'orderedBy', view: ViewNames.transportPosition.orderedBy!, extern: true, index: 10})
        @Import({propertyKey: 'orderedBy', view: ViewNames.transportPosition.orderedBy!, dataType: 'date'})
        public orderedBy?: string | null,
        @Export({propertyKey: 'bookingNumber', view: ViewNames.transportPosition.bookingNumber!, extern: true})
        @Import({propertyKey: 'bookingNumber', view: ViewNames.transportPosition.bookingNumber!, dataType: 'string'})
        public bookingNumber?: string | null,
        @Export({propertyKey: 'jobNumber', view: ViewNames.transportPosition.jobNumber!, extern: true})
        @Import({propertyKey: 'jobNumber', view: ViewNames.transportPosition.jobNumber!, dataType: 'string'})
        public jobNumber?: string | null,
        @Export({
            propertyKey: 'additionalInformation',
            view: ViewNames.transportPosition.additionalInformation!,
            extern: true,
            listValue: true
        })
        @Import({
            propertyKey: 'additionalInformation',
            view: ViewNames.transportPosition.additionalInformation!,
            dataType: 'string',
            listValue: true
        })
        public additionalInformation?: string[] | null,
        // not importable properties
        public articleGroupId?: number | null,
        public operator?: string | null,
        public initiator?: number | null,
        public transportState: TransportPositionState = TransportPositionState.Created,
        public transportPositionId?: number | null,
    ) {
        super();
    }

    /**
     * Writes display values from a eager-loaded transport-position directly into the position itself
     */
    static buildFromEagerLoad(position: TransportPosition): TransportPosition {
        position.articleGroupName = position?.articleGroupObj?.name;
        position.articleDescription = position?.articleObj?.description ?? undefined;
        position.articleWeight = position?.articleObj?.articleWeight;
        position.destinationWarehouseDescription = position?.destinationWarehouseObj?.description;
        position.sourceWarehouseDescription = position?.sourceWarehouseObj?.description;
        position.initiatorName = position?.initiatorObj?.name;
        if (position.userObj) {
            position.userName =
                position.userObj?.firstName && position.userObj?.lastName
                    ? `${position.userObj?.firstName} ${position.userObj?.lastName}`
                    : position.userObj.email;
        }
        return position;
    }

    /**
     * Removes all eagerloaded Objects from a transport-position to avoid backend trying to create
     * the nested objects
     *
     * @param {TransportPosition} transportPosition
     * @returns the cleaned TransportPosition
     */
    static removeEagerLoadedObjects(transportPosition: TransportPosition): TransportPosition {
        delete transportPosition.sourceWarehouseObj;
        delete transportPosition.destinationWarehouseObj;

        if (transportPosition.articleGroupId && transportPosition.articleGroupObj?.articleGroupId) {
            delete transportPosition.articleGroupObj;
        }

        delete transportPosition.articleObj;
        delete transportPosition.userObj;
        delete transportPosition.initiatorObj;
        delete transportPosition.superTaskObj;
        return transportPosition;
    }

    /**
     * Crreates a whole new transportposition by merging a given template with values from a new transport.
     * Aftewards resets all state values to default
     *
     * @param {Partial<TransportPosition>} newPosition a transportPosition containign all infos that shall be overriden in template
     * @param {TransportPosition} template a transportPosition as template that gives the missing values in {@link newPosition}
     * @returns the new TransportPosition
     */
    static createPositionFromTemplate(
        newPosition: Partial<TransportPosition>,
        template: TransportPosition
    ): Partial<TransportPosition> {
        const templateCopy: TransportPosition = JSON.parse(JSON.stringify(template));
        delete templateCopy.transportPositionId;
        return {
            ...templateCopy,
            ...newPosition,
            materialRequestId: null,
            isComplete: false,
            handlingUnitId: null,
            operator: null,
            transportState: TransportPositionState.Created,
        };
    }
}

type StatusChange = {
    timeStamp: string;
    userId: string;
    newState: TransportPositionState;
    usedVehicleId: Vehicle['vehicleId'] | null;
    usedVehicle: Vehicle | null;
};

type TransportPositionQueryExtensions = {
    transportState?: TransportPositionState | TransportPositionState[],
    articleSourceName?: string,
    destinationName?: string
};

type TransportPositionObjectQuery = DeepPartial<TransportPosition> & TransportPositionQueryExtensions;
type ImportedTransportPosition = TransportPositionObjectQuery;

type TransportPositionQuery = TransportPositionObjectQuery | string;
