import {ISelectionProperty} from '../interfaces';

export enum PriorityLevel {
  NoPriority,
  VeryLowPriority,
  LowPriority,
  MediumPriority,
  HighPriority,
  VeryHighPriority
}

export enum PriorityLevelDescriptions {
  NoPriority = 'Keine',
  VeryLowPriority = 'Sehr niedrig',
  LowPriority = 'Niedrig',
  MediumPriority = 'Mittel',
  HighPriority = 'Hoch',
  VeryHighPriority = 'Hoch'
}

export const PriorityLevelSelection: ISelectionProperty[] = [
  // {
  //   view: 'Sehr Hoch',
  //   value: PriorityLevel.VeryHighPriority
  // },
  {
    view: PriorityLevelDescriptions.HighPriority,
    value: PriorityLevel.VeryHighPriority
  },
  {
    view: PriorityLevelDescriptions.MediumPriority,
    value: PriorityLevel.MediumPriority
  },
  {
    view: PriorityLevelDescriptions.LowPriority,
    value: PriorityLevel.LowPriority,
  },
/*  {
    view: 'Sehr niedrig',
    value: PriorityLevel.VeryLowPriority
  },*/
  {
    view: PriorityLevelDescriptions.NoPriority,
    value: PriorityLevel.NoPriority
  }
];
