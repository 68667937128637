export * from './IConfirmDialogData.interface';
export * from './IDependencyConfig.interface';
export * from './IInputConfig.interface';
export * from './IManagementDialogCreation.interface';
export * from './IManagementDialogData.interface';
export * from './IManagementService.interface';
export * from './navigation-route.interface';
export * from './IOutput.interface';
export * from './IOutputConfig.interface';
export * from './IPropertyView.interface';
export * from './ITypeConfig.interface';
export * from './IRouteReturnState.interface';
export * from './IImageTableExportData.interface';
export * from './selection-property.interface';
