import {EntitySettings} from './entity-settings.interface';
import {SettingsMap} from '../settings-map.model';
import {ColumnReader} from '../../../import';
import {TransportPosition} from '../../../domain/tms-model';
import {ViewNames} from '../../../constants';

export {
  TransportPositionSettings
}

class TransportPositionSettings extends EntitySettings {
  importColumnOrder = Object.keys(ColumnReader.getColumns(TransportPosition)!);
  displayStrings = ViewNames.transportPosition;

  constructor(settings: SettingsMap) {
    super();

    if (settings['transport-position-import-order']) {
      this.importColumnOrder = settings['transport-position-import-order'];
    }


    if (settings['transport-position-column-names'] && settings['web-import-column-naming-preferred']) {
      const preferImportNaming: boolean = JSON.parse(settings['web-import-column-naming-preferred']![0]);
      if (preferImportNaming) {
        this.displayStrings = {...this.displayStrings, ...JSON.parse(settings['transport-position-column-names'][0])}
      }
    }
  }

  override toMap(): Partial<SettingsMap> {
    return {
      'transport-position-import-order': this.importColumnOrder,
      'transport-position-column-names': [JSON.stringify(this.displayStrings)]
    };
  }
}
