import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisclaimerComponent implements OnInit {

  @Input() width?: number;

  constructor() { }

  ngOnInit(): void {
  }

}
