<form [formGroup]="transportUpdateFormGroup" class="row small-spacing">
    <mat-form-field appearance="fill" color="accent">
        <mat-label>{{displayStrings.transportPosition.bookingNumber}}</mat-label>
        <input formControlName="bookingNumber" matInput type="text" />
    </mat-form-field>
    <mat-form-field appearance="fill" color="accent">
        <mat-label>{{displayStrings.transportPosition.jobNumber}}</mat-label>
        <input formControlName="jobNumber" matInput type="text" />
    </mat-form-field>
    <app-date-time-form-field label="Bestelldatum" appearance="fill" class="form-field" color="primary" formControlName="orderedBy"
        required></app-date-time-form-field>
</form>

<app-submit-row align="center" alignButtons="center" mat-dialog-actions>
    <app-save-button (click)="save()" (save)="save()" [disabled]="!updateIsValid"></app-save-button>
    <app-abort-button (click)="onNoChanges()" label="Keine Angabe"></app-abort-button>
</app-submit-row>