<button mat-icon-button [matMenuTriggerFor]="paginatorMenu">
    <mat-icon>more_vert</mat-icon>
</button>


<mat-menu #paginatorMenu>
    <button mat-menu-item (click)="removeSortState()">
        <mat-icon color="warn">delete</mat-icon>
        <span>{{'ui.common.table.actions.clear.storage.sort' | translate}}</span>
    </button>

    <!--  <button mat-menu-item (click)="removeFilterState()">-->
    <!--    <mat-icon color="warn">delete</mat-icon>-->
    <!--    <span>Filter-Speicher leeren</span>-->
    <!--  </button>-->
</mat-menu>
