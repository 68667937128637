import {Component, forwardRef, Injector, Optional, Self} from '@angular/core';
import {HandlingUnit} from '../../domain/core-model';
import {FormBuilder, NgControl} from '@angular/forms';
import {BaseFormFieldComponent} from '../base-form-field/base-form-field.component';
import {CustomControl} from '../model/custom-control.model';
import {HandlingUnitService} from '../../entity-state/handling-unit/handling-unit.service';

@Component({
    selector: 'app-handling-unit-form-field',
    templateUrl: '../base-form-field/base-form-field.component.html',
    styleUrls: ['../base-form-field/base-form-field.component.scss'],
    providers: [
        {provide: BaseFormFieldComponent, useExisting: forwardRef(() => HandlingUnitFormFieldComponent)},
        {provide: CustomControl, useExisting: forwardRef(() => HandlingUnitFormFieldComponent)}
    ]
})
export class HandlingUnitFormFieldComponent extends BaseFormFieldComponent<HandlingUnit> {

    constructor(
        _injector: Injector,
        _fb: FormBuilder,
        @Optional() @Self() public override ngControl: NgControl,
    ) {
        super(
            {providerType: HandlingUnitService},
            {key: 'handlingUnitId', displayKeys: ['handlingUnitId', 'description'], defaultLabel: 'Handlingunit'},
            ngControl
        );
    }
}
