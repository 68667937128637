import {UserManagerSettings} from 'oidc-client';
import {IcsCdkMessengerHubConfiguration} from '@ics-cdk/messenger';

export interface AppEnvironment {
    production: boolean;
    environmentType: EnvironmentType, // for separating dev instance from production i.e. for performance measuring
    version: string;
    lvsUrl: string;
    tmsUrl: string;
    coreUrl: string;
    tlHubUrl: string;
    userManagerSettings: UserManagerSettings;
    messenger: IcsCdkMessengerHubConfiguration
    appId: string;
    tmsEnabled: boolean;
    lvsEnabled: boolean;
    hubAPIEnabled: boolean;
    flattenedExport: boolean;
}


export enum EnvironmentType {
    LOCAL,
    DEVELOPMENT,
    PRODUCTION,
}


export const BaseEnvironment = {
    IdentityServerInstance: 'https://identity.ics-group.cloud/',
    IdentityServerFallbackInstance: 'https://identity2.ics-group.cloud/'
}
