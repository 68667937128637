import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-base-form-field-option',
  templateUrl: './base-form-field-option.component.html',
  styleUrls: ['./base-form-field-option.component.scss']
})
export class BaseFormFieldOptionComponent<T> implements OnInit {

  @Input() value!: T;
  @Input() fallbackKey?: keyof T;

  constructor() {
  }

  get hasAllKeys(): boolean {
    if (this.displayKeys.length === 1) {
      return !!this.value[this.displayKeys[0]];
    }

    return !!(this.value[this.displayKeys[0]] && this.value[this.displayKeys[1]]);
  }

  private _displayKeys!: [keyof T] | [keyof T, keyof T];

  get displayKeys() {
    return this._displayKeys;
  }

  @Input()
  set displayKeys(value: [keyof T] | [keyof T, keyof T]) {
    this._displayKeys = value.filter(v => !!v) as any;
  }

  ngOnInit(): void {
  }

}
