import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {BarcodeService} from "../barcode-service/barcode.service";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {NgIf, NgTemplateOutlet} from '@angular/common';
import {MatRippleModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';

type BarCodeDataDelimiter = '-' | '#' | '$' | '::';

interface BarcodeDialogData {
    encoding: string;
    barcodeData: string;
    width: number;
    scale?: number;
    delimiter?: BarCodeDataDelimiter;
    imagePadding?: string;
    noHeader?: boolean;
}

/**
 * Displays a given value encoded as barcode to the user
 * for implementation {@link https://github.com/metafloor/bwip-js} is used.
 * Supports all formats of bwipsjs 1-dimensional and 2-dimensional
 * Throws an error if the given string does not match the regulations of
 * the barcode format (i.e. max 13 digits for ean13)
 *
 *
 * Can also be used as mat-dialog component
 */
@Component({
    selector: 'app-barcode',
    templateUrl: './barcode.component.html',
    styleUrls: ['./barcode.component.scss'],
    imports: [
        NgIf,
        NgTemplateOutlet,
        MatDialogModule,
        MatRippleModule,
        MatButtonModule,
        TranslateModule
    ],
    standalone: true
})
export class BarcodeComponent implements OnInit {

    @ViewChild('codeElement') codeElement!: ElementRef;

    /**
     * Determines the width of the image of the barcode
     */
    @Input() width: number | undefined;
    /**
     * Emits the base64 representation of the barcode after rendering
     */
    @Output() codeRendered: EventEmitter<string> = new EventEmitter();
    barcodeImageUrl: string | undefined;
    dataHeader: string | undefined;
    noHeader: boolean = true;

    constructor(
        private _barcodeService: BarcodeService,
        @Optional() public dialogRef?: MatDialogRef<BarcodeComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data?: BarcodeDialogData
    ) {
        if (this.data) {
            this.codeData = this.data?.barcodeData;
            this.width = this.data?.width;

            if (this.data?.scale) {
                this.scale = this.data.scale;
            }

            if (this.data.delimiter) {
                this.codeDataDelimiter = this.data?.delimiter;
            }

            if (this.data.encoding) {
                this.encoding = this.data?.encoding;
            }

            if (this.data.noHeader) {
                this.noHeader = this.data?.noHeader;
            }
        }
    }

    private _codeData!: string;

    get codeData(): string {
        return this._codeData;
    }

    /**
     * The data that gets encoded as barcode
     * @param value string that matches the regulations of wanted barcode format
     */
    @Input()
    set codeData(value: string) {
        this._codeData = value;
        if (this._codeData) {
            this.encryptData();
            this.checkHeaderData();
        }
    }

    private _scale: number = 5;

    /**
     * Scaling factor of the barcode (image)
     * @param value
     */
    @Input()
    set scale(value: number) {
        this._scale = value;
        this.encryptData();
    }

    private _codeDataDelimiter: BarCodeDataDelimiter | undefined;

    get codeDataDelimiter(): BarCodeDataDelimiter | undefined {
        return this._codeDataDelimiter;
    }

    /**
     * determines by which character the data is delimited. This character is used for creating the
     * dialog header. If non delimiter is set, no splitting will be done and code gets displayed raw.
     * @param value supported delimiter characters are: '-' | '#' | '$'
     */
    @Input()
    set codeDataDelimiter(value: BarCodeDataDelimiter | undefined) {
        if (value) {
            this._codeDataDelimiter = value;
            this.checkHeaderData();
        }
    }

    private _imagePadding: string | undefined;

    get imagePadding(): number | undefined {
        return this._imagePadding ? Number(this._imagePadding.slice(this._imagePadding.indexOf('px'), 2)) : undefined;
    }

    /**
     * Controls the padding of the DIV surrounding the code. In that way can shrink the code.
     * @param value number assumed as pixels
     */
    @Input()
    set imagePadding(value: number | undefined) {
        if (value) {
            this._imagePadding = `${value}px`;
        }
    }

    private _interactive = false;

    get interactive(): boolean {
        return this._interactive;
    }

    /**
     * Controls whether the element show interactivity by using a mat-ripple or not
     * @param value coerced value. Can be used as flag.
     */
    @Input()
    set interactive(value: any) {
        this._interactive = coerceBooleanProperty(value);
    }

    private _encoding = 'datamatrix';

    get encoding(): string {
        return this._encoding;
    }

    /**
     * Controls which kind of code will be rendered for information about supported codes
     * check {@link https://github.com/metafloor/bwip-js/wiki/BWIPP-Barcode-Types}
     * @param value the type of encoding to be used
     */
    @Input()
    set encoding(value: string) {
        if (value) {
            this._encoding = value;
            this.encryptData();
        }
    }

    ngOnInit(): void {
    }

    private async encryptData() {
        if (this._codeData) {
            // reading base64 representation
            this.barcodeImageUrl = this._barcodeService.generateCode(this._encoding, this._codeData, {scale: this._scale});
            this.codeRendered.emit(this.barcodeImageUrl);
        }
    }

    /**
     * Checks if there is a delimiter in the origin value and build the dialog header from it
     * @private
     */
    private checkHeaderData(): void {
        this.dataHeader = this._codeDataDelimiter ? this._codeData?.split(this._codeDataDelimiter).join(' ') : this._codeData;
    }
}

