import {Injectable} from '@angular/core';
import {BaseApiService} from '../base-api-service/base-api.service';
import {Warehouse} from '../../domain/core-model';
import {HttpClient} from '@angular/common/http';
import {Observable, of, switchMap} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {IHttpResult} from '../interfaces/http-result.interface';
import {IcsUserFeedbackService} from 'ics-core';

@Injectable({
    providedIn: 'root'
})
export class ContainerApiService extends BaseApiService<Warehouse> {

    constructor(http: HttpClient, userFeedback: IcsUserFeedbackService) {
        super('Container', 'core', http, userFeedback);
    }

    // TODO: Type this param
    createFull(value: any, userFeedback = true): Observable<IHttpResult<Warehouse>> {
        return this._http.post<any>(this.buildEndpointUrl('createFull'), value, {observe: 'response'})
            .pipe(
                catchError(this.handleHttpErrorResult),
                map(this.buildHttpResult),
                switchMap((res) => {
                    if (userFeedback === true) {
                        const type = res.isError ? 'error' : 'success';
                        return this._userFeedback.show({
                            type: type,
                            message: `ui.user.feedback.${type}.container.create.full`
                        }).pipe(
                            map(() => res)
                        );
                    }

                    return of(res);
                })
            );
    }
}
