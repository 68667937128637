import {StorageBin} from './storage-bin.model';
import {FormValue} from '../utility-types';
import {StorageBinCollectionFormGroup} from '../form-groups/storage-area-form-group.model';
import {GOODS_RECEPTION_ID} from '../core-model';

export {
    StorageBinBuilder
}

type StorageBinTemplate =
    Pick<FormValue<StorageBinCollectionFormGroup>, 'levelCount' | 'rowCount' | 'shelfCount'>
    & Partial<FormValue<StorageBinCollectionFormGroup>>
    & { storageAreaId: string, clusterIndex: number };

type StorageBinIdTemplate = { storageBinId: string, row: number, shelf: number, level: number };

const generateIdDigit = (i: number): { index: number, digit: string } => {
    return {index: i, digit: i.toString().padStart(2, '0')};
}

const generateStorageBinIdTemplates = (storageAreaId: string, clusterIndex: number, shelfCount: number, levelCount: number, rowCount: number): Array<StorageBinIdTemplate> => {
    const result = new Array<StorageBinIdTemplate>();

    // minimum for first digit
    const start = clusterIndex;

    // maximum for last leading digit
    const end = clusterIndex + shelfCount;

    // creating a range of numbers from last shelf to new one
    const shelfRange = Array.from(new Array(end - start).keys(), (v, k) => k + start);

    const isGoodsReception = storageAreaId === GOODS_RECEPTION_ID;

    // created digits for all ids
    const shelves = shelfRange.map((s) => generateIdDigit(isGoodsReception ? s : s + 1)); // goods receptions start at 0
    const levels = Array.from({length: levelCount}, (_, i) => generateIdDigit(i + 1));
    const rows = Array.from({length: rowCount}, (_, i) => generateIdDigit(i + 1));

    for (const shelf of shelves) {
        for (const level of levels) {
            for (const row of rows) {
                const storageBinId = `${shelf.digit}${level.digit}${row.digit}`;
                result.push({storageBinId, shelf: shelf.index, row: row.index, level: level.index});
            }
        }
    }

    return result;
}

const generateStorageBinIds = (storageAreaId: string, clusterIndex: number, shelfCount: number, levelCount: number, rowCount: number): Array<string> => {
    return generateStorageBinIdTemplates(storageAreaId, clusterIndex, shelfCount, levelCount, rowCount,).map(s => s.storageBinId);
}

const generateStorageBins = (value: StorageBinTemplate): Array<StorageBin> => {
    return generateStorageBinIdTemplates(value.storageAreaId, value.clusterIndex ?? 0, value.shelfCount, value.levelCount, value.rowCount,)
        .map((template) => new StorageBin({
            ...template,
            isHighRack: !!value.isHighRack,
            storageAreaId: value.storageAreaId,
        }));
}

const StorageBinBuilder = {
    generateIdDigit,
    generateStorageBinIdTemplates,
    generateStorageBinIds,
    generateStorageBins
};
