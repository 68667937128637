import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {IconRegistry} from './icon-registry.constants';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {
    this.registerIcons();
  }

  registerIcons(): void {
    for (const icon of IconRegistry) {
      this._matIconRegistry.addSvgIcon(icon.iconName, this._domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.iconPath}`));
    }
  }
}
