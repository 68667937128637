import {Component, ElementRef, NgZone} from '@angular/core';
import {BaseButtonDirective} from '../base-button/base-button.directive';

@Component({
  selector: 'app-refresh-button',
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.scss']
})
export class RefreshButtonComponent extends BaseButtonDirective {

  constructor(elementRef: ElementRef, ngZone: NgZone) {
    super(elementRef, ngZone);
  }


}
