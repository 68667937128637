import {Pipe, PipeTransform} from '@angular/core';
import {InitiatorService} from '../entity-state/initiator-service/initiator.service';
import {Observable} from 'rxjs';
import {Initiator, InitiatorId} from '../domain/tms-model';
import {map} from 'rxjs/operators';

@Pipe({
    name: 'initiator',
    standalone: true
})
export class InitiatorPipe implements PipeTransform {

    constructor(private _initiatorState: InitiatorService) {
    }


    transform(initiatorId: InitiatorId): Observable<string | null> {
        return this._initiatorState.getById({initiatorId}, true).pipe(
            map((initiator: Initiator | null) => initiator?.name ?? '--')
        );
    }

}
