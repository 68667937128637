export {
  Disclaimers
}

const warning = {
  delete: `Wirklich löschen?`

};

const success = {};

const information = {
  import: {
    headerImport: `Dieser Import steht auch mit Kopfzeile zur Verfügung. In diesem Fall können Sie in der ersten Zeile der importierten Datei die Beschriftung der Spalten angeben und diese wird mit den vorhandenen Angaben (Spaltennamen) abgeglichen.
                    Durch den Import mit Kopfzeile können auch zusätzliche Angaben, die dem System noch nicht bekannt sind, importiert werden.
                    Das Startsymbol kann bei diesen Importen entfallen.`
  },
  materialRequest: {
    groupSuffix: `Dieser Suffix wird zu Unterscheidung von Artikelgruppen verwendet. Hierfür wird dieser Automatisch an bereits existierende Artikelgruppennamen angehängt, der
                  für eine Materialnachforderung verwendet wird`
  }
};


const Disclaimers = {
  ...warning,
  ...success,
  ...information
};
