import {InjectionToken} from "@angular/core";
import {Observable} from "rxjs";

export { ImportLoggingProvider, IMPORT_LOGGING };

// token for framewokr to identify the provided logging-service
const IMPORT_LOGGING = new InjectionToken<ImportLoggingProvider<any>>('ImportLoggingProvider');

/**
 * A Service that allows the import module to create a persistent log
 * of the failed and succeeded imports.
 */
interface ImportLoggingProvider<T extends { content: string }> {

  /**
   * all curently saved logs of imports
   */
  getCurrentLogs(source: string): Observable<T[]>;

  /**
   * writes an info log
   */
  info(content: string, source: string): void;

  /**
   * writes an warn log
   */
  warn(content: string, source: string): void;

  /**
   * writes an critical log
   */
  critical(content: string, source: string): void;

  /**
   * writes an debug log
   */
  debug(content: string, source: string): void;

  /**
   * writes an  trace log
   */
  trace(content: string, source: string): void;

  /**
   *
   */
  commit(source: string): void;
}
