import {ArticleRequisition} from '../../../domain/lvs-model';
import {
    StateEagerLoadingConfig,
    StateEagerLoadingOptions
} from '../../model/eager-loaded-state/eager-loaded-state.options';
import {WarehouseService} from '../../warehouse/warehouse-service/warehouse.service';
import {Warehouse} from '../../../domain/core-model';
import {InactiveWarehouseService} from '../../warehouse/inactive-warehouse-service/inactive-warehouse.service';

export {
    ArticleRequisitionServiceConfig
}

const ArticleRequisitionServiceConfig = new StateEagerLoadingConfig<ArticleRequisition>(
    {type: ArticleRequisition, primaryKeys: ['requisitionId']},
    {prefetching: true, eagerLoading: true},
    new StateEagerLoadingOptions(
        {
            serviceReference: WarehouseService as any,
            eagerProperty: 'warehouseId',
            writingProperty: 'warehouseObj',
            eagerLoadedType: Warehouse
        },
        {
            serviceReference: InactiveWarehouseService as any,
            eagerProperty: 'warehouseId',
            eagerLoadedType: Warehouse,
            writingProperty: 'warehouseObj'
        }
    ),
);
