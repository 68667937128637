import {Component, forwardRef, Optional, Self} from '@angular/core';
import {Initiator} from '../../domain/tms-model';
import {BaseFormFieldComponent} from '../base-form-field/base-form-field.component';
import {CustomControl} from '../model/custom-control.model';
import {InitiatorService} from '../../entity-state/initiator-service/initiator.service';
import {NgControl} from '@angular/forms';

@Component({
    selector: 'app-initiator-form-field',
    templateUrl: '../base-form-field/base-form-field.component.html',
    styleUrls: ['../base-form-field/base-form-field.component.scss'],
    providers: [
        {provide: BaseFormFieldComponent, useExisting: forwardRef(() => InitiatorFormFieldComponent)},
        {provide: CustomControl, useExisting: forwardRef(() => InitiatorFormFieldComponent)}
    ],
})
export class InitiatorFormFieldComponent extends BaseFormFieldComponent<Initiator> {

    constructor(
        @Optional() @Self() public override ngControl: NgControl
    ) {
        super(
            {providerType: InitiatorService},
            {defaultLabel: 'Auftraggeber', key: 'initiatorId', displayKeys: ['name']},
            ngControl
        );
    }

    override get selectedEntity(): Initiator | null {
        const numberId = Number(this.control.value);
        if (!isNaN(numberId)) {
            return this.autoCompleteOptions?.find(i => i.initiatorId === numberId) ?? null;
        }
        return super.selectedEntity;
    }
}
