import {Injectable} from '@angular/core';
import {combineLatest, mergeMap, Observable, of, switchMap, zip} from 'rxjs';
import {map} from 'rxjs/operators';
import {DialogMessage} from 'src/app/domain/enums';
import {ArticleGroup, MaterialRequest, TransportPosition, TransportPositionState} from '../../domain/tms-model';
import {MaterialRequestApiService} from '../../api/material-request-api/material-request-api.service';
import {SignalRMaterialRequestService} from "../../websocket";
import {StateService} from '../model/state/state.service';
import {ArticleGroupService} from '../article-group-service/article-group.service';
import {SettingsService} from '../../settings';
import {MaterialRequestSettings} from '../../settings/model/entity-settings';
import {
    TransportBookingDialogComponent
} from '../../modules/tms/tms-shared/transport-booking-dialog/transport-booking-dialog.component';
import {PropertySearcher} from '../../search/model';
import {SearchUtil} from '../../search/util';
import {DialogService} from '../../core';
import {TransportPositionService} from '../transport-position/transport-position.service';

@Injectable({
    providedIn: 'root'
})
export class MaterialRequestService extends StateService<MaterialRequest> {

    constructor(
        private _dialog: DialogService,
        private _articleGroups: ArticleGroupService,
        private _settings: SettingsService,
        private _transportPositions: TransportPositionService,
        protected override _webSocket: SignalRMaterialRequestService,
        protected override _api: MaterialRequestApiService,
    ) {
        super({
            type: MaterialRequest, api: MaterialRequestApiService,
            webSocket: _webSocket, primaryKeys: ['materialRequestId']
        }, {prefetching: true});
    }

    override get state(): Observable<MaterialRequest[]> {
        return combineLatest([this.storage.state, this._transportPositions.state]).pipe(
            map(([requests, transports]) => {
                return requests.map(m => this._buildEagerLoadedMaterialRequest(m, transports));
            })
        );
    }

    acceptMaterialRequest(materialRequest: MaterialRequest): Observable<MaterialRequest | null> {
        return this._checkIfBookingNumbersNeeded(materialRequest)
            .pipe(
                mergeMap((result) => {
                    // undefined is only returned force abort
                    if (result !== undefined) {
                        return this._runAcceptance({
                            ...materialRequest,
                            transportPositions: result ?? materialRequest.transportPositions
                        })
                    }
                    return of(null);
                }));
    }

    createAndNotifyUser(materialRequest: MaterialRequest, notifyInitiator: boolean): Observable<{
        created: boolean,
        notified: boolean | null
    }> {
        return this.createInternal(materialRequest)
            .pipe(
                mergeMap((mr: MaterialRequest | null) => {
                    if (mr) {
                        return this._api.sendMaterialRequestMailNotification(mr, notifyInitiator)
                            .pipe(
                                map((result) => {
                                    return {created: !!mr, notified: !result.isError};
                                })
                            )
                    }
                    return of({created: !!mr, notified: null});
                })
            );
    }

    getNextArticleGroupSuffix(searchValue: string | null | undefined): Observable<string | null> {
        if (!searchValue) {
            return of('');
        }
        return zip(this._articleGroups.findNameStartsWith(searchValue, 'un-sensitive'), this._settings.getEntitySettings(MaterialRequestSettings)).pipe(
            map(([articleGroups, setting]: [ArticleGroup[], MaterialRequestSettings]) => {
                const {automaticGroupSuffix} = setting ?? {};

                if (articleGroups.length === 0 || !automaticGroupSuffix) {
                    return null;
                }

                return this._buildNewGroupNameSuffix(searchValue, automaticGroupSuffix, articleGroups);
            }),
        );
    }

    askForBookingNumbers(...materialRequests: MaterialRequest[]): Observable<TransportPosition[] | null | undefined> {
        const transports = materialRequests.reduce((acc, curr) => acc.concat(curr.transportPositions), new Array<TransportPosition>())
        return this._dialog.showDialog(TransportBookingDialogComponent)
            .afterClosed()
            .pipe(
                map((dialogResult) => {
                    if (dialogResult.message === DialogMessage.NO_CHANGES) {
                        return null;
                    }

                    if (dialogResult?.message === DialogMessage.CONFIRM && dialogResult.result) {
                        const {bookingNumber, jobNumber, orderedBy} = dialogResult.result;
                        return transports.map(p => {
                            return {
                                ...p,
                                bookingNumber: bookingNumber ?? p.bookingNumber,
                                jobNumber: jobNumber ?? p.jobNumber,
                                orderedBy: orderedBy ?? p.orderedBy
                            };
                        })
                    }
                    // no changes
                    return undefined;
                })
            )
    }


    /**
     * Checks if a material-request is either in-complete or not checked
     * @param m
     * @protected
     */
    protected override isIncomplete(m: MaterialRequest): boolean {
        return (!m.isChecked || m.isComplete === false);
    }

    protected override searchProperty: PropertySearcher<MaterialRequest> = (searchList, key, query) => {
        if (key === 'transportPositions') {
            const transportQuery = query?.[0];
            const withTransports = searchList.filter(m => !!m.transportPositions);
            return withTransports.filter(m => {
                return SearchUtil.search(m.transportPositions!, transportQuery ?? {}).length > 0
            });
        }

        return undefined;
    };

    private _checkIfBookingNumbersNeeded(materialRequest: MaterialRequest) {
        if (materialRequest.transportPositions.find(p => !p.bookingNumber || !p.jobNumber)) {
            return this._askForMissingBookingNumbersAcceptance(materialRequest);
        }
        return of(null);
    }

    private _askForMissingBookingNumbersAcceptance(materialRequest: MaterialRequest) {
        // TODO: TEST this
        return this._confirm.askForConfirmation({
            title: 'ui.material.requests.confirmations.booking.numbers.missing.title',
            message: 'ui.material.requests.confirmations.booking.numbers.missing.message',
        }).pipe(
            switchMap(() => this._updateMissingBookingNumbers(materialRequest))
        );
    }

    private _updateMissingBookingNumbers(materialRequest: MaterialRequest) {
        const onlyMissingNumbersTransports = materialRequest.transportPositions.filter(p => !p.bookingNumber && !p.jobNumber);
        return this.askForBookingNumbers({...materialRequest, transportPositions: onlyMissingNumbersTransports})
            .pipe(
                map(result => {
                    if (result) {
                        return materialRequest.transportPositions.map(p => result.find(r => r.transportPositionId === p.transportPositionId) ?? p)
                    }
                    return result;
                })
            );
    }

    private _runAcceptance(materialRequest: MaterialRequest): Observable<MaterialRequest | null> {
        const materialRequestUpdate = new MaterialRequest(materialRequest.transportPositions, materialRequest.materialRequestId, true);
        materialRequestUpdate.transportPositions.forEach(p => p.transportState = TransportPositionState.Released);
        const updateDeepCopy = JSON.parse(JSON.stringify(materialRequestUpdate));
        return zip(
            this.update(materialRequestUpdate),
            materialRequest.transportPositions.length > 0 ? this._transportPositions.update(...materialRequest.transportPositions) : of(true)
        ).pipe(
            map((res: boolean[]) => !res.includes(false)),
            map(success => success ? updateDeepCopy : null)
        );
    }

    private _buildNewGroupNameSuffix(articleGroupName: string, suffix: string, articleGroups: ArticleGroup[]): string | null {
        const nameMatcher = new RegExp(`${articleGroupName.toLowerCase()}${suffix.toLowerCase()}-(\\d+)$`);

        const matched = articleGroups.map(g => g.name.toLowerCase()).filter(name => {
            return name === articleGroupName.toLowerCase() || name.match(nameMatcher);
        });

        if (matched.length === 0) {
            return null;
        }

        const suffixed = matched.filter(a => a.includes(suffix.toLowerCase()));

        return `${suffix}-${suffixed.length + 1}`;
    }

    private _buildEagerLoadedMaterialRequest(request: MaterialRequest, transports: TransportPosition[]): MaterialRequest {
        const stateTransports = transports.filter(p => p.materialRequestId === request.materialRequestId);
        return new MaterialRequest(stateTransports, request.materialRequestId, request.isChecked);
    }
}
