import {Article, HandlingUnit, User, Vehicle, Vehicletype, Warehouse,} from '../domain/core-model';
import {
    ArticleGroupRequisition,
    ArticleRequisition,
    StorageArea,
    StorageBin,
    StorageBinArticle,
} from '../domain/lvs-model';
import {ArticleGroup, Initiator, MaterialRequest, SuperTask, TransportPosition} from '../domain/tms-model';
import {ModuleWebsocketConfig, WebsocketRootConfiguration} from '../websocket';
import {TransportTask} from '../modules/tms/transport-task';

export {AppWebSockets};

const CoreWebsockets = new ModuleWebsocketConfig('core', [
    {
        name: 'Article',
        type: Article,
    },
    {
        name: 'Warehouse',
        type: Warehouse,
    },
    {
        name: 'Container',
        type: Warehouse,
    },
    {
        name: 'HandlingUnit',
        type: HandlingUnit,
    },
    {
        name: 'Vehicle',
        type: Vehicle,
    },
    {
        name: 'VehicleType',
        type: Vehicletype,
    },
    {
        name: 'User',
        type: User,
    },
]);

const LvsWebsockets = new ModuleWebsocketConfig('lvs', [
    {name: 'StorageArea', type: StorageArea},
    {name: 'StorageBin', type: StorageBin},
    {name: 'StorageBinArticle', type: StorageBinArticle},
    {name: 'ArticleRequisition', type: ArticleRequisition},
    {name: 'ArticleGroupRequisition', type: ArticleGroupRequisition},
]);

const TmsWebsockets = new ModuleWebsocketConfig('tms', [
    {name: 'TransportPosition', type: TransportPosition},
    {name: 'ArticleGroup', type: ArticleGroup},
    {name: 'SuperTask', type: SuperTask},
    {name: 'TransportTask', type: TransportTask},
    {name: 'MaterialRequest', type: MaterialRequest},
    {name: 'Initiator', type: Initiator},
]);

const AppWebSockets: WebsocketRootConfiguration = [CoreWebsockets, LvsWebsockets, TmsWebsockets];
