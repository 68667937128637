import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import Timeout = NodeJS.Timeout;

@Directive({
    selector: '[appFitText]'
})
export class FitTextDirective implements OnChanges, OnInit, AfterViewInit {
    @Input() fitText = true;
    @Input() compression = 1;
    @Input() activateOnResize = true;
    @Input() minFontSize: number | 'inherit' = 0;
    @Input() maxFontSize: number | 'inherit' = Number.POSITIVE_INFINITY;
    @Input() delay = 100;
    @Input() innerHTML = '';
    @Input() fontUnit?: 'px' | 'em' | string = 'px';

    private readonly _fitTextElement: HTMLElement;
    private readonly _computed: CSSStyleDeclaration;
    private readonly _newLines: number;
    private _fitTextParent?: HTMLElement;
    private _fitTextMinFontSize!: number;
    private _fitTextMaxFontSize!: number;
    private _lineHeight: string;
    private _display: string;
    private _calcSize = 10;
    private _resizeTimeout!: Timeout;

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this._fitTextElement = el.nativeElement;
        if (this._fitTextElement.parentElement) {
            this._fitTextParent = this._fitTextElement.parentElement;
        }

        this._computed = window.getComputedStyle(this._fitTextElement);
        this._newLines = this._fitTextElement.childElementCount > 0 ? this._fitTextElement.childElementCount : 1;
        this._lineHeight = (this._computed as any)['line-height'];
        this._display = this._computed.display;
    }

    @HostListener('window:resize')
    public onWindowResize = (): void => {
        if (this.activateOnResize) {
            this.setFontSize();
        }
    }

    public ngOnInit() {
        this._fitTextMinFontSize = this.minFontSize === 'inherit' ? (this._computed as any)['font-size'] : this.minFontSize;
        this._fitTextMaxFontSize = this.maxFontSize === 'inherit' ? (this._computed as any)['font-size'] : this.maxFontSize;
    }

    public ngAfterViewInit() {
        this.setFontSize(0);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.compression && !changes.compression.firstChange) {
            this.setFontSize(0);
        }
        if (changes.innerHTML) {
            this._fitTextElement.innerHTML = this.innerHTML;
            if (!changes.innerHTML.firstChange) {
                this.setFontSize(0);
            }
        }
    }

    private setFontSize = (delay: number = this.delay): void => {
        if (!this._fitTextParent) {
            return;
        }
        this._resizeTimeout = setTimeout(
            (() => {
                if (this._fitTextElement.offsetHeight * this._fitTextElement.offsetWidth !== 0) {
                    // reset to default
                    this.setStyles(this._calcSize, 1, 'inline-block');
                    // set new
                    this.setStyles(this.calculateNewFontSize(), this._lineHeight, this._display);
                }
            }).bind(this),
            delay
        );
    }

    private calculateNewFontSize = (): number => {
        const ratio = (this._calcSize * this._newLines) / this._fitTextElement.offsetWidth / this._newLines;

        return Math.max(
            Math.min(
                (this._fitTextParent!.offsetWidth - (parseFloat(getComputedStyle(this._fitTextParent!).paddingLeft) + parseFloat(getComputedStyle(this._fitTextParent!).paddingRight)) -
                    6) *
                ratio *
                this.compression ?? 0,
                this._fitTextMaxFontSize
            ),
            this._fitTextMinFontSize
        );
    }

    private setStyles = (fontSize: number, lineHeight: number | string, display: string): void => {
        this.renderer.setStyle(this._fitTextElement, 'fontSize', fontSize.toString() + this.fontUnit);
        this.renderer.setStyle(this._fitTextElement, 'lineHeight', lineHeight.toString());
        this.renderer.setStyle(this._fitTextElement, 'display', display);
    }
}
