import {EventEmitter, Inject, Injectable, OnDestroy, Type} from '@angular/core';
import * as signalR from '@microsoft/signalr';
import {HttpError, IHttpConnectionOptions, LogLevel} from '@microsoft/signalr';
import {mergeMap, Observable, of, skipWhile, Subject} from 'rxjs';
import {ISignalRService} from '../model/signal-r-service.interface';
import {IcsIdentityAuthenticationService} from 'ics-identity-authentication';
import {SignalREvents} from "../model/signal-r-events.enum";
import {ServerResponse} from '../../api/interfaces/http-result.interface';
import {LockService} from '../../lock/lock-service/lock.service';
import {LockEntry} from '../../lock/model/lock-entry.model';
import {take} from 'rxjs/operators';
import {StatusCodes} from 'http-status-codes';
import {BaseEntity} from "../../domain";


@Injectable({
  providedIn: 'root'
})
export class SignalRService<T extends BaseEntity> implements ISignalRService<T>, OnDestroy {

  protected readonly newNotification: EventEmitter<T> = new EventEmitter<T>();
  protected readonly updatedNotification: EventEmitter<T> = new EventEmitter<T>();
  protected readonly deletedNotification: EventEmitter<string[]> = new EventEmitter<string[]>();
  protected readonly connectionNotification = new EventEmitter<void>();
  protected connection!: signalR.HubConnection;
  protected opt!: IHttpConnectionOptions;
  protected connectionSignalReceived = false;
  protected connectionStarted = false;
  private _connectionStarted = new Subject<void>();

  constructor(
    protected _authService: IcsIdentityAuthenticationService,
    @Inject('hubRoute') protected _hubRoute: string,
    protected _type: Type<T>,
    protected _lock: LockService
  ) {
    this._authService.isAuthenticated
      .pipe(
        skipWhile((isAuthenticated) => !isAuthenticated),
        mergeMap((isAuthenticated: boolean) => isAuthenticated ? this._authService.token : of(null)),
        take(1)
      ).subscribe((token) => {
      if (token) {
        this.opt = {
          accessTokenFactory: () => token,
          logger: LogLevel.None
        };

        this.connection = new signalR.HubConnectionBuilder()
          .withUrl(_hubRoute, this.opt)
          .withAutomaticReconnect()
          .build();

        if (!this.isConnected) {
          this.connect();
          this._connectionStarted.next();
          this.connectionStarted = true;
        }
      }
    });
  }

  get isConnected(): boolean {
    return this.connection.state === signalR.HubConnectionState.Connected;
  }

  public rebuildConnection() {
    this.connection.stop();
    this.startConnection();
  }

  public getNewNotification(): Observable<T> {
    return this.newNotification.asObservable();
  }

  public getUpdatedNotification(): Observable<T> {
    return this.updatedNotification.asObservable();
  }

  public getDeletedNotification(): Observable<any> {
    return this.deletedNotification.asObservable();
  }

  public getConnectionNotification(): Observable<void> {
    return this.connectionNotification.asObservable();
  }

  public disconnect() {
    this.connection.stop();
  }

  ngOnDestroy() {
    this.disconnect();
    this._connectionStarted.next();
    this._connectionStarted.complete();

  }

  protected connect() {
    // single new  entity
    this.connection.on(SignalREvents.RCVNEW, (result: T) => {
      // console.debug(`${this._hubRoute} received new entity => `, {entity: result});
      this.newNotification.emit(result);
    });
    // single updated entity
    this.connection.on(SignalREvents.RCVUPDATED, (result: ServerResponse<T> | T) => {
      const resultingEntity: T = (result as ServerResponse<T>)?.result ?? result as T;
      // console.debug(`${this._hubRoute} received update entity => `, {entity: resultingEntity});
      if (resultingEntity) {
        this.updatedNotification.emit(resultingEntity);
      }
    });
    // single deleted entity
    this.connection.on(SignalREvents.RCVDELETED, (result: string[]) => {
      // console.debug(`${this._hubRoute} received deleted entities => `, {entity: result});
      this.deletedNotification.emit(result);
    });
    // message service is connected (can trigger load)
    this.connection.on(SignalREvents.RCVCONNECTION, (result: Array<LockEntry<T>>) => {
      // console.debug(`Connection established to ${this._hubRoute}`);
      this.connectionSignalReceived = true;
      // console.debug(`Locked entities for type ${this._type.name} => `, {locks: result});
      this._lock.addLock(this._type, ...result);
      this.connectionNotification.emit();
    });

    this.connection.on(SignalREvents.RCVLOCKED, (result) => {
      // console.debug(`${this._hubRoute} received lock notification for => `, {entity: result});
      this._lock.addLock(this._type, result);
    });
    this.connection.on(SignalREvents.RCVUNLOCKED, (result) => {
      // console.debug(`${this._hubRoute} received unlock notification for => `, {entity: result});
      this._lock.releaseLock(this._type, result);
    });

    this.startConnection();
  }

  protected startConnection(): void {
    // starting the connection after all event-handlers has been build
    this.connection.start().then(() => {
      // console.trace(`Client started connection to ${this._hubRoute} with result`);
    }).catch((err: HttpError) => {
      console.warn(`There happened an error due connecting to ${this._hubRoute} => `, {err});
      if (err.statusCode === StatusCodes.INTERNAL_SERVER_ERROR) {
        setTimeout(() => this.connection.start(), 100);
      }
    });
  }
}
