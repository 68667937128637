import * as dayjs from 'dayjs';

export {filterByTime};


/**
 * Filters if a given Date/date-string is before or after a threshold
 * Default is after
 *
 * If one of the dates is invalid false is returned
 *
 * @param threshold
 * @param timeStamp
 * @param isAfter
 */
function filterByTime(threshold: string | Date, timeStamp: string | Date | undefined | null, isAfter = true): boolean {
    if (!threshold || !timeStamp) {
        return false;
    }

    const currentDate = filterDate(typeof timeStamp === 'string' ? timeStamp : timeStamp.toISOString());

    if (!currentDate.isValid()) {
        return false;
    }

    const dateThreshold = filterDate(typeof threshold === 'string' ? threshold : threshold.toISOString());

    if (!dateThreshold.isValid()) {
        return false;
    }

    return isAfter ? currentDate.isAfter(dateThreshold) : currentDate.isBefore(dateThreshold);
}

function filterDate(date: string): dayjs.Dayjs {
    if (date.includes('T')) {
        // returning just the date values and removing time
        return dayjs(date.split('T')[0]);
    }
    return dayjs(date);
}
