import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ArticleGroup} from '../../domain/tms-model';
import {Observable} from 'rxjs';
import {BaseApiService} from '../base-api-service/base-api.service';
import {catchError} from 'rxjs/operators';
import {IcsUserFeedbackService} from 'ics-core';

@Injectable({
    providedIn: 'root'
})
export class ArticleGroupApiService extends BaseApiService<ArticleGroup> {

    constructor(http: HttpClient, userFeedback: IcsUserFeedbackService) {
        super('ArticleGroup', 'tms', http, userFeedback);
    }

    findGroupsInWarehouse(warehouseId: number): Observable<ArticleGroup[]> {
        return this._http.post<ArticleGroup[]>(this.buildEndpointUrl('FindGroupsInWarehouse'), {warehouseId})
            .pipe(
                catchError(err => this.handleHttpGetError(err) as Observable<ArticleGroup[]>)
            );
    }
}

