import {Component, forwardRef, Optional, Self} from '@angular/core';
import {ArticleGroup} from '../../domain/tms-model';
import {BaseFormFieldComponent} from '../base-form-field/base-form-field.component';
import {NgControl} from '@angular/forms';
import {CustomControl} from '../model/custom-control.model';
import {ArticleGroupService} from '../../entity-state/article-group-service/article-group.service';

@Component({
    selector: 'app-article-group-form-field',
    templateUrl: '../base-form-field/base-form-field.component.html',
    styleUrls: ['../base-form-field/base-form-field.component.scss'],
    providers: [
        {provide: BaseFormFieldComponent, useExisting: forwardRef(() => ArticleGroupFormFieldComponent)},
        {provide: CustomControl, useExisting: forwardRef(() => ArticleGroupFormFieldComponent)},
        {provide: 'DefaultLabel', useValue: 'Quelle/Ziel'}
    ],
})
export class ArticleGroupFormFieldComponent extends BaseFormFieldComponent<ArticleGroup> {

    constructor(
        protected override entityState: ArticleGroupService,
        @Optional() @Self() public override ngControl: NgControl,
    ) {
        super(
            {stateProvider: entityState, providerType: null},
            {
                key: 'articleGroupId',
                displayKeys: ['name'],
                defaultLabel: 'Artikelgruppe'
            },
            ngControl
        );
    }

    override get selectedEntity(): ArticleGroup | null {
        if (!this.autoCompleteOptions) {
            return null;
        }
        return this.autoCompleteOptions?.find(a => a.articleGroupId === this.control.value) ?? null;
    }
}
