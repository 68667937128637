import {Injectable} from '@angular/core';
import {ArticleRequisition, RequisitionQuery} from '../../../domain/lvs-model';
import {EagerLoadingStateService} from '../../model/eager-loaded-state/eager-loading-state.service';
import {ArticleRequisitionServiceConfig} from './article-requisition-service.config';
import {PropertySearcher} from '../../../search/model';


@Injectable({
    providedIn: 'root'
})
export class ArticleRequisitionService extends EagerLoadingStateService<ArticleRequisition, RequisitionQuery> {

    constructor() {
        super(
            ArticleRequisitionServiceConfig.stateAccess,
            ArticleRequisitionServiceConfig.stateOptions,
            ArticleRequisitionServiceConfig.stateEagerLoadingOptions
        );
    }

    protected override searchProperty: PropertySearcher<ArticleRequisition, RequisitionQuery> = (searchList, key, query) => {
        if (key === 'warehouseName') {
            return searchList.filter(r => r.warehouseObj?.warehouseName.toLowerCase().includes(query!.toLowerCase()));
        }

        return undefined;
    };
}
