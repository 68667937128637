<ng-container *ngIf="!dialogRef; else dialogTemplate">
    <ng-container *ngTemplateOutlet="codeTemplate"></ng-container>
</ng-container>


<ng-template #dialogTemplate>
    <h2 mat-dialog-title *ngIf="!!noHeader">Code für <br/> {{this.dataHeader}}</h2>

    <div class="row" mat-dialog-content>
        <ng-container *ngTemplateOutlet="codeTemplate"></ng-container>
    </div>

    <div mat-dialog-actions align="center">
        <button mat-raised-button mat-dialog-close>
            {{'ui.common.labels.close' | translate}}
        </button>
    </div>
</ng-template>


<ng-template #codeTemplate>

    <div *ngIf="barcodeImageUrl">
        <div #codeElement [style.padding]="imagePadding ?? 0" mat-ripple matRippleCentered
             [matRippleDisabled]="!interactive">
            <img [src]="barcodeImageUrl" style="background-color: white;" [width]="width ?? 50"/>
        </div>
    </div>
</ng-template>
