import {TransportPositionService} from '../../../../entity-state/transport-position/transport-position.service';
import {TransportTaskQuery} from '../model/transport-task.query';
import {TransportTask} from '../model/transport-task.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export function filterTransportTasksByDestination$(
    transportPositionState: TransportPositionService,
    query: NonNullable<TransportTaskQuery['destination']>,
    searchList: TransportTask[]
): Observable<TransportTask[]> {
    const transportPositionQuery = typeof query === 'string' ? {destinationName: query} : {destination: query};

    return transportPositionState.search(transportPositionQuery).pipe(
        map((positions) => positions.map(p => p.transportTaskId)),
        map((taskIdsWithCorrectDestination) =>
            (searchList.filter(t => taskIdsWithCorrectDestination.includes(t.transportTaskId)))
        )
    );
}
