import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, Self, ViewChild} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ControlValueAccessor, NgControl, UntypedFormBuilder} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {FocusMonitor} from '@angular/cdk/a11y';
import {map, startWith} from 'rxjs/operators';
import {CustomFormFieldComponent} from "../custom-form-field.component";
import {Vehicletype} from "../../domain/core-model";
import {isEmpty, vehicletypeFilter} from "../../domain/functions";
import {entitySelectedValidator} from "../validators";
import {BaseApiService} from '../../api/base-api-service/base-api.service';
import {MAT_FORM_FIELD, MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';


@Component({
    selector: 'app-vehicletype-form-field',
    templateUrl: './vehicletype-form-field.component.html',
    styleUrls: ['./vehicletype-form-field.component.scss'],
    providers: [{provide: MatFormFieldControl, useExisting: VehicletypeFormFieldComponent}],
})
export class VehicletypeFormFieldComponent extends CustomFormFieldComponent<Vehicletype> implements OnInit, OnDestroy, ControlValueAccessor {
    static nextId = 0;
    filteredVehicletypeOptions!: Observable<Vehicletype[]>;
    @Input('aria-describedby') userAriaDescribedBy = '';
    @Input() style = '';
    @Input() tabindex = '';
    @ViewChild(MatAutocompleteTrigger, {static: true}) autoCompleteTrigger!: MatAutocompleteTrigger;
    stateChanges = new Subject<void>();
    focused = false;
    touched = false;
    controlType = 'vehicletype-selection';
    id = `vehicletype-selection-${VehicletypeFormFieldComponent.nextId++}`;
    optionsLoading = true;
    private _vehicletypeOptions: Vehicletype[] = [];

    constructor(
        @Inject(Vehicletype) private vehicletypeApi: BaseApiService<Vehicletype>,
        private fb: UntypedFormBuilder,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        super(fb.control(null));
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    private _disabled = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: any) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.control.disable() : this.control.enable();
    }

    @Input()
    get value(): string | Vehicletype | null {
        const selectedVehicletype = this.selectedEntity;
        if (this.emitOnlyOnSelect) {
            return this.emitOnlyEntities ? selectedVehicletype ?? null : selectedVehicletype?.vehicletypeId ?? null;
        } else if (this.emitOnlyEntities) {
            return selectedVehicletype ?? this.control.value;
        }
        return this.control.value;
    }

    set value(value: string | Vehicletype | null) {
        this.control.setValue(typeof value === 'string' ? value : value?.vehicletypeId);
        this.stateChanges.next();
    }

    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    get empty(): boolean {
        return this.control.value === '';
    }

    get errorState(): boolean {
        return this.control.invalid && this.touched || !isEmpty(this.control.errors);
    }

    get selectedEntity(): Vehicletype | undefined {
        return this._vehicletypeOptions?.find(w => w.vehicletypeId === this.control.value);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (_: any) => {
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {
    };

    ngOnInit(): void {
        this.vehicletypeApi.getAll()
            .subscribe((vehicletypes: Vehicletype[]) => {
                this.optionsLoading = false;
                this._vehicletypeOptions = vehicletypes;
                this.setUpFilter();
                this.checkValidators();
                this.reEnableValue();
            });
    }

    setUpFilter(): void {
        this.filteredVehicletypeOptions = this.control.valueChanges
            .pipe(
                startWith(''),
                map((input: string) => {
                    const filter = input?.toLowerCase() ?? '';
                    return this._vehicletypeOptions.filter((w: Vehicletype) => vehicletypeFilter(filter, w));
                })
            );
    }

    displayWithDescription(vehicletypeId: string): string {
        const vehicletype = this._vehicletypeOptions?.find((w: Vehicletype) => vehicletypeId && w.vehicletypeId.toLowerCase() === vehicletypeId.toLowerCase());
        if (vehicletype) {
            return `${vehicletype.vehicletypeId} | ${vehicletype.description}`;
        }
        return vehicletypeId;
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const controlElement = this._elementRef.nativeElement.querySelector(
            '.vehicletype-input-container',
        )!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent) {
        // fill
    }

    writeValue(obj: Vehicletype | string | null) {
        this.value = obj;
        this._handleInput();
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    _handleInput(): void {
        this.onChange(this.value);
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

    private reEnableValue(): void {
        if (this.control.value) {
            this.value = this.control.value;
            this._handleInput();
        }
    }

    private checkValidators(): void {
        if (this.emitOnlyOnSelect && !this.control.hasValidator(entitySelectedValidator<Vehicletype>(this._vehicletypeOptions, 'vehicletypeId'))) {
            this.control.addValidators(entitySelectedValidator<Vehicletype>(this._vehicletypeOptions, 'vehicletypeId'));
        }
    }
}
