import {EventEmitter, Injectable} from '@angular/core';
import {IcsIdentityAuthenticationService} from 'ics-identity-authentication';
import {Subject} from 'rxjs';
import {MaterialRequest} from 'src/app/domain/tms-model/material-request.model';
import {SignalREvents} from '../model/signal-r-events.enum';
import {SignalRService} from "../base-service/signal-r.service";
import {environment} from "../../../environments/environment";
import { LockService } from '../../lock/lock-service/lock.service';

@Injectable({
  providedIn: 'platform'
})
export class SignalRMaterialRequestService extends SignalRService<MaterialRequest>{

  private readonly countNotification: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    protected override _authService: IcsIdentityAuthenticationService,
    lock: LockService
  ) {
    super(_authService, environment.tmsUrl + '/MaterialRequestHub', MaterialRequest, lock);
  }

  protected override connect() {
    // number of material requests
    this.connection.on(SignalREvents.RCVCOUNT, (result) => {
      this.countNotification.next(result);
    });;
    super.connect();
  }

  public getCountNotification(): Subject<number> {
    return this.countNotification;
  }
}
