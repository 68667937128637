import {Component, ElementRef, forwardRef, Inject, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {CustomFormFieldComponent} from '../custom-form-field.component';
import {ControlValueAccessor, FormControl, NgControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {isEmpty} from '../../domain/functions';
import {PrivilegeLevels} from '../../roles';
import {FocusMonitor} from '@angular/cdk/a11y';
import {takeUntil} from 'rxjs/operators';
import {MAT_FORM_FIELD, MatFormField} from '@angular/material/form-field';
import {IcsRolesService} from 'ics-core';
import {TransportTaskState, TransportTaskStateSelections} from '../../modules/tms/transport-task';

@Component({
    selector: 'app-transport-task-state-form-field',
    templateUrl: './transport-task-state-form-field.component.html',
    styleUrls: ['./transport-task-state-form-field.component.scss'],
    providers: [
        {provide: CustomFormFieldComponent, useExisting: forwardRef(() => TransportTaskStateFormFieldComponent)}
    ]
})
export class TransportTaskStateFormFieldComponent extends CustomFormFieldComponent<TransportTaskState> implements OnInit, OnDestroy, ControlValueAccessor {

    static nextId = 0;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('aria-describedby') userAriaDescribedBy = '';
    stateOptions = TransportTaskStateSelections;
    stateChanges: Subject<void> = new Subject();
    touched = false;
    focused = false;
    userAccessLoaded = false;
    controlType = 'transport-task-state-selection';
    id = `${this.controlType}-${TransportTaskStateFormFieldComponent.nextId++}`;
    private _subscriptions: Subject<void> = new Subject();

    constructor(
        private roleService: IcsRolesService,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        super(new FormControl(null));
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    private _orderManagementAllowed = false;

    set orderManagementAllowed(value: boolean) {
        this._orderManagementAllowed = this.disableRoleManagement ?? value;
        this.checkDisabledState();
    }

    private _disabled = false;

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set disabled(value: any) {
        this._disabled = coerceBooleanProperty(value);
        this.checkDisabledState();
    }

    get value(): TransportTaskState {
        return this.control.value;
    }

    @Input()
    set value(value: TransportTaskState | number) {
        this.control.patchValue(value);
        this.stateChanges.next();
    }

    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    get empty(): boolean {
        return this.control.value === '';
    }

    get errorState(): boolean {
        return this.control.invalid && this.touched || !isEmpty(this.control.errors);
    }

    get selectedEntity(): TransportTaskState {
        return this.control.value;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (_: any) => {
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {
    };

    ngOnInit(): void {
        this.userAccessLoaded = false;
        this.roleService.hasPrivilege(PrivilegeLevels.OrderManagement)
            .pipe(takeUntil(this._subscriptions))
            .subscribe((orderManagementAllowed: boolean) => {
                this.userAccessLoaded = true;
                this.orderManagementAllowed = orderManagementAllowed;
            });
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const controlElement = this._elementRef.nativeElement.querySelector(
            '.transport-position-state-select-container',
        )!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent) {
        // fill
    }

    writeValue(value: TransportTaskState | number): void {
        this.value = value;
        this._handleInput();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    _handleInput(): void {
        this.onChange(this.value);
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
        this._subscriptions.next();
        this._subscriptions.complete();
    }

    private checkDisabledState() {
        if (!this._orderManagementAllowed || this.disabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }
}

