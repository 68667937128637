import {Directive, EventEmitter, Output} from "@angular/core";
import {LegacyPageEvent as PageEvent} from "@angular/material/legacy-paginator";
import {
    TableAddEvent,
    TableButtonEvent,
    TableDeleteEvent,
    TableDetailsEvent,
    TableEditEvent
} from "./button-events.model";
import {IndexedSelection, SelectionChangeEvent} from "./indexed-selection-model";

@Directive()
export class EventTable<T> {
    // protected static OUTPUTS = ['selectionChange', 'delete', 'edit', 'details', 'refresh', 'pageChange'];

    @Output() selectionChange: EventEmitter<SelectionChangeEvent> = new EventEmitter();
    @Output() delete: EventEmitter<TableButtonEvent<T>> = new EventEmitter();
    @Output() edit: EventEmitter<TableButtonEvent<T>> = new EventEmitter();
    @Output() details: EventEmitter<TableButtonEvent<T>> = new EventEmitter();
    @Output() add: EventEmitter<TableButtonEvent<T>> = new EventEmitter();
    @Output() refresh: EventEmitter<void> = new EventEmitter<void>();
    @Output() pageChange: EventEmitter<PageEvent> = new EventEmitter();

    /**
     * initiates a refresh event for the component user
     */
    onRefresh(): void {
        this.refresh.emit();
    }

    /**
     * Initiates a delete event for the parent component
     * @param value
     * @param index
     */
    onDelete(value: any, index: number): void {
        this.delete.emit(new TableDeleteEvent(value, index));
    }

    /**
     * Initiates a edit event for the parent component
     * @param value
     * @param index
     */
    onEdit(value: any, index: number): void {
        this.edit.emit(new TableEditEvent(value, index));
    }

    /**
     * Initiates a details event for the parent component
     * @param value
     * @param index
     */
    onDetails(value: any, index: number): void {
        this.details.emit(new TableDetailsEvent(value, index));
    }

    /**
     *
     * @param selectedValues
     */
    onSelectionChange(selectedValues: IndexedSelection<any>[]): void {
        this.selectionChange.emit({selected: selectedValues});
    }

    onPageChange(event: PageEvent): void {
        this.pageChange.emit(event);
    }

    onAdd(value: T, index: number) {
        this.add.emit(new TableAddEvent(value, index));
    }
}
