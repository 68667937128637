import packageJson from '../../package.json';
import {AppEnvironment, BaseEnvironment, EnvironmentType} from './environment.interface';
import {LogLevel} from '@microsoft/signalr';

export const environment: AppEnvironment = {
    production: true,
    environmentType: EnvironmentType.DEVELOPMENT,
    version: `${packageJson.version} | DEV`,
    lvsUrl: 'https://pck-dev.ics-group.cloud/lvs-api',
    tmsUrl: 'https://pck-dev.ics-group.cloud/tms-api',
    coreUrl: 'https://pck-dev.ics-group.cloud',
    tlHubUrl: 'https://pck-dev.ics-group.cloud/transportListsHub',
    userManagerSettings: {
        authority: BaseEnvironment.IdentityServerInstance,
        client_id: 'pck.web.client',
        redirect_uri: 'https://pck-dev.ics-group.cloud/#/auth-callback',
        post_logout_redirect_uri: 'https://pck-dev.ics-group.cloud/#/',
        response_type: 'id_token token',
        scope: 'openid email roles profile pck.api.all',
        filterProtocolClaims: true,
        loadUserInfo: true,
        automaticSilentRenew: true,
        silent_redirect_uri: 'https://pck-dev.ics-group.cloud/#/silent-refresh.html'
    },
    messenger: {
        hubUrl: 'https://pck-dev.ics-group.cloud/chatHub',
        logLevel: LogLevel.Error,
        allowLongPolling: false
    },
    appId: 'c2c89404-4547-cc77-ba63-714765c42aaf',
    tmsEnabled: true,
    lvsEnabled: true,
    hubAPIEnabled: false,
    flattenedExport: false
};

