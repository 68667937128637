import {Component, Inject} from '@angular/core';
import {IConfirmDialogData} from '../../../domain/interfaces';
import {Color, DialogMessage, DialogPurpose, DialogTitle} from '../../../domain/enums';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  title: string = DialogTitle.DELETE
  message: string = '';
  color?: Color;
  action?: DialogPurpose;
  submitButton: string = this.title === DialogTitle.WARN ? "Weiter" : "Bestätigen";
  abortButton: string = 'Abbrechen';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData
  ) {
    this.readInitialData();
    this.dialogRef.disableClose = this.data.enforceDecision;
  }

  get messageLines(): string[] | null {
    if (this.message.includes('~')) {
      return this.message.split('~');
    }
    return null;
  }

  onConfirm(): void {
    this.dialogRef.close(DialogMessage.CONFIRM);
  }

  closeDialog() {
    this.dialogRef.close(DialogMessage.ABORT);
  }

  private readInitialData(): void {
    this.title = this.data.title === DialogTitle.WARN ? DialogTitle.WARN : `${this.data.title} bestätigen`;
    if (this.data.message) {
      this.message = this.data.message;
    }
    if (this.data.color) {
      this.color = this.data.color;
    }
    if (this.data.action) {
      this.action = this.data.action;
    }
    if (this.data.confirmButton) {
      this.submitButton = this.data.confirmButton;
    }
    if (this.data.abortButton) {
      this.abortButton = this.data.abortButton;
    }
  }
}
