/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
export {AppInfo, DistributionGroup, Owner};

class AppInfo {
  constructor(
    public app_name: string,
    public app_display_name: string,
    public app_os: string,
    public app_icon_url: string,
    public release_notes_url: string,
    public owner: Owner,
    public is_external_build: boolean,
    public origin: string,
    public id: number,
    public version: string,
    public short_version: string,
    public size: number,
    public min_os: string,
    public android_min_api_level: string,
    public device_family: null,
    public bundle_identifier: string,
    public fingerprint: string,
    public uploaded_at: string,
    public download_url: string,
    public install_url: string,
    public mandatory_update: boolean,
    public enabled: boolean,
    public fileExtension: string,
    public is_latest: boolean,
    public release_notes: string,
    public package_hashes: string[],
    public destination_type: string,
    public status: string,
    public distribution_group_id: string,
    public distribution_groups: DistributionGroup[],
  ) {
  }
}

interface DistributionGroup {
  id: string;
  name: string;
  origin: string;
  display_name: string;
  is_public: boolean;
}

interface Owner {
  name: string;
  display_name: string;
}
