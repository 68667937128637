import {Component, Input} from '@angular/core';
import {ThemePalette} from '@angular/material/core';

@Component({
    selector: 'app-centered-loading-shade',
    templateUrl: './centered-loading-shade.component.html',
    styleUrls: ['./centered-loading-shade.component.scss']
})
export class CenteredLoadingShadeComponent {

    @Input() color?: ThemePalette;


}
