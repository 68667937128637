import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats} from '@angular/material/core';
import {FactoryProvider} from '@angular/core';

export {addDateFormats};

function addDateFormats(): FactoryProvider {
    return {
        provide: MAT_DATE_FORMATS,
        useFactory: dateFormatFactory,
        deps: [MAT_DATE_LOCALE]
    };
}

function dateFormatFactory(locale: string): MatDateFormats {
    const match = DATE_MAP[locale.replace('-', '')];

    return match ?? deDE;
}

const deDE: MatDateFormats = {
    parse: {
        dateInput: ['DD.MM.YYYY', 'DD.MM.YY', 'D.MM.YYYY', 'DD.M.YYYY', 'D.M.YY']
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

const enUS: MatDateFormats = {
    parse: {
        dateInput: ['YYYY-MM-DD', 'YY-MM-DD', 'YYYY-MM-D', 'YYY-M-DD', 'YY-M-D']
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

const DATE_MAP: { [key: string]: MatDateFormats } = {
    deDE,
    enUS
};
