import {InjectionToken, Injector, ModuleWithProviders, NgModule, Provider, Type} from '@angular/core';
import {
    WebsocketConnectionIndicatorComponent
} from './websocket-connection-indicator/websocket-connection-indicator.component';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SignalRMaterialRequestService} from './signalr-material-request/signal-r-material-request.service';
import {SignalRSettingService} from './signalr-settings/signal-r-setting.service';
import {WebsocketRootConfiguration} from './model/websocket-config.model';
import {SignalRService} from './base-service/signal-r.service';
import {IcsIdentityAuthenticationService} from 'ics-identity-authentication';
import {LockService} from '../lock/lock-service/lock.service';
import {environment} from '../../environments/environment';


export const WEBSOCKET_MARKER = new InjectionToken('WebsocketMarker');

@NgModule({
    declarations: [
        WebsocketConnectionIndicatorComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule
    ],
    providers: [],
    exports: [
        WebsocketConnectionIndicatorComponent
    ]
})
export class WebsocketModule {

    static PROVIDED_WEBSOCKETS = new Array<string>();
    private static STATIC_PROVIDERS = [
        SignalRMaterialRequestService,
        SignalRSettingService
    ];

    constructor(
        private _injector: Injector,
    ) {
        this.loadWebsockets([...WebsocketModule.PROVIDED_WEBSOCKETS, ...WebsocketModule.STATIC_PROVIDERS]);
    }

    static forRoot(socketConfigurations: WebsocketRootConfiguration): ModuleWithProviders<WebsocketModule> {
        const socketProviders = this.buildDynamicProviders(socketConfigurations);

        WebsocketModule.PROVIDED_WEBSOCKETS = socketProviders.map(p => 'provide' in p ? p.provide : null);

        return {
            ngModule: WebsocketModule,
            providers: [
                ...this.STATIC_PROVIDERS,
                ...socketProviders,
            ]
        };
    }

    static forHealthCheck(websocketName: string | Type<SignalRService<any>>): ModuleWithProviders<WebsocketModule> {
        return {
            ngModule: WebsocketModule,
            providers: [
                {
                    provide: WEBSOCKET_MARKER,
                    useValue: websocketName
                }
            ]
        };
    }

    private static buildDynamicProviders(socketConfigurations: WebsocketRootConfiguration): Provider[] {
        return socketConfigurations.reduce((websockets, moduleConf) => {
            return [...websockets, ...(moduleConf.websockets.reduce((moduleSockets, curr) => {
                return [...moduleSockets, this.buildProviderFromName(curr.name, curr.type, moduleConf.module)];
            }, new Array<Provider>()))]
        }, new Array<Provider>());
    }

    private static buildProviderFromName(name: string, type: Type<any>, module: 'tms' | 'core' | 'lvs'): Provider {
        return {
            provide: `${name}SignalRService`,
            useFactory: (auth: IcsIdentityAuthenticationService, l: LockService) => new SignalRService(auth, this.getHubUrl(name, module), type, l),
            deps: [IcsIdentityAuthenticationService, LockService],
            multi: false
        }
    }

    private static getHubUrl(name: string, module: 'tms' | 'core' | 'lvs'): string {
        switch (module) {
            case 'lvs':
                return `${environment.lvsUrl}/${name}Hub`;
            case 'core':
                return `${environment.coreUrl}/${name}Hub`;
            case 'tms':
                return `${environment.tmsUrl}/${name}Hub`;
        }
    }

    private loadWebsockets(providers: (string | Function)[]) {
        for (const p of providers) {
            if (p) {
                this._injector.get<SignalRService<any>>(p as any);
            }
        }
    }

}
