<div class="row center-alignment small-spacing" *ngIf="featureIsEnabled">
  <ng-container *ngIf="webSocket.isConnected; else noConnection">
    <mat-icon matTooltip="Live-Update aktiv">cloud</mat-icon>
  </ng-container>

  <button (click)="endWebsocketConnection()" mat-icon-button *ngIf="isAllowedToStop"
          matTooltip="Live-Update unterbrechen">
    <mat-icon>stop</mat-icon>
  </button>
</div>


<ng-template #noConnection>
  <button (click)="reconnectWebsocket()" mat-icon-button matTooltip="Live-Update inaktiv. Wiederherstellen?">
    <mat-icon color="warn">cloud_off</mat-icon>
  </button>
</ng-template>


