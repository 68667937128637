import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, switchMap} from 'rxjs';
import {MaterialRequest} from 'src/app/domain/tms-model/material-request.model';
import {BaseApiService} from '../base-api-service/base-api.service';
import {catchError, map} from 'rxjs/operators';
import {IHttpResult} from '../interfaces/http-result.interface';
import {IcsUserFeedbackService} from 'ics-core';

@Injectable({
    providedIn: 'root'
})
export class MaterialRequestApiService extends BaseApiService<MaterialRequest> {


    constructor(http: HttpClient, userFeedback: IcsUserFeedbackService) {
        super('MaterialRequest', 'tms', http, userFeedback);
    }

    filter(query: Partial<MaterialRequest | any>): Observable<MaterialRequest[]> {
        return this._http.post<MaterialRequest[]>(this.buildEndpointUrl('filter'), query)
            .pipe(
                catchError(err => this.handleHttpGetError(err) as Observable<MaterialRequest[]>)
            );
    }

    sendMaterialRequestMailNotification(mrIdDto: Partial<MaterialRequest>, sendInitiatorMail: boolean): Observable<IHttpResult<any>> {
        return this._http.post<MaterialRequest>(this.buildEndpointUrl('SendEMailNotification'), mrIdDto,
            {observe: 'response', params: this.createIdParams({sendInitiatorMail})})
            .pipe(
                catchError(err => this.handleHttpErrorResult(err) as Observable<any>),
                map((res: HttpResponse<MaterialRequest>) => this.buildHttpResult<MaterialRequest>(res)),
                switchMap((res) => {
                    if (res.isError) {
                        return this._userFeedback.show({
                            type: 'error',
                            message: `ui.user.feedback.error.materialrequest.could.not.notify`
                        }).pipe(
                            map(() => res)
                        );
                    }

                    return of(res);
                })
            );
    }
}
