import {FormControl, Validators} from '@angular/forms';
import {Warehouse} from '../core-model';
import {StorageArea} from '../lvs-model';
import {CustomValidators} from '../../form-fields';

export {
  StorageBinFormGroup
}

class StorageBinFormGroup {
  storageBinId = new FormControl<string>('', {
    nonNullable: true,
    validators: Validators.required
  });
  warehouseId = new FormControl<Warehouse | number | null>(null, {
    nonNullable: true,
    validators: [Validators.required, CustomValidators.objectSelectedValidator]
  });
  storageAreaId = new FormControl<StorageArea | string>('', {
    nonNullable: true,
    validators: [Validators.required, CustomValidators.objectSelectedValidator]
  });
  isHighRack = new FormControl<boolean>(false, {nonNullable: true});
  level = new FormControl<number>(0, {nonNullable: true, validators: [Validators.required, Validators.min(0)]});
  row = new FormControl<number>(0, {nonNullable: true, validators: [Validators.required, Validators.min(1)]});
  shelf = new FormControl<number>(0, {nonNullable: true, validators: [Validators.required, Validators.min(1)]});
}
