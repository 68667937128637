<div (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)" [attr.aria-labelledby]="formField?.getLabelId()"
     class="date-time-input-container">
    <mat-form-field [appearance]="appearance" [color]="color">
        <mat-label>{{ label ?? "Datum" }}</mat-label>

        <ng-container *ngIf="!dateOnly; else dateOnlyPicker">
            <input (input)="_handleInput()" [formControl]="control" [placeholder]="startDate" [required]="required"
                   aria-label="Date form field" matInput type="datetime-local"/>
        </ng-container>

        <ng-template #dateOnlyPicker>
            <input (input)="_handleInput()" [formControl]="control"
                   [required]="required" aria-label="Date Input" matInput type="date"/>
        </ng-template>

        <mat-error>
            <app-form-errors [control]="control" [entityName]="label ?? 'Datum'"></app-form-errors>
        </mat-error>
    </mat-form-field>
</div>
