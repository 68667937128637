import {Component} from '@angular/core';
import {CanBeDisabled} from '../model/can-be-disabled';


@Component({
    selector: 'app-edit-button',
    templateUrl: './edit-button.component.html',
    styleUrls: ['./edit-button.component.scss'],
    inputs: ['disabled']
})
export class EditButtonComponent extends CanBeDisabled {

    constructor() {
        super();
    }
}
