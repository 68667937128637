import {InjectionToken} from "@angular/core";

export { IMPORT_CLASS };

// typing for better understanding that class is only represented by its constructor
// so this actually points to the constructor of a class
// eslint-disable-next-line @typescript-eslint/ban-types
type Class = Function;

// Provider token for the Class
const IMPORT_CLASS = new InjectionToken<Class>('IMPORT_CLASS');
