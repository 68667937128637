import {Injectable} from '@angular/core';
import bwipjs from 'bwip-js';
import {BarcodeType} from '../model/barcode-types.model';

type BarcodeOptions = Omit<bwipjs.ToBufferOptions, 'bcid' | 'text'>;

const DefaultBwipOptions: BarcodeOptions = {
  scale: 5
};

@Injectable({
  providedIn: 'root'
})
/**
 * Can be used for generation of base64 representations of bar-codes.
 */
export class BarcodeService {

  constructor() {
  }

  /**
   *  Generates a barcode in the given format i.e. datamatrix
   *  or implementation {@link https://github.com/metafloor/bwip-js} is used.
   *  Supports all formats of bwipsjs 1-dimensional and 2-dimensional
   *  Throws an error if the given string does not match the regulations of
   *  the barcode format (i.e. max 13 digits for ean13)
   * @param encoding Controls which kind of code will be rendered for information about supported code check {@link https://github.com/metafloor/bwip-js/wiki/BWIPP-Barcode-Types}
   * @param value the data to be encoded
   * @param scale size of the code
   */
  generateCode(encoding: BarcodeType, value: string, options: BarcodeOptions = DefaultBwipOptions): string {
    // create a canvas element not visible to user to get the base64 representation
    // never added to DOM important!
    const canvasElement = document.createElement('canvas');

    // creating barcode in canvas element
    bwipjs.toCanvas(canvasElement, {
      bcid: String(encoding),
      text: String(value),
      ...options
    });

    // reading base64 from imaginary canvas
    return canvasElement.toDataURL();
  }
}
