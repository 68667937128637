export {
    BaseEntity
}

class BaseEntity {
    isComplete?: boolean = true;
    createdAt?: string | null;
    createdBy?: string | null;
    lastModified?: string | null;
    lastModifiedBy?: string | null;

    constructor(value?: BaseEntity) {
        if (value?.isComplete) {
            this.isComplete = value.isComplete;
        }

        if (value?.createdBy) {
            this.createdBy = value.createdBy;
        }

        if (value?.createdAt) {
            this.createdAt = value.createdAt;
        }

        if (value?.lastModified) {
            this.lastModified = value.lastModified;
        }

        if (value?.lastModifiedBy) {
            this.lastModifiedBy = value.lastModifiedBy;
        }
    }

    static clearMeta<T extends BaseEntity>(value: Readonly<T>): T {
        const result: T = {...value};

        delete result.createdBy;
        delete result.createdAt;
        delete result.lastModified;
        delete result.lastModifiedBy;

        return result;
    }
}
