import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UnsavedImports} from '../model/unsaved-import.interface';
import {UnsavedImportsDialogComponent} from '../unsaved-imports-dialog/unsaved-imports-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class ImportFinishedGuard {

    constructor(private dialog: MatDialog) {
    }

    /**
     * Asks the component about to leave if all imports are done. If not asks the user if he really wants to leave.
     * After users answer it allows to leave the route
     * @param component
     * @param currentRoute
     * @param currentState
     * @param nextState
     * @returns
     */
    canDeactivate(
        component: UnsavedImports,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (component.hasUnsavedImports()) {
            return this.dialog.open(UnsavedImportsDialogComponent).afterClosed().pipe(map((result) => result === 'confirm'));
        }
        return true;

    }

}
