import {Injectable} from '@angular/core';
import {BaseApiService} from '../base-api-service/base-api.service';
import {HandlingUnit, HandlingUnitArticle} from '../../domain/core-model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {IcsUserFeedbackService} from 'ics-core';

@Injectable({
    providedIn: 'root'
})
export class HandlingUnitApiService extends BaseApiService<HandlingUnit> {

    constructor(http: HttpClient, userFeedback: IcsUserFeedbackService) {
        super('HandlingUnit', 'core', http, userFeedback);
    }

    // TODO: Type this to IHttpResult
    getHandlingUnitIdPattern(): Observable<string> {
        return this._http.get(this.buildEndpointUrl('GetHuIdPattern'), {responseType: 'text'});
    }

    getMobileInventory(): Observable<HandlingUnitArticle[]> {
        return this._http.get<HandlingUnitArticle[]>(this.buildEndpointUrl('GetMobileInventory'))
            .pipe(
                catchError(err => this.handleHttpGetError<HandlingUnitArticle>(err) as Observable<HandlingUnitArticle[]>),
                map((handlingUnitArticles: HandlingUnitArticle[]) => handlingUnitArticles.map(HandlingUnitArticle.buildFromEagerLoad)),
            );
    }
}
