import {map, switchMap} from 'rxjs/operators';
import {Observable, of, zip} from 'rxjs';
import {SearchUtil} from '../util';
import {DeepPartial, PropertySearcher, SearchOperatorOptions, SearchType} from '../model';

export {
    searchAnd,
    searchOr,
    search
}

/**
 * Searches all properties of the objects in the list. If any property matches the passed string query this
 * object will remain in the list.
 *
 * IMPORTANT: To remove duplicates from the array the objects reference is checked and not the object itself
 * @param {string} query
 * @param {PropertySearcher<T>} searcher
 */
function searchOr<X extends object>(query: string, searcher?: PropertySearcher<X>): (source$: Observable<X[]>) => Observable<X[]>;

/**
 * Matches all properties (in any depth) against the objects in the list. If any property of the query matches to the same
 * property in an object this object will remain in the array.
 *
 * IMPORTANT: To remove duplicates from the array the objects reference is checked and not the object itself
 * @param {DeepPartial<T>} query
 * @param {PropertySearcher<T>} searcher
 */
function searchOr<X extends object, Y extends object = X>(query: DeepPartial<Y>, searcher?: PropertySearcher<X, Y>): (source$: Observable<X[]>) => Observable<X[]>;
function searchOr<X extends object, Y extends object = X>(query: string | DeepPartial<Y>, searcher?: PropertySearcher<X, Y>): (source$: Observable<X[]>) => Observable<X[]> {
    return (source$) => {
        return source$.pipe(
            search(query, {searchType: 'or', matcher: searcher})
        );
    }
}

/**
 * Searches all properties of the objects in the list. If a property is found that matches the passed string the object will
 * remain in the list.
 *
 * @param {string} query
 * @param {PropertySearcher<T>} searcher
 */
function searchAnd<X extends object>(query: string, searcher?: PropertySearcher<X>): (source$: Observable<X[]>) => Observable<X[]>;

/**
 * Matches all properties (in any depth) against the objects in the list. Only if all properties of the query matches to the same
 * properties in an object this object will remain in the array.
 *
 * @param {DeepPartial<T>} query
 * @param {PropertySearcher<T>} searcher
 */
function searchAnd<X extends object, Y extends object = X>(query: DeepPartial<Y>, searcher?: PropertySearcher<X, Y>): (source$: Observable<X[]>) => Observable<X[]>;
function searchAnd<X extends object, Y extends object = X>(query: string | DeepPartial<Y>, searcher?: PropertySearcher<X, Y>): (source$: Observable<X[]>) => Observable<X[]> {
    return (source$) => {
        return source$.pipe(
            search(query, {searchType: 'and', matcher: searcher})
        );
    }
}


function search<X extends object, Y = X>(query: string | DeepPartial<Y>, options?: PropertySearcher<X, Y>): (source$: Observable<X[]>) => Observable<X[]>;
function search<X extends object, Y = X>(query: string | DeepPartial<Y>, options?: SearchType): (source$: Observable<X[]>) => Observable<X[]>;
function search<X extends object, Y = X>(query: string | DeepPartial<Y>, options?: Partial<SearchOperatorOptions<X, Y>>): (source$: Observable<X[]>) => Observable<X[]>;
/**
 * Searches all objects in the list for matches against the passed query. Returns the remaining objects as array to the pipe stream.
 *
 * If a {@link PropertySearcher} is passed it gets called for each property and the result of that will build the result for the next property
 *
 * For the specific behavior with the passed {@link SearchType} checkout {@link searchAnd} and {@link searchOr}.
 * @param query
 * @param options
 *
 * @see searchAnd
 * @see searchOr
 */
function search<X extends object, Y = X>(query: string | DeepPartial<Y>, options?: Partial<SearchOperatorOptions<X, Y>> | SearchType | PropertySearcher<X, Y>): (source$: Observable<X[]>) => Observable<X[]> {
    return (source$) => {
        const searchType = readSearchType(options);

        const matcher = readPropertyMatcher(options);

        return source$.pipe(
            switchMap((searchList) => {
                if (typeof query === 'string') {
                    return of(SearchUtil.search(searchList, query, searchType));
                }

                if (Object.keys(query).length === 0) {
                    return of(searchList);
                }

                if (matcher) {
                    return searchType === 'and' ? useSearcherForAnd(searchList, {...query}, matcher) : useSearcherForOr(searchList, {...query}, matcher);
                }

                return of(SearchUtil.search(searchList, query, searchType));
            })
        );
    }
}

/**
 *
 * @param searchList
 * @param query
 * @param matcher
 */
function useSearcherForOr<X extends object, Y = X>(searchList: X[], query: DeepPartial<Y>, matcher: PropertySearcher<X, Y>): Observable<X[]> {
    const allSearches = Object.entries(query).map(([key, value]) => {
        return of(searchList).pipe(searchAnd({[key]: value} as any, matcher));
    });

    return zip(allSearches)
        .pipe(
            map((partialSearches) => {
                const allMerged = partialSearches.reduce((acc, curr) => [...acc, ...curr], new Array<X>());

                const set = new Set(allMerged);

                // due to recursive resolution result is in wrong order
                return Array.from(set).reverse();
            })
        );
}

/**
 *
 * @param searchList
 * @param query
 * @param matcher
 */
function useSearcherForAnd<X extends Object, Y = X>(searchList: X[], query: DeepPartial<Y>, matcher: PropertySearcher<X, Y>): Observable<X[]> {
    let start = of(searchList);

    const searches = Object.entries(query).map(([key, value]) => {
        return switchMap((list: X[]) => {

            // trying to use alternative search logic for this property
            let result = matcher(list, key as keyof Y, value);

            if (!result) {
                const partialQuery: any = {[key as keyof Y]: value as any};

                // default search logic for current property
                result = of(SearchUtil.search(list, partialQuery, 'and'));
            }

            return result instanceof Observable ? result : of(result);
        });
    });

    // Don't know exactly why this needs ts-ignore pipe is defined with a rest param
    // @ts-ignore
    return start.pipe(...searches);
}

function readSearchType(options: Partial<SearchOperatorOptions<any>> | SearchType | PropertySearcher<any> | undefined): SearchType {
    if (typeof options === 'string') {
        return options;
    }

    if (typeof options === 'object') {
        return options.searchType ?? 'and';
    }

    return 'and';
}


function readPropertyMatcher<X extends object, Y = X>(options: Partial<SearchOperatorOptions<X, Y>> | SearchType | PropertySearcher<X, Y> | undefined): PropertySearcher<X, Y> | undefined {
    if (typeof options === 'function') {
        return options;
    }

    if (typeof options === 'object') {
        return options?.matcher;
    }

    return undefined;
}
