import {FactoryProvider, ProviderToken} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export function currentLanguageFactoryFor(
    provider: ProviderToken<any>
): FactoryProvider {
    return {
        provide: provider,
        useFactory: currentLanguageFactory,
        deps: [TranslateService]
    };
}

function currentLanguageFactory(translate: TranslateService): string {
    return toDayJsLocale(
        translate.currentLang ?? translate.defaultLang ?? translate.getBrowserLang()
    );
}

function toDayJsLocale(locale: string) {
    return locale === 'en-US' ? 'en' : 'de';
}
