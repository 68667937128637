import {Component, Input} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'app-floating-button-bar',
  templateUrl: './floating-button-bar.component.html',
  styleUrls: ['./floating-button-bar.component.scss']
})
export class FloatingButtonBarComponent {

  @Input()
  set vertical(value: any) {
    this._verticalAlignment = coerceBooleanProperty(value);
  }
  get vertical(): boolean {
    return this._verticalAlignment;
  }


  private _verticalAlignment = false;
}
