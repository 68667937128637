import {NgModule, Optional, SkipSelf} from '@angular/core';
import {ArticleService} from './article-service/article.service';
import {ArticleGroupService} from './article-group-service/article-group.service';
import {ArticleRequisitionService} from './requisition/article-requisition-service/article-requisition.service';
import {HandlingUnitService} from './handling-unit/handling-unit.service';
import {InitiatorService} from './initiator-service/initiator.service';
import {MaterialRequestService} from './material-request-service/material-request.service';
import {StorageAreaService} from './storage-area-service/storage-area.service';
import {StorageBinService} from './storage-bin/storage-bin.service';
import {TransportPositionService} from './transport-position/transport-position.service';
import {UserService} from './user-service/user.service';
import {VehicleService} from './vehicle-service/vehicle.service';
import {VehicletypeService} from './vehicletype-service/vehicletype.service';
import {WarehouseService} from './warehouse/warehouse-service/warehouse.service';
import {SuperTaskService} from './super-task-service/super-task.service';

const entityServices = [
    ArticleService,
    ArticleGroupService,
    ArticleRequisitionService,
    HandlingUnitService,
    InitiatorService,
    MaterialRequestService,
    StorageAreaService,
    StorageBinService,
    UserService,
    VehicleService,
    VehicletypeService,
    WarehouseService,
    SuperTaskService,
    TransportPositionService,
];

@NgModule({
    providers: [
        ...entityServices.map(s => {
            return {
                provide: s,
                multi: false
            }
        })
    ]
})
export class EntityStateModule {
    constructor(@Optional() @SkipSelf() parentModule: EntityStateModule) {
        if (parentModule) {
            throw new Error(
                'EntityStateModule is already loaded. Import it only once in AppModule'
            );
        }
    }
}
