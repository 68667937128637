import {HandlingUnit, Warehouse} from "../core-model";
import {StorageArea} from './storage-area.model';
import {Merged} from '../utility-types';
import {BaseEntity} from '../base-entity.model';
import {StorageBinArticle} from './storage-bin-article.model';

export {StorageBin, ExtendedStorageBinQuery};


class StorageBin extends BaseEntity {
    public storageBinId: string;
    public storageAreaId: string;
    public warehouseId?: number | null = null;
    public isHighRack: boolean = false;
    public level: number = 0;
    public row: number = 1;
    public shelf: number = 1;
    public fullLocationLabelData?: string;
    public binOnlyLocationData?: string;
    public warehouseObj: Warehouse | null = null;
    public storageAreaObj: StorageArea | null = null;
    public handlingUnitObj?: HandlingUnit[] | null = null;
    public storageBinArticles?: StorageBinArticle[] | null = null;


    constructor(value: Merged<Omit<StorageBin, 'handlingUnitId' |'storageAreaObj' | 'warehouseObj'>, {
        warehouseId?: Warehouse,
        storageAreaId: StorageArea
    }>) {
        super();
        this.storageBinId = value.storageBinId;

        if (value.warehouseId) {
            if (typeof value.warehouseId === 'object') {
                this.warehouseId = value.warehouseId.warehouseId ?? null;
            } else if (typeof value.warehouseId === 'number') {
                this.warehouseId = value.warehouseId;
            }
        }
        

        this.storageAreaId = typeof value.storageAreaId === 'object' ? value.storageAreaId.storageAreaId : value.storageAreaId;

        this.level = value.level;
        this.shelf = value.shelf;
        this.row = value.row;
        this.isHighRack = value.isHighRack;

    }
}

type ExtendedStorageBinQuery =
    Partial<StorageBin>
    & Partial<{
    articleId: string,
    articleGroupId: number,
    articleCount: string,
    warehouseName: string,
    articleGroupName: string
}>;



