<button (click)="navigate()" [disabled]="disabled" class="button" color="warn" mat-raised-button>

  <span [class.justify-center]="!hovered" [class.small-spacing]="hovered" class="row center-alignment">

    <span [@horizontalExpand]="animationState" [ngClass]="{'hidden': !hovered, 'visible': hovered}">
      <span>{{'ui.external.api.labels.warnings.imports.to.check' | translate}}</span>
    </span>

    <mat-icon *ngIf="!hovered">warning</mat-icon>

  </span>
</button>
