import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {MatPaginator, MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {IcsThemeModeService} from "ics-theme";
import {MatToolbarModule} from '@angular/material/toolbar';
import {AsyncPipe, NgIf, NgStyle} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {PaginatorMenuComponent} from '../paginator-menu/paginator-menu.component';

@Component({
    selector: 'app-themed-paginator',
    templateUrl: './themed-paginator.component.html',
    styleUrls: ['./themed-paginator.component.scss'],
    imports: [
        MatToolbarModule,
        NgIf,
        NgStyle,
        AsyncPipe,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule,
        MatPaginatorModule,
        PaginatorMenuComponent,
    ],
    standalone: true
})
export class ThemedPaginatorComponent implements OnInit {

    @ViewChild(MatPaginator) materialPaginator!: MatPaginator;
    @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
    @Output() pageChange: EventEmitter<PageEvent> = new EventEmitter();
    darkPaginator$!: Observable<boolean>;

    constructor(
        private _theme: IcsThemeModeService
    ) {
    }

    private _noRefresh!: boolean;

    get noRefresh(): boolean {
        return this._noRefresh;
    }

    @Input()
    set noRefresh(value: any) {
        this._noRefresh = coerceBooleanProperty(value);
    }

    ngOnInit(): void {
        this.darkPaginator$ = this._theme.isDarkMode;
    }

    onRefresh(): void {
        this.refresh.emit();
    }

    onPageChange(event: PageEvent): void {
        this.pageChange.emit(event);
    }

}
