import {DeepPartial, SearchQuery, SearchType} from '../model';

export const SearchUtil = {
    search,
    searchAnd,
    searchOr,
};

function searchAnd<T extends object, X extends object = T>(searchList: T[], query: SearchQuery<X>) {
    return search(searchList, query, 'and');
}

function searchOr<T extends object, X extends object = T>(searchList: T[], query: SearchQuery<X>) {
    return search(searchList, query, 'or');
}

function search<T extends object, X extends object = T>(searchList: T[], query: SearchQuery<X>, searchType: SearchType = 'and'): T[] {

    // primitive types
    if (typeof query !== 'object') {
        return searchByString(query, searchList);
    }

    if (Array.isArray(query)) {
        return searchByList(searchList, query, searchType);
    }

    return searchByObject(query, searchList, searchType);
}

function searchByList<T extends object>(searchList: T[], query: (DeepPartial<T> | string)[], searchType: SearchType = 'and') {
    return searchList.filter((value) => {

        const target = Array.isArray(value) ? value : [value];
        const searchFn = (query: string | DeepPartial<T>) => {
            return searchAnd(target, query).length > 0;
        }

        if (searchType === 'and') {
            return query.every(searchFn);
        }

        return query.some(searchFn);
    });
}


/**
 * Checks for each object in the array if at least one property value matches the given query
 * @param query
 * @param values
 */
function searchByString<T extends object | string>(query: string, values: T[]): T[] {
    return values.filter(v => {

        if (isInvalidValue(v)) {
            return false;
        }

        if (typeof v === 'string') {
            return compareData(v, query);
        }

        return Object.entries(v)
            .filter(([k]) => propertyCanBeSearched(k))
            .some(([_, propertyValue]) => {

                if (isInvalidValue(propertyValue)) {
                    return false;
                }

                if (typeof propertyValue !== 'object') {
                    return compareData(propertyValue, query);
                }

                const searchProperties = Object.entries(propertyValue)
                    .filter(([k]) => propertyCanBeSearched(k))
                    .map(([_, v]) => v);

                return compareData(searchProperties, query);
            })
    });
}

/**
 * Checks for each object in the array if at least one property defined in {@link query} matches the property
 * with the same name in the object.
 *
 * @param query defines the properties that are searched
 * @param values the values to be searched
 * @param searchType
 */
function searchByObject<T extends object, X extends object = T>(query: DeepPartial<X>, values: T[], searchType: SearchType = 'and'): T[] {
    const searchPredicateList: [keyof T, any][] = Object.entries(query)
        .filter(([k, _]) => propertyCanBeSearched(k))
        .map(([key, querValue]) => [key as keyof T, querValue]);

    return values.filter((currentEntry, i) => {
        const searchFn = ([key, queryValue]: [keyof T, any]) => matchesProperty(currentEntry, key, queryValue, searchType)

        if (searchType === 'and') {
            return searchPredicateList.every(searchFn);
        }

        return searchPredicateList.some(searchFn);
    });
}

/**
 *
 * @param current
 * @param key
 * @param shouldMatch
 * @param searchType
 */
function matchesProperty<T>(current: T, key: keyof T, shouldMatch: any, searchType: SearchType): boolean {

    if (typeof current[key] !== 'object') {
        return compareData(current[key], shouldMatch);
    }

    const value = current[key];

    if (isInvalidValue(value)) {
        return false;
    }

    let searchList = Array.isArray(value) ? value : [value];

    if (typeof shouldMatch !== 'object') {
        return compareData(value, shouldMatch);
    }

    if (Array.isArray(shouldMatch)) {
        return search(searchList, shouldMatch, searchType).length > 0;
    }

    return search(Object.values(searchList), shouldMatch).length > 0;
}

function isInvalidValue(value: any) {
    return value === undefined || value === null || value === '';
}

function compareData(property: any, query: string | number | boolean) {

    if (typeof property === 'number') {
        return property === (typeof query === 'number' ? query : Number(query));
    }

    // only can match boolean to boolean otherwise there will be weird results like
    // "FCC" === true => true
    if (typeof property === 'boolean' && typeof query === 'boolean') {
        return property === query;
    }

    if (Array.isArray(property) && property.length > 0) {
        return search(property, String(query)).length > 0;
    }

    return String(property).trim().toLowerCase().includes(String(query).trim().toLowerCase());
}

function propertyCanBeSearched(property: string) {
    const lowerCaseProp = property.toLowerCase();
    return !(
        lowerCaseProp.includes('created') ||
        lowerCaseProp.includes('modified') ||
        lowerCaseProp.includes('deleted') ||
        lowerCaseProp === 'iscomplete'
    );
}

