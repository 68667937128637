import {Component, Input} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'app-form-row',
  templateUrl: './form-row.component.html',
  styleUrls: ['./form-row.component.scss']
})
export class FormRowComponent{

  private _wrap = false;

  @Input()
  get wrap(): boolean {
    return this._wrap;
  }

  set wrap(value: any) {
    this._wrap = coerceBooleanProperty(value);
  }
}
