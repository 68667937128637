import {Injectable} from '@angular/core';
import {IRouteNameResolver} from "ics-core";
import {Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ChildRoutes} from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class RouteNamesService implements IRouteNameResolver {

    constructor(
        private _translations: TranslateService,
        private _router: Router
    ) {
    }

    getRouteTitle(url: string | undefined): Observable<string | undefined> {
        if (!url || url === '/' || url.includes('auth') || url.includes('login') || url.includes('logout')) {
            return of('');
        }

        const fullUrl = this._router.url;
        const end = fullUrl.includes('?') ? fullUrl.indexOf('?') : fullUrl.length;

        const toTranslate = fullUrl.slice(0, end).split('/')
            .filter(p => !!p && p !== ChildRoutes.Survey) // only strings with content
            .slice(0, 3) // max depth 3
            .map((p) => `ui.routing.${p.replace(new RegExp('-', 'g'), '.')}`); // translate string

        return this._translations.get(toTranslate).pipe(
            map((translations) => Object.values(translations).join(' | '))
        );
    }
}
