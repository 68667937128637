import { Component, ElementRef, forwardRef, Inject, Input, OnDestroy, Optional, Self } from '@angular/core';
import { CustomFormFieldComponent } from '../custom-form-field.component';
import { ControlValueAccessor, NgControl, UntypedFormBuilder } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { DateInputComponent } from '../../generic-components';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DateTimeService } from '../../core';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';


@Component({
    selector: 'app-date-time-form-field',
    templateUrl: './date-time-form-field.component.html',
    styleUrls: ['./date-time-form-field.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: DateInputComponent },
        { provide: CustomFormFieldComponent, useExisting: forwardRef(() => DateTimeFormFieldComponent) }
    ],
})
export class DateTimeFormFieldComponent extends CustomFormFieldComponent<Date | string> implements ControlValueAccessor, OnDestroy {

    static nextId = 0;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('aria-describedby') userAriaDescribedBy = '';
    stateChanges = new Subject<void>();
    focused = false;
    touched = false;
    controlType = 'date-time-form-field';
    id = `${this.controlType}-${DateTimeFormFieldComponent.nextId++}`;
    startDate: string;

    @Input() inServerTime: boolean = true;

    constructor(
        private _fb: UntypedFormBuilder,
        private _dateTime: DateTimeService,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl
    ) {
        super(_fb.control(null));
        this.startDate = this._dateTime.getCurrentDate() as string;


        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    private _disabled = false

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: any) {
        this._disabled = coerceBooleanProperty(value);
        if (this._disabled) {
            this.control.disable();
        }
    }

    private _dateOnly = false;

    @Input()
    get dateOnly(): boolean {
        return this._dateOnly;
    }

    set dateOnly(value: any) {
        this._dateOnly = coerceBooleanProperty(value);
    }

    private _emitDateValue = false;

    @Input()
    get emitDateValue(): boolean {
        return this._emitDateValue;
    }

    set emitDateValue(value: any) {
        this._emitDateValue = coerceBooleanProperty(value);
    }

    @Input()
    get value(): Date | string | null {
        if (this.inServerTime) {
            return this._dateTime.toServerTimeUTC(this.control.value, this._emitDateValue);
        }
        return this._dateTime.fromServerTimeUTC(this.control.value, this._emitDateValue);
    }

    set value(value: Date | string | null) {
        if (value) {
            const dateString = (this._dateTime.fromServerTimeUTC(typeof value === 'string' ? value : value?.toISOString()) as string);
            this.control.patchValue(dateString);
            this.stateChanges.next();
        }
    }

    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    get empty(): boolean {
        return this.control.value === '';
    }

    get errorState(): boolean {
        return this.control.invalid && this.touched;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (_: any) => {
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {
    };

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const controlElement = this._elementRef.nativeElement.querySelector(
            '.date-time-input-container',
        )!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent) {
        // fill
    }

    writeValue(value: Date | string): void {
        this.value = value;
        this._handleInput();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    _handleInput(): void {
        this.onChange(this.value);
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

}
