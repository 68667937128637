import {Directive, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, switchMap, timer} from 'rxjs';
import {delay, map, takeWhile, timeout} from 'rxjs/operators';

type DirectionCommand = 'up' | 'down' | null;

@Directive({
    selector: '[appMarquee]',
    standalone: true
})
export class MarqueeDirective implements OnInit, OnDestroy {

    private _changeDirectionOrDestroy = new Subject<DirectionCommand>();
    private delayS: number = 2 * 1000;

    constructor(
        private _el: ElementRef
    ) {
    }

    private get _element(): HTMLElement {
        return this._el.nativeElement;
    }

    private get _isScrollable() {
        return this._element.scrollHeight > this._element.clientHeight;
    }


    ngOnInit() {
        this._start();
        this._changeDirectionOrDestroy.next('down');
    }

    ngOnDestroy() {
        this._changeDirectionOrDestroy.next(null);
        this._changeDirectionOrDestroy.complete();
    }

    private _start() {
        this._changeDirectionOrDestroy.pipe(
            switchMap((direction) => this._scrollFromInterval(direction as any)),
            takeWhile(([direction, _]) => direction !== null) // ends subscription on null command
        ).subscribe(([direction, scrollTop]) => {
            if ((this._element.scrollTop +5) >= (this._element.scrollHeight - this._element.offsetHeight) && direction==='down' ) {
                this._changeDirectionOrDestroy.next('up');
                return;
            }
            else if ((this._element.scrollTop === 0 ) && direction ==='up') {
                this._changeDirectionOrDestroy.next('down')
                return;
            }

            this._element.scrollTop = scrollTop
        });
    }

    private _scrollFromInterval(direction: DirectionCommand): Observable<[DirectionCommand, number]> {
        return timer(this.delayS, 10).pipe(
            map((intervalTime) => {
                if (direction === 'down') {
                    return [direction, intervalTime];
                }

                return [direction, this._element.scrollHeight - (intervalTime + 755)]
            })
        );
    }

}
