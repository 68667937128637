import {Component, ElementRef, forwardRef, Inject, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {CustomFormFieldComponent} from '../custom-form-field.component';
import {PriorityLevel, PriorityLevelDescriptions, PriorityLevelSelection} from '../../domain/tms-model';
import {ControlValueAccessor, FormControl, NgControl, UntypedFormBuilder} from '@angular/forms';
import {Subject} from 'rxjs';
import {RoleService} from '../../roles';
import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {isEmpty} from '../../domain/functions';
import {ISelectionProperty} from '../../domain/interfaces';
import {MAT_FORM_FIELD, MatFormField} from '@angular/material/form-field';

@Component({
    selector: 'app-priority-level-form-field',
    templateUrl: './priority-level-form-field.component.html',
    styleUrls: ['./priority-level-form-field.component.scss'],
    providers: [
        {provide: CustomFormFieldComponent, useExisting: forwardRef(() => PriorityLevelFormFieldComponent)}
    ]
})
export class PriorityLevelFormFieldComponent extends CustomFormFieldComponent<PriorityLevel> implements OnInit, OnDestroy, ControlValueAccessor {
    static nextId = 0;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('aria-describedby') userAriaDescribedBy = '';
    priorityOptions: ISelectionProperty[] = PriorityLevelSelection;
    priorityDescriptions = PriorityLevelDescriptions;
    stateChanges: Subject<void> = new Subject();
    touched = false;
    focused = false;
    userAccessLoaded = false;
    controlType = 'priority-level-selection';
    id = `${this.controlType}-${PriorityLevelFormFieldComponent.nextId++}`;
    private _subscriptions: Subject<void> = new Subject();

    constructor(
        private _roleService: RoleService,
        private _fb: UntypedFormBuilder,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        super(_fb.control(null));
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    private _orderManagementAllowed = false;

    set orderManagementAllowed(value: boolean) {
        this._orderManagementAllowed = this.disableRoleManagement ?? value;
        this.checkDisabledState();
    }

    private _disabled = false;

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set disabled(value: any) {
        this._disabled = coerceBooleanProperty(value);
        this.checkDisabledState();
    }

    get value(): PriorityLevel {
        return this.control.value;
    }

    @Input()
    set value(value: PriorityLevel | number) {
        this.control.patchValue(value);
        this.stateChanges.next();
    }

    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    get empty(): boolean {
        return this.control.value === '';
    }

    get errorState(): boolean {
        return this.control.invalid && this.touched || !isEmpty(this.control.errors);
    }

    get selectedEntity(): PriorityLevel {
        return this.control.value;
    }

    get currentPriorityDescription() {
        return this.priorityOptions?.find(o => o.value === this.control.value)?.view;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (_: any) => {
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {
    };

    ngOnInit() {
        if (this.ngControl?.control) {
            this.control = this.ngControl.control as FormControl;
        }
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const controlElement = this._elementRef.nativeElement.querySelector(
            '.priority-level-selection-container',
        )!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent) {
        // filler
    }

    writeValue(value: PriorityLevel | number): void {
        if (value && value !== this.control.value) {
            this.value = value;
            this._handleInput();
        }

    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    _handleInput(): void {
        this.onChange(this.value);
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
        this._subscriptions.next();
        this._subscriptions.complete();
    }

    private checkDisabledState(): void {
        if (this._disabled && this.control.enabled) {
            this.control.disable({emitEvent: false});
        } else {
            this.control.enable({emitEvent: false});
        }
    }
}
