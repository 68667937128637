import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-submit-row',
  templateUrl: './submit-row.component.html',
  styleUrls: ['./submit-row.component.scss']
})
export class SubmitRowComponent implements OnInit {

  @Input() alignButtons: 'center' | 'left' | 'right' = 'right';

  ngOnInit(): void {
    if (!this.alignButtons) {
      this.alignButtons = 'center';
    }
  }
}
