import {Setting} from "../../domain/core-model";

export {
  SettingBatch
}

class SettingBatch {
  constructor(
    public update = new Array<Setting>(),
    public create = new Array<Setting>()
  ) {
  }
}
