<div [class.col]="layout === 'col'" [class.row]="layout !== 'col'" class="array-form-field">
  <div *ngFor="let ctr of control.controls; let i = index; last as isLast" style="display: flex; flex-flow: row">

    <mat-form-field *ngIf="controlAtIndex(i); let formControl" [appearance]="'fill'" [color]="color">

      <mat-label>
        <div #contentLabel class="content-label">
          <ng-content label=""></ng-content>
        </div>

        <span
          *ngIf="contentLabel?.children?.length === 0">{{'ui.common.labels.additional.information' | translate}}</span>
      </mat-label>

      <input
        #formArrayInput
        (input)="_handleInput()"
        (keydown.control.+)="updateArrayCount($event, i, 'add')"
        (keydown.control.-)="updateArrayCount($event, i, 'remove')"
        [formControl]="formControl"
        [placeholder]="'Anrufen bei...'"
        data-cy="array-input-{{i}}"
        matInput
      />

    </mat-form-field>

    <div *ngIf="!disabled && isLast" class="array-actions">
      <button (click)="addControl()" *ngIf="notHasMaximumValues" color="accent" data-cy="array-control-add"
              mat-icon-button>
        <mat-icon>add</mat-icon>
      </button>
      <button (click)="removeControl()" *ngIf="notHasMinimumValues" color="warn" data-cy="array-control-remove"
              mat-icon-button>
        <mat-icon>remove</mat-icon>
      </button>
    </div>

  </div>
</div>
