<mat-card appUpload (fileDropped)="uploadFile($event)">
  <!-- Hidden file-input for file-upload -->
  <input hidden type="file" [accept]="acceptedFileTypes" (change)="uploadFile($event.target.files)" #fileInput />
  <div id="upload-message" *ngIf="files.length === 0" mat-ripple (click)="openFileInput()" matRippleRadius="50" matRippleCentered>
    <mat-icon>cloud_upload</mat-icon>
    <span> Datei hier hochladen </span>
  </div>

  <div id="upload-list" *ngIf="files.length > 0">
    <mat-list>
      <!-- <ng-container *ngFor="let file of files; let i = index"> -->
      <mat-list-item>
        <mat-icon mat-list-icon>upload_file</mat-icon>
        <div>{{ currentFile.fileName }}</div>
      </mat-list-item>
      <!-- <mat-divider *ngIf="i !== files.length - 1"></mat-divider> -->
      <!-- </ng-container> -->
    </mat-list>
  </div>
</mat-card>
