import {Component} from '@angular/core';
import {CanBeDisabled} from "../model/can-be-disabled";

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss'],
  inputs: ['disabled']
})
export class DeleteButtonComponent extends CanBeDisabled {

  constructor() {
    super();
  }

}
