import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Params, Router} from '@angular/router';
import {ChildRoutes, RouteQueryParams} from '../../constants';
import {isArray, isEmpty} from '../../domain/functions';

@Component({
    selector: 'app-return-button',
    templateUrl: './return-button.component.html',
    styleUrls: ['./return-button.component.scss']
})
export class ReturnButtonComponent implements OnInit {

    @Input() defaultReturn: string | undefined;

    constructor(
        private _activeRoute: ActivatedRoute,
        private _router: Router
    ) {
    }

    get hasReturnToStatement(): boolean {
        return !!this.routerLink;
    }

    private _routerLink: string = '..';

    get routerLink(): string {
        return this._routerLink.split('?')[0]
    }

    @Input()
    set routerLink(val: string) {
        this._routerLink = val;
    }

    get queryParams(): Params {
        return {...this._readQueryParamsToPreserve(), ...this._readQueryParamsFromUrl()};
    }

    ngOnInit(): void {
        this.routerLink = this._readReturnRouterLink();
    }

    navigateBack() {
        this._router.navigate(this.routerLink.split('/'), {
            queryParams: this.queryParams,
            relativeTo: this._activeRoute.parent
        });
    }

    private _readTopLevelMasterRoute() {
        const parentRoute = this._activeRoute.snapshot.parent;
        if (parentRoute) {
            const fromParentToRoot = parentRoute.pathFromRoot.reverse();
            const nextMasterIndex = fromParentToRoot.findIndex(this._hasMasterView);

            if (nextMasterIndex) {

                const initialNavigation = isEmpty(this._activeRoute.snapshot.params) ? `${ChildRoutes.List}` : `../${ChildRoutes.List}`;
                return Array.from({length: nextMasterIndex + 1}, (_, i) => i).reduce((arr, curr) => `../${arr}`, initialNavigation);
            }

        }
        return ChildRoutes.List;
    }

    private _hasMasterView(val: ActivatedRouteSnapshot) {
        let paths = [val.routeConfig?.path];
        if (val.routeConfig?.children) {
            paths = paths.concat(val.routeConfig.children.map(r => r.path));
        }
        return paths.includes(ChildRoutes.List) || paths.includes(ChildRoutes.Table);
    }

    private _readQueryParamsToPreserve(): { [key: string]: any } {
        const query = this._activeRoute.snapshot.queryParams;
        const preserveQueries: string | string[] = query[RouteQueryParams.Preserve];
        if (preserveQueries) {
            const queryNameList: string[] = (isArray(preserveQueries) ? preserveQueries : [preserveQueries]) as string[];

            return queryNameList.reduce<any>((acc, curr) => {
                return query[curr] ? {...acc, [curr]: query[curr]} : acc;
            }, {});
        }

        return {};
    }

    private _readQueryParamsFromUrl() {
        return this._routerLink.split('?').slice(1).reduce((acc: any, cur) => {
            const temp = cur.split('=')
            acc[temp[0]] = temp[1]
            return acc;
        }, {});
    }

    private _readReturnRouterLink(): string {
        const {returnTo} = this._activeRoute.snapshot.queryParams;

        if (returnTo) {
            return returnTo;
        }

        if (this.defaultReturn) {
            return this.defaultReturn;
        }

        return this._readTopLevelMasterRoute() ?? '..';
    }
}
