export {
  TableColumns, ActionColumn, userActionColumns,
  DefaultActionColumns
};

enum ActionColumn {
  Select = 'select',
  Edit = 'edit',
  Delete = 'delete',
  Details = 'details',
  ActionMenu = 'actionMenu'
}

const userActionColumns = ['select', 'details', 'edit', 'delete', 'actionMenu'];

const DefaultActionColumns = ['details', 'edit', 'delete'];

type ActionColumns = 'select' | 'details' | 'edit' | 'delete' | 'actionMenu';

type TypeColumns<T> = keyof T;

type TableColumns<T> = (TypeColumns<T> | ActionColumns)[];
