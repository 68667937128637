<ng-container *ngIf="hasAllKeys; else fallback">
  {{value[displayKeys[0]]}}
  <ng-container *ngIf="displayKeys[1]">
    | <small>{{value[displayKeys[1]]}}</small>
  </ng-container>
</ng-container>

<ng-template #fallback>
  {{value[fallbackKey]}}
</ng-template>
