import {Injectable} from '@angular/core';
import {EagerLoadingStateService} from '../model/eager-loaded-state/eager-loading-state.service';
import {ExtendedHandlingUnitQuery, HandlingUnit} from '../../domain/core-model';
import {TransportPositionService} from '../transport-position/transport-position.service';
import {HandlingUnitServiceConfig} from './handling-unit-service.config';
import {EntityKey} from '../../domain/utility-types';
import {StorageBin} from '../../domain/lvs-model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PropertySearcher} from '../../search/model';
import {SearchUtil} from '../../search/util';
import {AllTransportPositionStates, ArticleGroup, TransportPosition} from '../../domain/tms-model';

@Injectable({
    providedIn: 'root'
})
export class HandlingUnitService extends EagerLoadingStateService<HandlingUnit, ExtendedHandlingUnitQuery> {

    constructor(
        private _transports: TransportPositionService
    ) {
        super(
            HandlingUnitServiceConfig.stateAccess,
            HandlingUnitServiceConfig.stateOptions,
            HandlingUnitServiceConfig.stateEagerLoadingOptions
        );
    }

    getHandlingUnitsOfStorageBin(storageBinKey: EntityKey<StorageBin>): Observable<HandlingUnit[]> {
        return this.state.pipe(
            map(s => s.filter(h => {
                return h.storageAreaId === storageBinKey.storageAreaId && h.storageBinId === storageBinKey.storageBinId && h.warehouseId === storageBinKey.warehouseId;
            }))
        );
    }

    searchStoredArticle(query: {
        articleId?: string,
        articleGroupId?: number,
        articleGroupName?: string
    }): Observable<HandlingUnit[]> {
        return this.search(query)
            .pipe(
                map(s => s.filter(h => h.warehouseId && h.storageAreaId && h.storageBinId))
            );
    }

    protected override searchProperty: PropertySearcher<HandlingUnit, ExtendedHandlingUnitQuery> = (hus, key, query) => {
        if (key === 'articleId') {
            return hus.filter(h => {
                return SearchUtil.search(h.handlingUnitArticles ?? [], {articleId: query}).length > 0
            })
        }

        if (key === 'articleGroupName' || key === 'articleGroupId') {
            const group = key === 'articleGroupName' ? {name: query} : {articleGroupId: query};
            return this._searchStoredArticleGroup(hus, group);
        }

        if (key === 'warehouseName') {
            // TODO: Make description searchable
            const stored = hus.filter(h => h.warehouseId);
            return stored.filter(h => h.warehouseObj!.warehouseName.toLowerCase().includes(query.toLowerCase()));
        }

        return undefined;
    };

    private _searchStoredArticleGroup(hus: HandlingUnit[], articleGroup: Partial<ArticleGroup>) {

        const transportQuery = {
            transportState: AllTransportPositionStates,
            articleGroupObj: articleGroup
        };

        return this._transports.search(transportQuery as any).pipe(
            map((tps: TransportPosition[]) => {
                return hus.filter((currentHU) => {
                    const assignedTransports = tps.filter(p => p.handlingUnitId === currentHU.handlingUnitId);
                    return assignedTransports.length > 0;
                });
            })
        )
    }
}
