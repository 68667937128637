export { LogEntry, LogLevel, LogLevelOptions, LogEntryQuery };

class LogEntry {
    constructor(
        public content: string,
        public logType: string,
        public source: string,
        public logLevel: LogLevel,
        public appLogEntryId?: number,
        public createdAt?: string
    ) { }
}

type LogEntryQuery = string | (Partial<LogEntry> & {source?: string | string[], logLevel?: LogLevel | LogLevel[], logType?: string | string[]});

enum LogLevel {
    Trace = 0,
    Debug = 10,
    Information = 20,
    Warning = 30,
    Error = 40,
    Critical = 50
}

const LogLevelOptions: {view: string, value: LogLevel}[] = [
    {
        view: 'Trace',
        value: LogLevel.Trace
    },
    {
        view: 'Debug',
        value: LogLevel.Debug
    },
    {
        view: 'Information',
        value: LogLevel.Information,
    },
    {
        view: 'Warning',
        value: LogLevel.Warning
    },
    {
        view: 'Error',
        value: LogLevel.Error
    },
    {
        view: 'Kritisch',
        value: LogLevel.Critical
    }
];
