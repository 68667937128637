import {Component, EventEmitter, forwardRef, Inject, Optional, Output} from '@angular/core';
import {View, ViewChangedEvent} from '../../buttons/model/view-change.model';
import {ImportPage} from '../model/import-page.model';
import {FileImportEvent, ImportView} from '../model/import-event.model';

@Component({
  selector: 'app-import-view-toggle',
  templateUrl: './import-view-toggle.component.html',
  styleUrls: ['./import-view-toggle.component.scss']
})
export class ImportViewToggleComponent {

  get activeViews(): View[] {
    const initialEvent = this._parentView?.initialEvent;
    if (initialEvent) {
      return this.importViews.filter((v) => this.hasViewData(v));
    }
    return this.importViews;
  }

  @Output() viewChange: EventEmitter<ViewChangedEvent<ImportView>> = new EventEmitter();
  readonly importViews: Array<View<keyof FileImportEvent>> = [
    {name: 'complete', displayValue: 'Vollständig'},
    {name: 'inComplete', displayValue: 'Unvollständig'},
    {name: 'corrupted', displayValue: 'Nicht lesbar'}
  ];

  constructor(
    @Inject(forwardRef(() => ImportPage)) @Optional() private _parentView?: ImportPage<any> | null
  ) {
  }

  private hasViewData(v: View<keyof FileImportEvent>): boolean {
    const viewKey = v.name;
    const presentDataSet = this._parentView?.initialEvent?.[viewKey];
    return !!(presentDataSet && presentDataSet.length > 0);
  }

  emitEvent(event: ViewChangedEvent<ImportView>) {
    console.time('VIEW CHANGE');
    this.viewChange.emit(event);
  }
}
