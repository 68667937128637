import {FileType} from "./export-filetpyes.enum";

export {
  ExportEvent
}

/**
 * Event that es triggered when user wants to export a set of data
 */
class ExportEvent {
  constructor(
    public fileType: FileType
  ) {
  }
}
