/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

export {AppReleaseInfo, Build, Destination, AppReleaseDistributionGroup};

class AppReleaseInfo {
  constructor(
    public origin: string,
    public id: number,
    public short_version: string,
    public version: string,
    public uploaded_at: Date,
    public enabled: boolean,
    public is_external_build: boolean,
    public file_extension: string,
    public build: Build,
    public destinations: Destination[],
    public distribution_groups: AppReleaseDistributionGroup[],
    public install_url: string,
    public min_os: string,
    public download_url: string,
  ) {
  }
}

interface Build {
  commitHash: string;
}

interface Destination {
  id: string;
  name: string;
  destination_type: string;
}

interface AppReleaseDistributionGroup {
  id: string;
  name: string;
}
