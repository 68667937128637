import * as dayjs from 'dayjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Initiator, PriorityLevel} from '../tms-model';
import {Article, Warehouse} from '../core-model';
import {CustomValidators} from '../../form-fields';
import {Observable} from 'rxjs';

export {
    TransportPositionCreateForm,
    TransportPositionCreateFormGroup
}

type TransportPositionCreateFormGroup = FormGroup<TransportPositionCreateForm>;

class TransportPositionCreateForm {

    constructor(articleGroupExistsCheck?: (e: string) => Observable<boolean>) {
        this.staticForm = new FormGroup(new StaticInformationForm());
        this.dynamicForm = new FormGroup(new DynamicInformationForm(articleGroupExistsCheck));
    }

    staticForm: FormGroup<StaticInformationForm>;
    dynamicForm: FormGroup<DynamicInformationForm>;
}

class StaticInformationForm {

    priorityLevel = new FormControl<PriorityLevel | number>(PriorityLevel.NoPriority, {
        nonNullable: true,
        validators: [Validators.required]
    });

    destinationWarehouseObj = new FormControl<Warehouse | null>(null, {
        nonNullable: true,
        validators: [Validators.required, CustomValidators.objectSelectedValidator]
    });


    toBeDoneBy = new FormControl<string | null>(dayjs().toISOString());

    initiatorObj = new FormControl<Initiator | null>(null, {
        nonNullable: false,
        validators: [CustomValidators.objectSelectedValidator]
    });

    additionalInformation = new FormControl<Array<string> | null>(['']);
}

class DynamicInformationForm {

    constructor(articleGroupExistsCheck?: (e: string) => Observable<boolean>) {
        if (articleGroupExistsCheck) {
            this.articleGroupName.addAsyncValidators(CustomValidators.entityAlreadyExistsValidator(articleGroupExistsCheck as any));
            this.articleGroupName.updateValueAndValidity();
        }
    }

    articleGroupName = new FormControl<string | undefined>(undefined, {nonNullable: true});

    articleObj = new FormControl<Article | null>(null, {
        nonNullable: true,
        validators: [Validators.required, CustomValidators.objectSelectedValidator]
    });

    articleCount = new FormControl<number>(1, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(0.1)]
    });

    sourceWarehouseObj = new FormControl<Warehouse | null>(null, {
        nonNullable: true,
        validators: [Validators.required, CustomValidators.objectSelectedValidator]
    });

    jobNumber = new FormControl<string | null>(null, {nonNullable: false, validators: [Validators.minLength(3)]});
    bookingNumber = new FormControl<string | null>(null, {nonNullable: false, validators: [Validators.minLength(3)]});
    additionalInformation = new FormControl<Array<string> | null>(['']);
}
