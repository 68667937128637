import {IExportColumn} from '../interfaces/export-column.interface';

export {
    ExportColumn
}

class ExportColumn implements IExportColumn {

    propertyKey!: string;
    view!: string;

    alt?: string;
    combine?: string;
    destruct?: boolean;
    extern?: boolean;
    index: number = -1;
    link?: Function;
    listValue = false;

    private readonly _base: IExportColumn;

    constructor(config: IExportColumn, public enforceIndex?: boolean) {
        this._base = config;
        Object.assign(this, config);
    }

    get asIExportColumn(): IExportColumn {
        return this._base;
    }
}
