import {Component} from '@angular/core';
import {BaseButtonDirective} from "../base-button/base-button.directive";

@Component({
  selector: 'app-create-fab',
  templateUrl: './create-fab.component.html',
  styleUrls: ['./create-fab.component.scss']
})
export class CreateFabComponent extends BaseButtonDirective {

}
