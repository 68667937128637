import {SettingsMap} from '../settings-map.model';
import {EntitySettings} from './entity-settings.interface';

export {
  HandlingUnitSettings
}

class HandlingUnitSettings extends EntitySettings {

  idMaxLength = 10;
  idMinLength = 1;
  idPrefix = '';
  idSuffix = '';
  idAllowedSinges = '';

  constructor(settings: SettingsMap) {
    super();
    const maxLength = Number(settings.hu_id_max_length[0]);
    if (!isNaN(maxLength)) {
      this.idMaxLength = maxLength;
    }

    const minLength = Number(settings.hu_id_min_length[0]);
    if (!isNaN(minLength)) {
      this.idMinLength = minLength;
    }

    if (settings.hu_id_prefix) {
      this.idPrefix = settings.hu_id_prefix[0];
    }

    if (settings.hu_id_suffix) {
      this.idSuffix = settings.hu_id_suffix[0];
    }

    if (settings.hu_id_allowed_signs) {
      this.idAllowedSinges = settings.hu_id_allowed_signs[0];
    }
  }

  toMap(): Partial<SettingsMap> {
    return {
      hu_id_max_length: [String(this.idMaxLength)],
      hu_id_min_length: [String(this.idMinLength)],
      hu_id_prefix: [this.idPrefix],
      hu_id_suffix: [this.idSuffix],
      hu_id_allowed_signs: [this.idAllowedSinges]
    };
  }
}
