<span [appCanBeDisabled]="disabled">
  <button [disabled]="disabled" [matTooltip]="toolTip|translate" color="accent"
          mat-raised-button>
    <span>
      <app-icon-stack bolder>
        <mat-icon top-right>undo</mat-icon>
        <mat-icon svgIcon="handling-unit"></mat-icon>
      </app-icon-stack>
    </span>

  </button>
</span>
