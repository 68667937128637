import {HandlingUnitArticle} from './handling-unit-article.model';
import {TransportPosition} from "../tms-model";
import {Warehouse} from "./warehouse.model";
import {BaseEntity} from '../base-entity.model';

export {
    HandlingUnit,
    HandlingUnitId,
    ExtendedHandlingUnitQuery
};

type HandlingUnitId = HandlingUnit['handlingUnitId'];

class HandlingUnit extends BaseEntity {

    public transportPositions? = new Array<TransportPosition>();
    public warehouseObj?: Warehouse;

    constructor(
        public handlingUnitId: string,
        public description: string,
        public storageBinId?: string,
        public warehouseId?: number,
        public storageAreaId?: string,
        public isOpen?: boolean,
        public handlingUnitArticles?: HandlingUnitArticle[],
        // just for searching
        public articleId?: string,
        // for eager loading
    ) {
        super();
    }

    static toId(handlingUnits: HandlingUnit[]): HandlingUnitId[] {
        return handlingUnits.map(h => h.handlingUnitId);
    }
}

type ExtendedHandlingUnitQuery = Partial<HandlingUnit> & Partial<TransportPosition> & { warehouseName?: string };
