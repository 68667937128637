import {TableContentInjector} from "./column-injector.interface";

export class NoColumnInjectorError extends Error {
    constructor(compoentenClass: Function | object) {
        const className = typeof compoentenClass === 'object' ? Object.constructor.name : compoentenClass.name;
        super(`Component ${className} is not a sub-class of ${TableContentInjector.name} and can therefore not predicated to inject table content.
        If you see this but also have a working table, it is possible that your component provides the correct property but
        it would be safer to extend the ${TableContentInjector.name} class.`);
    }
}
