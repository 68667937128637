export enum InputTypeEnum {
  Text = 'text',
  Number = 'number',
  Selection = 'selection',
  Date = 'datetime-local',
  Email = 'email',
  Url = 'url',
  Array = 'array',
  AsyncSelection= 'async-selection',
  Checkbox = 'checkbox'
}
