import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {TransportPosition} from 'src/app/domain/tms-model';
import {TransportPositionState} from 'src/app/domain/tms-model/transport-position-state.model';
import {BaseApiService} from '../base-api-service/base-api.service';
import {IcsUserFeedbackService} from 'ics-core';
import {IHttpResult} from '../interfaces/http-result.interface';
import {BaseEntity} from '../../domain';

@Injectable({
    providedIn: 'root'
})
export class TransportPositionApiService extends BaseApiService<TransportPosition> {

    constructor(http: HttpClient, userFeedback: IcsUserFeedbackService) {
        super('TransportPosition', 'tms', http, userFeedback);
    }

    override getEager(): Observable<TransportPosition[]> {
        return super.getEager(TransportPosition.buildFromEagerLoad);
    }

    /**
     * Fetches all TransportPositions from the server which do not have the given status
     * @param {TransportPositionState} exceptStatus the excluded state
     */
    getExceptStatusEager(exceptStatus: TransportPositionState): Observable<TransportPosition[]> {
        return this._http.post<TransportPosition[]>(this.buildEndpointUrl('GetExceptStatusEager'), exceptStatus)
            .pipe(
                catchError((err) => this.handleHttpGetError(err)),
                map((transports: TransportPosition[] | null) => {
                    if (transports) {
                        return transports.map(TransportPosition.buildFromEagerLoad)
                    }
                    return new Array<TransportPosition>();
                })
            )
    }

    getByStatusEager(...status: TransportPositionState[]) {
        return this._http.post<TransportPosition[]>(this.buildEndpointUrl('GetByStatusEager'), status)
            .pipe(
                catchError((err) => this.handleHttpGetError(err)),
                map((transports: TransportPosition[] | null) => {
                    if (transports) {
                        return transports.map(TransportPosition.buildFromEagerLoad)
                    }
                    return new Array<TransportPosition>();
                })
            )
    }

    getMaterialRequestsForDestinationWarehouse(warehouseId: number) {
        return this._http.get<TransportPosition[]>(this.buildEndpointUrl('GetMaterialRequestsForDestinationWarehouse'), {params: {warehouseId: warehouseId}})
            .pipe(
                catchError((err) => this.handleHttpGetError(err)),
                map((transports: TransportPosition[] | null) => {
                    return transports ?? []
                })
            )
    }

    override updateEntities(entities: Array<TransportPosition>, userFeedback: boolean = false): Observable<IHttpResult<TransportPosition[] | null>> {
        const update = entities.map(BaseEntity.clearMeta);
        return super.updateEntities(update, userFeedback);
    }


}
