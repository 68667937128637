export {
  BarcodeTypes,
  BarcodeType,
  BarcodeCheck
}

interface BarcodeTypes {
  readonly Code128: string;
  readonly Interleaved2of5: string;
  readonly DataMatrix: string;
}

const OneDimensionalCodes = {
  Code128: 'code128',
  Interleaved2of5: 'interleaved2of5'
}

const TwoDimensionalCodes = {
  DataMatrix: 'datamatrix',
}

const BarcodeTypes: BarcodeTypes = {
  ...OneDimensionalCodes,
  ...TwoDimensionalCodes
};

type ValueOf<T> = T[keyof T];

type BarcodeType = ValueOf<BarcodeTypes> | typeof BarcodeTypes;

function isOneDimensional(type: BarcodeType): boolean {
  return !!Object.values(OneDimensionalCodes).find(c => c === type);
}

function isTwoDimensional(type: BarcodeType): boolean {
  return !!Object.values(TwoDimensionalCodes).find(c => c === type);
}

const BarcodeCheck = {
  isOneDimensional,
  isTwoDimensional
};
