export {
    TableButtonEvent, TableDetailsEvent, TableEditEvent, TableDeleteEvent, TableAddEvent
};

type EventType = 'delete' | 'details' | 'edit' | 'add' | 'unknown';

/**
 * Ebent that is emitted on a button press in a list or table.
 * Emits the index as uniquie identifier.
 */
class TableButtonEvent<T> {
    constructor(
        public value: T,
        public index: number,
        public type: EventType = 'unknown'
    ) {
    }
}

class TableDeleteEvent<T> extends TableButtonEvent<T> {
    constructor(
        value: T,
        index: number
    ) {
        super(value, index, 'delete');
    }
}

class TableEditEvent<T> extends TableButtonEvent<T> {
    constructor(
        value: T,
        index: number
    ) {
        super(value, index, 'edit');
    }
}

class TableDetailsEvent<T> extends TableButtonEvent<T> {
    constructor(
        value: T,
        index: number
    ) {
        super(value, index, 'details');
    }
}

class TableAddEvent<T> extends TableButtonEvent<T> {
    constructor(
        value: T,
        index: number
    ) {
        super(value, index, 'add');
    }
}
