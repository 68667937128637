import {Inject, Injectable} from '@angular/core';
import {combineLatest, Observable, ReplaySubject, switchMap, zip} from 'rxjs';
import {Warehouse, WarehouseConfig, WarehouseTypes} from '../../../domain/core-model';
import {map} from 'rxjs/operators';
import {ITypeConfig} from '../../../domain/interfaces';
import {SignalRService} from '../../../websocket';
import {StorageArea, StorageBin} from '../../../domain/lvs-model';
import {StateService} from '../../model/state/state.service';
import {ContainerService} from '../container-service/container.service';
import {IHttpResult} from '../../../api/interfaces/http-result.interface';
import {WarehouseApiService} from '../../../api/warehouse/warehouse-api.service';
import {DeepPartial} from '../../../search/model';
import {search} from '../../../search/operators';

@Injectable({
    providedIn: 'root'
})
export class WarehouseService extends StateService<Warehouse> {

    private _inactiveWareHouses = new ReplaySubject<Warehouse[]>(1);

    constructor(
        private _containers: ContainerService,
        protected override _api: WarehouseApiService,
        @Inject('WarehouseSignalRService') private signalRWarehouseService: SignalRService<Warehouse>,
        @Inject('ContainerSignalRService') private signalRContainerService: SignalRService<Warehouse>,
    ) {

        super(
            {
                type: Warehouse,
                primaryKeys: ['warehouseId'],
                webSocket: signalRWarehouseService,
                api: WarehouseApiService
            },
        );

        // also listening to container-socket updates
        super.connectToWebSocket(signalRContainerService);

        this._refreshInActive();
    }

    override get state(): Observable<Warehouse[]> {
        return combineLatest([
            super.state, this._containers.state
        ]).pipe(
            map(([w, c]) => [...w, ...c]),
            map((warehouses: Array<Warehouse>) => warehouses.filter(w => w.warehouseType !== WarehouseTypes.Inactive)),
        )
    }

    getTypeConfig(): ITypeConfig<Warehouse> {
        return JSON.parse(JSON.stringify(WarehouseConfig));
    }

    createFullWarehouse(fullWarehouse: {
        warehouse: Warehouse,
        storageAreas: StorageArea[],
        storageBins: StorageBin[]
    }): Observable<boolean> {
        const isTemporary = fullWarehouse.warehouse.warehouseType === WarehouseTypes.Container;
        if (isTemporary) {
            return this._containers.createFullContainer(fullWarehouse)
            .pipe(
                map((res: IHttpResult) => !res.isError)
            );
        }
        return this._api.createFull(fullWarehouse)
            .pipe(
                map((res: IHttpResult) => !res.isError)
            );
    }

    getInactiveWarehouses(query?: Partial<Warehouse> | string | [DeepPartial<Warehouse> | string, DeepPartial<Warehouse> | string]): Observable<Warehouse[]> {
        if (Array.isArray(query)) {
            const [first, second] = query;

            return this._inactiveWareHouses.asObservable()
                .pipe(
                    search(first),
                    search(second),
                )
        }

        return this._inactiveWareHouses.asObservable().pipe(search<Warehouse>(query ?? ''));
    }

    override update(...entities: Warehouse[]): Observable<boolean> {
        const {containers, warehouses} = entities.reduce((acc, curr) => {
            curr.warehouseType === WarehouseTypes.Warehouse ? acc.warehouses.push(curr) : acc.containers.push(curr);
            return acc;
        }, {warehouses: new Array<Warehouse>(), containers: new Array<Warehouse>()});

        const calls = new Array<Observable<boolean>>();

        if (containers.length > 0) {
            calls.push(this._containers.update(...containers));
        }

        if (warehouses.length > 0) {
            calls.push(super.update(...warehouses));
        }

        return zip(calls).pipe(map(e => !e.includes(false)));
    }

    override delete(entity: Warehouse): void {
        this._confirm.askForConfirmation({
            title: 'ui.warehouses.confirmations.delete.title',
            message: 'ui.warehouses.confirmations.delete.message'
        }).pipe(
            switchMap(() => this._api.hasInventory(entity.warehouseId!)),
            switchMap((hasInventory) => {
                if (hasInventory) {
                    return this._confirm.askForConfirmation({
                        title: 'ui.warehouses.confirmations.delete.title',
                        message: 'ui.warehouses.confirmations.delete.with.inventory'
                    }).pipe(
                        switchMap(() => this._api.delete(entity))
                    );
                }
                return this._api.delete(entity);
            })
        ).subscribe();
    }

    override refresh(inactive = false): void {
        if (inactive) {
            this._refreshInActive();
        }

        super.refresh();
        this._containers?.refresh();
    }

    private _refreshInActive() {
        this._api.getInactiveWarehouses().subscribe(w => {
            this._inactiveWareHouses.next(w)
        });
    }
}
