import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter, map, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {ModuleTitles} from '../../constants';
import {NavigationRoute} from '../../domain/interfaces';

@Injectable({
  providedIn: 'root'
})
export class NavigationHistoryService {

  get last(): string | undefined {
    return this._currentRoute?.route;
  }

  private _currentRoute: NavigationRoute | undefined;
  private _historyState: BehaviorSubject<NavigationRoute[]> = new BehaviorSubject(new Array<NavigationRoute>());

  constructor(private router: Router) {
    this.trackHistory();
  }

  private trackHistory(): void {
    this.router.events
      .pipe(
        filter(ev => ev instanceof NavigationEnd),
        map(ev => ev as NavigationEnd),
        // checking if last navigation was to a list view of a child module
        map((end: NavigationEnd) => {
          if (hasSubModule(end.urlAfterRedirects) && isListView(end.urlAfterRedirects)) {
            return new NavigationRoute(
              end.urlAfterRedirects!,
              (ModuleTitles as any)[getSubModuleName(end.urlAfterRedirects)]
            );
          }
          return undefined;
        }),
        // storing current route in service
        tap((route: NavigationRoute | undefined) => {
          if (route) {
            this._currentRoute = route
          }
        }),
        // checking if entry is not already in history and history is not too big
        map((route: NavigationRoute | undefined) => {
          if (route) {
            this.addHistoryEntry(this._historyState.value, route)
          }
          return [];
        }),
      ).subscribe((history: NavigationRoute[]) => {
      this._historyState.next(history);
    });
  }

  private addHistoryEntry(history: NavigationRoute[], newEntry: NavigationRoute | undefined): NavigationRoute[] {
    if (newEntry) {
      if (!history.find((n: NavigationRoute) => n.route === newEntry?.route)) {
        history.push(newEntry);
      }

      if (history.length > 5) {
        history.shift();
      }
    }
    return history;
  }

  getEntireHistory(): Observable<NavigationRoute[] | undefined> {
    return this._historyState.asObservable()
      .pipe(map((history: NavigationRoute[] | undefined) => {
        return history?.filter(r => r.route !== this._currentRoute?.route);
      }));
  }
}

function hasSubModule(route: string): boolean {
  return route.split('/').length > 2;
}

function isListView(route: string): boolean {
  return route.endsWith('list') || route.endsWith('history');
}

function getSubModuleName(route: string): string {
  return (route.split('/')[2]).replace(/-/g, '');
}
