import {TransportTask} from '../model/transport-task.model';
import {TransportTaskQuery} from '../model/transport-task.query';
import dayjs from 'dayjs';

import * as isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export function filterTransportTasksInDateRange(
    query: NonNullable<TransportTaskQuery['lastMovementRange']>,
    searchList: TransportTask[]
): TransportTask[] {

    if (!query.start || !query.end) {
        return searchList;
    }

    return searchList.filter(task => lastMovementIsInDateRange(task, query))
}

function lastMovementIsInDateRange(task: TransportTask, query: NonNullable<TransportTaskQuery["lastMovementRange"]>) {
    const {start, end} = query;

    const lastChange = task.statusChanges?.slice(-1)?.[0];

    if (!lastChange) {
        return false;
    }

    return dayjs(lastChange?.timeStamp).isBetween(
        start?.subtract(1, 'day'), // include the real start
        end?.add(1, 'day'), // include the real end
        'day');
}

