import {Injectable} from '@angular/core';
import {Warehouse} from '../../domain/core-model';
import {HttpClient} from '@angular/common/http';
import {Observable, of, switchMap} from 'rxjs';
import {BaseApiService} from '../base-api-service/base-api.service';
import {catchError, map} from 'rxjs/operators';
import {IHttpResult} from '../interfaces/http-result.interface';
import {IcsUserFeedbackService} from 'ics-core';

@Injectable({
    providedIn: 'root'
})
export class WarehouseApiService extends BaseApiService<Warehouse> {

    constructor(http: HttpClient, userFeedback: IcsUserFeedbackService) {
        super('Warehouse', 'core', http, userFeedback);
    }

    getInactiveWarehouses(): Observable<Warehouse[]> {
        return this._http.get<Warehouse[]>(this.buildEndpointUrl('GetInactive'))
            .pipe(
                catchError(err => of(new Array<Warehouse>()))
            )
    }

    hasInventory(id: number): Observable<boolean> {
        return this._http.get<boolean>(this.buildEndpointUrl('HasInventory'), {params: this.createIdParams({warehouseId: id})});
    }

    getList(value: { warehouseId: number }[]): Observable<Warehouse[]> {
        return this._http.post<Warehouse[]>(this.buildEndpointUrl('GetList'), value)
            .pipe(
                catchError(err => this.handleHttpGetError(err) as Observable<Warehouse[]>)
            )
    }

    createFull(value: any, userFeedback = true): Observable<IHttpResult<Warehouse>> {
        return this._http.post<any>(this.buildEndpointUrl('CreateFull'), value, {observe: 'response'})
            .pipe(
                catchError(this.handleHttpErrorResult),
                map(this.buildHttpResult),
                switchMap((res) => {
                    if (userFeedback === true) {
                        const type = res.isError ? 'error' : 'success';
                        return this._userFeedback.show({
                            type: type,
                            message: `ui.user.feedback.${type}.warehouse.create.full`
                        }).pipe(
                            map(() => res)
                        );
                    }

                    return of(res);
                })
            );
    }
}
