import {Observable} from 'rxjs';
import {TransportTask} from '../model/transport-task.model';
import {map} from 'rxjs/operators';
import {Warehouse} from '../../../../domain/core-model';
import {WarehouseService} from '../../../../entity-state/warehouse/warehouse-service/warehouse.service';
import {TransportTaskQuery} from '../model/transport-task.query';

export function filterTransportTasksBySourceWarehouseName$(
    warehouseService: WarehouseService,
    query: NonNullable<TransportTaskQuery['sourceWarehouseName']>,
    tasks: TransportTask[]
): Observable<TransportTask[]> {
    return warehouseService.search({warehouseName: query})
        .pipe(
            map((warehouses: Warehouse[]) => {
                return tasks.filter(t => warehouses.find(w => w.warehouseId === t.sourceWarehouseId));
            })
        );
}
