import {Article, HandlingUnit, User, Vehicle, Vehicletype, Warehouse} from 'src/app/domain/core-model';
import {MaterialRequest, SuperTask, TransportPosition} from 'src/app/domain/tms-model';
import {DisplayNames} from '../../domain/utility-types';
import {RequisitionType, StorageArea, StorageBin} from '../../domain/lvs-model';
import {TransportTask} from '../../modules/tms/transport-task';

const transportPosition: DisplayNames<TransportPosition> = {
    transportPositionId: 'Id',
    priorityLevel: 'Priorität',
    articleCount: 'Anzahl',
    articleSourceId: 'Quelle',
    destination: 'Ziel',
    toBeDoneBy: 'Liefertag',
    orderedBy: 'Bestelldatum',
    articleId: 'Artikelnummer',
    bookingNumber: 'Buchungsnummer',
    jobNumber: 'Auftragsnummer',
    additionalInformation: 'Zusatzinformationen',
    superTaskDestination: 'Hauptziel',
    articleGroupName: 'Artikelgruppe',
    articleGroupId: 'Artikelgruppen Id',
    transportState: 'Status',
    transportListId: 'Transportlisten Id',
    articleDescription: 'Artikelbeschreibung',
    articleWeight: 'Artikelgewicht',
    destinationWarehouseDescription: 'Ziel',
    isComplete: 'Vollständig',
    operator: 'Mitarbeiter',
    initiator: 'Auftraggeber',
    handlingUnitId: 'Handlingunit',
};

const article: DisplayNames<Article> = {
    articleId: 'Artikelnummer',
    articleWeight: 'Gewicht(kg)',
    description: 'Beschreibung',
    additionalFields: 'Zusatzangaben',
    heightInMillimetres: 'Höhe (in mm)',
    lengthInMillimetres: 'Länge (in mm)',
    transportDescription: 'Transportinformationen',
    widthInMillimetres: 'Breite (in mm)',
};

const materialRequest: DisplayNames<MaterialRequest> = {
    materialRequestId: 'Materialnachforderung',
    isChecked: 'Geprüft',
    transportPositions: 'Transporte',
};

const warehouse: DisplayNames<Warehouse> = {
    warehouseId: 'ID',
    warehouseName: 'Lagername',
    description: 'Beschreibung',
    warehouseType: 'Typ',
    isInterimStorage: 'Zwischenlager',
};

const transportTask: DisplayNames<Partial<TransportTask>> = {
    transportTaskId: 'ID',
    state: `Status`,
    priorityLevel: `Priorität`,
    sourceWarehouseId: 'Quelle',
    sourceWarehouseObj: 'Quelle',
    operator: 'Mitarbeiter',
    dateFinished: 'Abgeschlossen am',
    usedVehicleId: 'Fahrzeug'
}

const requisition: DisplayNames<Partial<RequisitionType>> = {
    identificationNumber: 'Identifikationsnummer',
    ...transportPosition
};

const storageBin: DisplayNames<Partial<StorageBin>> = {
    storageBinId: 'ID',
};

const storageArea: DisplayNames<Partial<StorageArea>> = {
    storageAreaId: 'Lagerbereich-ID',
    description: 'Beschreibung',
    warehouseId: 'Lager/Container',
    storageBins: 'Lagerplätze'
}

const handlingUnit: DisplayNames<Partial<HandlingUnit>> = {
    handlingUnitId: 'ID',
    description: 'Beschreibung'
};

const user: DisplayNames<User> = {
    userId: 'Mitarbeiter-ID',
    email: 'Email',
    firstName: 'Vorname',
    lastName: 'Nachname'
};

const vehicle: DisplayNames<Vehicle> = {
    vehicleId: 'Fahrzeug-ID',
    description: 'Beschreibung'
};

const vehicletype: DisplayNames<Vehicletype> = {
    vehicletypeId: 'Fahrzeug-Typ-ID',
    description: 'Beschreibung'
};

const superTask: DisplayNames<SuperTask> = {
    superTaskId: 'ID',
    destinationWarehouseId: 'Ziel',
    bookingNumber: 'Buchungsnummer',
    state: 'Status'
};

export const ViewNames = {
    transportPosition,
    article,
    materialRequest,
    warehouse,
    handlingUnit,
    user,
    vehicle,
    vehicletype,
    storageArea,
    superTask,
    transportTask,
    storageBin,
    requisition
};
