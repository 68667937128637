import {ExportMap, FlattenedStringMap, IObjectParser, StringMap} from './object-parser.interface';
import {ExportValidator} from '../validators/export-validator';
import {IExportColumn} from '../decorators/export.decorator';
import {ParsingError} from '../errors/parsing.errors';
import {DestructuringParser} from './destructuring.parser';
import {LinkObjectParser} from './link-object.parser';

export {
    PlainObjectParser
}

/**
 * @inheritDoc
 *
 * Provides the functionality to parse objects from there internal representation to an external one.
 * This external representation is provided via @Export({...}) decorators on classes with the
 * @Exportable({...}) decorator.
 */
class PlainObjectParser implements IObjectParser {

    private readonly _emptySign = '--';
    private readonly _validator = new ExportValidator();
    private readonly _linkParser: LinkObjectParser;
    private readonly _destructColumns: Array<IExportColumn>;

    constructor(private _columns: ExportMap) {
        this._destructColumns = Object.values(_columns).filter(c => c.destruct);

        // parser for linked columns
        this._linkParser = new LinkObjectParser(Object.values(_columns).filter(c => c.link));
    }

    private get _parsingColumns() {
        return Object.entries(this._columns).filter(([_, c]) => !c.link);
    }

    /**
     * @inheritDoc
     */
    parseObjectsAsReadable(input: StringMap): FlattenedStringMap;
    parseObjectsAsReadable(input: StringMap[]): FlattenedStringMap[];
    parseObjectsAsReadable(input: StringMap | StringMap[]): FlattenedStringMap | FlattenedStringMap[] {
        if (Array.isArray(input)) {
            const destructuringTemplates = new DestructuringParser(this._destructColumns).buildDestructuringTemplates(input);
            return input.map(v => this._parseSingle(v, destructuringTemplates));
        }
        return this._parseSingle(input);
    }

    /**
     * Parses one single object from its internal representation into an external (human-readable) one that
     * is defined by the Export-Columns of this parser. These columns get normally generated from @Export({...}) decorators on
     * class properties.
     *
     * @param value
     * @param templates
     * @private
     */
    private _parseSingle(value: StringMap, templates?: Map<string, FlattenedStringMap>) {
        let result: FlattenedStringMap = this._linkParser.parseObjectsAsReadable(value);

        for (const [_, col] of this._parsingColumns) {

            let targetValue = this._checkValueIsPresent(value, col.propertyKey, col.alt);

            if (this._validator.validateColumnCanBeExportedWithoutValue(col)) {

                // destructuring
                if (col.destruct && typeof targetValue === 'object') {
                    const template = templates?.get(col.propertyKey);
                    result = {...result, ...this._stringifyObjectValues(targetValue, template)};
                    continue;
                }

            }

            // there is no value. This column gets marked with '--' and is skipped;
            if (!targetValue) {
                result[col.view] = this._emptySign;
                continue;
            }

            // list destructuring
            if (col.listValue && Array.isArray(targetValue)) {
                result[col.view] = this._joinListValue(targetValue, col.propertyKey);
                continue;
            }

            // check if both of the combined values are present or the alt is already used
            if (col.combine && value[col.propertyKey]) {
                const combined = value[col.combine];
                targetValue = combined ? `${targetValue} ${combined}` : targetValue;
            }


            result[String(col.view)] = String(targetValue);
        }

        return result;
    }


    /**
     * Converts all properties of an object to a plain string. Does not check for typings.
     *
     * @param value
     * @param template
     * @private
     */
    private _stringifyObjectValues(value: object | undefined | null, template?: FlattenedStringMap): any {
        if (!value) {
            return {};
        }

        return Object.entries(value).reduce((acc: any, [name, value]) => {
            let resultValue = value ? String(value) : this._emptySign;

            // catching empty strings
            if (!resultValue.trim()) {
                resultValue = this._emptySign;
            }

            return {...acc, [name]: resultValue};
        }, template ?? {});
    }

    /**
     * Checks if there is a value present for the provided column in the exported object.
     * Returns the found value. If there is an alternative property configured and this value is present
     * this one gets returned.
     * If no value was present null gets returned;
     * @param parent
     * @param targetProperty
     * @param alternative
     * @private
     */
    private _checkValueIsPresent(parent: StringMap, targetProperty: string, alternative: string | undefined) {
        if (parent?.[targetProperty]) {
            return parent[targetProperty];
        }

        if (alternative && parent?.[alternative]) {
            return parent[alternative];
        }

        return null;
    }

    /**
     * Combines all list values stringifies the entries into one single result string
     * @param value
     * @param propertyKey
     * @private
     */
    private _joinListValue(value: any[], propertyKey: string): string {
        if (value.find(v => typeof v !== 'string' && typeof v !== 'number')) {
            throw new ParsingError('Cannot parse arrays containing objects for listValue', propertyKey, value);
        }

        return value.map(v => String(v)).join(', ');
    }
}
