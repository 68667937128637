import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {Inject, Optional, Provider} from '@angular/core';

import dayjs, {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import de from 'dayjs/locale/de';
import en from 'dayjs/locale/en';
import {DayJsDateAdapterOptions, MAT_DAYJS_DATE_ADAPTER_OPTIONS} from './adapter.options';

export function addDayjsAdapter(): Provider {
    return {
        provide: DateAdapter,
        useClass: DayjsDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    };
}


/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
    const valuesArray = Array(length);
    for (let i = 0; i < length; i++) {
        valuesArray[i] = valueFunction(i);
    }
    return valuesArray;
}

/** Adapts Dayjs Dates for use with Angular Material. */
export class DayjsDateAdapter extends DateAdapter<Dayjs> {
    private localeData!: {
        firstDayOfWeek: number;
        longMonths: string[];
        shortMonths: string[];
        dates: string[];
        longDaysOfWeek: string[];
        shortDaysOfWeek: string[];
        narrowDaysOfWeek: string[];
    };

    constructor(
        @Optional() @Inject(MAT_DATE_LOCALE) public dateLocale: string,
        @Optional()
        @Inject(MAT_DAYJS_DATE_ADAPTER_OPTIONS)
        private options?: DayJsDateAdapterOptions
    ) {
        super();

        this._initPlugins();

        this.setLocale(dateLocale);
    }

    private get shouldUseUtc(): boolean {
        const {useUtc}: DayJsDateAdapterOptions = this.options || {};
        return !!useUtc;
    }

    override setLocale(locale: string) {
        super.setLocale(locale);

        if (locale.includes('en')) {
            dayjs.locale('en-US', en);
        } else {
            dayjs.locale('de-DE', de);
        }

        const dayJsLocaleData = this._toDayjs().localeData();

        this.localeData = {
            firstDayOfWeek: dayJsLocaleData.firstDayOfWeek(),
            longMonths: dayJsLocaleData.months(),
            shortMonths: dayJsLocaleData.monthsShort(),
            dates: range(31, (i) => this.createDate(2017, 0, i + 1).format('D')),
            longDaysOfWeek: range(7, (i) =>
                this._toDayjs().set('day', i).format('dddd')
            ),
            shortDaysOfWeek: dayJsLocaleData.weekdaysShort(),
            narrowDaysOfWeek: dayJsLocaleData.weekdaysMin()
        };
    }

    getYear(date: Dayjs): number {
        return this._toDayjs(date).year();
    }

    getMonth(date: Dayjs): number {
        return this._toDayjs(date).month();
    }

    getDate(date: Dayjs): number {
        return this._toDayjs(date).date();
    }

    getDayOfWeek(date: Dayjs): number {
        return this._toDayjs(date).day();
    }

    getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
        return style === 'long'
            ? this.localeData.longMonths
            : this.localeData.shortMonths;
    }

    getDateNames(): string[] {
        return this.localeData.dates;
    }

    getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
        if (style === 'long') {
            return this.localeData.longDaysOfWeek;
        }
        if (style === 'short') {
            return this.localeData.shortDaysOfWeek;
        }
        return this.localeData.narrowDaysOfWeek;
    }

    getYearName(date: Dayjs): string {
        return this._toDayjs(date).format('YYYY');
    }

    getFirstDayOfWeek(): number {
        return this.localeData.firstDayOfWeek;
    }

    getNumDaysInMonth(date: Dayjs): number {
        return this._toDayjs(date).daysInMonth();
    }

    clone(date: Dayjs): Dayjs {
        return date.clone();
    }

    createDate(year: number, month: number, date: number): Dayjs {
        return this._toDayjs()
            .set('year', year)
            .set('month', month)
            .set('date', date);
    }

    today(): Dayjs {
        return this._toDayjs();
    }

    parse(value: any, parseFormat: string): Dayjs | null {
        if (value && typeof value === 'string') {
            return this._toDayjs(value, parseFormat, this.locale);
        }
        return value ? this._toDayjs(value).locale(this.locale) : null;
    }

    format(date: Dayjs, displayFormat: string): string {
        if (!this.isValid(date)) {
            throw Error('DayjsDateAdapter: Cannot format invalid date.');
        }
        return date.locale(this.locale).format(displayFormat);
    }

    addCalendarYears(date: Dayjs, years: number): Dayjs {
        return date.add(years, 'year');
    }

    addCalendarMonths(date: Dayjs, months: number): Dayjs {
        return date.add(months, 'month');
    }

    addCalendarDays(date: Dayjs, days: number): Dayjs {
        return date.add(days, 'day');
    }

    toIso8601(date: Dayjs): string {
        return date.toISOString();
    }

    /**
     * Attempts to deserialize a value to a valid date object. This is different from parsing in that
     * deserialize should only accept non-ambiguous, locale-independent formats (e.g. a ISO 8601
     * string). The default implementation does not allow any deserialization, it simply checks that
     * the given value is already a valid date object or null. The `<mat-datepicker>` will call this
     * method on all of it's `@Input()` properties that accept dates. It is therefore possible to
     * support passing values from your backend directly to these properties by overriding this method
     * to also deserialize the format used by your backend.
     * @param value The value to be deserialized into a date object.
     * @returns The deserialized date object, either a valid date, null if the value can be
     *     deserialized into a null date (e.g. the empty string), or an invalid date.
     */
    override deserialize(
        value: string | null | Date | dayjs.Dayjs
    ): Dayjs | null {
        let date: dayjs.Dayjs | undefined;

        if (!value) {
            return null;
        }

        if (dayjs.isDayjs(value)) {
            return this.clone(value);
        }

        if (value instanceof Date) {
            date = this._toDayjs(value);
        }

        if (typeof value === 'string') {
            date = this._toDayjs(value);
        }

        if (!date || !this.isValid(date)) {
            return super.deserialize(value);
        }

        return this._toDayjs(date); // NOTE: Is this necessary since Dayjs is immutable and Moment was not?
    }

    isDateInstance(obj: any): boolean {
        return dayjs.isDayjs(obj);
    }

    isValid(date: Dayjs): boolean {
        return this._toDayjs(date).isValid();
    }

    invalid(): Dayjs {
        return this._toDayjs(null);
    }

    private _toDayjs(
        input?: any,
        format?: string,
        locale = this.dateLocale,
        useUtc = this.shouldUseUtc
    ): Dayjs {
        if (!useUtc) {
            const parsed = dayjs(input, format, locale, false);
            return dayjs(parsed.format());
        }

        const utcValue = dayjs.utc(input, format, false);

        return dayjs(utcValue.format());
    }

    private _initPlugins() {
        if (this.shouldUseUtc) {
            dayjs.extend(utc);
        }

        dayjs.extend(LocalizedFormat);
        dayjs.extend(customParseFormat);
        dayjs.extend(localeData);
    }
}
