import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StorageBin} from '../../domain/lvs-model';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {BaseApiService} from '../base-api-service/base-api.service';
import {FileService} from '../../core';
import {IHttpResult} from '../interfaces/http-result.interface';
import {IcsUserFeedbackService} from 'ics-core';
import {Inventory} from '../../modules/lvs/inventory/model';

type StorageBinDataMatrix = { storageBin: StorageBin, dataMatrixUrl: string };

@Injectable({
    providedIn: 'root'
})
export class StorageBinApiService extends BaseApiService<StorageBin> {

    constructor(http: HttpClient, userFeedback: IcsUserFeedbackService, private fileService: FileService) {
        super('StorageBin', 'lvs', http, userFeedback);
    }

    findStorageBins(articleGroupId: number): Observable<StorageBin[]> {
        return this._http.post<StorageBin[]>(this.buildEndpointUrl('FindStorageBins'), {articleGroupId})
            .pipe(
                catchError(err => this.handleHttpGetError(err) as Observable<StorageBin[]>)
            );
    }

    /**
     * Sends a http request to receive all articles stored on storage-bins
     */
    getStoredInventory(): Observable<Inventory[]> {
        console.time('FETCH_STORED_INVENTORY');
        return this._http.get<Inventory[]>(this.buildEndpointUrl('GetStoredInventory'))
            .pipe(
                catchError((err: HttpErrorResponse) => this.handleHttpGetError<Inventory>(err) as Observable<Inventory[]>),
                map((inventory: Inventory[]) => inventory.map(Inventory.buildFromEagerLoad)),
                tap(() => console.timeEnd('FETCH_STORED_INVENTORY'))
            );
    }

    getStoredInventoryByWarehouse(warehouseId: number): Observable<Inventory[]> {
        console.time('FETCH_STORED_INVENTORY_FOR_WAREHOUSE');
        return this._http.get<Inventory[]>(this.buildEndpointUrl('GetStoredInventory'), {params: {warehouseId: warehouseId}})
            .pipe(
                catchError((err: HttpErrorResponse) => this.handleHttpGetError<Inventory>(err) as Observable<Inventory[]>),
                map((inventory: Inventory[]) => inventory.map(Inventory.buildFromEagerLoad)),
                tap(() => console.timeEnd('FETCH_STORED_INVENTORY_FOR_WAREHOUSE'))
            );
    }



    /**
     * Sends a http-request asking for generating a pdf containing the given datamatrix codes.
     * @returns {@link IHttpResult} containing the base64 of the revived pdf file
     * @param value object containing all bins for pdf and their datamatrix codes and the current active company logo
     */
    generateDataMatrixPdf(value: {
        storageBinDataMatrix: StorageBinDataMatrix[],
        companyLogo: string
    }): Observable<IHttpResult<string>> {
        return this._http.post(this.buildEndpointUrl('GenerateDataMatrixPdf'), value, {
            observe: 'response',
            responseType: 'blob',
            headers: {'accept': 'application/pdf'}
        })
            .pipe(
                catchError(this.handleHttpErrorResult),
                map(this.buildHttpResult),
                mergeMap((res: IHttpResult) => {
                        return this.fileService.blobToBase64(res.responseValue)
                            .pipe(map((base64) => {
                                    return {...res, responseValue: base64}
                                })
                            )
                    }
                ),
            );
    }
}
