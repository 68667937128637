import {Pipe, PipeTransform} from '@angular/core';
import {User} from 'src/app/domain/core-model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserService} from '../../entity-state/user-service/user.service';

@Pipe({
    name: 'userName',
    standalone: true
})
export class UserNamePipe implements PipeTransform {

    constructor(
        private _users: UserService
    ) {
    }

    transform(value: string): Observable<string | null>;
    transform(value: User | string | undefined | null, short: 'short' | null = null): string | null | Observable<string | null> {
        if (!value) {
            return null;
        }

        if (typeof value === 'object') {
            return User.buildUserName(value, {shortenFirstName: !!short});
        }

        return this._users.getById({userId: value}, true)
            .pipe(
                map(u => u ? User.buildUserName(u, {shortenFirstName: !!short}) : null)
            );
    }
}
