import {Component, forwardRef, Optional, Self} from '@angular/core';
import {BaseFormFieldComponent} from '../base-form-field/base-form-field.component';
import {CustomControl} from '../model/custom-control.model';
import {Vehicle} from '../../domain/core-model';
import {NgControl} from '@angular/forms';
import {VehicletypeFormFieldComponent} from '../vehicletype-form-field/vehicletype-form-field.component';
import {VehicleService} from '../../entity-state/vehicle-service/vehicle.service';

@Component({
    selector: 'app-vehicle-form-field',
    templateUrl: '../base-form-field/base-form-field.component.html',
    styleUrls: ['../base-form-field/base-form-field.component.scss'],
    providers: [
        {provide: BaseFormFieldComponent, useExisting: forwardRef(() => VehicletypeFormFieldComponent)},
        {provide: CustomControl, useExisting: forwardRef(() => VehicleFormFieldComponent)}
    ]
})
export class VehicleFormFieldComponent extends BaseFormFieldComponent<Vehicle> {
    constructor(
        @Optional() @Self() public override ngControl: NgControl,
    ) {
        super(
            {providerType: VehicleService},
            {defaultLabel: 'Fahrzeug', key: 'vehicleId', displayKeys: ['vehicleId', "description"]},
            ngControl
        );
    }
}
