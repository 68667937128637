import {Component} from '@angular/core';
import {BaseButtonDirective} from '../base-button/base-button.directive';

@Component({
  selector: 'app-app-download-button',
  templateUrl: './app-download-button.component.html',
  styleUrls: ['./app-download-button.component.scss']
})
export class AppDownloadButtonComponent extends BaseButtonDirective {


}
