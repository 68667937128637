<div *ngIf="!editing" class="property-card" draggable="false" id="item">
    <span class="property-name">
        {{ columnName }}{{ required ? "*" : "" }}
    </span>

    <button *ngIf="!disabled && !restructuringProperty" [matMenuTriggerFor]="menu" class="more-actions" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
    </button>
</div>

<mat-form-field *ngIf="editing" [subscriptSizing]="'dynamic'" appearance="outline" cdkTrapFocus
                class="form-field property-name">
    <input #inputElement (keydown)="changeOnEnter($event)" [formControl]="columnNameControl" matInput required/>
</mat-form-field>

<mat-menu #menu="matMenu">
    <button *ngIf="columnValues" [matMenuTriggerFor]="valueMenu" mat-menu-item>
        <span>Werte</span>
    </button>
    <button (click)="startEditing()" [disabled]="disabled" mat-menu-item>
        <mat-icon>create</mat-icon>
        <span>Umbenennen</span>
    </button>
</mat-menu>

<mat-menu #valueMenu>
    <mat-list>
        <mat-list-item *ngFor="let value of columnValues">
            {{ value.view }}
        </mat-list-item>
    </mat-list>
</mat-menu>
