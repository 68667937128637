import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-unsaved-imports-dialog',
    templateUrl: './unsaved-imports-dialog.component.html',
    styleUrls: ['./unsaved-imports-dialog.component.scss']
})
export class UnsavedImportsDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<UnsavedImportsDialogComponent>
    ) {
    }

    confirm(): void {
        this.dialogRef.close('confirm');
    }

}
