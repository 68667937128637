<ng-container *ngTemplateOutlet="fab ? fabLayout : tableLayout"></ng-container>

<ng-template #tableLayout>

  <button (click)="initExport()" id="export-button" mat-icon-button>
    <mat-icon>save_alt</mat-icon>
  </button>

  <!--    <mat-form-field appearance="outline" color="none" id="export-selection" subscriptSizing="dynamic">-->
  <!--      <mat-select [(value)]="exportFormat">-->
  <!--        <mat-option *ngFor="let op of exportSelection" [value]="op.value">{{ op.view }}</mat-option>-->
  <!--      </mat-select>-->
  <!--    </mat-form-field>-->
</ng-template>

<ng-template #fabLayout>
  <button (click)="initExport()" color="primary" mat-fab>
    <mat-icon>download</mat-icon>
  </button>
</ng-template>
