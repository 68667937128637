import {IPropertyView} from '../interfaces';
import {InputTypeEnum} from '../enums';

export {
  isArrayProperty,
  isComplexProperty,
  isArray,
  toEntries,
  compare,
  searchAllProperties,
  sanitizeFormValue,
  isEmpty,
  insertAtIndex,
  filterObjectQuery,
  ArticleWeight,
  nullEmptyStrings,
  propertyIsNotMetaInformation
};

function isArrayProperty(property: IPropertyView, entity?: any): boolean {
  return (
    property?.input?.inputType === InputTypeEnum.Array ||
    property.isArray ||
    (entity &&
      entity[property.view] &&
      entity[property.view].constructor.name === 'Array') ||
    (entity &&
      entity[property.key] &&
      entity[property.key].constructor.name === 'Array')
  );
}

function isComplexProperty(prop: IPropertyView): boolean {
  return prop.input?.inputType === InputTypeEnum.Selection;
}

/**
 * Checks if a given value is an array by checking its constructor
 * @param value any variable which could be an array
 * @returns true if value is an array else returns false
 */
function isArray(value: any): boolean {
  return value?.constructor.name === 'Array';
}

/**
 * Turns an array into an iterable with which you can iterate index and value at once
 * @param listValue An array, which should be iterated with index and value
 * @returns an array of tuples. Each tuple holds [index, valueOfIndex]
 */
function toEntries<T>(listValue: T[]): (readonly [number, T])[] {
  return listValue.map((value, index) => {
    return [index, value] as const;
  });
}

/**
 * Decides whether a value [a] should be placed before or after a value [b] in a sorting
 * @param a first value to be compared
 * @param b value to be compared to [a]
 * @param isAsc decides the sorting order; true for ascending false for descending
 * @return 1 if [a] is bigger in sorting of [isAsc] order otherwise false
 */
function compare(a: string | number, b: string | number, isAsc: boolean): number {
  if (a === b) {
    return 0;
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

/**
 * Decides whether an object property belongs to the origin object information or is
 * a meta property like a created at flag or a modified at flag
 * @param property the property to be judged
 */
function propertyIsNotMetaInformation(property: string): boolean {
  const lowerCaseProp = property.toLowerCase();
  return !(
    lowerCaseProp.includes('created') || lowerCaseProp.includes('modified') || lowerCaseProp.includes('deleted') || lowerCaseProp === 'iscomplete'
  );
}

/**
 * Searches all properties of the given object, handles every property as string
 * @param value the object to be checked
 * @param query the filter string which all properties of value are checked against
 * @param searchMetaInformation set to true if information like 'createdAt' or 'createdBy' should also be searched else they get skipped; default is false
 * @returns true if the query was found in one of the objects properties
 */
function searchAllProperties(
  value: any,
  query: string,
  searchMetaInformation = false
): boolean {
  // when meta-properties shall also be searched we can just return every property
  const filteredProperties = Object.keys(value).filter((prop: string) =>
    searchMetaInformation ? true : propertyIsNotMetaInformation(prop)
  );

  return !!filteredProperties.find((key) =>
    value[key]?.toString()?.toLowerCase()?.includes(query?.toLowerCase())
  );
}

/**
 * clears a given object from all properties that are set to undefined, null or ''
 * leaves the original object unchanged
 * @typeParam T type of the given Object, makes strong typing possible by defining return type
 * @param value the object to be sanitized
 * @return Partial<T> returns an object that holds some properties of type T, all that evaluate to true
 */
function sanitizeFormValue<T>(value: Partial<T>): Partial<T> {
  const obj: any = value;
  Object.keys(value).forEach((key: string) => {
    if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
      delete obj[key];
    }
  });
  return obj;
}

function nullEmptyStrings<T = any>(value: T): T {
  const result: any = value;
  Object.keys(value as any).forEach(key => result[key] = result[key] === '' ? null : result[key]);
  return result;
}

/**
 * Checks whether a given object has properties or not
 * @param value the object to be checked
 * @return boolean true if the object has no properties; null and undefined are empty;
 */
function isEmpty(value: any | null | undefined): boolean {
  if (!value) {
    return false;
  }
  if (value) {
    return Object.keys(value).length === 0;
  }
  return true;
}

/**
 * Inserts an Object at the given Index into the array
 * @param arr outgoing array to be extended
 * @param newValues values to be inserted into the array
 * @param index index at which [newValue] is inserted
 * @return a copy of the original Array with [newValue] at [index]
 */
function insertAtIndex<T>(arr: T[], index: number, ...newValues: T[]): T[] {
  arr.join();
  for (const value of newValues) {
    arr.splice(index, 0, value);
  }
  return arr;
}

/**
 * Searches the given values for all values that fit the properties of the given query
 * Therefore checks all properties of query not of the values
 * also works with nested objects, by searching recursively up to any depth
 * @param values the array which shall be searched for all properties of query
 * @param query either type of values, own extended query type or a string; Gives all properties that are searched in values;
 * @return Array with all Objects from values that holds all properties from query
 */
function filterObjectQuery<T, Q = T>(values: T[], query: Partial<Q> | string): T[] {
  if (typeof query === 'string') {
    if (query.length === 0) {
      return values;
    } else {
      return values.filter((value: T) => {
        // check if one property in value can be found that matches the query
        return checkPropertySimple(value, query);
      });
    }
  } else {
    return values.filter((value: T) => {
      // check if one property in query can be found that not matches the values property
      return !Object.keys(query).find(
        (key) => !checkProperty((value as any)[key], (query as any)[key])
      );
    });
  }
}

/**
 * Checks if any property of the given value includes the query string.
 * Runs recursively on arrays and nested objects.
 * @param value the value whose properties shall be checked for matches
 * @param query a string that will be matched with the properties of the  value
 * @param checkMetaProperties defines whether meta-properties like createdAt or createdBy will be searched for a match; default is false
 * @returns A boolean indicating that any one property of value included the query string
 */
function checkPropertySimple(value: any, query: string, checkMetaProperties = false): boolean {
  if (!value) {
    return false;
  }
  return Object.keys(value).filter(k => checkMetaProperties ? true : propertyIsNotMetaInformation(k)).some(
    (key) => {
      if (!value[key]) {
        return false;
      }
      if (isArray(value[key])) {
        const arrayProperty: any[] = value[key];
        for (const element of arrayProperty) {
          return checkPropertySimple(element, query);
        }
        return false;
      } else if (typeof value[key] === 'object') {
        return checkPropertySimple(value[key], query);
      }
      return value[key]?.toString()?.toLowerCase()?.includes(query?.toLowerCase());
    });
}

/**
 * Checks if a given value matches the expected value
 * also works with nested objects, by searching recursively up to any depth
 * @param propertyValue the value of an object that shall be checked against a given check
 * @param checkValue the value propertyValue is checked against
 * @return boolean true if the property was successfully matched and typing of values is equal else false
 */
function checkProperty(
  propertyValue: string | number | boolean | object | any,
  checkValue: string | number | boolean | object | any
): boolean {
  if (typeof propertyValue === 'string' && typeof checkValue === 'string') {
    // check if parts are included
    return propertyValue?.toLowerCase()?.includes(checkValue?.toLowerCase());
  } else if
  (typeof propertyValue === 'number' && typeof checkValue === 'number' || (!Number.isNaN(parseFloat(propertyValue)))) {
    // exact check for numbers and booleans
    return parseFloat(propertyValue) === parseFloat(checkValue);
  } else if (typeof propertyValue === 'boolean' && typeof checkValue === 'boolean') {
    return propertyValue === checkValue;
  } else if (isArray(propertyValue)) {
    for (const element of propertyValue) {
      if (checkProperty(element, checkValue[0])) {
        return true;
      }
    }
    return false;
  } else if (
    typeof propertyValue === 'object' &&
    typeof checkValue === 'object'
  ) {
    return (
      Object.keys(checkValue).filter((key) => {
        if (
          typeof checkValue?.[key] === 'object' &&
          typeof propertyValue?.[key] === 'object'
        ) {
          // if both are objects search recursively to any depth
          return !checkProperty(propertyValue?.[key], checkValue?.[key]);
        }
        // check recursively all properties of nested objects as strings
        return !checkProperty(
          String(propertyValue?.[key]),
          String(checkValue?.[key])
        );
      }).length === 0
    );
  } else {
    // throw Error('Error Filtering Objects: Different typing of query-property and object-property');
    return false;
  }
}


class ArticleWeight {
  static get KILO_FACTOR(): number {
    return 1_000;
  }

  /**
   * Converts the given normalized article weight into kilograms
   * @param articleWeightNormalized to convert into kilograms
   * @returns the given normalized article weight as kilograms
   */
  static toKilogram(articleWeightNormalized: number): number {
    return articleWeightNormalized / ArticleWeight.KILO_FACTOR;
  }

  /**
   * Converts the given article weight in kilos into a normalized article weight
   * @param articleWeightInKilograms to convert into normalized article weight
   * @returns the given article weight in kilos as normalized article weight
   */
  static fromKilogram(articleWeightInKilograms: number): number {
    return Math.ceil(articleWeightInKilograms * ArticleWeight.KILO_FACTOR);
  }
}
