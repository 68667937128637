import {NgModule} from '@angular/core';
import {MatDividerModule} from "@angular/material/divider";
import {IcsThemeModule} from "ics-theme";
import {IcsNavModule} from 'ics-core';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
    declarations: [],
    imports: [
        MatDividerModule,
        IcsThemeModule,
        MatButtonModule,
        IcsNavModule
    ]
})
export class SettingsModule {
}
