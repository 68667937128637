import {
  ArticleGroupRequisition,
  ArticleGroupRequisitionStorageBin,
  ArticleRequisition,
  StorageArea,
  StorageBinArticle
} from 'src/app/domain/lvs-model';
import {Audit, HandlingUnitArticle, Setting} from 'src/app/domain/core-model';
import {Initiator} from 'src/app/domain/tms-model';
import {LogEntry} from 'src/app/logging';
import {SuperTask} from 'src/app/domain/tms-model/supertask.model';
import {Vehicle} from 'src/app/domain/core-model/vehicle.model';
import {Vehicletype} from 'src/app/domain/core-model/vehicletype.model';
import {ApiConfig} from '../api/interfaces/api-config.interface';

export {
  ApiConfigurations
}

const CoreApis: ApiConfig[] = [
  {
    type: Audit,
    module: 'core',
    path: 'Audit',
  },
  {
    type: HandlingUnitArticle,
    module: 'core',
    path: 'HandlingUnitArticle'
  },
  {
    type: LogEntry,
    module: 'core',
    path: 'AppLogEntry'
  },
  {
    type: Setting,
    module: 'core',
    path: 'Setting'
  },
  {
    type: Vehicle,
    module: 'core',
    path: 'Vehicle'
  },
  {
    type: Vehicletype,
    module: 'core',
    path: 'Vehicletype'
  }
];

const TmsApis: ApiConfig[] = [
  {
    type: Initiator,
    module: 'tms',
    path: 'Initiator'
  },
  {
    type: SuperTask,
    module: 'tms',
    path: 'SuperTask'
  }
];

const LvsApis: ApiConfig[] = [
  {
    type: ArticleGroupRequisition,
    module: 'lvs',
    path: 'ArticleGroupRequisition'
  },
  {
    type: ArticleGroupRequisitionStorageBin,
    module: 'lvs',
    path: 'ArticleGroupRequisitionStorageBin'
  },
  {
    type: ArticleRequisition,
    module: 'lvs',
    path: 'ArticleRequisition'
  },
  {
    type: StorageArea,
    module: 'lvs',
    path: 'StorageArea'
  },
  {
    type: StorageBinArticle,
    module: 'lvs',
    path: 'StorageBinArticle'
  },
];

const ApiConfigurations: ApiConfig[] = [
  ...CoreApis,
  ...LvsApis,
  ...TmsApis
];
