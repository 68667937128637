import 'reflect-metadata';
import {EXPORTABLE_DECORATOR_KEY} from './key';

type Class = Function;


/**
 * Definition of an exportable class
 */
interface Exportable {
    /**
     * Views descriptive name of the class. Will be used as filename
     */
    name: string;

    /**
     * the name of the main writing property. Can be used if the data of a class is mainly
     * provided by one of its properties which should be a linked class. In the cas of this property
     * provided the export service will take every instance of the main class and will create a list of
     * new objects representing the info of the main class appended with one instance of the subclass
     *
     * IMPORTANT: the property linked to this has to be a list value
     */
    writingProperty?: string;

    usesCustomExport?: boolean;
}

/**
 * Marks a class as exportable for ExportModule
 * @decorator
 * @param {Exportable} config of the exportable class
 * @returns
 */
function exportable(config: Exportable) {
    return function (constructor: Class) {
        Reflect.defineMetadata(EXPORTABLE_DECORATOR_KEY, config, constructor);
    }
}


export {
    Exportable as ExportableConfig,
    exportable as Exportable,
};
