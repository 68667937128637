import {ImportSettings, WebAppSettings} from "src/app/settings/model/settings.enum"

export {
  SettingsChangeEvent
}

class SettingsChangeEvent {
  public settingId: WebAppSettings | ImportSettings;
  public oldValue: string[];
  public newValue: string[];

  constructor(value: SettingsChangeEvent) {
    this.settingId = value.settingId;
    this.oldValue = value.oldValue;
    this.newValue = value.newValue;
  }

}
