import {Type} from '@angular/core';
import {BaseFormFieldComponent} from '../base-form-field/base-form-field.component';
import {IState} from '../../entity-state/model/state.interface';
import {EntityKey} from '../../domain/utility-types';
import {BaseEntity} from "../../domain";

export {
  BaseFormFieldProviders,
  BaseFormFieldOptions,
  NoStateProviderError
}

class NoStateProviderError extends Error {
  constructor() {
    super(`${BaseFormFieldComponent.name} received no provider for its state!`);
  }
}

class BaseFormFieldProviders<T extends BaseEntity> {
  public stateProvider?: IState<T> | null = null;
  public providerType?: Type<IState<T>> | null = null;

  constructor(values: Partial<BaseFormFieldProviders<T>>) {
    if (values.stateProvider) {
      this.stateProvider = values.stateProvider;
    }

    if (values.providerType) {
      this.providerType = values.providerType;
    }
  }
}

class BaseFormFieldOptions<T extends BaseEntity> {
  constructor(
    public readonly defaultLabel: string,
    public readonly key: keyof EntityKey<T>,
    public readonly displayKeys: [keyof T] | [keyof T, keyof T],
    public readonly fallbackDisplay?: keyof T
  ) {
  }
}
