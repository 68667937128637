import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, retry, switchMap, timer} from 'rxjs';
import {User} from '../../domain/core-model';
import {BaseApiService} from '../base-api-service/base-api.service';
import {IHttpResult} from '../interfaces/http-result.interface';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {FileService} from '../../core';
import {UserRole} from '../../roles';
import {IcsUserFeedbackService} from 'ics-core';

@Injectable({
    providedIn: 'root'
})
export class UserApiService extends BaseApiService<User> {

    constructor(http: HttpClient, userFeedback: IcsUserFeedbackService, private fileService: FileService) {
        super('User', 'core', http, userFeedback);
    }

    getReport(date?: string): Observable<IHttpResult<string>> {
        const params = date ? this.createIdParams({date}) : undefined;
        return this._http.get(this.buildEndpointUrl('GetReport'), {
            observe: 'response',
            responseType: 'blob',
            headers: {'accept': 'application/pdf'},
            params
        }).pipe(
            catchError(this.handleHttpErrorResult),
            map(this.buildHttpResult),
            mergeMap((res: IHttpResult) => {
                    return this.fileService.blobToBase64(res.responseValue)
                        .pipe(map((base64) => {
                                return {...res, responseValue: base64}
                            })
                        )
                }
            ),
        );
    }

    getUserRoles(): Observable<UserRole[]> {
        return this._http.get<UserRole[]>(this.buildEndpointUrl('GetRoles'))
            .pipe(
                retry({
                    count: 5, delay: (error, retryCount) => {
                        return this._userFeedback.show({
                            type: 'error',
                            message: 'ui.user.feedback.error.user.roles.pending'
                        }).pipe(
                            switchMap(() => timer(retryCount * 1000))
                        );
                    }
                }),
                catchError(err => {
                    return this._userFeedback.show({
                        type: 'error',
                        message: 'ui.user.feedback.error.user.roles.not.loaded'
                    }).pipe(
                        map(() => new Array<UserRole>())
                    );
                })
            );
    }
}

