import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse, HttpStatusCode} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseApiService} from '../../../../api/base-api-service/base-api.service';
import {IHttpResult} from '../../../../api/interfaces/http-result.interface';
import {IcsUserFeedbackService} from 'ics-core';
import {TransportTask, TransportTaskId} from '../model/transport-task.model';
import {asSetArray} from '../../../../util';
import {HandlingUnitId, WarehouseId} from '../../../../domain/core-model';

@Injectable({
    providedIn: 'root'
})
export class TransportTaskApiService extends BaseApiService<TransportTask> {

    constructor(http: HttpClient, userFeedback: IcsUserFeedbackService) {
        super('TransportTask', 'tms', http, userFeedback);
    }

    // TODO: Type this to IHttpResult
    getTaskImage(transportTask: TransportTask): Observable<Blob | null | undefined> {
        const body = {transportTaskId: transportTask.transportTaskId};
        return this._http.post(this.buildEndpointUrl('GetImage'), body, {
            responseType: 'blob',
            observe: 'response'
        })
            .pipe(
                catchError((err: HttpErrorResponse) => err.status === HttpStatusCode.NotFound ? of(undefined) : of(null)),
                map((res: HttpResponse<Blob> | null | undefined) => {
                    if (res?.status === HttpStatusCode.Ok) {
                        return res.body;
                    }
                    return null;
                })
            );
    }

    removeOperatorAssignment(transportTasks: TransportTaskId[]): Observable<boolean> {
        return this._http.post<void | null>(
            this.buildEndpointUrl('RemoveAssignement'),
            asSetArray(transportTasks),
            {observe: 'response'})
            .pipe(
                catchError(this.handleHttpErrorResult),
                map((res) => {
                    if (res instanceof IHttpResult) {
                        return false;
                    }

                    return true;
                })
            )
    }

    createTaskAndFinishHu$(warehouseId: NonNullable<WarehouseId>, handlingUnitId: NonNullable<HandlingUnitId>) {
        return this._http.post<TransportTask | null>(this.buildEndpointUrl('CreateTaskAndFinishHU'), {
            huIdDto: {
                handlingUnitId: handlingUnitId
            },
            warehouseIdDto: {
                warehouseId: warehouseId
            }
        }, {observe: 'response'}).pipe(
            catchError(this.handleHttpErrorResult),
            map(this.buildHttpResult)
        );
    }
}
