import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RouteQueryParams} from '../../constants';
import {StorageService} from '../../core';
import {isEmpty} from '../../domain/functions';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private static QUERY_PARAM_IGNORE_LIST: string[] = Object.values(RouteQueryParams);
  private static STORAGE_KEY = "FILTER_STATES";

  private _states = new Map<string, { [key: string]: string }>();

  constructor(
    private _activeRoute: ActivatedRoute,
    private _router: Router,
    private _storage: StorageService
  ) {
    const fromStorage = this._storage.get(FilterService.STORAGE_KEY);

    if (fromStorage && Array.isArray(fromStorage)) {
      this._states = new Map<string, { [key: string]: string }>(fromStorage);
    }
  }

  get currentFilterKey() {
    return this._urlToStorageKey(this._router.url);
  }

  hasState(): boolean {
    return this._hasQueryParams(this._activeRoute) || this._states.has(this.currentFilterKey);
  }

  getState(): { [key: string]: string } | undefined {
    if (this._hasQueryParams(this._activeRoute)) {
      return this._activeRoute.snapshot.queryParams;
    }

    return this._states.get(this.currentFilterKey);
  }

  addFilterState(state: any): void {
    if (isEmpty(state)) {
      return;
    }
    this._states.set(this.currentFilterKey, state);
    this._updateStorage();
  }

  clearStorage(all = false): void {
    if (all) {
      this._storage.remove(FilterService.STORAGE_KEY);
      return;
    }

    this._states.delete(this.currentFilterKey);
    this._updateStorage();
    return;
  }

  private _updateStorage() {
    this._storage.set(FilterService.STORAGE_KEY, Array.from(this._states.entries()));
  }

  private _hasQueryParams(path: ActivatedRoute): boolean {
    return path.snapshot.queryParamMap.keys.filter(c => FilterService.QUERY_PARAM_IGNORE_LIST.includes(c)).length > 0;
  }

  private _urlToStorageKey(url: string) {
    const queryIndex = url.indexOf('?');
    const lastIndex = queryIndex >= 0 ? queryIndex : url.length;
    return url.slice(0, lastIndex + 1);
  }
}
