<div class="layout-row">
  <div class="array-input-container layout-row" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
    <ng-container *ngFor="let control of formArray.controls; index as i">
      <mat-form-field [appearance]="appearance" *ngIf="inputType !== 'async-selection'; else selections">
        <mat-label>{{ label }}</mat-label>
        <input matInput [formControl]="formArray.controls[i]" (input)="_handleInput()" [type]="inputType" />
      </mat-form-field>

      <ng-template #selections>
        <app-async-select
          [formControl]="formArray.controls[i]"
          [optionResolver]="property.input.selectionResolver"
          [propertyConfig]="getProperty()"
          [appearance]="appearance"
          style="width: 100%"
        ></app-async-select>
      </ng-template>
    </ng-container>
  </div>

  <div class="array-control-buttons">
    <button mat-icon-button color="accent" (click)="onAdd()" *ngIf="!maxAmount || formArray.length < maxAmount">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-icon-button color="warn" (click)="onRemove()" *ngIf="formArray.length > 1">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
</div>
