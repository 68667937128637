import {ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import {DialogMessage, DialogPurpose} from '../../domain/enums';
import {UntypedFormBuilder} from '@angular/forms';
import {IPropertyView, ManagementDialogData} from '../../domain/interfaces';
import {Router} from '@angular/router';
import {DialogTitles} from '../../constants';
import {GenericFormComponent} from '../generic-form/generic-form.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-generic-dialog',
    templateUrl: './generic-dialog.component.html',
    styleUrls: ['./generic-dialog.component.scss'],
    // needed to change strategy, so error hints wont crash
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericDialogComponent implements OnInit {

    @ViewChild(GenericFormComponent) form!: GenericFormComponent;
    readonly entity: any;
    purpose: DialogPurpose;
    readonly properties: IPropertyView[];
    title = '';
    action = 'display';
    submit: EventEmitter<any> = new EventEmitter<any>();
    disabled = false;


    constructor(
        public _dialogRef: MatDialogRef<GenericDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ManagementDialogData<any>,
        private _fb: UntypedFormBuilder,
        private _router: Router
    ) {
        // better handling with internal properties
        this.entity = this.data.entity;
        this.purpose = this.data.purpose;
        this.properties = this.data.typeConfig.properties;
        if (this.data.leadTitle) {
            this.title = this.data.leadTitle;
        }
    }

    ngOnInit(): void {
        const module = this._router.url.split('/')[2].replace(/-/g, '');
        this.title = this.title ? this.title : (DialogTitles as any)[module + 's'];
        if (this.purpose != DialogPurpose.DISPLAY) {
            this.action = this.purpose === DialogPurpose.CREATE ? 'erstellten' : 'bearbeiten';
        } else {
            this.action = ''
            this.disabled = true
        }
    }

    close() {
        this._dialogRef.close(DialogMessage.CLOSE);
    }

    onAbort(): void {
        this._dialogRef.close(DialogMessage.ABORT);
    }

    onSubmit(value: any): void {
        this.submit.emit(value);
    }
}
