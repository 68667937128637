import {ITypeConfig} from "../interfaces";
import {InputTypeEnum} from "../enums";
import {Validators} from "@angular/forms";
import {Vehicletype} from "./vehicletype.model";
import {BaseEntity} from '../base-entity.model';

export class Vehicle extends BaseEntity {
  constructor(
    public vehicleId: string,
    public description: string,
    public vehicletypeId: string,
    public vehicletypeObj?: Vehicletype
  ) {
    super();
  }
}


export const VehicleConfig: ITypeConfig<Vehicle> = {
  properties: [
    {
      key: 'vehicleId',
      view: 'Fahrzeugkennung',
      keyProperty: true,
      input: {
        inputType: InputTypeEnum.Text,
        required: true,
        isSettable: true,
        validators: [Validators.maxLength(50)]
      }
    },
    {
      key: 'description',
      view: 'Beschreibung',
      input: {
        inputType: InputTypeEnum.Text,
        required: true,
        isSettable: true,
        validators: [Validators.maxLength(50)]
      }
    },
    {
      key: 'vehicletypeId',
      view: 'Fahrzeugtyp',
      input: {
        inputType: InputTypeEnum.Text,
        required: true,
        isSettable: true,
        validators: [Validators.maxLength(50)]
      }
    },
  ],
  keyNames: [
    'vehicleId', 'description', 'vehicletypeId'
  ]
};
