import {InjectionToken} from "@angular/core";
import {Observable} from 'rxjs';

export { ImportDefinitionsProvider, IMPORT_DEFINITIONS, NamedColumns }

// named injection-token for col-def-provider
const IMPORT_DEFINITIONS = new InjectionToken<ImportDefinitionsProvider>('ImportDefinitionsProvider');

type NamedColumns = { [key: string]: string };

/**
 * Grants access to the column-order stored in a system.
 * Abstracts from Import-Module
 */
interface ImportDefinitionsProvider {
  /**
   * Reads the configuration column order from the system and provides it as observable
   * @param {string} columnsId configured ID of the class
   */
  getColumnsInOrder(columnsId: string): Observable<string[]>;

  /**
   *  Read the current configuration of user display column-names from the system
   *
   * @param columnsId
   */
  getColumnNames(columnsId: string): Observable<NamedColumns>;

  /**
   * Reads the current set of import range limiters from the system;
   *
   * @returns a observable containing a tuple of delimiters or null if non are set
   */
  getImportRangeSymbols(): Observable<[string | null, string | null]>;
}
