import {Directive, ElementRef, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Directive({
    selector: '[appBaseButton]',
    host: {
        '[attr.disabled]': 'buttonDisabled || null',
    }
})
export class BaseButtonDirective implements OnInit, OnDestroy {
    @Input('disabled')
    set buttonDisabled(value: any) {
        this._buttonDisabled = coerceBooleanProperty(value);
    }

    get buttonDisabled(): boolean {
        return this._buttonDisabled;
    }

    @Input('matTooltip') tooltip: string = '';

    @Input() label?: string;

    protected get element() {
        return this._elementRef.nativeElement as HTMLElement;
    }

    protected get matButtonWrapper(): Element | null | undefined {
        return this.element?.querySelector('.mat-button-wrapper');
    }

    protected _buttonDisabled = false;

    constructor(
        protected _elementRef: ElementRef,
        protected _ngZone: NgZone
    ) {
    }

    ngOnInit(): void {
        this._ngZone.runOutsideAngular(() => {
            // stopping events emitted from the content of a mat-button
            // this is needed for a disabled button to do nothing on click
            this.matButtonWrapper?.addEventListener('click', this.preventDisabledEvents);
        });
    }


    ngOnDestroy() {
        this._ngZone.runOutsideAngular(() => {
            this.matButtonWrapper?.removeEventListener('click', this.preventDisabledEvents)
        });
    }

    protected preventDisabledEvents = (event: Event): void => {
        // A disabled button shouldn't apply any actions
        if (this.buttonDisabled) {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
        }
    };
}

