import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {SettingsService} from '../settings';
import {NavigationHistoryService} from './navigation-history/navigation-history.service';
import {PwaService} from './pwa-service/pwa.service';
import {DateTimeService} from './date-time-service/date-time.service';
import {RoleService} from "../roles";
import {IconService} from "./icon-service/icon.service";
import {StorageService} from './storage/storage.service';

@NgModule({
    providers: [
        CookieService,
        SettingsService,
        NavigationHistoryService,
        PwaService,
        DateTimeService,
        RoleService,
        IconService,
        StorageService
    ],
})
export class CoreModule {
    constructor(
        @Optional() @SkipSelf() parentModule: CoreModule,
        private _settings: SettingsService,
        private _icons: IconService,
        private _pwa: PwaService
    ) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it only once in AppModule'
            );
        }
    }
}
