<div class="transport-task-state-select-container" [attr.aria-labelledby]="_formField?.getLabelId()" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
  <mat-form-field [appearance]="appearance" [color]="color">
    <mat-label>{{ label ?? "Status" }}</mat-label>
    <mat-select [required]="required" [formControl]="control" class="transport-task-state-select-element" [aria-label]="label ?? 'Status'" (selectionChange)="_handleInput()">
      <ng-container *ngIf="!required">
        <mat-option [value]="null">
          <span>--</span>
        </mat-option>
      </ng-container>

      <mat-option *ngFor="let state of stateOptions" [value]="state.value">
        <span>{{ state.view }}</span>
      </mat-option>
    </mat-select>

    <mat-progress-spinner mode="indeterminate" *ngIf="!userAccessLoaded" color="accent" diameter="16" matSuffix></mat-progress-spinner>

    <mat-error>
      <app-form-errors [control]="control" [entityName]="label ?? 'Status'"></app-form-errors>
    </mat-error>
  </mat-form-field>
</div>
