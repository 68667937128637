import {Component, Input} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgClass} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {PriorityLevel} from '../../../../domain/tms-model';

@Component({
    selector: 'app-priority',
    templateUrl: './priority.component.html',
    styleUrls: ['./priority.component.scss'],
    imports: [
        MatTooltipModule,
        NgClass,
        TranslateModule
    ],
    standalone: true
})
export class PriorityComponent {

    protected priority = PriorityLevel.NoPriority;
    protected readonly PriorityLevel = PriorityLevel;

    @Input()
    set priorityLevel(priorityLevel: PriorityLevel | null | undefined) {
        this.priority = priorityLevel ?? PriorityLevel.NoPriority;
    }
}
