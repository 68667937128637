<ng-container class="async-select-container">
  <mat-form-field [appearance]="appearance" style="width: 100%">
    <mat-label>{{ propertyConfig.view }}</mat-label>
    <input
      matInput
      (focusin)="onFocusIn($event)"
      (focusout)="onFocusOut($event)"
      (input)="_handleInput()"
      (click)="autoCompleteTrigger.openPanel()"
      [formControl]="control"
      [matAutocomplete]="autocomplete"
      [required]="required"
      class="async-select-input"
      type="text"
      #inputElement
    />

    <cdk-virtual-scroll-viewport [itemSize]="10" [minBufferPx]="200" [maxBufferPx]="400">
      <mat-autocomplete autoActiveFirstOption #autocomplete [displayWith]="displayConfig.bind(this)" (optionSelected)="_handleInput()">
        <mat-option *cdkVirtualFor="let option of filteredOptions | async" [value]="option">
          <!-- Displaying keys that belong together and are displayed in that manner -->
          <ng-container *ngIf="propertyConfig.input.displayMultipleProperties; else displayBoth; let multipleDisplay">
            <span *ngIf="option[multipleDisplay.firstDisplay] && option[multipleDisplay.secondDisplay]; else singleDisplay">
              {{ option[multipleDisplay.firstDisplay] }}, {{ option[multipleDisplay.secondDisplay] }}
            </span>
          </ng-container>

          <!-- Displaying independent keys -->
          <ng-template #displayBoth>
            <span *ngIf="propertyConfig.input.displayBoth; else singleDisplay">
              <span>
                {{ option[propertyConfig.key] ? option[propertyConfig.key] : option[propertyConfig.input.altSelectionName] }}
              </span>
              |
              <small>{{ option[propertyConfig.input.altSelectionDisplay] }}</small>
            </span>
          </ng-template>

          <!-- Display only one value -->
          <ng-template #singleDisplay>
            <span *ngIf="propertyConfig.input.altSelectionDisplay && option[propertyConfig.input.altSelectionDisplay]; else displayRaw">
              {{ option[propertyConfig.input.altSelectionDisplay] }}
            </span>
          </ng-template>

          <!-- Displaying the raw value raed from key/altKey-->
          <ng-template #displayRaw>
            <span>{{ option[propertyConfig.key] ? option[propertyConfig.key] : option[propertyConfig.input.altSelectionName] }}</span>
          </ng-template>
        </mat-option>
      </mat-autocomplete>
    </cdk-virtual-scroll-viewport>

    <mat-error>
      <app-form-errors [control]="ngControl"></app-form-errors>
    </mat-error>

    <mat-spinner matSuffix color="accent" [diameter]="16" *ngIf="optionsLoading | async"></mat-spinner>
  </mat-form-field>
</ng-container>
