import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Article, User, Warehouse} from '../core-model';
import {ArticleGroup, Initiator, PriorityLevel, TransportPositionState} from '../tms-model';
import {CustomValidators} from '../../form-fields';
import * as dayjs from 'dayjs';

export {
    TransportPositionForm,
    TransportPositionFormGroup
}

class TransportPositionFormGroup extends FormGroup<TransportPositionForm> {
    constructor() {
        super(new TransportPositionForm());
    }
}

class TransportPositionForm {
    articleId = new FormControl<Article | string | null>(null, [Validators.required]);
    articleCount = new FormControl<number>(1, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(0.1)]
    });
    articleGroupId = new FormControl<ArticleGroup | string | number | null>(null);
    jobNumber = new FormControl<string | null>(null, [Validators.minLength(3)]);
    bookingNumber = new FormControl<string | null>(null, [Validators.minLength(3)]);
    operator = new FormControl<User | string | null>(null, [CustomValidators.objectSelectedValidator]);
    priorityLevel = new FormControl<PriorityLevel>(PriorityLevel.NoPriority, {
        nonNullable: true,
        validators: [Validators.required]
    });
    transportState = new FormControl<TransportPositionState>(TransportPositionState.Created, {
        nonNullable: true,
        validators: [Validators.required]
    });
    articleSourceId = new FormControl<Warehouse | number | null>(null, [CustomValidators.objectSelectedValidator]);
    destination = new FormControl<Warehouse | number | null>(null, {
        nonNullable: true,
        validators: [Validators.required, CustomValidators.objectSelectedValidator]
    });
    toBeDoneBy = new FormControl<string>(dayjs().toISOString());
    orderedBy = new FormControl<string>(dayjs().toISOString());
    initiator = new FormControl<Initiator | number | null>(null, {nonNullable: true});
    additionalInformation = new FormControl<string[]>([]);
}
