import {ExportMap, IHeaderLineParser, StringMap} from './object-parser.interface';
import {DestructuringParser} from './destructuring.parser';
import {LinkObjectParser} from './link-object.parser';

export {
    HeaderLineParser,
    DestructuringMarker
}

class DestructuringMarker {

    private static readonly _template = '##DESTRUCT-$##'

    static create(forProperty: string) {
        return this._template.replace('$', forProperty);
    }
}

class HeaderLineParser implements IHeaderLineParser {

    private readonly linkParser: LinkObjectParser;

    constructor(
        private _columns: ExportMap,
    ) {
        this.linkParser = new LinkObjectParser(Object.values(_columns).filter(c => c.link));
    }

    /**
     * @inheritDoc
     */
    produceHeaderLine(): string[] {
        const sortedColumns = this._sortByDecoratorIndex();

        return sortedColumns.reduce((acc, [_, column]) => {

            if (!column.view && !column.link && !column.destruct) {
                console.warn(`Could not create headline for column ${column.propertyKey!}. Column is skipped during export.`);
                return acc;
            }

            if (column.link) {
                return [...acc, ...this.linkParser.produceHeaderLine(column.propertyKey)];
            }

            if (column.destruct) {
                return [...acc, DestructuringMarker.create(column.propertyKey)];
            }

            return [...acc, column.view];


        }, new Array<string>());
    }

    insertDestructedHeaders(headers: string[], values: StringMap[]): string[] {
        let result = [...headers];
        const destructs = Object.values(this._columns).filter((c) => c.destruct);

        const parser = new DestructuringParser(destructs);

        const headerMap = parser.buildDestructuringHeaders(values);

        for (const [prop, destructuredHeaders] of headerMap.entries()) {
            const targetIndex = result.indexOf(DestructuringMarker.create(prop));

            result = [...result.slice(0, targetIndex), ...destructuredHeaders, ...result.slice(targetIndex + 1, result.length)];
        }

        return result;
    }

    private _sortByDecoratorIndex() {
        return Object.entries(this._columns).sort(([_m, firstCol], [__, secondCol]) => {
            return firstCol.index! > secondCol.index! ? 1 : -1;
        });
    }
}
