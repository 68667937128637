import {Component, ElementRef, NgZone} from '@angular/core';
import {BaseButtonDirective} from '../base-button/base-button.directive';

@Component({
  selector: 'app-group-button',
  templateUrl: './group-button.component.html',
  styleUrls: ['./group-button.component.scss']
})
export class GroupButtonComponent extends BaseButtonDirective {

  constructor(elementRef: ElementRef, ngZone: NgZone) {
    super(elementRef, ngZone);
  }

}
