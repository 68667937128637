import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {NamedColumns} from '../model/column-definitions-provider.interface';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-import-property',
  templateUrl: './import-property.component.html',
  styleUrls: ['./import-property.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportPropertyComponent implements OnInit {

  /**
   * The internal name of the column
   */
  @Input() columnKey?: string;

  /**
   * the view of the column displayed to the user
   */
  @Input() columnName?: string;

  /**
   * if the property is required for an import
   */
  @Input() required = false;

  /**
   * the values that are possible to select for the property
   */
  @Input() columnValues: { view: string, value: any }[] = [];


  @Input() restructuringProperty: boolean = false;

  /**
   * Whether the user can currently edit the namings of column or not
   */
  @Input()
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  /**
   * Event emitted after the user changed the description of a column
   */
  @Output() descriptionChange: EventEmitter<NamedColumns> = new EventEmitter();

  // the element that contains the matInput, needed to focus it on start editing
  @ViewChild('inputElement', { static: false, read: ElementRef }) formFieldElement!: ElementRef;

  // form-control to have fallback on edit abort
  columnNameControl!: UntypedFormControl;

  // whether the user is currently editing or not
  editing = false;

  // disables the menu for editing
  private _disabled = false;

  constructor(
    private _fb: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.columnNameControl = this._fb.control(this.columnName);
  }

  /**
   * Shows the form-field to give the user the possibility to change the column name
   */
  startEditing(): void {
    this.editing = true;
    // timeout to give change-detection the chance to find the element
    setTimeout(() => this.formFieldElement.nativeElement.focus());
  }

  /**
   * Handles to set the name when user hits the enter key
   * @param {KeyboardEvent} event to check the hit key
   */
  changeOnEnter(event: KeyboardEvent): void {
    if (event.key.toUpperCase() === 'ESCAPE') {
      this.editing = false;
    }
    if (event.key.toUpperCase() === 'ENTER') {
      this.editing = false;
      this.descriptionChange.emit({ [this.columnKey!]: this.columnNameControl.value });
      this.columnName = this.columnNameControl.value;
    }
  }
}
