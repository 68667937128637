<div [class.scrollable]="!!maxHeight" [style.max-height]="maxHeight" class="table-wrapper" id="table-wrapper">
    <table #privileged="privileged" (matSortChange)="onSort($event)" [dataSource]="tableDataSource"
           [disableUnlessPrivileged]="privilegeLevel"
           [fixedLayout]="!!fixedLayout"
           mat-table matSort>
        <!-- generic select row -->
        <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell>
                <mat-checkbox
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [disabled]="disabled || blockSelection || selectableValues.length === 0"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        matTooltip="Alle auswählen"
                >
                </mat-checkbox>
            </th>
            <td *matCellDef="let value; let i = index" mat-cell>
                <mat-checkbox
                        (change)="$event ? selectPosition(value, i) : deselectPosition(i)"
                        (click)="$event.stopPropagation()"
                        [checked]="isSelected(i)"
                        [disabled]="!!(disabled || blockSelection || blockedSelectionIndexes?.includes(i))"
                        [matTooltip]="selectionTooltip ? selectionTooltip(value) : ''"
                >
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- extern column injection -->
        <ng-content></ng-content>

        <!-- Generation of raw data columns for unprovided cols -->
        <ng-container *ngFor="let col of dataColumnNames; let i = index">
            <ng-container *ngIf="!columnIsProvided(col)" [matColumnDef]="col">
                <th *matHeaderCellDef mat-header-cell
                    mat-sort-header>{{ columnNames && columnNames?.[col] ? columnNames?.[col] : col }}
                </th>
                <td *matCellDef="let value; let i = index" mat-cell>
                    <span>{{ value?.[col] ?? '--' }}</span>
                </td>
            </ng-container>
        </ng-container>

        <!-- default action columns -->
        <ng-container *ngIf="!columnIsProvided('edit')" matColumnDef="edit">
            <th *matHeaderCellDef i18n mat-header-cell>
                {{ 'ui.common.labels.edit' | translate }}
            </th>
            <td *matCellDef="let value; let i = index" mat-cell>
                <button (click)="onEdit(value, i)" [disabled]="!!privilegeLevel && privileged.isDisabled" color="accent"
                        data-cy="table-edit-row" mat-icon-button>
                    <mat-icon>create</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container *ngIf="!columnIsProvided('delete')" matColumnDef="delete">
            <th *matHeaderCellDef i18n mat-header-cell>{{ 'ui.common.labels.delete' | translate }}</th>
            <td *matCellDef="let value; let i = index" mat-cell>
                <button (click)="onDelete(value, i)" [disabled]="!!privilegeLevel && privileged.isDisabled" color="warn"
                        data-cy="table-delete-row"
                        mat-icon-button>
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container *ngIf="!columnIsProvided('details')" matColumnDef="details">
            <th *matHeaderCellDef i18n mat-header-cell>
                {{ 'ui.common.labels.details' | translate }}
            </th>
            <td *matCellDef="let value; let i = index" mat-cell>
                <button (click)="onDetails(value, i)" mat-icon-button>
                    <mat-icon>info</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container *ngIf="!columnIsProvided('add')" matColumnDef="add">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
                {{ 'ui.common.labels.add' | translate }}
            </th>
            <td *matCellDef="let article; let i = index" mat-cell>
                <app-add-button (click)="onAdd(article, i)"></app-add-button>
            </td>
        </ng-container>

        <!-- menu cell -->
        <ng-container matColumnDef="actionMenu">
            <th *matHeaderCellDef mat-header-cell>
                <span i18n>Menü</span>
            </th>
            <td *matCellDef="let value; let i = index" mat-cell>
                <button [matMenuTriggerData]="{ value: value, index: i }" [matMenuTriggerFor]="rowMenu" mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                </button>
            </td>
        </ng-container>


        <ng-container matColumnDef="refresh-indicator">
            <th *matHeaderCellDef [attr.colspan]="columns.length" mat-header-cell>
                <mat-progress-bar *ngIf="refreshing" color="accent" data-cy="table-refresh-indicator"
                                  mode="indeterminate"></mat-progress-bar>
            </th>
        </ng-container>

        <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>

        <ng-container>
            <tr *matHeaderRowDef="['refresh-indicator']; sticky: true" class="refresh-row" mat-header-row></tr>
        </ng-container>

        <!-- the actual table rows os the content -->
        <!-- Need to watch mouseover to store the hovered index to emit the correct event to extended menu items -->
        <tr (dblclick)="detailsOrEdit(value, i)" (mouseover)="hoveredTableRowIndex = i"
            *matRowDef="let value; columns: columns; let i = index"
            mat-row>

        </tr>

        <!-- row for no data entries -->
        <tr *matNoDataRow class="no-data-row">
            <td [colSpan]="columns.length" class="no-data-cell">
                <span *ngIf="!refreshing; else pendingTemplate">{{ 'ui.common.table.labels.no.data' | translate }}</span>
                <ng-template #pendingTemplate>
                    <span>{{ 'ui.common.table.labels.data.pending' | translate }}</span>
                </ng-template>

            </td>
        </tr>

        <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr> -->
    </table>
</div>
<app-themed-paginator (pageChange)="onPageChange($event)" (refresh)="onRefresh()" *ngIf="usePagination"
                      [noRefresh]="!!noRefresh">
    <ng-content select="[paginator-actions]"></ng-content>
</app-themed-paginator>

<mat-menu #rowMenu="matMenu">
    <ng-template let-rowIndex="index" let-rowValue="value" matMenuContent>
        <ng-container *ngIf="!hasExternalMenuContent">
            <button (click)="onDetails(rowValue, rowIndex)" mat-menu-item>
                <mat-icon>info</mat-icon>
                <span>{{ 'ui.common.labels.details' | translate }}</span>
            </button>
            <button (click)="onEdit(rowValue, rowIndex)" [disabled]="!!privilegeLevel && privileged.isDisabled"
                    mat-menu-item>
                <mat-icon color="accent">create</mat-icon>
                <span>{{ 'ui.common.labels.edit'|translate }}</span>
            </button>
            <button (click)="onDelete(rowValue, rowIndex)" [disabled]="!!privilegeLevel && privileged.isDisabled"
                    mat-menu-item>
                <mat-icon color="warn">delete</mat-icon>
                <span>{{ 'ui.common.labels.delete' | translate }}</span>
            </button>
        </ng-container>

        <span #menuContent>
            <ng-content select="[menu-items]"></ng-content>
        </span>
    </ng-template>
</mat-menu>
