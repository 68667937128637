import {Component, forwardRef, Optional, Self} from '@angular/core';
import {NgControl} from '@angular/forms';
import {StorageArea} from '../../domain/lvs-model';
import {BaseFormFieldComponent} from '../base-form-field/base-form-field.component';
import {CustomControl} from '../model/custom-control.model';

// can not be imported in other way
import {StorageAreaService} from '../../entity-state/storage-area-service/storage-area.service';
import {WarehouseService} from '../../entity-state/warehouse/warehouse-service/warehouse.service';
import {map} from 'rxjs';
import {DeepPartial} from '../../search/model';

@Component({
    selector: 'app-storage-area-form-field',
    templateUrl: '../base-form-field/base-form-field.component.html',
    styleUrls: ['../base-form-field/base-form-field.component.scss'],
    providers: [
        {provide: BaseFormFieldComponent, useExisting: forwardRef(() => StorageAreaFormFieldComponent)},
        {provide: CustomControl, useExisting: forwardRef(() => StorageAreaFormFieldComponent)},
        {provide: 'DefaultLabel', useValue: 'Lagerbereich'},
    ]
})
export class StorageAreaFormFieldComponent extends BaseFormFieldComponent<StorageArea> {

    constructor(
        @Optional() @Self() public override ngControl: NgControl,
        private _warehouses: WarehouseService
    ) {
        super(
            {providerType: StorageAreaService, stateProvider: null},
            {key: 'storageAreaId', displayKeys: ['storageAreaId'], defaultLabel: 'Lagerbereich'},
            ngControl
        );
    }

    protected override checkFilterLabel = (filter: DeepPartial<StorageArea>) => {
        const warehouseId = filter?.warehouseId;
        if (warehouseId) {
            return this._warehouses.search({warehouseId})
                .pipe(
                    map(w => w[0]),
                    map(w => w?.warehouseName ?? ''),
                    map(w => `${this.behaviorConfig.defaultLabel}-${w}`)
                );
        }
        return null;
    };

}
