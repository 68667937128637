import {ITypeConfig} from "../interfaces";
import {InputTypeEnum} from "../enums";
import {Validators} from "@angular/forms";
import {BaseEntity} from '../base-entity.model';

export class Vehicletype extends BaseEntity {
  constructor(
    public vehicletypeId: string,
    public description: string,
  ) {
    super();
  }
}


export const VehicletypeConfig: ITypeConfig<Vehicletype> = {
  properties: [
    {
      key: 'vehicletypeId',
      view: 'Fahrzeugtyp ID',
      keyProperty: true,
      input: {
        inputType: InputTypeEnum.Text,
        required: true,
        isSettable: true,
        validators: [Validators.maxLength(50)]
      }
    },
    {
      key: 'description',
      view: 'Beschreibung',
      input: {
        inputType: InputTypeEnum.Text,
        required: true,
        isSettable: true,
        validators: [Validators.maxLength(50)]
      }
    }
  ],
  keyNames: [
    'vehicletypeId', 'description'
  ]
};
