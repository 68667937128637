import { Type } from "@angular/core";

export {
  ModuleWebsocketConfig,
  WebsocketRootConfiguration
}

class ModuleWebsocketConfig {
  constructor(
    public module: 'tms' | 'core' | 'lvs',
    public websockets: Array<{name: string, type: Type<any>}>
  ) {
  }
}

type WebsocketRootConfiguration = Array<ModuleWebsocketConfig>;
