import {Component, Input} from '@angular/core';
import {LogLevel} from "../model/logg-entry.model";
import {NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';

@Component({
    selector: 'app-log-level',
    templateUrl: './log-level.component.html',
    styleUrls: ['./log-level.component.scss'],
    imports: [
        NgSwitch,
        NgSwitchCase,
        NgSwitchDefault
    ],
    standalone: true
})
export class LogLevelComponent {

    @Input() logLevel: LogLevel | undefined = LogLevel.Information;
}
