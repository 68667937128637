import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-data-source-found-warning',
  templateUrl: './no-data-source-found-warning.component.html',
  styleUrls: ['./no-data-source-found-warning.component.scss']
})
export class NoDataSourceFoundWarningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
