<mat-toolbar *ngIf="!noRefresh; else plainPagination"
             [ngStyle]="{ 'background-color': (darkPaginator$ | async)?'#424242':'#ffffff' }"
             class="mat-table-sticky">
    <mat-toolbar-row>

        <div class="action-row">
            <button (click)="onRefresh()" mat-icon-button
                    matTooltip="{{'ui.common.tooltips.full.refresh' | translate}}">
                <mat-icon>refresh</mat-icon>
            </button>


            <!-- Ng-Content is used at this point to make it possible to add custom content to the paginator   -->
            <ng-content></ng-content>
        </div>


        <div class="separator"></div>

        <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[20, 50, 100]" class="mat-table-sticky"
                       showFirstLastButtons></mat-paginator>

        <app-paginator-menu></app-paginator-menu>

    </mat-toolbar-row>

</mat-toolbar>

<ng-template #plainPagination>
    <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[20, 50, 100]" class="mat-table-sticky"
                   showFirstLastButtons></mat-paginator>
</ng-template>
