import {Inject, Injectable} from '@angular/core';
import {Article, Setting} from '../../domain/core-model';
import {SettingsMap} from '../model/settings-map.model';
import {concat, Observable, of} from 'rxjs';
import {TransportPosition} from '../../domain/tms-model';
import {delay, map} from 'rxjs/operators';
import {SettingBatch} from '../model/setting-batch.model';
import {ColumnReader} from '../../import';
import {defaultSettings} from '../model/settings.enum';
import {LoggingService} from '../../logging';
import {BaseApiService} from '../../api/base-api-service/base-api.service';
import {IHttpResult} from '../../api/interfaces/http-result.interface';

@Injectable({
  providedIn: 'root'
})
export class SettingsInitializerService {

  private _loggingSource = 'SETTINGS';

  constructor(
    private _logger: LoggingService,
    @Inject(Setting) private _settingsApi: BaseApiService<Setting>
  ) {
  }

  public checkInitialState(settings: SettingsMap): Observable<null> {
    return concat(
      ...this.checkSettings(settings),
      ...this.checkImportSettings(settings, [TransportPosition, Article])
    ).pipe(
      delay(50),
      map(() => null)
    )
  }

  /**
   * Initializes the column-order-setting and the columns name settings-service for each passed entity.
   * Catches if an exisiting setting has lost its value and restores the defautl values.
   *
   * @param {Setting[]} currentSettings the current state of settings-service
   * @param {Function[]} classes the constructors of the classes that have an import.
   */
  private checkImportSettings(currentSettings: SettingsMap, classes: Function[] = new Array<Function>()): Observable<IHttpResult<Setting[] | null> | null>[] {
    const settingBatch = new SettingBatch();
    for (const currentClass of classes) {
      const settingNames = getImportSettingsNames(currentClass);

      // check if settings-service exist
      const existingOrderValues = currentSettings[settingNames.columnOrder as keyof SettingsMap];
      const existingNamingValues = currentSettings[settingNames.columnNaming as keyof SettingsMap];

      const orderSetting = existingOrderValues ? new Setting(settingNames.columnOrder, existingOrderValues) : null;
      const namingSetting = existingNamingValues ? new Setting(settingNames.columnNaming, existingNamingValues) : null;

      this.checkSettingHealth(settingNames.columnOrder, orderSetting, Object.keys(ColumnReader.getColumns(currentClass)!), settingBatch);
      this.checkSettingHealth(settingNames.columnNaming, namingSetting, [JSON.stringify(ColumnReader.getColumnNames(currentClass))], settingBatch);
    }

    return this.buildBatchCalls(settingBatch);
  }

  /**
   * Initializes all default settings-service if they not exist.
   * Catches if an exisitng setting has an NULL value and restores the default
   * @param currentSettings
   * @returns
   */
  private checkSettings(currentSettings: SettingsMap): Observable<IHttpResult<Setting[] | null> | null>[] {
    const batches = new SettingBatch();
    for (const settingId in defaultSettings) {
      const settingExists = Object.keys(currentSettings).includes(settingId);
      let existingSetting: Setting | undefined = undefined;
      if (settingExists) {
        existingSetting = {settingId, values: currentSettings[settingId as keyof SettingsMap]};
      }
      this.checkSettingHealth(settingId, existingSetting, defaultSettings[settingId], batches);
    }
    return this.buildBatchCalls(batches);
  }

  private checkSettingHealth(settingId: string, existingSetting: Setting | null | undefined, defaultValue: string[], batch: SettingBatch): void {
    const defaultSetting = new Setting(settingId, defaultValue);
    if (!existingSetting) {
      this.writeRestoringLog(settingId, 'create');
      batch.create.push(defaultSetting);
    } else if (existingSetting.values === undefined || existingSetting.values === null) {
      this.writeRestoringLog(settingId, 'update');
      batch.update.push(defaultSetting);
    }
  }

  private writeRestoringLog(settingId: string, action: 'update' | 'create'): void {
    if (action === 'update') {
      this._logger.critical(`Setting ${settingId} needed to be restored. Value was NULL or undefined`, this._loggingSource);
    } else {
      this._logger.info(`Creating Setting with ID ${settingId}`, this._loggingSource);
    }
  }

  private buildBatchCalls(batch: SettingBatch): Observable<IHttpResult<Setting[] | null> | null>[] {
    return [
      batch.create.length > 0 ? this._settingsApi.createNewEntities(batch.create) : of(null),
      batch.update.length > 0 ? this._settingsApi.updateEntities(batch.update) : of(null)
    ];
  }
}

/**
 * Splits a given name in upper camel case into its segments and returns the segments in lowercase.
 * @param {string} className the name of the class can be accessed by constructor.name
 * @returns {string[]} list of the lowercase segments of the classname
 */
function splitCamelCaseClassName(className: string): string[] {
  return className
    .replace(/([a-z])([A-Z])/, '$1 $2')
    .split(/\s/)
    .map((s) => s.toLowerCase());
}

function getImportSettingsNames(type: Function): { columnOrder: keyof SettingsMap, columnNaming: keyof SettingsMap } {
  // splitting name and building pattern <firstClassCamel-secondClassCamel...>
  const settingsPrefix = splitCamelCaseClassName(type.name).join('-');

  return {
    columnNaming: `${settingsPrefix}-column-names` as keyof SettingsMap,
    columnOrder: `${settingsPrefix}-import-order` as keyof SettingsMap
  };
}
