<div class="message-row">

    <mat-icon class="icon">
        lock
    </mat-icon>

    <span class="message">
        {{'ui.routing.' + data.module |translate}}&nbsp;{{'ui.lock.user.feedback.page.locked' | translate}}&nbsp;{{userName}}
    </span>
</div>
