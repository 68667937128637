export {
  View,
  ViewChangedEvent
}

class View<X = string> {

  public svgIcon?: boolean;

  constructor(
    public name: X,
    public displayValue: string,
    public icon?: string
  ) {
  }
}

class ViewChangedEvent<X extends string = string> {
  constructor(
    public currentView: X,
    public oldView: X
  ) {
  }
}
