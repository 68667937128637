import {ExportableConfig} from '../decorators/exportable.decorator';
import {ExportMap, FlattenedStringMap, IObjectParser, StringMap} from './object-parser.interface';
import {PlainObjectParser} from './plain-object.parser';
import {ColumnReader} from '../readers/column.reader';
import {IExportColumn} from '../decorators/export.decorator';
import {ExportParserError} from '../errors/parsing.errors';


export {
    OneToManyParser
}

/**
 * Parser for objects with one-to-many relationships.
 *
 * @example
 * A simple example of a class annotated to use this parser
 * ```ts
 *
 * @Exportable({
 *  name: 'Eltern'
 *  writingProperty: 'children'
 * })
 * class Parent {
 *
 *  @Export({...})
 *  name!: string;
 *
 *  @Export({...});
 *  children!: Array<Children>;
 *
 * }
 * ```
 */
class OneToManyParser implements IObjectParser<FlattenedStringMap[]> {

    private _parentParser: IObjectParser;
    private _childParser: IObjectParser;

    constructor(
        private _exportable: ExportableConfig,
        columns: ExportMap,
    ) {
        if (!_exportable.writingProperty) {
            throw new ExportParserError(OneToManyParser, 'Cannot create a one-to-many-parser for a class without writingProperty');
        }

        const {one, many} = this._separateConfigurations(columns);

        this._checkManyColumnConfig(many);

        // parser for the surrounding entity
        this._parentParser = new PlainObjectParser(one);

        // parser for the main entity
        this._childParser = new PlainObjectParser(new ColumnReader(many.link!).getExternColumns());
    }

    private get _manyProperty(): string {
        return this._exportable.writingProperty!;
    }

    /**
     * @inheritDoc
     *
     * Parses classes that are in a one-to-many relationship from a nested list to a list of
     * one dimensional objects. For each object of the many side of this relation on list entry
     * of the [one] side is created.
     *
     * This means one parent with four children will create four list entries where the data of the parent
     * is repeated for and with every child.
     *
     * @param input
     */
    parseObjectsAsReadable(input: StringMap | StringMap[]): FlattenedStringMap[] {
        if (Array.isArray(input)) {
            return this._parseList(input);
        }
        return this._parseSingle(input);
    }

    /**
     * Parses one single value from the [one] entity to a list of many entries with
     * the data of the [many] children of the object.
     * @param value
     * @private
     */
    private _parseSingle(value: StringMap): FlattenedStringMap [] {
        const many = value[this._manyProperty];

        // here only arrays can and shall be parsed as this is a one-to-many relationship
        const children = this._childParser.parseObjectsAsReadable(Array.isArray(many) ? many : []);

        const parent = this._parentParser.parseObjectsAsReadable(value);

        return children.map((child) => this._mergeObjects(parent, child));
    }

    /**
     * Pareses all entries in a list of [one] entities.
     * @param input
     * @private
     */
    private _parseList(input: StringMap[]): FlattenedStringMap[] {
        return input.reduce((acc, value) => [...acc, ...this._parseSingle(value)], new Array<FlattenedStringMap>());
    }

    private _mergeObjects(one: object, two: object) {
        return {...one, ...two};
    }

    /**
     * Checks if the [many] property config is valid
     * @param manyColumn
     * @private
     */
    private _checkManyColumnConfig(manyColumn: IExportColumn) {
        if (!manyColumn) {
            throw new ExportParserError(OneToManyParser, `Could not find ${this._exportable.writingProperty} in configuration for class exported as ${this._exportable.name}. One to many parsing is not possible`);
        }

        // allowed is destructuring or linking for these properties
        if (!manyColumn?.listValue) {
            throw new ExportParserError(OneToManyParser, `Cannot create a one-to-many-parser for a class with a writing property that is not a list. 
            Please set {listValue: true} for ${this._exportable.writingProperty}`);
        }
    }

    /**
     * Splits the configuration of the [one] class from the [many] property and returns them both
     * seperated.
     *
     * The [one] ExportMap gets returned without the [many] property.
     * @param columns
     * @private
     */
    private _separateConfigurations(columns: ExportMap): { many: IExportColumn, one: ExportMap } {
        const one = {...columns};

        const many = one[this._manyProperty];

        delete one[this._manyProperty];

        return {one, many};
    }
}
