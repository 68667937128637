import {Component, Injector, Input, OnInit} from '@angular/core';
import {map, Observable} from "rxjs";
import {MatBadgeModule} from "@angular/material/badge";
import {AsyncPipe, NgIf, NgTemplateOutlet} from "@angular/common";
import {BooleanInput, coerceBooleanProperty} from "@angular/cdk/coercion";
import {AppRoute} from "../../app.routes";
import {tap} from 'rxjs/operators';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-navigation-label',
    templateUrl: './navigation-label.component.html',
    styleUrls: ['./navigation-label.component.scss'],
    imports: [
        MatBadgeModule,
        AsyncPipe,
        NgIf,
        NgTemplateOutlet,
        TranslateModule
    ],
    standalone: true
})
export class NavigationLabelComponent implements OnInit {

    @Input() route!: AppRoute;

    hasCount = false;

    incompleteEntityCount$?: Observable<number>;

    translationString = '';

    private readonly _replaceRegex = new RegExp('[/|-]', 'g')

    constructor(
        private _injector: Injector
    ) {
    }

    protected _inset = false;

    @Input()
    set inset(value: BooleanInput) {
        this._inset = coerceBooleanProperty(value);
    }


    ngOnInit() {
        this.hasCount = this._loadCounter();
        this.translationString = `ui.routing.${this.route.id.replace(this._replaceRegex, '.')}`
    }

    private _loadCounter(): boolean {
        if (!this.route.stateProvider) {
            return false;
        }

        const service = this._injector.get(this.route.stateProvider);

        if (!service) {
            return false;
        }

        this.incompleteEntityCount$ = service.getIncompleteEntities().pipe(
            map(values => values.length),
            tap(c => this.hasCount = c > 0) // side effect to communicat to outside
        );
        return true;
    }
}
