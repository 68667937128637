import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ISelectionProperty} from "src/app/domain/interfaces";
import {ExportEvent} from "../model/export-event.model";
import {FileSelection, FileType} from "../model/export-filetpyes.enum";
import {NgTemplateOutlet} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';


@Component({
    selector: 'app-export',
    templateUrl: './export.component.html',
    styleUrls: ['./export.component.scss'],
    imports: [
        NgTemplateOutlet,
        MatIconModule,
        MatButtonModule
    ],
    standalone: true
})
export class ExportComponent implements OnInit {

    /**
     * Emits an {@link ExportEvent} when user selects an export file representation
     * and clicks the export button
     */
    @Output() export: EventEmitter<ExportEvent> = new EventEmitter();
    /**
     * stores per two-way data binding the currently selected file representation
     */
    exportFormat: FileType = FileType.XLSX;
    // the possible to select file representations
    exportSelection: ISelectionProperty[] = FileSelection;

    constructor() {
    }

    private _fab: boolean = false;

    get fab(): boolean {
        return this._fab;
    }

    /**
     * Flag for using the export button as mat-fab and not selection and icon button
     */
    @Input()
    set fab(value: any) {
        this._fab = coerceBooleanProperty(value);
    }

    ngOnInit(): void {
    }

    /**
     * Starts an export by telling the parent component to export a set of data
     */
    initExport(): void {
        this.export.emit(new ExportEvent(this.exportFormat));
    }
}
