<div class="icon-stack">
    <div class="background-icon">
        <ng-content></ng-content>
    </div>

    <div class="foreground-icon top right shrink-icon" [class.bolder-icon]="_bolder">
        <ng-content select="[top-right]"></ng-content>
    </div>

    <div class="foreground-icon bottom right shrink-icon" [class.bolder-icon]="_bolder">
        <ng-content select="[bottom-right]"></ng-content>
    </div>

    <div class="foreground-icon top left shrink-icon" [class.bolder-icon]="_bolder">
        <ng-content select="[top-left]"></ng-content>
    </div>

    <div class="foreground-icon bottom left shrink-icon" [class.bolder-icon]="_bolder">
        <ng-content select="[bottom-left]"></ng-content>
    </div>

</div>
