import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ChildRoutes, RouteQueryParams} from '../../constants';

@Component({
    selector: 'app-import-fab',
    templateUrl: './import-fab.component.html',
    styleUrls: ['./import-fab.component.scss']
})
export class ImportFabComponent implements OnInit {

    @Input() routerLink = `../${ChildRoutes.Import}`;


    get queryParams(): {
        [RouteQueryParams.ReturnTo]: string
    } {
        return {[RouteQueryParams.ReturnTo]: this._router.url};
    }

    constructor(
        private _router: Router
    ) {
    }

    ngOnInit(): void {
    }

}
