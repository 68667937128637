import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {User, UserConfig} from '../../domain/core-model';
import {FileType} from '../../domain/enums';
import {FileService} from '../../core';
import {ITypeConfig} from '../../domain/interfaces';
import {StateService} from '../model/state/state.service';
import {UserApiService} from '../../api/user/user-api.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends StateService<User> {

    constructor(
        protected override _api: UserApiService,
        private fileService: FileService,
    ) {
        super(
            {type: User, api: UserApiService, primaryKeys: ['userId'], webSocket: 'UserSignalRService'},
            {prefetching: true, eagerLoading: false}
        );
    }

    getTypeConfig(): ITypeConfig<User> {
        return JSON.parse(JSON.stringify(UserConfig));
    }

    getReport(date?: string): Observable<boolean> {
        return this._api.getReport(date)
            .pipe(
                // downloading received file
                tap((httpResult) => {
                    if (httpResult.responseValue) {
                        this.fileService.download(httpResult.responseValue, FileType.PDF, 'lizenzuebersicht')
                    }
                }),
                map(res => {
                    return !res.isError;
                })
            );
    }
}

