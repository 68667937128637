import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ImportService} from '../import-service/import.service';
import {catchError, filter, finalize, takeWhile, toArray} from 'rxjs/operators';
import {FileImportEvent, ImportEvent, ImportViewChangeEvent} from '../model/import-event.model';
import {Observable, of} from 'rxjs';
import {ImportError, ImportRangeSymbolsMissingError} from '../model/import-errors.model';
import {FileUploadComponent} from '../file-upload/file-upload.component';
import {ViewChangedEvent} from '../../buttons/model/view-change.model';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-import',
    templateUrl: './import.component.html',
    styleUrls: ['./import.component.scss']
})
export class ImportComponent<T> implements OnInit {

    // the actual file-upload to clean it on error
    @ViewChild(FileUploadComponent, {static: false}) fileUpload!: FileUploadComponent;
    // emitter for imported components
    @Output() importChange: EventEmitter<FileImportEvent<T>> = new EventEmitter();
    // emitter for changes of view toggle
    @Output() viewDataChange: EventEmitter<ImportViewChangeEvent<T>> = new EventEmitter();
    // flag if importing is in progress
    importing = false;
    // flag if importing is done
    importDone = false;
    // flag for an happened error
    errorHappened = false;
    lastImport!: FileImportEvent;

    constructor(
        private _importService: ImportService,
        private _snackbar: MatSnackBar
    ) {
    }

    /**
     * @returns if the current file reading an importing process was done successfull
     */
    get fileFullyImported(): boolean {
        return this.importDone && !this.errorHappened;
    }

    /**
     * @returns if all entities are raed from the file and no error happend
     */
    get entityImportReady(): boolean {
        return this.importDone && this.importing === false && !this.errorHappened;
    }

    ngOnInit(): void {
    }


    /**
     * Start point for data import. Show progressbar to user and adds a small delay to
     * the start of the import to give the ui time to show the progressbar and give a smoother flow
     * @param dataUrl
     */
    importFromFile(dataUrl: string): void {
        this.importing = true;
        this.errorHappened = false;
        // adding delay to the start of the import
        setTimeout(() => this.startImport(dataUrl), 20);
    }

    onViewChange(event: ViewChangedEvent<"complete" | "inComplete" | "corrupted">) {
        this.viewDataChange.emit(new ImportViewChangeEvent<T>(event, this.lastImport?.[event.currentView]!));
    }

    /**
     * Starts the import of data from the file uplaod
     * Collects all imported objects and emitts them at once as event to the parent component
     * @param dataUrl
     */
    private startImport(dataUrl: string): void {
        this._importService
            .readFromFile(dataUrl)
            .pipe(
                // handle import errors (currently there is only no range symbols)
                catchError((err) => this.handleImportErrors(err)),
                // abort handle to end subscription after an error happened to avoid wrong usage of resources
                takeWhile(() => !this.errorHappened),
                // removes the start marker event
                filter((ev: ImportEvent<any> | null) => !ev?.start),
                /// after last emit set importing flag to en
                finalize(() => this.importing = false),
                // collecting all emitted value into one array
                toArray()
            )
            .subscribe((events) => {
                if (events?.filter((e) => e === null).length !== events.length) {

                    this.lastImport = new FileImportEvent<T>({
                        complete: events.filter(e => e?.complete).map(e => e?.complete),
                        inComplete: events.filter(e => e?.inComplete).map(e => e?.inComplete),
                        corrupted: events.filter(e => e?.corrupted).map(e => e?.corrupted)
                    });

                    // emitting everything wie collected
                    this.importChange.emit(this.lastImport);
                    this.importDone = true;
                }
            });
    }

    /**
     * Avoids components state to change in case an error happend during the import. In this way the user can retry to import the file
     * after he changed it
     * @param {ImportError} err the error from the {@link ImportService}
     * @returns {Observable<null>} null because after the error we wannt to stop all import-processes because we are in an unaccaptable state
     */
    private handleImportErrors(err: ImportError): Observable<null> {
        if (err instanceof ImportRangeSymbolsMissingError) {
            // showing user that imported file was corrupted
            this._snackbar.open(`Begrenzungssymbole ${err.rangeSymbols.start} oder ${err.rangeSymbols.end} nicht gefunden`, undefined, {
                panelClass: 'warn'
            });
        }
        // avoiding view change to nexts state
        this.errorHappened = true;
        // giving snackbar time to appear
        setTimeout(() => this.fileUpload.clear(), 200);
        return of(null);
    }
}
