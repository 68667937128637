import {Injectable} from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  constructor() {
    // including utc plugin
    dayjs.extend(utc);
  }

  /**
   * Takes an utc-iso-string and converts it to the correct locale time.
   * Uses dayjs for date-time conversion.
   * @param dateValue the string or Date object that needs to be converted and used as locale time
   * @param asDate whether the function should return the result as Date object or as plane string (Default is string)
   * @param formatForFormField whether the function should remove the utc-offset marker and seconds from the result (needs to be done for inputs to understand format)
   * @param format defines the format of the resulting date-string
   */
  fromServerTimeUTC(dateValue: Date | string, asDate = false, formatForFormField = true, format = ''): Date | string | null {
    if (!dateValue) {
      return null;
    }
    // when incoming value is an object we convert it to an iso-string
    const dateString = typeof dateValue === 'string' ? dateValue : dateValue.toISOString();

    const dayJsValue = dayjs.utc(dateString);

    // checking if date is valid if not stop parsing
    if (!dayJsValue.isValid()) {
      return null;
    }

    // converting string to locale time (exp. GMT+1)
    let localeDayjsValue = dayJsValue.local().format(format ?? undefined);

    if (formatForFormField && localeDayjsValue.includes('+')) {
      // utc time offset always starts with a + sign in iso string
      // removing 3 characters more fo losing seconds
      const offsetStartIndex = localeDayjsValue.indexOf('+');
      // using whole string up to index of '+'
      localeDayjsValue = localeDayjsValue.slice(0, offsetStartIndex);
    }

    return asDate ? new Date(localeDayjsValue) : localeDayjsValue;
  }

  /**
   * Converts a string in local time format to an utc string.
   * @param dateValue the locale date-time to be converted to utc
   * @param asDate whether the function shall return a Date object or a string. (Default is string)
   */
  toServerTimeUTC(dateValue: Date | string, asDate = false): Date | string | null {
    if (!dateValue) {
      return null;
    }
    // if the value isn't a string we use the iso string
    const dateString = typeof dateValue === 'string' ? dateValue : dateValue.toISOString();

    // par sing date down to locale date to have a clear base for conversion
    const localeDate = dayjs(dateString);

    // checking if incoming valid
    if (!localeDate.isValid()) {
      return null;
    }

    // converting to iso-string
    const dayJsValue = dayjs.utc(localeDate).toISOString();

    return asDate ? new Date(dayJsValue) : dayJsValue;
  }

  /**
   * returns the current date and time as iso-string
   * @param asDate whether the function shall return a Date object or a string. (Default is string)
   */
  getCurrentDate(asDate = false): Date | string {
    const currentDateString = dayjs().toISOString();
    return asDate ? new Date(currentDateString) : currentDateString;
  }

  /**
   * Checks if the date first given is before the second date
   * @param olderDate the that expected to be older
   * @param youngerDate the date expected to be younger
   */
  checkDateIsOlder(olderDate: string, youngerDate: string): boolean {
    return dayjs(this.fromServerTimeUTC(olderDate)).isBefore(this.fromServerTimeUTC(youngerDate));
  }


}
