import {Component, Input} from '@angular/core';
import {TransportPositionState} from '../../../../domain/tms-model';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-transport-position-state',
    templateUrl: './transport-position-state.component.html',
    styleUrls: ['./transport-position-state.component.scss'],
    imports: [
        TranslateModule
    ],
    standalone: true
})
export class TransportPositionStateComponent {

    @Input() state: TransportPositionState = TransportPositionState.Created;
}
