import {of, OperatorFunction, retry} from 'rxjs';

export {withDefaultRetryHandler}

function withDefaultRetryHandler<T>(delayRate = 500): OperatorFunction<T, T> {
    return (source$) => source$.pipe(
        retry({
            count: 3, delay: (_, retryCount) => of(retryCount * delayRate)
        })
    );
}
