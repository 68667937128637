import {
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl, Validators} from "@angular/forms";
import {Observable, of, startWith, Subject} from "rxjs";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {CustomControl} from '../model/custom-control.model';
import {
  BaseFormFieldOptions,
  BaseFormFieldProviders,
  NoStateProviderError
} from '../model/base-form-field-config.model';
import {map, switchMap, tap} from 'rxjs/operators';
import {DeepPartial} from '../../search/model';
import {IState} from '../../entity-state/model/state.interface';
import {KeyBuilder} from '../../entity-state/model/key-builder';
import {EntityKey} from '../../domain/utility-types';
import {BaseEntity} from "../../domain";


@Component({
  selector: 'app-base-form-field',
  templateUrl: './base-form-field.component.html',
  styleUrls: ['./base-form-field.component.scss'],
  inputs: ['color', 'appearance'],
  providers: [{provide: CustomControl, useExisting: forwardRef(() => BaseFormFieldComponent)}]
})
export class BaseFormFieldComponent<T extends BaseEntity> extends CustomControl<T> implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() style?: string;
  @Input() tabindex?: string;
  @Output() selectionChange: EventEmitter<T> = new EventEmitter();

  filteredOptions$!: Observable<T[]>;
  control: FormControl;
  stateChanges = new Subject<void>();

  readonly displayKeys: [keyof T] | [keyof T, keyof T];
  readonly fallbackKey?: keyof T;

  protected checkFilterLabel?: (f: DeepPartial<T>) => Observable<string> | null | undefined;

  protected keyBuilder: KeyBuilder<T>;
  protected label: Observable<string>;

  protected autoCompleteOptions: T[] | null = null;
  protected autoCompleteLoaded = false;

  protected readonly entityState!: IState<T>;

  constructor(
    @Optional() public providerConfig: BaseFormFieldProviders<T>,
    @Optional() public behaviorConfig: BaseFormFieldOptions<T>,
    @Optional() @Self() public ngControl: NgControl | null,
  ) {
    super();

    this.label = of(behaviorConfig.defaultLabel);

    // reading entity-type stuff
    this.keyBuilder = new KeyBuilder([behaviorConfig.key]);
    this.displayKeys = behaviorConfig.displayKeys;
    this.fallbackKey = behaviorConfig.fallbackDisplay;

    // building control for usage without FormControlDirective
    this.control = new FormControl(null);

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.entityState = this._loadStateProvider(inject(Injector));
  }

  @Input()
  get value(): T | string | number {
    if (this.selectedEntity) {
      const primaryValue = this.selectedEntity[this.behaviorConfig.key];
      return typeof primaryValue === 'string' ? String(primaryValue) : Number(primaryValue);
    }
    return this.control.value;
  }

  set value(value: string | T | number | null | undefined) {
    this.control.setValue(value);
  }


  get selectedEntity(): T | null {
    if (typeof this.control.value === 'string') {
      return this.autoCompleteOptions?.find(e => this.keyBuilder.isSameKey(e, this._buildKey())) ?? null;
    }
    return this.control.value;
  }

  private _filter: DeepPartial<T> = {};

  @Input()
  set filter(value: DeepPartial<T>) {
    this._filter = value;
    this.clear();

    if (this.checkFilterLabel) {
      this.label = this.checkFilterLabel(this._filter) ?? of(this.behaviorConfig.defaultLabel);
    }

    this._buildAutoCompleteFilter();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled || this.control.disabled;
  }

  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _required = false;

  get required(): boolean {
    return this._required || this.control.hasValidator(Validators.required);
  }

  @Input()
  set required(value: any) {
    this._required = coerceBooleanProperty(value);
    if (this._required && !this.control.hasValidator(Validators.required)) {
      this.control.addValidators(Validators.required);
      this.control.updateValueAndValidity();
    }
    this.stateChanges.next();
  }

  private _clearable: boolean = false;

  get clearable(): boolean {
    return this._clearable;
  }

  @Input('clear')
  set clearable(value: any) {
    this._clearable = coerceBooleanProperty(value);
  }

  private _blockedEntities: (string | number)[] = [];

  get blockedEntities(): (string | number)[] {
    return this._blockedEntities;
  }

  @Input()
  set blockedEntities(value: (string | number)[]) {
    this._blockedEntities = value;
  }

  onChange = (_: any) => {
  };

  onTouched = () => {
  };

  ngOnInit(): void {
    if (this.ngControl?.control) {
      this.control = this.ngControl.control as FormControl;
    }
    this._buildAutoCompleteFilter();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  displayWithKeys(optionValue: T | string | number | null | undefined): string {
    if (!optionValue) {
      return '';
    }

    const entity: T | null = typeof optionValue === 'object' ? optionValue : this.selectedEntity;

    return entity ? this._buildUserDisplayedValue(entity) : String(optionValue)
  }


  writeValue(nextValue: T | string | number | null) {
    const currentValue = this.control.value;

    const selectedValue = this.selectedEntity;

    const valueDiffers = currentValue !== nextValue || (selectedValue && selectedValue !== currentValue);

    if (!valueDiffers) {
      return;
    }

    // we have either a user-input or a selected entity
    let entity = selectedValue ?? nextValue;

    this.control.setValue(entity);

    this.stateChanges.next();

  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  clear() {
    this.control.patchValue(null);
    this.selectionChange.emit(undefined);
  }

  isBlocked(e: T) {
    return this.blockedEntities.find(t => String(t) === String(e[this.behaviorConfig.key]));
  }

  private _buildAutoCompleteFilter(): void {
    this.filteredOptions$ = this.control.valueChanges
      .pipe(
        startWith(this.control.value ?? ''), // take initial value in scope
        switchMap((filter: string | null | undefined | T) => {
          // got cleared
          if (!filter) {
            return this.entityState.search([{...this._filter}, '']);
          }

          // is better for searching number by an exact match
          if (typeof filter === 'number') {
            return this.entityState.getById(this._buildKey() as any, true)
              .pipe(
                map(v => v ? [v] : [])
              );
          }

          //is either a user-input or directly the key of an entity
          if (typeof filter === 'string') {
            return this.entityState.search([{...this._filter}, filter.toLowerCase()]);
          }

          // option got selected
          return of([filter]);
        }),
        tap((o) => this._nextLoadingState(o))
      );
  }

  private _nextLoadingState(options: T[]) {
    this.autoCompleteOptions = options;

    // the first time options are loaded
    if (!this.autoCompleteLoaded) {

      // hide loading shade
      this.autoCompleteLoaded = options.length > 0;

      // reapply value to make it visible to the user in the case id value was there before the options
      this.control.setValue(this.selectedEntity ?? this.control.value);
    }
  }

  private _loadStateProvider(injectorRef: Injector) {
    if (this.providerConfig.stateProvider) {
      return this.providerConfig.stateProvider;
    }

    if (!this.providerConfig.providerType) {
      throw new NoStateProviderError();
    }

    return injectorRef.get<IState<T>>(this.providerConfig.providerType);
  }

  private _buildUserDisplayedValue(entity: T): string {
    const [firstDisplayKey, secondaryDisplayKey] = this.displayKeys;

    let value = String(entity[firstDisplayKey]);

    if (!secondaryDisplayKey) {
      return value ?? this._getFallbackKeyValue(entity) ?? '';
    }

    const secondaryValue = entity[secondaryDisplayKey];

    // runs only when both values are present like first and lastname
    if (secondaryValue) {
      return `${value} | ${secondaryValue}`;
    }

    return this._getFallbackKeyValue(entity) ?? '';
  }

  private _getFallbackKeyValue(entity: T): string | null | undefined {
    return this.fallbackKey ? String(entity[this.fallbackKey]) : null;
  }

  private _buildKey(): T | EntityKey<T> {
    const {value} = this.control;

    if (!value) {
      return {};
    }

    if (typeof value === 'object') {
      return value;
    }

    return {[this.behaviorConfig.key]: value} as EntityKey<T>
  }
}

