import {ViewNames} from '../../../../constants';
import {SettingsService} from '../../../../settings';
import {ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {TransportPosition} from '../../../../domain/tms-model';
import {BaseTableComponent, TableColumns, TableMapper} from '../../../../table';
import {TransportPositionSettings} from '../../../../settings/model/entity-settings';
import {takeUntil} from 'rxjs/operators';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {TableSortService} from '../../../../table/table-sort/table-sort.service';
import {ActivatedRoute} from '@angular/router';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {PriorityComponent, TransportPositionStateComponent} from '../index';
import {SharedModule} from '../../../../shared';
import {ArticleGroupPipe, LocalizedDatePipe, UserNamePipe, WarehousePipe, WeightPipe} from '../../../../pipes';
import {ArticlePipe} from '../../../management-core/article/pipes';

@Component({
    selector: 'app-transport-position-table',
    templateUrl: './transport-position-table.component.html',
    styleUrls: ['./transport-position-table.component.scss'],
    outputs: BaseTableComponent.OUTPUTS,
    inputs: [...BaseTableComponent.BASE_INPUTS, 'columns'],
    providers: [
        {provide: BaseTableComponent, useExisting: forwardRef(() => TransportPositionTableComponent)}
    ],
    standalone: true,
    imports: [
        MatTableModule,
        BaseTableComponent,
        MatSortModule,
        PriorityComponent,
        TransportPositionStateComponent,
        SharedModule,
        WeightPipe,
        LocalizedDatePipe,
        UserNamePipe,
        ArticleGroupPipe,
        WarehousePipe,
        ArticlePipe
    ]
})
export class TransportPositionTableComponent extends BaseTableComponent<TransportPosition> implements OnInit {

    @Input()
    set localImportMode(value: any) {
        const isInLocalImportMode = coerceBooleanProperty(value);
        this.reducedDataSet = isInLocalImportMode;
        this.noRefresh = true;
        if (isInLocalImportMode) {
            const dueDayIndex = this.columns.indexOf('toBeDoneBy');
            this.columns = [...this.columns.slice(0, dueDayIndex), 'superTaskDestination', ...this.columns.slice(dueDayIndex, this.columns.length)];
        }
    }

    @Input()
    set reducedDataSet(value: any) {
        this._reducedDataSet = coerceBooleanProperty(value);
        this._reducedDataSet ? this.reduceDataSet() : this.extendDataSet();
    }

    @Input()
    set noAsync(value: any) {
        this._noAsync = coerceBooleanProperty(value);
    }

    get noAsync(): boolean {
        return this._noAsync;
    }

    @Input()
    set readonly(value: any) {
        const isReadOnly = coerceBooleanProperty(value);

        if (isReadOnly) {
            // removing all interactive columns
            this.columns = this.columns.filter(c => c !== 'actionMenu' && c !== 'select');

        } else if (!this.columns.includes('actionMenu')) {
            // re-adding interactive columns
            this.columns = ['select', ...this.columns, 'actionMenu'];
        }

    }

    @ViewChild(BaseTableComponent, {static: true}) baseTable!: BaseTableComponent;


    private readonly extendedDataSetColumns: TableColumns<TransportPosition> = ['articleDescription', 'articleWeight'];
    private readonly defaultColumns: TableColumns<TransportPosition> = ['select', 'transportPositionId', 'priorityLevel', 'transportState', 'bookingNumber', 'jobNumber', 'articleGroupName', 'articleId', 'articleDescription', 'articleCount', 'articleWeight', 'articleSourceId', 'destination', 'toBeDoneBy', 'operator', 'initiator', 'actionMenu'];
    private _reducedDataSet = false;
    private _noAsync = false;

    override columns = this.defaultColumns;
    override columnNames = ViewNames.transportPosition;

    constructor(
        _elementRef: ElementRef,
        _changeDetector: ChangeDetectorRef,
        _tableSort: TableSortService,
        _activeRoute: ActivatedRoute,
        private _settings: SettingsService
    ) {
        super(_elementRef, _changeDetector, _tableSort, _activeRoute);
    }

    override ngOnInit(): void {
        super.ngOnInit();
        TableMapper.mapOutputs(this.baseTable, this, BaseTableComponent.OUTPUTS);
        this.watchColumnNaming();
    }

    override search(query: string) {
        this.baseTable.search(query);
    }

    override clearSelection() {
        this.baseTable.clearSelection();
    }

    private watchColumnNaming(): void {
        this._settings.getEntitySettings(TransportPositionSettings)
            .pipe(takeUntil(this._subscriptions))
            .subscribe((settings) => {
                this.columnNames = settings.displayStrings;
            })
    }

    private extendDataSet() {
        this.columns = this.defaultColumns;
    }

    private reduceDataSet() {
        this.columns = this.defaultColumns.filter(c => !this.extendedDataSetColumns.includes(c));
    }
}
