import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationHistoryService} from '../../core';
import {ChildRoutes, FeatureModuleRoutes} from '../../constants';
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  imports: [
    MatButtonModule
  ],
  standalone: true
})
export class PageNotFoundComponent implements OnInit {

  accessedRoute: string | undefined;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _routerHistory: NavigationHistoryService
  ) {
  }

  ngOnInit(): void {
    this.accessedRoute = this._router.routerState.snapshot.url;
  }

  returnToPageBefore(): void {
    const urlSegments = this._router.routerState.snapshot.url.split('/');
    const currentPseudoModule = urlSegments.find(s => Object.keys(FeatureModuleRoutes).includes(s));
    let currentFeatureModule: string | undefined;
    if (currentPseudoModule) {
      const featureModuleRoutes = (FeatureModuleRoutes as any)[currentPseudoModule];
      currentFeatureModule = urlSegments.find(s => Object.values(featureModuleRoutes).includes(s));
    }


    if (this._routerHistory?.last) {
      this._router.navigateByUrl(this._routerHistory.last);
    } else if (currentFeatureModule) {
      this._router.navigate([currentPseudoModule, currentFeatureModule, ChildRoutes.List]);
    } else {
      this._router.navigate(['/']);
    }
  }

}
