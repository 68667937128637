import {animate, state, style, transition, trigger} from '@angular/animations';

export {Animations};

const horizontalExpand = [
  trigger('horizontalExpand', [
    state('collapsed', style({width: '0px', minWidth: '0px'})),
    state('expanded', style({width: '*'})),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ])
];

const verticalExpand = [
  trigger('horizontalExpand', [
    state('collapsed', style({height: '0px', minHeight: '0px'})),
    state('expanded', style({height: '*'})),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ])
];


const Animations = {
  horizontalExpand,
  verticalExpand
}
