import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationHistoryService} from 'src/app/core'
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {NavigationRoute} from "../../domain/interfaces";
import {MatMenuModule} from "@angular/material/menu";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {NgForOf} from "@angular/common";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-history-handle',
  templateUrl: './history-handle.component.html',
  styleUrls: ['./history-handle.component.scss'],
  standalone: true,
  imports: [
    MatMenuModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    NgForOf,
    RouterLink
  ]
})
export class HistoryHandleComponent implements OnInit, OnDestroy {

  history: NavigationRoute[] | undefined;
  private _subscriptions: Subject<void> = new Subject();

  constructor(
    private _navigationHistory: NavigationHistoryService
  ) {
  }

  ngOnInit(): void {
    this._navigationHistory
      .getEntireHistory()
      .pipe(takeUntil(this._subscriptions))
      .subscribe((history) => this.history = history);
  }

  ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }
}
