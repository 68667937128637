import {Component, forwardRef, Optional, Self} from '@angular/core';
import {User} from '../../domain/core-model';
import {BaseFormFieldComponent} from '../base-form-field/base-form-field.component';
import {NgControl} from '@angular/forms';
import {BaseFormFieldProviders} from '../model/base-form-field-config.model';
import {CustomControl} from '../model/custom-control.model';
import {UserService} from '../../entity-state/user-service/user.service';

@Component({
    selector: 'app-user-form-field',
    templateUrl: '../base-form-field/base-form-field.component.html',
    styleUrls: ['../base-form-field/base-form-field.component.scss'],
    providers: [
        {provide: BaseFormFieldComponent, useExisting: forwardRef(() => UserFormFieldComponent)},
        {provide: CustomControl, useExisting: forwardRef(() => UserFormFieldComponent)}
    ]
})
export class UserFormFieldComponent extends BaseFormFieldComponent<User> {

    constructor(
        @Optional() @Self() public override ngControl: NgControl
    ) {
        super(
            new BaseFormFieldProviders<User>({providerType: UserService}),
            {
                key: 'userId',
                displayKeys: ['lastName', 'firstName'],
                fallbackDisplay: 'email',
                defaultLabel: 'Mitarbeiter'
            },
            ngControl
        );
    }


    override get selectedEntity(): User | null {
        if (!this.autoCompleteOptions) {
            return null;
        }
        return this.autoCompleteOptions.find(u => u.userId === this.control.value) ?? null;
    }
}
