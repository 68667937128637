<div (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)"
     [attr.aria-labelledby]="formField?.getLabelId()" class="priority-level-select-container">
    <mat-form-field [appearance]="appearance" [color]="color">
        <mat-label>{{ label ?? "Priorität" }}</mat-label>
        <mat-select (selectionChange)="_handleInput()" [aria-label]="label ?? 'Priorität'" [formControl]="control"
                    [required]="required" class="priority-level-select-element">
            <mat-select-trigger>
        <span class="option-container">
          <app-priority [priorityLevel]="control.value"></app-priority>
          <span>{{ currentPriorityDescription }}</span>
        </span>
            </mat-select-trigger>

            <ng-container *ngIf="!required">
                <mat-option [value]="null">
                    <span>--</span>
                </mat-option>
            </ng-container>

            <mat-option *ngFor="let option of priorityOptions" [value]="option.value">
              <span class="option-container">
                <app-priority [priorityLevel]="option.value"></app-priority>
                <span>{{ option.view }}</span>
              </span>
            </mat-option>
        </mat-select>

        <mat-error>
            <app-form-errors [control]="control" [entityName]="label ?? 'Status'"></app-form-errors>
        </mat-error>
    </mat-form-field>
</div>
