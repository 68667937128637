import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {AssetPaths} from './constants';
import {APP_ROUTES} from "./app.routes";
import {of} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {ICS_MESSENGER_NAVIGATION_ROOT} from '@ics-angular/messenger';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    title = 'ICS-TLS';
    version = environment.version;
    assetPaths = AssetPaths;

    routes = APP_ROUTES;

    isMessengerRoute$ = of(false);

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
    ) {
        this.isMessengerRoute$ = this._router.events.pipe(
            filter(ev => ev instanceof NavigationEnd),
            map(() => this._router.url.includes(ICS_MESSENGER_NAVIGATION_ROOT)),
            distinctUntilChanged(),
        );
    }

    ngOnInit() {
        this.checkLogin();
    }

    private checkLogin() {
        // reading whole url
        const url = window.location.href;
        // check if url is mentioned for auth-callback and has a token
        if (url.includes('auth-callback') && url.includes('token')) {
            // splitting token from complete url
            const urlSplit = url.split('auth-callback');
            const loginToken = urlSplit[urlSplit.length - 1];
            // double check if token was found
            if (loginToken.includes('token')) {
                // re-direct to auth-callback to do it the right way
                this._router.navigate(['auth-callback'], {relativeTo: this._activatedRoute, fragment: loginToken});
            }
        }
    }
}
