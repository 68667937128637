import {Inject, Injectable} from '@angular/core';
import {StateService} from '../../model/state/state.service';
import {Warehouse, WarehouseTypes} from '../../../domain/core-model';
import {WarehouseApiService} from '../../../api/warehouse/warehouse-api.service';
import {ISignalRService, SignalRService} from '../../../websocket';

@Injectable({
    providedIn: 'root'
})
export class InactiveWarehouseService extends StateService<Warehouse> {

    constructor(
        protected override _api: WarehouseApiService,
        @Inject('WarehouseSignalRService') protected override _webSocket: SignalRService<Warehouse>
    ) {
        super({
            api: WarehouseApiService, type: Warehouse, webSocket: _webSocket, primaryKeys: ['warehouseId']
        });
    }

    override get refreshFn() {
        return this._api.getInactiveWarehouses();
    }

    protected override connectToWebSocket(websocket: ISignalRService<Warehouse>) {
        if (websocket) {
            websocket.getUpdatedNotification().subscribe((w) => {
                if (w.warehouseType === WarehouseTypes.Inactive) {
                    this.updateState([w], 'update');
                }
            });
        }
    }
}
