import {Component, ElementRef, NgZone} from '@angular/core';
import {BaseButtonDirective} from '../base-button/base-button.directive';

@Component({
  selector: 'app-destination-button',
  templateUrl: './destination-button.component.html',
  styleUrls: ['./destination-button.component.scss']
})
export class DestinationButtonComponent extends BaseButtonDirective {

  constructor(elementRef: ElementRef, ngZone: NgZone) {
    super(elementRef, ngZone);
  }
}
