import {Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {TransportTaskState} from '../../transport-task';

@Component({
    selector: 'app-transport-task-state',
    templateUrl: './transport-task-state.component.html',
    styleUrls: ['./transport-task-state.component.scss'],
    imports: [
        TranslateModule
    ],
    standalone: true
})
export class TransportTaskStateComponent {

    @Input() state: TransportTaskState = TransportTaskState.Created;

}

