import {Injectable} from '@angular/core';
import {FileType} from '../../domain/enums';
import {saveAs} from 'file-saver';
import {HttpClient} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {DateTimeService} from "../date-time-service/date-time.service";


@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(private http: HttpClient, private dateTime: DateTimeService) {
    }


    /**
     * Allows to download a file to the client pc. File must be base64 encoded.
     * @param data vase64 representation of file
     * @param fileType type of downloaded file
     * @param fileName name of file (don't add date, will be done here)
     */
    download(data: string | Blob, fileType: FileType, fileName = 'data_export'): void {
        const today = this.dateTime.getCurrentDate(true) as Date;
        saveAs(data, `${fileName}_${today.toLocaleDateString()}${fileType}`);
    }


    /**
     * Converts given Data (Blob of a file) to base64 representation
     * @param data
     */
    blobToBase64(data: Blob): Observable<string> {
        return from(
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result as string);
                }
                reader.onerror = ((err) => reject(err));
                reader.readAsDataURL(data);
            })
        ) as Observable<string>;
    }

    /**
     * Allows reading static assets as base64 representation
     * Uses {@link blobToBase64} for conversion
     * @param path to file located on server so for example /assets
     */
    getBase64ImageFromURL(path: string): Observable<string> {
        return this.http.get(path, {responseType: 'blob'})
            .pipe(
                mergeMap((blob: Blob): Observable<string> => this.blobToBase64(blob))
            );
    }
}
