import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {CanBeDisabled} from '../model/can-be-disabled';

@Component({
    selector: 'app-save-button',
    templateUrl: './save-button.component.html',
    styleUrls: ['./save-button.component.scss'],
    inputs: ['disabled'],
})
export class SaveButtonComponent extends CanBeDisabled {

    @Input() label: string | undefined;

    @Output('save') saveEmitter: EventEmitter<void> = new EventEmitter();
    private _shortCutSuppressed = false;

    constructor() {
        super();
    }

    get suppressShortcut(): boolean {
        return this._shortCutSuppressed;
    }

    @Input()
    set suppressShortcut(value: any) {
        this._shortCutSuppressed = coerceBooleanProperty(value);
    }

    private _saving = false;

    get saving(): boolean {
        return this._saving;
    }

    @Input()
    set saving(value: any) {
        this._saving = coerceBooleanProperty(value);

        // using small timeout to let the initial-event flow on
        setTimeout(() => this.disabled = this._saving, 5);
    }

    @HostListener('window:keydown.control.enter', ['$event'])
    onSaveCombination() {
        if (this.disabled || this.suppressShortcut) {
            return
        }
        this.saveEmitter.emit();
        this.saving = true;
    }

    onClick(event: MouseEvent) {
        this.saving = true;
    }

    endSaving(): void {
        this.saving = false;
    }
}
