import {BaseEntity} from '../../../domain';
import {StateService} from '../state/state.service';
import {inject, Injectable} from '@angular/core';
import {StateAccess, StateOptions} from '../state/state.service.options';
import {StateEagerLoadingOptions} from './eager-loaded-state.options';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EntityKey} from '../../../domain/utility-types';
import {IcsConfirmationService} from 'ics-core';

@Injectable({
    providedIn: null
})
export abstract class EagerLoadingStateService<T extends BaseEntity, Q extends object = T> extends StateService<T, Q> {

    protected _referenceServices = new Map<keyof T, Array<StateService<any>>>();
    protected _referenceState!: Observable<EagerLoadingStateReference<T>[]>;

    protected constructor(
        _stateManagementAccessor: StateAccess<T>,
        _stateManagementOptions: StateOptions<T>,
        protected _eagerLoadingOptions: StateEagerLoadingOptions<T>,
        confirmRef?: IcsConfirmationService
    ) {
        super(_stateManagementAccessor, _stateManagementOptions, confirmRef);

        this._loadReferenceServices();

        this._buildReferenceState();
    }

    override get state(): Observable<T[]> {
        return combineLatest([super.state, this._referenceState])
            .pipe(
                // skipWhile(([ownState, refStates]) => !!refStates.find((s: EagerLoadingStateReference<any>) => !s.prefetched)), // first emitting after all data is present
                map((states: [Array<T>, Array<EagerLoadingStateReference<any>>]) => {
                    const ownState: T[] = states[0];
                    const referenceStates = states[1];
                    return ownState.map(e => {
                        return referenceStates.reduce((acc, curr) => this.eagerLoad(e, curr.propertyName as keyof T, curr.state), e)
                    });
                }),
                map((eagerLoadedState: T[]) => this.writeEagerLoadingProperties(...eagerLoadedState))
            );
    }

    protected referencesEntity<X extends BaseEntity>(entity: T, referenceProperty: keyof T, referenceKey: EntityKey<X>): boolean {
        const referenceValue = entity[referenceProperty];
        const referencedService = this._referenceServices.get(referenceProperty);
        const referenceOptions = this._eagerLoadingOptions.eagerLoadingReferenceMap.get(referenceProperty);

        if (referencedService && referenceOptions) {
            const eagerLoadingKey = referenceOptions.eagerQueryBuilder ? referenceOptions.eagerQueryBuilder(entity) : {[referenceProperty]: referenceValue};

            const [service] = referencedService;

            return service.keyBuilder.isSameKey(eagerLoadingKey, referenceKey);
        }

        return false;
    }

    protected eagerLoad(entity: T, property: keyof T, referenceState: Array<BaseEntity>): T {
        if (entity[property]) {
            const referencedEntity = referenceState.find(e => this.referencesEntity(entity, property, e));
            const writingProperty = this._eagerLoadingOptions.eagerLoadingReferenceMap.get(property)?.writingProperty ?? property;
            if (referencedEntity) {
                entity[writingProperty] = referencedEntity as any;
            }
        }
        return entity;
    }

    private _buildReferenceState() {
        if (this._referenceServices.size > 0) {
            const referenceList = Array.from(this._referenceServices.keys());
            const serviceList = Array.from(this._referenceServices.values());
            this._referenceState = combineLatest(
                serviceList.map(services => combineLatest(services.map(s => s.state))
                    .pipe(
                        map((propStates) => propStates.reduce((acc, curr) => [...acc, ...curr]))
                    )
                )
            ).pipe(
                map((states: any[]) => {
                    return states.map((e: Array<any>, i) => {
                        // const prefetched = serviceList[i].find(s => !s.prefetchComplete);
                        return new EagerLoadingStateReference(e, referenceList[i]);
                    });
                }),
            );
        }
    }

    private _loadReferenceServices() {
        for (const referenceOption of this._eagerLoadingOptions.eagerLoadingReferences) {
            const {serviceReference} = referenceOption;

            let service: StateService<any> | null = null;

            if (typeof serviceReference === 'function') {
                service = inject(serviceReference);
            }

            if (typeof serviceReference === 'object') {
                service = serviceReference;
            }

            if (!service) {
                throw new Error(`Could not load referenced Service for ${this._type?.name} by class ${(referenceOption.serviceReference as Function)?.name}`);
            }

            const existingReferences = this._referenceServices.get(referenceOption.eagerProperty) ?? [];

            this._referenceServices.set(referenceOption.eagerProperty, [...existingReferences, service]);
        }
    }
}

class EagerLoadingStateReference<T, X = any> {
    constructor(
        public state: Array<X>,
        public propertyName: keyof T
    ) {
    }
}
