import {Component, HostListener, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ChildRoutes, NavigationQueryParams} from '../../constants';
import {Animations} from '../../animations/animations';
import {CanBeDisabled} from "../model/can-be-disabled";

@Component({
  selector: 'app-broken-imports-button',
  templateUrl: './broken-imports-button.component.html',
  styleUrls: ['./broken-imports-button.component.scss'],
  animations: [Animations.horizontalExpand],
  inputs: ['disabled']
})
export class BrokenImportsButtonComponent extends CanBeDisabled implements OnInit {

  @Input() route = '..';
  hovered = false;

  constructor(
    private _router: Router,
    private _activeRoute: ActivatedRoute
  ) {
    super();
  }

  get animationState(): 'collapsed' | 'expanded' {
    return this.hovered ? 'expanded' : 'collapsed';
  }

  ngOnInit() {
    const route = this._activeRoute.snapshot.routeConfig?.path;
    if (route && !this.route) {
      this.route = route;
    }

  }

  @HostListener('mouseover')
  setHovered(): void {
    this.hovered = true;
  }

  @HostListener('mouseleave')
  setNotHovered(): void {
    this.hovered = false;
  }

  navigate(): void {
    this._router.navigate([this.route, ChildRoutes.Check], {
      relativeTo: this._activeRoute,
      queryParams: this.buildQueryParams()
    });
  }

  private buildQueryParams(): NavigationQueryParams {
    const query: NavigationQueryParams = {inComplete: true};

    if (this.route === '..') {
      query.returnTo = this._router.url;
    }

    return query;
  }
}
