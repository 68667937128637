import {Injectable} from '@angular/core';
import {Initiator} from '../../domain/tms-model';
import {StateService} from '../model/state/state.service';

@Injectable({
    providedIn: 'root'
})
export class InitiatorService extends StateService<Initiator> {

    constructor() {
        super({type: Initiator, primaryKeys: ['initiatorId'], webSocket: 'InitiatorSignalRService'});
    }
}
