import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CanBeDisabledDirective} from './model/can-be-disabled';
import {AngularMaterialModule} from '../shared';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-link-button',
    standalone: true,
    imports: [CommonModule, AngularMaterialModule, TranslateModule, CanBeDisabledDirective],
    template: `
        <button mat-flat-button>
            <mat-icon>search</mat-icon>
            <span>{{ 'ui.common.labels.show' |translate }}</span>
        </button>
    `
})
export class LinkButtonComponent extends CanBeDisabledDirective {

}
