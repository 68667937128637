import {SettingsMap} from '../settings-map.model';
import {EntitySettings} from './entity-settings.interface';

export {
  MaterialRequestSettings
}

class MaterialRequestSettings extends EntitySettings {
  emailList = new Array<string>();
  automaticGroupSuffix: string = '';
  articleFilters = new Array<string>('articleId', 'description');

  constructor(settings: SettingsMap) {
    super();
    if (settings['web-material-request-article-filters']) {
      this.articleFilters = [...this.articleFilters, ...settings['web-material-request-article-filters']];
    }

    if (settings['web-material-request-group-suffix']) {
      this.automaticGroupSuffix = settings['web-material-request-group-suffix']![0]!;
    }

    if (settings['material-request-email-list']) {
      this.emailList = settings['material-request-email-list'];
    }
  }

  override toMap(): Partial<SettingsMap> {
    return {
      'web-material-request-article-filters': this.articleFilters,
      'web-material-request-group-suffix': [this.automaticGroupSuffix],
      'material-request-email-list': this.emailList
    };
  }
}
