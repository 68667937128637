import {Component, Input} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {AbstractControl, Validators} from '@angular/forms';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {ThemePalette} from '@angular/material/core';

type PrimaryKey = string | number;

/**
 * This class defines all common properties for a custom form field.
 * By creating a custom form-field just extend this class.
 * All properties are marked as inputs, so they can be used from the template.
 * For using @Input() it needs to be a component. Therefore, can not be abstract.
 * @typeParam defines the Type of the entities managed by the subclass form-field
 */
@Component({template: ''})
export class CustomFormFieldComponent<T = any> {

    constructor(
        public control: AbstractControl
    ) {
    }

    private _filter: Partial<T> = {};

    get filter(): Partial<T> {
        return this._filter;
    }

    /**
     * If this value is set only the articles which apply to the [search] Endpoint of this query will be loaded
     * @param value query for search endpoint
     */
    @Input()
    set filter(value: Partial<T>) {
        this._filter = value;
    }

    private _blockedEntities: PrimaryKey[] = [];

    get blockedEntities(): PrimaryKey[] {
        return this._blockedEntities;
    }

    /**
     * If this value is set the given entity-ids will be not shown as selectable Option
     * @param value an array of strings or numbers which holds the entities IDs
     */
    @Input()
    set blockedEntities(value: PrimaryKey[]) {
        this._blockedEntities = value;
    }

    private _emitOnlyOnSelect = false;

    get emitOnlyOnSelect(): boolean {
        return this._emitOnlyOnSelect;
    }

    /**
     * If this value is set the form-field will only emit a value after user selects an entity
     * @param value of any type, so it can be used as flag in the template without requiring a dedicated value
     */
    @Input()
    set emitOnlyOnSelect(value: any) {
        this._emitOnlyOnSelect = coerceBooleanProperty(value);
    }

    private _emitOnlyEntities = false;

    get emitOnlyEntities(): boolean {
        return this._emitOnlyEntities;
    }

    /**
     * If this value is set the form-field will emit the whole entity instead of only the ID
     * @param value of any type, so it can be used as flag in the template without requiring a dedicated value
     */
    @Input()
    set emitOnlyEntities(value: any) {
        this._emitOnlyEntities = coerceBooleanProperty(value);
    }

    private _label?: string;

    get label(): string | undefined {
        return this._label;
    }

    /**
     * Overrides the default label of a custom form field.
     * Is this field not set the entities default name will be set.
     * @param value string to replace label of form-field
     */
    @Input()
    set label(value: string | undefined) {
        this._label = value;
    }

    private _placeholder = '';

    get placeholder(): string {
        return this._placeholder;
    }

    /**
     * Defines and overrides a possible Placeholder value that is show until user types first char.
     * A default placeholder is possible in a form-field but not a must-have.
     * @param value the placeholder to be placed in a form-field
     */
    @Input()
    set placeholder(value: string) {
        this._placeholder = value;
    }

    private _required = false;

    get required(): boolean {
        return this._required || this.control.hasValidator(Validators.required);
    }

    /**
     * Marks the form-field as required in a form-group or stand alone.
     * If this is set, form will be invalid until this field is filled and all other constraints are satisfied.
     * @param value of type any, so it can be used as a simple flag without enforcing a boolean.
     */
    @Input()
    set required(value: any) {
        this._required = coerceBooleanProperty(value);
    }

    private _appearance: MatFormFieldAppearance = 'outline';

    get appearance(): MatFormFieldAppearance {
        return this._appearance;
    }

    /**
     * Changes the appearance of a custom form field. Values can be selected from legacy | standard | fill | outline.
     * Default is standard. Check Angular-Material docs for more.
     * @param value defines the look and feel of a form-field. Select out of legacy | standard | fill | outline.
     */
    @Input()
    set appearance(value: MatFormFieldAppearance) {
        this._appearance = value;
    }

    private _color: ThemePalette = 'accent';

    get color(): ThemePalette {
        return this._color;
    }

    /**
     * Defines the color of the Custom-Form-Field. Color is selectable from values
     * primary, accent and warn. Uses Angular-Material color mechanism
     * @param value value out of primary | accent | warn
     */
    @Input()
    set color(value: ThemePalette) {
        this._color = value;
    }

    private _purpose?: 'edit' | 'create';

    get purpose(): 'edit' | 'create' | undefined {
        return this._purpose;
    }

    /**
     * Makes it possible for the form field to detect which values are allowed and which are forbidden
     * Determines the behavior of the special form field. For example blocks going back to 'created' state for
     * transport-positions
     * @param value either a form  field is used for editing or creating an entity
     */
    @Input()
    set purpose(value: 'edit' | 'create' | undefined) {
        this._purpose = value;
    }

    private _disableRoleManagement = false;

    get disableRoleManagement(): boolean {
        return this._disableRoleManagement;
    }

    /**
     * Forces the form field to ignore the current users role and allows them to use the form field
     * like an admin. Can be used as a pure HTML-Flag
     * WARNING: Should only be used for FILTERS!
     * @param value any type of value. Is coerced as a boolean.
     */
    @Input()
    set disableRoleManagement(value: any) {
        this._disableRoleManagement = coerceBooleanProperty(value);
    }

    private _disableProcessBlocking = false;

    get disableProcessBlocking(): boolean {
        return this._disableProcessBlocking;
    }

    /**
     * Forces the form field to ignore its own state for blocking inputs. For Example TransportPositionStates are not longer
     * blocked backwards.
     * Can be used as a pure HTML-Flag
     * WARNING: Should only be used for FILTERS!
     * @param value any type of value. Is coerced as a boolean.
     */
    @Input()
    set disableProcessBlocking(value: any) {
        this._disableProcessBlocking = coerceBooleanProperty(value);
    }
}

