import {NgModule} from '@angular/core';
import {SaveButtonComponent} from './save-button/save-button.component';
import {MatIconModule} from '@angular/material/icon';
import {GroupButtonComponent} from './group-button/group-button.component';
import {TransportButtonComponent} from './transport-button/transport-button.component';
import {RefreshButtonComponent} from './refresh-button/refresh-button.component';
import {BaseButtonDirective} from './base-button/base-button.directive';
import {CommonModule} from '@angular/common';
import {AbortButtonComponent} from './abort-button/abort-button.component';
import {CreateFabComponent} from './create-fab/create-fab.component';
import {DestinationButtonComponent} from './destination-button/destination-button.component';
import {DeleteButtonComponent} from './delete-button/delete-button.component';
import {SquareButtonComponent} from './square-button/square-button.component';
import {BrokenImportsButtonComponent} from './broken-imports-button/broken-imports-button.component';
import {SettingsButtonComponent} from './settings-button/settings-button.component';
import {AppDownloadButtonComponent} from './app-download-button/app-download-button.component';
import {ReturnButtonComponent} from './return-button/return-button.component';
import {RouterModule} from '@angular/router';
import {ViewToggleComponent} from './view-toggle/view-toggle.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {LogButtonComponent} from './log-button/log-button.component';
import {UnassignButtonComponent} from './unassign-button/unassign-button.component';
import {IconStackComponent} from '../components/icon-stack/icon-stack.component';
import {BarcodeDownloadButtonComponent} from './barcode-download-button/barcode-download-button.component';
import {EditButtonComponent} from './edit-button/edit-button.component';
import {
  UnassignHandlingUnitButtonComponent
} from './unassign-handling-unit-button/unassign-handling-unit-button.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {TranslateModule} from "@ngx-translate/core";
import {CanBeDisabledDirective} from "./model/can-be-disabled";

const BUTTONS = [
  SaveButtonComponent,
  GroupButtonComponent,
  TransportButtonComponent,
  RefreshButtonComponent,
  BaseButtonDirective,
  AbortButtonComponent,
  CreateFabComponent,
  DestinationButtonComponent,
  DeleteButtonComponent,
  SquareButtonComponent,
  DeleteButtonComponent,
  BrokenImportsButtonComponent,
  SettingsButtonComponent,
  AppDownloadButtonComponent,
  ReturnButtonComponent,
  ViewToggleComponent,
  LogButtonComponent,
  UnassignButtonComponent,
  BarcodeDownloadButtonComponent,
  EditButtonComponent,
  UnassignHandlingUnitButtonComponent,
];

@NgModule({
  declarations: [
    ...BUTTONS,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    IconStackComponent,
    TranslateModule,
    CanBeDisabledDirective
  ],
  exports: [
    ...BUTTONS
  ]
})
export class ButtonsModule {
}

