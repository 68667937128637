import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-form-row-controls',
  templateUrl: './form-row-controls.component.html',
  styleUrls: ['./form-row-controls.component.scss']
})
export class FormRowControlsComponent {

  @Input() layout: 'column' | 'row' = 'row';
}
