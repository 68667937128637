<form *ngIf="form; else buildError" [formGroup]="form" [class.row-layout]="layout === 'row'" [class.column-layout]="layout === 'column'">
  <ng-container *ngFor="let prop of properties">
    <ng-container [ngSwitch]="prop.input.inputType">
      <ng-container *ngSwitchCase="'selection'">
        <mat-form-field [appearance]="appearance" [color]="color">
          <mat-label>{{ prop.view }}</mat-label>
          <mat-select [formControlName]="prop.key" [required]="!ignoreValidation && prop.input.required">
            <mat-option *ngIf="ignoreValidation || !prop.input.required" [value]="undefined"> -- </mat-option>
            <mat-option *ngFor="let option of prop.input.selectionValues" [value]="option.value">
              {{ option.view }}
            </mat-option>
          </mat-select>
          <mat-error>
            <app-form-errors [control]="getControl(prop.key)"></app-form-errors>
          </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase="'async-selection'">
        <app-async-select
          [formControlName]="prop.key"
          [optionResolver]="prop.input.selectionResolver"
          [propertyConfig]="prop"
          [required]="!ignoreValidation && prop.input.required"
          [appearance]="appearance"
          class="form-field"
        >
        </app-async-select>
      </ng-container>

      <ng-container *ngSwitchCase="'array'">
        <app-array-input [formControlName]="prop.key" [property]="prop" [appearance]="appearance" [layout]="'column'" [class.form-field]="propertyFilter === 'search'"></app-array-input>
      </ng-container>

      <ng-container *ngSwitchCase="'datetime-local'">
        <app-date-input
          [format]="'date-time-offset'"
          [label]="prop.view"
          [formControlName]="prop.key"
          [appearance]="appearance"
          [color]="color"
          [required]="!ignoreValidation && prop.input.required"
          style="margin-right: -1.5%"
        ></app-date-input>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <mat-form-field [appearance]="appearance" [color]="color">
          <mat-label>{{ prop.view }}</mat-label>
          <input matInput [formControlName]="prop.key" [type]="prop.input.inputType" [required]="!ignoreValidation && prop.input.required" />
          <mat-error>
            <app-form-errors [control]="getControl(prop.key)"></app-form-errors>
          </mat-error>
        </mat-form-field>
      </ng-container>
    </ng-container>
  </ng-container>
</form>

<ng-template #buildError> Error by building form </ng-template>
