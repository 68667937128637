<div class="centered-content" *ngIf="!fileFullyImported">
  <app-file-upload (file)="importFromFile($event)"></app-file-upload>
</div>

<div class="centered-layout" *ngIf="entityImportReady">
  <!-- Parent component content that gets injected and only receives position from this-->
  <div class="row control-bar">
    <div class="control-bar-before">
      <ng-content select="[control-bar-before]"></ng-content>
      <app-import-view-toggle (viewChange)="onViewChange($event)"></app-import-view-toggle>
    </div>
    <ng-content select="[control-bar-after]"></ng-content>
  </div>


  <div class="list-area">
    <ng-content select="[check-area]"></ng-content>
  </div>

  <div class="form-area">
    <ng-content select="[form]"></ng-content>
  </div>
</div>

<mat-progress-bar *ngIf="importing" mode="indeterminate"></mat-progress-bar>
