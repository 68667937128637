import {Injectable} from '@angular/core';
import {EMPTY, from, noop, Observable, of, skipWhile, switchMap} from 'rxjs';
import {catchError, map, mergeMap, take, timeout} from 'rxjs/operators';
import {TransportTaskApiService} from './transport-task-api.service';
import {StateService} from '../../../../entity-state/model/state/state.service';
import {PropertySearcher} from '../../../../search/model';
import {WarehouseService} from '../../../../entity-state/warehouse/warehouse-service/warehouse.service';
import {UserService} from '../../../../entity-state/user-service/user.service';
import {IcsUserFeedbackService} from 'ics-core';
import {TransportTask, TransportTaskId} from '../model/transport-task.model';
import {TransportTaskState} from '../model/transport-task-state.model';
import {TransportTaskQuery} from '../model/transport-task.query';
import {TransportPositionService} from '../../../../entity-state/transport-position/transport-position.service';
import {filterTransportTaskByOperator$} from '../filters/operator.filter';
import {filterTransportTasksByHandlingUnit$} from '../filters/handling-unit.filter';
import {filterTransportTasksByInitiator$} from '../filters/initiator.filter';
import {filterTransportTasksBySourceWarehouseName$} from '../filters/warehouse.filter';
import {asSetArray} from '../../../../util';
import {HandlingUnit, Warehouse} from '../../../../domain/core-model';
import {filterTransportTasksByDestination$} from '../filters/destination.filter';
import {TransportPosition} from '../../../../domain/tms-model';
import {filterTransportTasksInDateRange} from '../filters/date-range.filter';

@Injectable({
    providedIn: 'root'
})
export class TransportTaskService extends StateService<TransportTask, TransportTaskQuery> {

    constructor(
        protected override _api: TransportTaskApiService,
        private _userFeedback: IcsUserFeedbackService,
        private _warehouseService: WarehouseService,
        private _userService: UserService,
        private _transportPositionState: TransportPositionService,
    ) {
        super({
            type: TransportTask,
            api: TransportTaskApiService,
            primaryKeys: ['transportTaskId'],
            webSocket: 'TransportTaskSignalRService'
        }, {
            eagerLoading: true, // deactivationg this will result in feature break
            prefetching: true
        });
    }

    searchUnfinished(query: [TransportTaskQuery, string]) {
        return this.search(query).pipe(
            // this could get problematic as ist could pend endless
            skipWhile((state) => !state.find(p => p.state !== TransportTaskState.Finished)),
            timeout({first: 10000}), // therefore there is this timeout
            catchError(() => of([])), // and this catches the timeout error and lets pending end
            map(s => s.filter(t => t.state !== TransportTaskState.Finished))
        )
    }

    loadArticleImage(transportTask: TransportTask): Observable<string | null> {
        return this._api.getTaskImage(transportTask)
            .pipe(
                mergeMap((blob: Blob | null | undefined) => blob ? blobToBase64(blob) : of(null)),
                map((base64String) => base64String ? `data:image/png;base64,${base64String}` : null)
            );
    }

    checkAssignmentsCanBeRemoved$(tasks: TransportTaskId[]): Observable<boolean> {
        const transportTaskSet = asSetArray(tasks);

        return this.state.pipe(
            map((state) => state.filter(t => transportTaskSet.includes(t.transportTaskId!))),
            map((tasksToUnassign) =>
                (tasksToUnassign.every(t => t.state === TransportTaskState.InProgress))
            )
        );
    }

    removeAssignment(transportTasks: TransportTaskId[]): Observable<void> {
        return this.checkAssignmentsCanBeRemoved$(transportTasks).pipe(
            switchMap(canBeDone => {
                if (!canBeDone) {
                    return this._userFeedback.show({
                        type: 'error',
                        message: 'ui.user.feedback.error.transporttasks.not.all.handling.units.assigned'
                    });
                }
                return this._api.removeOperatorAssignment(transportTasks);
            }),
            switchMap(success => {
                if (typeof success !== 'boolean') {
                    return EMPTY;
                }

                return this._userFeedback.show({
                    type: success ? 'success' : 'error',
                    message: `ui.user.feedback.${success ? 'success' : 'error'}.transporttasks.remove.assignment`
                })
            }),
            take(1),
            map(() => noop())
        );
    }

    createTaskForHandlingUnit$(warehouse: Warehouse, handlingUnit: HandlingUnit) {
        return this._api.createTaskAndFinishHu$(warehouse.warehouseId!, handlingUnit.handlingUnitId!).pipe(
            map(v => v.isError)
        );
    }

    protected override searchProperty: PropertySearcher<TransportTask, TransportTaskQuery> = (searchList, key, query) => {
        if (key === 'operatorName') {
            return filterTransportTaskByOperator$(this._userService, query, searchList);
        }

        if (key === 'handlingUnitId') {
            return filterTransportTasksByHandlingUnit$(this._transportPositionState, query, searchList);
        }

        if (key === 'sourceWarehouseName') {
            return filterTransportTasksBySourceWarehouseName$(this._warehouseService, query, searchList);
        }

        if (key === 'destination') {
            return filterTransportTasksByDestination$(this._transportPositionState, query, searchList);
        }

        if (key === 'initiator') {
            return filterTransportTasksByInitiator$(this._transportPositionState, query, searchList);
        }

        if (key === 'lastMovementRange') {
            return filterTransportTasksInDateRange(query, searchList);
        }
        
        return undefined;
    };
}

function blobToBase64(blob: Blob): Observable<string> {
    return from(
        new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const octetStream: string = reader.result as string;
                resolve(octetStream.substring(octetStream.indexOf(',') + 1));
            };
            reader.readAsDataURL(blob);
        })
    );
}

function mergeWithPositions(transportTasks: TransportTask[], positions: TransportPosition[]) {
    return transportTasks.map((task) => {
        return {
            ...task,
            transportPositions: positions.filter(p => p.transportTaskId === task.transportTaskId)
        }
    })
}
