import {Export} from 'src/app/export/decorators/export.decorator';
import {Exportable} from 'src/app/export/decorators/exportable.decorator';
import {ArticleGroupRequisitionStorageBin} from './article-groupr-equisition-storage-bin.model';
import {Article, Warehouse} from '../core-model';
import {ArticleGroup} from '../tms-model';
import {BaseEntity} from '../base-entity.model';

export {
    Requisition,
    ArticleGroupRequisition,
    ArticleRequisition,
    RequisitionType,
    requisitionsHaveSamePrimaryKey,
    RequisitionQuery
};

type RequisitionType = ArticleRequisition | ArticleGroupRequisition;

type RequisitionQuery = Partial<ArticleRequisition & ArticleGroupRequisition> & { warehouseName?: string };


@Exportable({name: 'Entnahmen'})
class Requisition extends BaseEntity {
    static buildFromEagerLoad(requisition: RequisitionType): RequisitionType {
        if ((requisition as ArticleGroupRequisition)?.articleGroupId) {
            return ArticleGroupRequisition.buildFromEagerLoad(requisition as ArticleGroupRequisition);
        } else {
            return ArticleRequisition.buildFromEagerLoad(requisition as ArticleRequisition);
        }
    }

    @Export({view: 'ID', propertyKey: 'requisitionId'})
    public requisitionId?: number;

    @Export({view: 'EntnahmeDatum', propertyKey: 'withdrawalDate'})
    public withdrawalDate?: string | Date;
    @Export({view: 'Entnommen', propertyKey: 'withDrawn'})
    public withDrawn?: boolean;
    @Export({view: 'Identifikationsnummer', propertyKey: 'identificationNumber'})
    public identificationNumber?: string;

    @Export({link: Warehouse})
    public warehouseObj?: Warehouse;
    public warehouseId?: number;

    @Export({view: 'Lagerplatz', propertyKey: 'storageBinId'})
    public storageBinId?: string;
    @Export({view: 'Lagerbereich', propertyKey: 'storageAreaId'})
    public storageAreaId?: string;

    constructor() {
        super();
    }
}


class ArticleRequisition extends Requisition {
    static override buildFromEagerLoad(articleRequisition: ArticleRequisition): ArticleRequisition {
        articleRequisition.articleDescription = articleRequisition?.articleObj?.description ?? undefined;
        return articleRequisition;
    }

    constructor(@Export({view: 'Artikelnummer', propertyKey: 'articleId'})
                public articleId: string,
                @Export({view: 'Anzahl', propertyKey: 'articleCount'})
                public articleCount: number,
                public articleDescription?: string,
                public articleGroupId?: number,
                @Export({propertyKey: 'articleObj', link: Article})
                public articleObj?: Article) {
        super();
    }
}

@Exportable({name: 'Artikelgruppenentnahmen', writingProperty: 'articleGroupRequisitionStorageBinObjs'})
class ArticleGroupRequisition extends Requisition {
    static override buildFromEagerLoad(articleGroupRequisition: ArticleGroupRequisition): ArticleGroupRequisition {
        articleGroupRequisition.articleGroupName = articleGroupRequisition?.articleGroupObj?.name;
        articleGroupRequisition.warehouseId = articleGroupRequisition?.articleGroupRequisitionStorageBinObjs?.[0]?.warehouseId;
        articleGroupRequisition.ambigiuousSource = !!articleGroupRequisition?.articleGroupRequisitionStorageBinObjs?.find(b => b.warehouseId !== articleGroupRequisition.warehouseId);
        return articleGroupRequisition;
    }

    constructor(public articleGroupId: number,
                @Export({view: 'Artikelgruppe', propertyKey: 'articleGroupName'})
                public articleGroupName?: string,
                public articleGroupObj?: ArticleGroup,
                public warehouse?: Warehouse,
                @Export({
                    propertyKey: 'articleGroupRequisitionStorageBinObjs',
                    link: ArticleGroupRequisitionStorageBin,
                    listValue: true
                })
                public articleGroupRequisitionStorageBinObjs?: ArticleGroupRequisitionStorageBin[],
                public ambigiuousSource?: boolean
    ) {
        super();
    }
}

function requisitionsHaveSamePrimaryKey(a: RequisitionType, b: RequisitionType): boolean {
    if (((a as ArticleGroupRequisition).articleGroupId) && ((b as ArticleGroupRequisition).articleGroupId)) {
        return (a as ArticleGroupRequisition).articleGroupId === (b as ArticleGroupRequisition).articleGroupId;
    } else {
        const articleOne = (a as ArticleRequisition);
        const articleTwo = (b as ArticleRequisition);
        return (articleOne.articleId === articleTwo.articleId) && (articleOne.articleCount === articleTwo.articleCount);
    }
}


