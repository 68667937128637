import {Inject, Injectable, Optional} from '@angular/core';
import {IMPORT_DEFINITIONS, ImportDefinitionsProvider} from '../model/column-definitions-provider.interface';
import {IMPORT_CLASS} from '../model/import-class.provider';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import {ImportEvent} from '../model/import-event.model';
import {Observable, Observer, of, Subscription} from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators';
import {IMPORT_LOGGING, ImportLoggingProvider} from '../model/import-logging-provider.interface';
import {ColumnConfig, ColumnReader, ParsableDataType, PropertyValues} from '../decorators/column.decorator';
import {ImportableConfig, ImportableReader} from '../decorators/importable.decorator';
import {
  ClassNotImportableError,
  ImportRangeSymbolsMissingError,
  NoImportableColumnsError
} from '../model/import-errors.model';

/**
 * Service to import Object of type of the as {@link IMPORT_CLASS} marked class / constructor.
 * Reads the config and imports objects of this class
 */
@Injectable({
    providedIn: 'root'
})
export class ImportService<T = any> {

    // only imports from this and not more
    private _classImportConfiguration!: { importable: ImportableConfig, columns: { [key: string]: ColumnConfig } };
    // source-name for logging
    private readonly _loggingSource = 'IMPORT';
    private readonly _csvReadingSeparator = '~';

    /**
     * Creates a new service instance for importing objects
     * @param {Function} _importClass the class layout to be imported
     * @param {ImportLoggingProvider} _logger service for writing import logs
     * @param {ImportDefinitionsProvider} _importDefinitionsProvider provider of the column-order in the using system (default fallback to class-declaration order)
     *
     * @throws {@link Error}
     * Thrown if no provider for the class was found to inject
     */
    constructor(
        @Inject(IMPORT_CLASS) private _importClass: Function,
        @Optional() @Inject(IMPORT_LOGGING) private _logger?: ImportLoggingProvider<{ content: string }>,
        @Optional() @Inject(IMPORT_DEFINITIONS) private _importDefinitionsProvider?: ImportDefinitionsProvider
    ) {

        this._logger?.trace('Import service created', this._loggingSource);

        // service can't work if no class is provided
        if (!_importClass) {
            throw new Error("No class was provided for importing objects");
        }

        // reading configuration of provided class
        this.readImportConfiguration();
    }

    // the whole configuration of the provided class

    /**
     * Configuration of all class properties marked with {@link @Column}
     */
    get importableColumns(): { [key: string]: ColumnConfig } {
        return this._classImportConfiguration?.columns;
    }

    /**
     * The ID configured for the class. This is needed for column-order-provider
     */
    get classId(): string {
        return this._classImportConfiguration?.importable?.id;
    }

    get columnNamesId(): string {
        return this._classImportConfiguration?.importable?.columnNamesId;
    }

    get restructuringColumn(): string | undefined {
        return Object.keys(this._classImportConfiguration.columns).find(k => !!this._classImportConfiguration.columns[k]?.restructure);
    }

    /**
     * Reads all objects from a given Excel or CSV file and parses them to the given
     * Configuration.
     * @param {string} data binary representation of the file to read
     * @returns {Observable<ImportEvent<T> | ImportError<T>> | null} Updates after every read object. Returns null if no objects are found
     */
    readFromFile(data: string): Observable<ImportEvent<T> | null> {
        // reading plane indexed objects
        return this.readFile(data)
            .pipe(
                mergeMap((result) => {
                    // no object so there is nothing to import
                    if (!result.importedObjects) {
                        // showing that nothing is found
                        return of(null);
                    }

                    return this.parseImportedObjects(result.importedObjects, result.headerDriven);

                })
            );
    }

    /**
     * Checks if all required properties are set in the provided Object
     * @param {T = any} importedObject to be checked
     * @returns true if there are no missing required properties
     */
    importIsComplete(importedObject: any): boolean {
        const requiredColumns = Object.entries(this._classImportConfiguration.columns).filter((k) => k[1]?.required).map((k) => k[0]);
        const missingProperties = Object.keys(importedObject).filter((k) => requiredColumns.includes(k) && importedObject[k] === null);
        const isComplete = missingProperties?.length === 0;
        if (!isComplete) {
            this._logger?.info(`Import was marked as incomplete for missing properties ${missingProperties}`, this._loggingSource);
        }
        return isComplete;
    }

    /**
     * Creates an observable by which every imported object is emitted by its classification
     * @param importedObjects
     * @param headerDriven if the header line shall be interpreted as property names
     * @returns
     */
    private parseImportedObjects(importedObjects: {
        [key: number]: string
    }[], headerDriven: boolean): Observable<ImportEvent<T>> {
        // creating the main-emitting observable
        return new Observable((observer) => {
            // emitting the start event to send the amount of assuming ly imported objets
            observer.next({start: importedObjects.length});

            // creating the subscription to destroy it on unsubscribe()
            let columnOrderSubscription: Subscription;

            // reading the order of columns defined is class declaration as fallback
            const basicColumnOrderDefinition = Object.keys(this._classImportConfiguration.columns)

            // check if service can retrieve a system-defined column order
            if (this._importDefinitionsProvider) {
                // watching system defined column order at this point to have the possibility to destroy it
                columnOrderSubscription = this._importDefinitionsProvider
                    .getColumnsInOrder(this._classImportConfiguration.importable.id) // reading order by provided ID
                    .subscribe((columnOrder) => {
                        // if there are no cols provided service can still use the basic order
                        this.parseImportedObject(importedObjects, columnOrder ?? basicColumnOrderDefinition, observer, headerDriven);
                    });
            } else {
                // using basic order
                this.parseImportedObject(importedObjects, basicColumnOrderDefinition, observer, headerDriven);
            }

            // destroying subscription when the using component unsubscribes this
            return {
                unsubscribe() {
                    columnOrderSubscription.unsubscribe();
                }
            }
        });
    }

    /**
     * Tries to parse all imported indexed-objects
     * @param importedObjects
     * @param columnOrder
     * @param observer
     * @param headerDriven
     */
    private parseImportedObject(importedObjects: any[], columnOrder: string[], observer: Observer<ImportEvent<T>>, headerDriven: boolean): void {
        // iterating all objects
        for (const line in importedObjects) {

            // user readable line
            const fileLine: number = Number(line) + 1;

            // unknown typed object that will be imported
            const obj = importedObjects[line];

            const importedObject: any = headerDriven ? this.parseByHeader(obj) : this.parseByIndex(obj, columnOrder);

            // checking whether the config can accept an Object or not
            const importHasAllRequiredProperties = this.importIsComplete(importedObject);

            // emitting the corresponding event for a complete entity or an incomplete
            if (importHasAllRequiredProperties) {
                this._logger?.info(`Import at line ${fileLine} was successfull`, this._loggingSource);
                observer.next({complete: importedObject});
            } else {
                this._logger?.info(`Import at line ${fileLine} was incomplete`, this._loggingSource);
                observer.next({inComplete: importedObject});
            }
        }

        // saving logs to system storage after everything is done
        this._logger?.commit(this._loggingSource);

        // ending and destroying observable
        observer.complete();
    }

    private parseByHeader(obj: { [key: string]: any }): { [key: string]: any } {
        this._logger?.debug(`Import for class ${this._importClass.name} has started by reading headers`, this._loggingSource);
        for (const property in obj) {
            const objValue = obj[property];
            const importProp = Object.values(this.importableColumns).find(c => c.view === property);

            const systemKey = !importProp ? this.restructuringColumn : importProp.propertyKey;

            if (systemKey) {
                if (!importProp) {
                    obj[systemKey] = obj[systemKey] ? {
                        ...obj[systemKey],
                        [property]: String(objValue)
                    } : {[property]: String(objValue)};
                    delete obj[property];
                } else {
                    const parsedEntry = this.checkDataType(objValue, importProp);
                    this.addPropertyEntry(obj as any, parsedEntry, systemKey, importProp);
                    delete obj[property];
                }
            }
        }
        return obj;
    }

    private parseByIndex(obj: { [key: string]: any }, columnOrder: string[]): { [key: string]: any } {
        this._logger?.debug(`Import for class ${this._importClass.name} has started by reading indexes`, this._loggingSource);
        let importedObject: any = {};
        // iterating the key-value pairs of all objects to retrieve the number index mapped to the value
        for (const [key, value] of Object.entries(obj)) {
            const currentIndex = Number(key);
            // reading all information's about the current column
            const associatedColumn = columnOrder[currentIndex];
            const columnDefinition = this._classImportConfiguration.columns[associatedColumn];

            if (associatedColumn && columnDefinition && columnDefinition?.restructure) {
                // try reading next column into this restructuring one
                continue;
            } else if (associatedColumn && columnDefinition) {
                // parse imported entry to correct data-type if possible
                const parsedDataEntry = this.checkDataType(value as string, columnDefinition);
                // add the returned value (this is important for decision about complete and incomplete)
                this.addPropertyEntry(importedObject, parsedDataEntry, associatedColumn, columnDefinition);
            } else {
                if (associatedColumn && this.restructuringColumn) {
                    if (importedObject[this.restructuringColumn]) {
                        importedObject[this.restructuringColumn] = {
                            ...importedObject[this.restructuringColumn],
                            [associatedColumn]: value
                        };
                    } else {
                        importedObject[this.restructuringColumn] = {[associatedColumn]: value};
                    }
                }
            }

        }
        return importedObject;
    }

    /**
     * Reads the configuration of the class provided to the module
     *
     * @throws {Error}
     * If the class was not marked as @Importable
     *
     * @throws {Error}
     * If the @Importable class has no @Column property
     *
     * @private
     */
    private readImportConfiguration(): void {

        // reading provider of class to be imported
        // throws error by itself if no class was provided
        // added check if provided class is already the constructor of that class or not
        const providedClass = typeof this._importClass === 'function' ? this._importClass : (this._importClass as any)?.constructor;

        // can not import class that is not marked properly because we cant read settings-service
        if (!ImportableReader.isImportable(providedClass)) {
            this._logger?.critical('Could not find importable class', this._loggingSource);
            throw new ClassNotImportableError(`The provided class ${providedClass.name} is not marked as @Importable`, providedClass);
        }

        // can not import an object that's properties are not marked properly because in this case
        // we don't know what to check for
        if (!ColumnReader.hasColumns(providedClass)) {
            this._logger?.critical('Importable class has no columns', this._loggingSource);
            throw new NoImportableColumnsError(`The provided class ${providedClass.name} has no property marked as @Column`, providedClass);
        }

        this._classImportConfiguration = {
            importable: ImportableReader.getImportable(providedClass)!,
            columns: ColumnReader.getColumns(providedClass)!
        };
    }

    /**
     * Reads the file provided to the function as Excel file (doesn't matter if csv or real excel).
     * Maps the imported object to indexed objects
     *
     * @param dataUrl
     *
     * @returns
     */
    private readFile(dataUrl: string): Observable<{
        importedObjects: { [key: number]: string }[],
        headerDriven: boolean
    }> {
        this._logger?.trace(`Reading data-url...`, this._loggingSource);
        // reading all sheets from binary data
        const book: XLSX.WorkBook = XLSX.read(dataUrl, {type: 'binary', raw: true});


        // reading different data representations from file
        const sheetData = this.readPlaneImportData(book);

        const dataSetToImport = sheetData.headerLineFound && this._classImportConfiguration.importable.allowHeaderLine ? sheetData.headerDriven : sheetData.indexed;

        this._logger?.trace(`Import for class ${this._importClass.name} started ${sheetData.headerLineFound ? 'header driven' : 'headless'}`, this._loggingSource);

        // checking if system provides possibility to read definitions
        if (this._importDefinitionsProvider) {
            return this._importDefinitionsProvider.getImportRangeSymbols()
                .pipe(
                    take(1), // auto end after first emigration
                    map((importRangeSymbols: [string | null, string | null]) => {

                        this._logger?.trace(`Received range-symbols ${importRangeSymbols}`, this._loggingSource);

                        // checking if system defines input range without restrictions
                        if (!importRangeSymbols[0] && !importRangeSymbols[1]) {
                            return {importedObjects: dataSetToImport, headerDriven: sheetData.headerLineFound};
                        }

                        const importRange = this.readDataRange(sheetData, importRangeSymbols as [string, string]);

                        this._logger?.trace(`Reading entities of class ${this._importClass.name} from line ${importRange.start} to ${importRange.end}`, this._loggingSource);

                        // given import data has NO start or NO end sign but both are defined
                        if (importRange.start < 0 || !importRange.end || importRange.end < 0) {
                            this._logger?.critical('Could not find range-symbols in sheet', this._loggingSource);
                            throw new ImportRangeSymbolsMissingError(
                                `No ${!importRange.start ? 'start' : 'end'}-sign for imported data was found`,
                                {start: String(importRangeSymbols[0]), end: String(importRangeSymbols[1])}
                            );
                        }

                        // importing all objects between start and end sign in to the system
                        return {
                            importedObjects: dataSetToImport.slice(importRange.start, importRange.end),
                            headerDriven: sheetData.headerLineFound
                        };
                    })
                );
        }

        // no import signs defined => import everything
        return of({importedObjects: dataSetToImport, headerDriven: sheetData.headerLineFound});
    }

    private readDataRange(sheetData: {
        indexed: Array<{ [key: string]: any }>,
        headerDriven: Array<{ [key: string]: any }>,
        headerLineFound: boolean
    }, importRangeSymbols: [string, string]): { start: number, end: number } {
        // checking if the import already starts with the header line therefore we can use zero as starting index
        let startFound = sheetData.headerLineFound ? 0 : -1;

        // check start symbol for import without header
        if (!sheetData.headerLineFound) {
            startFound = sheetData.indexed.findIndex(v => v['0'].trim() === importRangeSymbols[0]) + 1;
        }

        // checking indexes of start and end of import
        let endFound = sheetData.indexed.findIndex(v => v['0'].trim() === importRangeSymbols[1]);

        // when there is a header-line we start reading at zero and therefore need to
        // read one line less to get to the END marker
        if (sheetData.headerLineFound) {
            endFound = endFound - 1;
        }


        return {start: startFound, end: endFound};
    }

    private readPlaneImportData(book: XLSX.WorkBook): {
        indexed: Array<{ [key: string]: any }>,
        headerDriven: Array<{ [key: string]: any }>,
        headerLineFound: boolean
    } {
        // reading all sheets of the file
        const sheets: XLSX.WorkSheet = book.Sheets;

        // check for classes that allow a header import beside the indexed one
        let headerJsons: any[] = [];
        let hasHeaderLine: boolean = true;
        if (this._classImportConfiguration.importable.allowHeaderLine) {
            headerJsons = Object.values(sheets)
                .map(sheet => XLSX.utils.sheet_to_json(sheet))
                .reduce((collected, curr) => [...collected, ...curr]);
            // starting at the first entry to avoid matching the START symbol
            hasHeaderLine = Object.keys(headerJsons[0]).slice(1).includes('__EMPTY');
        }

        // mapping all sheets into the lines of their csv representation
        const indexedJsons = Object.values(sheets)
            .map(v => XLSX.utils.sheet_to_csv(v, {
                FS: this._csvReadingSeparator,
                blankrows: false
            }).split('\n')) // csv representation to lines
            .reduce((collected, curr) => [...collected, ...curr], []) // collecting all lines into one array
            .filter((line) => line !== '')
            .map((v) => this.toIndexedJson(v)); // converting read lines into indexed-JSON objects

        return {headerDriven: headerJsons, indexed: indexedJsons, headerLineFound: !hasHeaderLine};
    }

    /**
     * Consumes object-date in csv format and creates and indexed JSON of it, where the index is the key
     * @param value csv object representation
     * @returns indexed json
     */
    private toIndexedJson(value: string): { [key: number]: string } {
        // splitting csv and mapping to index
        return value.split(this._csvReadingSeparator).reduce((res, curr, i) => {
            res[`${i}`] = curr;
            return res;
        }, {} as any);
    }

    /**
     * Checks whether a property is configured as list or not and injects the corresponding representation of data in the
     * given object
     * @param target
     * @param value
     * @param propertyName
     * @param columnDefinition
     * @private
     */
    private addPropertyEntry(target: any, value: ParsableDataType | null, propertyName: string, columnDefinition: ColumnConfig): void {
        if (columnDefinition.listValue) {
            target[propertyName] = (value as string)?.split(';')?.filter(v => !!v) ?? null;
        } else {
            target[propertyName] = value;
        }
    }

    /**
     * Checks the incoming data for match of data-type configured and parses if possible
     * @private
     */
    private checkDataType(value: string | number | null | undefined, columnDefinition: ColumnConfig): ParsableDataType | null {
        if (value === null || value === undefined || value === '') {
            return null;
        }

        if (typeof value === 'string') {
            value = value?.trim();
        }

        switch (columnDefinition.dataType) {
            case 'number':
                return this.parseNumber(value);
            case 'boolean':
                return this.parseBoolean(String(value))
            case 'date':
                return this.parseDate(String(value));
            case 'internal':
                if (columnDefinition.propertyValues) {
                    return this.matchInternalPropertyValues(value, columnDefinition?.propertyValues);
                }
                return value;
            default:
                return value;
        }
    }

    private parseDate(value: string | null | undefined): string | null {
        const date = dayjs(value, 'MM/DD/YY');
        return date.isValid() ? date.format('MM/DD/YYYY') : null;
    }

    private parseNumber(value: string | number | undefined | null): number | null {
        if (typeof value === 'string' && value !== '') {
            const numberString = value?.includes(',') ? value?.replace(',', '') : value;
            return isNaN(Number(numberString)) ? null : Number(numberString);
        }

        if (typeof value === 'number') {
            return value;
        }

        return null;
    }

    private parseBoolean(value: string | undefined | null): boolean | null {
        if (!value) {
            return null;
        }

        const trueValues = ['JA', 'YES', 'TRUE', 'WAHR'];
        const falseValues = ['NEIN', 'NO', 'FALSE', 'FALSCH'];
        if (trueValues.includes(value.toUpperCase())) {
            return true;
        } else if (falseValues.includes(value.toUpperCase())) {
            return false;
        }
        return null;
    }

    /**
     * Reads data and tries to find the matching, provided internal representation and returns the found, corresponding value
     * @param value the user given value to map to an internal representation
     * @param propertyValues the internal representation collection that holds all possible internal values
     * @private
     */
    private matchInternalPropertyValues(value: string | number, propertyValues: PropertyValues): ParsableDataType | null {
        if (!propertyValues) {
            return null;
        }
        const matchValue = typeof value === 'number' ? String(value).toLowerCase() : value.toLowerCase();
        return propertyValues?.find(v => v.view.toLowerCase() === matchValue || String(v.value)?.toLowerCase() === matchValue)?.value ?? null;
    }
}
