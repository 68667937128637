import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {WarehouseService} from '../../entity-state/warehouse/warehouse-service/warehouse.service';
import {SettingsService} from '../../settings';
import {
    InactiveWarehouseService
} from '../../entity-state/warehouse/inactive-warehouse-service/inactive-warehouse.service';
import {WarehouseSettings} from '../../settings/model/entity-settings';
import {Warehouse} from '../../domain/core-model';

@Pipe({
    name: 'warehouse',
    standalone: true
})
export class WarehousePipe implements PipeTransform, OnDestroy {

    private _objectKey: 'warehouseName' | 'description' = 'warehouseName';
    private _subscriptions = new Subject<void>();

    constructor(
        private settings: SettingsService,
        private _warehouses: WarehouseService,
        private _inactiveWarehouses: InactiveWarehouseService
    ) {
        this.settings.getEntitySettings(WarehouseSettings)
            .pipe(takeUntil(this._subscriptions))
            .subscribe((setting) => {
                this._objectKey = setting.warehouseUIProperty;
            });
    }

    ngOnDestroy() {
        this._subscriptions.next();
        this._subscriptions.complete();
    }

    transform(warehouseObj: number | null): Observable<string | null> | null;
    transform(warehouseObj: Warehouse | null): string | null;
    transform(warehouseObj: Warehouse | number | null): Observable<string | null> | (string | null) {
        if (!warehouseObj) {
            return null;
        }

        if (typeof warehouseObj === 'object') {
            return warehouseObj[this._objectKey];
        }

        // can not use local-only mode because it needs to find inactive warehouses
        return this._warehouses.getById({warehouseId: warehouseObj})
            .pipe(
                map((w) => w?.[this._objectKey] ?? null),
            );
    }
}
