import dayjs from 'dayjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ArticleGroup, Initiator} from '../tms-model';
import {Warehouse} from '../core-model';
import {CustomValidators} from '../../form-fields';

export {
    MaterialRequestCreateForm,
    RequestedArticle
}

class MaterialRequestCreateForm {
    transportInformation = new FormGroup<TransportInformation>(new TransportInformation());
    articleInformation = new FormGroup<ArticleInformation>(new ArticleInformation());

    constructor() {
    }
}


class TransportInformation {

    destination = new FormControl<Warehouse | null>(null, {
        nonNullable: true,
        validators: [Validators.required, CustomValidators.objectSelectedValidator]
    });
    articleGroupName = new FormControl<ArticleGroup | string | null>(null, {validators: [Validators.required, Validators.minLength(6)]})
    initiator = new FormControl<Initiator | null>(null);
    sendInitiatorMail = new FormControl<boolean>(false);
    toBeDoneBy = new FormControl<Date | dayjs.Dayjs | string>(dayjs());

    constructor() {
    }

}

class ArticleInformation {
    check = new FormControl<boolean>(true);
    articleCount = new FormControl<number | null>(null, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(0.1)]
    });
    articleSourceId = new FormControl<Warehouse | null>(null);
    explanatoryInformation = new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(3)]
    });

    constructor() {
    }
}

class RequestedArticle {
    constructor(
        public articleId: string,
        public description: string,
        public articleCount: number,
        public articleSourceId: number | undefined,
        public explanatoryInformation: string | undefined | null,
        public check: boolean
    ) {
    }
}
