import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {EnvironmentType} from './environments/environment.interface';

if (environment.production) {
  enableProdMode();
}

if (environment.environmentType === EnvironmentType.PRODUCTION) {
  console.log, console.debug = () => {
  };
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
