import {EMPTY, Observable, of, Subject, switchMap} from 'rxjs';
import {BuildFromEagerLoadCallback, EntityKey, KeyPropertyNames} from '../../../domain/utility-types';
import {map, tap} from 'rxjs/operators';
import {isArray} from '../../../domain/functions';
import {ISignalRService, SignalRService} from '../../../websocket';
import {StateAccess, StateOptions} from "./state.service.options";
import {inject, Injectable, OnDestroy, Type} from "@angular/core";
import {BaseEntity} from '../../../domain';
import {BaseApiService} from '../../../api/base-api-service/base-api.service';
import {IHttpResult} from '../../../api/interfaces/http-result.interface';
import {IState} from '../state.interface';
import {DeepPartial, PropertySearcher} from '../../../search/model';
import {searchAnd} from '../../../search/operators';
import {IStateLoader, StateStorage} from '../state-storage';
import {KeyBuilder} from '../key-builder';
import {collectForTime} from '../collect.operator';
import {IcsConfirmationService} from 'ics-core';

@Injectable({
    providedIn: null
})
export abstract class StateService<T extends BaseEntity, Q extends object = T> implements IState<T, Q>, IStateLoader<T>, OnDestroy {

    protected static PREFETCH_AMOUNT = 100;
    protected static PREFETCH_PAGE_INDEX = 1;
    // has to be public for eager loading service
    readonly keyBuilder: KeyBuilder<T>;

    // services
    protected _confirm!: IcsConfirmationService;
    protected _webSocket: ISignalRService<T> | undefined | null = null;
    protected _api: BaseApiService<T>;

    // typing
    protected _entityKeys: KeyPropertyNames<T>;
    protected _type: Type<T>;

    protected _eagerLoadingBuilder: BuildFromEagerLoadCallback<T> | null = null;

    protected searchProperty: PropertySearcher<T, Q> | undefined = undefined;

    protected readonly storage: StateStorage<T>;

    protected readonly destructionRef = new Subject<void>();

    protected constructor(
        protected _stateManagementAccessor: StateAccess<T>,
        protected _options: StateOptions<T> = new StateOptions<T>(),
        confirmationRef?: IcsConfirmationService
    ) {
        this._entityKeys = _stateManagementAccessor.primaryKeys;
        this._type = _stateManagementAccessor.type;

        this._api = this._loadApiService();

        this._webSocket = this._getWebsocket();

        this._confirm = confirmationRef ?? inject(IcsConfirmationService);

        this.keyBuilder = new KeyBuilder<T>(_stateManagementAccessor.primaryKeys);

        this.storage = new StateStorage<T>(this, this.keyBuilder);

        this._eagerLoadingBuilder = (this._type as any).buildFromEagerLoad;

        if (this._webSocket) {
            this.connectToWebSocket(this._webSocket);
        }
    }

    get state(): Observable<T[]> {
        return this.storage.state
            .pipe(
                map(s => s.filter(e => !this.isIncomplete(e)))
            )
    }

    get prefetchFn(): Observable<T[]> | undefined {
        if (!this._options.prefetching) {
            return undefined;
        }

        if (typeof this._options.prefetching === 'boolean') {
            return this._api.getPage(StateService.PREFETCH_PAGE_INDEX, StateService.PREFETCH_AMOUNT);
        }
        return this._options.prefetching!;
    }

    get refreshFn(): Observable<T[]> {
        return this._options.eagerLoading ? this._api.getEager() : this._api.getAll();
    }

    private get _webSocketConnected(): boolean {
        return !!this._webSocket?.isConnected;
    }

    getIncompleteEntities(query?: [DeepPartial<Q> | undefined, string | undefined]): Observable<T[]> {
        if (!query) {
            return this.storage.state.pipe(
                map(s => s.filter(e => this.isIncomplete(e))), // needs explicit check
            );
        }

        const [objQuery, stringQuery] = query;

        // need to access subject directly because otherwise inComplete will already be filtered
        return this.storage.state.pipe(
            map(s => s.filter(e => this.isIncomplete(e))), // needs explicit check
            searchAnd<T, Q>(objQuery ?? {}),
            searchAnd<T>(stringQuery ?? ''),
        );
    }

    search(query: DeepPartial<Q> | [DeepPartial<Q>, string | undefined]): Observable<T[]> {
        if (Array.isArray(query)) {
            const [objQuery, stringQuery] = query;

            return this.state.pipe(
                searchAnd<T>(stringQuery ?? ''),
                searchAnd<T, Q>(objQuery, this.searchProperty)
            );
        }

        return this.state.pipe(
            searchAnd({...query}, this.searchProperty),
        );
    }

    has(value: Partial<EntityKey<T>> | null): Observable<boolean> {
        if (!value) {
            return of(false);
        }
        return this.getById(this.keyBuilder.buildPrimaryKey(value as any))
            .pipe(
                map((e) => !!e),
            );
    }

    refresh(): void {
        this.storage.refresh();
    }

    getById(keyValue: EntityKey<T>, localOnly = false): Observable<T | null> {
        return this.state
            .pipe(
                map((state) => state.find((e) => this.keyBuilder.isSameKey(this.keyBuilder.buildPrimaryKey(e), keyValue)) ?? null),
                switchMap((entityInLocalState: T | null) => {
                    if (!entityInLocalState && !localOnly) {
                        return this._api.getById(keyValue as any);
                    }
                    return of(entityInLocalState);
                }),
            );
    }

    createAndGet(entity: T): Observable<T | null> {
        return this.createInternal(entity);
    }

    create(...entities: T[]): Observable<boolean> {
        return this.createInternal(entities).pipe(
            map((entity) => !!entity)
        );
    }


    update(...entities: T[]): Observable<boolean> {
        return this.updateInternal(entities)
            .pipe(
                map((res) => isArray(res) ? !((res as Array<T>)?.length === 0) : !!res)
            )
    }

    delete(entity: EntityKey<T>): void {
        this._confirm.askForConfirmation({
            title: 'ui.common.confirmations.delete.title',
            message: 'ui.common.confirmations.delete.message',
        }).pipe(
            switchMap(() => this._api.delete(this.keyBuilder.buildPrimaryKey(entity as any))),
            switchMap(success => success ? this.storage.remove(entity) : EMPTY)
        ).subscribe();
    }

    ngOnDestroy() {
        this.storage.close();
        this.destructionRef.next();
        this.destructionRef.complete();
    }

    protected updateInternal(entity: T): Observable<T | null>;

    protected updateInternal(entity: T[]): Observable<T[] | null>;

    protected updateInternal(entities: T | T[]): Observable<T | T[] | null> {
        let update = entities;
        if (Array.isArray(entities) && entities.length == 1) {
            update = entities[0];
        }

        if (Array.isArray(update)) {
            return this._api.updateEntities(update).pipe(
                map((res) => res.responseValue),
                tap(r => {
                    if (r) {
                        this.updateState(r, 'update')
                    }
                })
            );
        } else {
            return this._api.update(update)
                .pipe(
                    map(res => res.responseValue),
                    tap((r) => {
                        if (r) {
                            this.updateState(r, 'update');
                        }
                    })
                );
        }
    }

    createInternal(entities: T[]): Observable<T[] | null>;

    createInternal(entities: T): Observable<T | null>;

    createInternal(entities: T[] | T): Observable<T | null> | Observable<T[] | null> {
        const saveTap = tap((res: IHttpResult<T | null> | IHttpResult<T[] | null>) => {
            if (res?.responseValue) {
                this.updateState(res.responseValue, 'create');
            }
        });

        const typeMap = map((res: IHttpResult<T | null> | IHttpResult<T[] | null>) => res.responseValue);

        if (Array.isArray(entities)) {
            return this._api.createNewEntities(entities).pipe(saveTap, typeMap) as Observable<T[] | null>;
        }
        return this._api.create(entities).pipe(saveTap, typeMap) as Observable<T | null>;
    }

    protected connectToWebSocket(websocket: ISignalRService<T>): void {
        websocket.getNewNotification()
            .pipe(collectForTime(300))
            .subscribe(collected => this.storage.add(collected));

        websocket.getUpdatedNotification()
            .pipe(collectForTime(300))
            .subscribe((collected) => this.storage.replace(collected));

        websocket.getDeletedNotification()
            .pipe(collectForTime(300))
            .subscribe((collected) => this.storage.remove(collected));
    }

    protected updateState(entities: T[] | T, action: 'create' | 'update' | 'delete', local = true): void {
        console.debug(`Updating state for class ${this._type.name} with action: ${action} for changes from ${local ? 'local' : 'remote'}. Websocket is ${this._webSocketConnected ? 'connected' : 'lost'}`, {entities});

        if (action === 'create') {
            this.storage.add(entities);
            return;
        }

        if (action === 'update') {
            this.storage.replace(entities);
            return;
        }

        if (action === 'delete') {
            this.storage.remove(entities);
            return;
        }
    }

    protected writeEagerLoadingProperties(...entities: T[]): T[] {
        if (this._options.eagerLoading && this._eagerLoadingBuilder) {
            return entities.map(this._eagerLoadingBuilder);
        }
        return entities;
    }

    protected isIncomplete(e: T) {
        return e.isComplete === false;
    }

    private _getWebsocket(): ISignalRService<T> | null {
        const {webSocket} = this._stateManagementAccessor;

        if (!webSocket) {
            return null;
        }

        if (typeof webSocket === 'function' || typeof webSocket === 'string') {
            return inject<SignalRService<T>>(webSocket as any);
        }

        return webSocket;
    }

    private _loadApiService(): BaseApiService<T> {
        const {api} = this._stateManagementAccessor;

        if (!api) {
            return inject<BaseApiService<T>>(this._stateManagementAccessor.type);
        }

        if (typeof api === 'function') {
            return inject(api);
        }

        return api;
    }
}
