import {Injectable} from '@angular/core';
import {Vehicle} from "../../domain/core-model";
import {StateService} from '../model/state/state.service';

@Injectable({
    providedIn: 'root'
})
export class VehicleService extends StateService<Vehicle> {

    constructor() {
        super({primaryKeys: ['vehicleId'], type: Vehicle, webSocket: 'VehicleSignalRService'});
    }
}
