export {IconRegistry};

const IconRegistry: { iconName: string, iconPath: string }[] = [
    {
        iconName: 'handling-unit',
        iconPath: 'logistics-delivery-cart-svgrepo-com.svg'
    },
    {
        iconName: 'storage-bin',
        iconPath: 'warehouse-svgrepo-com.svg'
    },
    {
        iconName: 'screw-group',
        iconPath: 'screws-group-svgrepo-com.svg'
    },
    {
        iconName: 'screw-single',
        iconPath: 'screw-single-svgrepo-com.svg'
    },
    {
        iconName: 'barcode',
        iconPath: 'barcode_scan_icon.svg'
    },
    {
        iconName: 'storage-area',
        iconPath: 'area-svgrepo-com.svg'
    },
    {
        iconName: 'file-tray-full-outline',
        iconPath: "file-tray-full-outline.svg"
    },
    {
        iconName: 'file-tray-full',
        iconPath: "file-tray-full.svg"
    }
];
