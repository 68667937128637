import {searchOr} from '../../../../search/operators';
import {map} from 'rxjs/operators';
import {UserService} from '../../../../entity-state/user-service/user.service';
import {TransportTask} from '../model/transport-task.model';
import {Observable} from 'rxjs';
import {TransportTaskQuery} from '../model/transport-task.query';

export function filterTransportTaskByOperator$(
    userService: UserService,
    query: NonNullable<TransportTaskQuery['operatorName']>,
    searchList: TransportTask[]
): Observable<TransportTask[]> {
    return userService.state.pipe(
        searchOr({firstName: query, lastName: query, email: query}),
        map(users => searchList.filter(t => users.find(u => u.userId === t.operator)))
    );
}
