import {Component, ElementRef, NgZone} from '@angular/core';
import {BaseButtonDirective} from '../base-button/base-button.directive';

@Component({
  selector: 'app-transport-button',
  templateUrl: './transport-button.component.html',
  styleUrls: ['./transport-button.component.scss'],
})
export class TransportButtonComponent extends BaseButtonDirective {

  constructor(elementRef: ElementRef, ngZone: NgZone) {
    super(elementRef, ngZone);
  }

}
