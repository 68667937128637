import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {filter, first} from 'rxjs/operators';
import {concat, interval, switchMap} from 'rxjs';
import {IcsConfirmationService} from 'ics-core';

@Injectable({
    providedIn: 'root'
})
export class PwaService {

    constructor(
        private _serviceWorkerUpdate: SwUpdate,
        private _appRef: ApplicationRef,
        private _confirm: IcsConfirmationService
    ) {
        // starting to track updates
        this.trackUpdates();

        // starting to prompt user on every update
        this.promptOnUpdate();

        // tracking for version mismatches that lead to unrecoverable states and tell the user to refresh
        this.handleUnrecoverableStates();
    }

    private trackUpdates(): void {
        // Allow the app to stabilize first, before starting
        // polling for updates with `interval()`.
        const appIsStable = this._appRef.isStable.pipe(first(isStable => isStable === true));
        const everySixHours = interval(6 * 60 * 60 * 1000);
        const everySixHoursOnceAppIsStable = concat(appIsStable, everySixHours);

        everySixHoursOnceAppIsStable.subscribe(() => this.checkForUpdate());
    }

    private checkForUpdate() {
        if (this._serviceWorkerUpdate.isEnabled) {
            this._serviceWorkerUpdate.checkForUpdate();
        }
    }

    private promptOnUpdate() {
        this._serviceWorkerUpdate.versionUpdates
            .pipe(
                filter((ev) => ev.type === "VERSION_READY")
            ).subscribe((event) => this.askUserToUpdate());
    }

    private askUserToUpdate() {
        this._confirm.askForConfirmation({
            title: 'ui.common.confirmations.sw.update.title',
            message: 'ui.common.confirmations.sw.update.message'
        }).pipe(
            switchMap(() => this._serviceWorkerUpdate.activateUpdate()),
        ).subscribe((message) => document.location.reload());
    }

    private handleUnrecoverableStates(): void {
        this._serviceWorkerUpdate.unrecoverable.pipe(
            this._confirm.insertInformation((ev) => {
                return {
                    title: 'ui.common.errors.sw.update.unrecoverable.state.title',
                    message: 'ui.common.errors.sw.update.unrecoverable.state.message'
                }
            })
        ).subscribe();
    }
}
