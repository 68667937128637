import {SettingsMap} from '../settings-map.model';
import {ViewNames} from '../../../constants';
import {ActionColumn, DefaultActionColumns} from '../../../table';
import {DisplayNames} from '../../../domain/utility-types';
import {Article} from '../../../domain/core-model';
import {EntitySettings} from './entity-settings.interface';

export {
  ArticleSettings
}

class ArticleSettings extends EntitySettings {

  additionalFields = new Array<string>();
  additionalFieldsMaxAmount = 5;
  additionalFieldMinLength = 3;
  articleImportOrder: string[] = new Array<string>();
  articleDisplayStrings: DisplayNames<Article> = ViewNames.article;
  articleFilters = new Array<string>('articleId', 'description', 'articleWeight');
  articleTableColumns = new Array<string>('articleId', 'description', 'articleWeight', 'details', 'edit', 'delete');

  constructor(settings: SettingsMap) {
    super();
    if (settings['web-article-fields-global']) {
      this.additionalFields = settings['web-article-fields-global'];
    }

    const maxAmount = Number(settings['web-article-fields-max-amount']?.[0]);

    if (!isNaN(maxAmount)) {
      this.additionalFieldsMaxAmount = maxAmount;
    }

    const minLength = Number(settings['web-article-field-min-length']?.[0]);
    if (!isNaN(minLength)) {
      this.additionalFieldMinLength = minLength;
    }

    if (settings['article-column-names'] && settings['web-import-column-naming-preferred']) {
      const preferImportNaming: boolean = JSON.parse(settings['web-import-column-naming-preferred']![0]);
      if (preferImportNaming) {
        this.articleDisplayStrings = {...this.articleDisplayStrings, ...JSON.parse(settings['article-column-names']![0])};
      }
    }

    const columns = settings['web-selected-article-table-columns'];
    if (columns) {
      const actionColumns = columns.length >= 10 ? [ActionColumn.ActionMenu] : DefaultActionColumns;
      this.articleFilters = columns;
      this.articleTableColumns = [...columns, ...actionColumns];
    }

    if (settings['article-import-order']) {
      this.articleImportOrder = settings['article-import-order'];
    }
  }

  toMap(): Partial<SettingsMap> {
    return {
      'web-article-field-min-length': [String(this.additionalFieldMinLength)],
      'web-article-fields-max-amount': [String(this.additionalFieldsMaxAmount)],
      'web-article-fields-global': this.additionalFields,
      'article-column-names': [JSON.stringify(this.articleDisplayStrings)],
      'web-selected-article-table-columns': this.articleTableColumns

    };
  }
}
