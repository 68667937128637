import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ArticleGroupService} from '../../entity-state/article-group-service/article-group.service';
import {ArticleGroup} from '../../domain/tms-model';

@Pipe({
    name: 'articleGroup',
    standalone: true
})
export class ArticleGroupPipe implements PipeTransform {

    constructor(
        private _articleGroups: ArticleGroupService
    ) {
    }

    transform(value: number, alternativeValue?: string): Observable<string | null>
    transform(value: ArticleGroup | number | null, alternativeValue?: string): string | null | Observable<string | null> {
        if (!value) {
            return alternativeValue ?? null;
        }

        if (typeof value === 'number') {
            return this._articleGroups.getById({articleGroupId: value}, true)
                .pipe(
                    map((a: ArticleGroup | null) => a?.name ?? null)
                )
        }

        return value.name;
    }

}
