import {ISelectionProperty} from '../../../../domain/interfaces';


export enum TransportTaskState {
    Created = 0,
    InProgress = 1,
    Finished = 2,
    ToBeChecked = 3
}

export enum TransportTaskStateDescription {
    Created = 'Erstellt',
    InProgress = 'In Bearbeitung',
    Finished = 'Abgeschlossen',
    ToBeChecked = 'Prüfen'
}

export const TransportTaskStateSelections: ISelectionProperty[] = [
    {
        view: TransportTaskStateDescription.Created,
        value: TransportTaskState.Created
    },
    {
        view: TransportTaskStateDescription.InProgress,
        value: TransportTaskState.InProgress
    },
    {
        view: TransportTaskStateDescription.Finished,
        value: TransportTaskState.Finished
    },
    {
        view: TransportTaskStateDescription.ToBeChecked,
        value: TransportTaskState.ToBeChecked
    }
];


export function toReadableTransportTaskState(state: TransportTaskState) {
    switch (state) {
        case TransportTaskState.Created:
            return TransportTaskStateDescription.Created
        case TransportTaskState.InProgress:
            return TransportTaskStateDescription.InProgress
        case TransportTaskState.ToBeChecked:
            return TransportTaskStateDescription.ToBeChecked
        case TransportTaskState.Finished:
            return TransportTaskStateDescription.Finished
    }
}
