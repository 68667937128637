import {ITypeConfig} from '../interfaces';
import {InputTypeEnum} from '../enums';
import {Export} from 'src/app/export';
import {BaseEntity} from '../base-entity.model';


export {User, UserConfig};

class User extends BaseEntity {
    static buildUserName(user: Pick<User, 'firstName' | 'lastName' | 'email'>, options?: { shortenFirstName: boolean }): string | null {
        // early return if there is no user
        if (!user) {
            return null;
        }
        if (options?.shortenFirstName) {
            const result = `${user?.firstName?.[0] ?? ''}. ${user?.lastName}`;
            return user?.lastName ? result : user.email;
        }
        return user?.firstName && user?.lastName ? `${user.lastName}, ${user.firstName}` : user.email;
    }

    constructor(
        public userId: string,
        public email: string,
        @Export({propertyKey: 'firstName', view: 'Mitarbeiter', combine: 'lastName', alt: 'email', extern: true,})
        public firstName: string | null,
        public lastName: string | null,
        public lastLogin: Date | string,
        public transportTasks?: string[],
    ) {
        super();
    }
}

const UserConfig: ITypeConfig<User> = {
    properties: [
        {
            key: 'userId',
            view: 'ID',
            input: {
                inputType: InputTypeEnum.Number,
                isSettable: false,
                isSearchable: false,
                required: true
            }
        },
        {
            key: 'email',
            view: 'E-Mail',
            input: {
                inputType: InputTypeEnum.Email,
                isSettable: false,
                isSearchable: true,
                required: true
            }
        },
        {
            key: 'firstName',
            view: 'Vorname',
            input: {
                inputType: InputTypeEnum.Text,
                isSettable: true,
                isSearchable: true,
                required: true
            }
        },
        {
            key: 'lastName',
            view: 'Nachname',
            input: {
                inputType: InputTypeEnum.Text,
                isSettable: true,
                isSearchable: true,
                required: true
            }
        },
        {
            key: 'transportTasks',
            view: 'Aktive Transporttasks',
            input: {
                inputType: InputTypeEnum.Text,
                isSettable: false,
                isSearchable: false,
                required: true
            }
        },
        {
            key: 'lastLogin',
            view: 'Letzte Anmeldung',
            input: {
                inputType: InputTypeEnum.Date,
                isSearchable: true,
                isSettable: false,
                required: false
            }
        }
    ],
    keyNames: [
        'userId', 'firstName', 'lastName', 'email', 'lastLogin'
    ]
};
