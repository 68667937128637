import 'reflect-metadata';
import {ColumnReader} from './column.decorator';

// eslint-disable-next-line @typescript-eslint/ban-types
type Class = Function;

const IMPORTABLE_DECORATOR_KEY = Symbol('@Importable');

/**
 * Configuration of a Class marked as {@link Importable}.
 *
 * Provides the ID of the class wich can be used to create a setting
 * in db to check the user-expected order of marked columns.
 *
 * Provides the ui-name of the Class so ImportModule can give better Feedback
 * to user, which Class gets imported by now.
 */
interface Importable {
    /**
     * The id of the importable class
     */
    id: string;

    /**
     * The id to retrieve the column-names from the {@link ColumnOrderProvider}
     */
    columnNamesId: string;

    /**
     * Allows the existnce of a header line in an imported file.
     *
     * Only works with classes that have a property that is marked with restructure, because this property will be
     * used as storage for unknown and overflowing fields.
     *
     * If a header line is found during readin process the existence of the START-Symbol is obsolete and will be ignored
     * If no header line is found the service will expect that it shall import as normal based on property order between START and END
     */
    allowHeaderLine?: boolean;
}

/**
 * Decorator that marks a Class as Importable by ImportModule.
 * Provides the needed Config to import instances of a classs.
 *
 * @decorator
 * @external
 * @param {Importable} config
 * @returns {ClassDecorator}
 */
function importable(config: Importable): ClassDecorator {
    return function (constructor: Class) {
      const classColumns = ColumnReader.getColumns(constructor);
        if (config.allowHeaderLine && classColumns && !Object.values(classColumns)?.find(c => c.restructure)) {
            throw new Error(`The Importable decorator of the class ${constructor.name} allows a header line but has no restructuring property`);
        }
        Reflect.defineMetadata(IMPORTABLE_DECORATOR_KEY, config, constructor);
    }
}

/**
 * Checks wheter a class is marked as importable or not
 *
 * @external
 * @param {Function} constructor the constructor of class or easier to say the class itself
 * @returns {boolean} true if the class is importable
 */
function isImportable(constructor: Class): boolean {
    return Reflect.hasMetadata(IMPORTABLE_DECORATOR_KEY, constructor);
}

/**
 * Returns the config of the importable class if one was found else returns null
 *
 * @external
 * @param {Function} constructor the constructor of class or easier to say the class itself
 * @returns {Importable | null } the config of the given class
 */
function getImportable(constructor: Class): Importable | null {
    if (!isImportable(constructor)) {
        return null;
    }
    return Reflect.getMetadata(IMPORTABLE_DECORATOR_KEY, constructor) ?? null;
}

// adding context to reading functions of importable decorator
const ImportableReader = {
    isImportable,
    getImportable
};

export { importable as Importable, Importable as ImportableConfig, ImportableReader };
