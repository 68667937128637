import {Type} from "@angular/core";
import {KeyPropertyNames} from "../../../domain/utility-types";
import {ISignalRService, SignalRService} from "../../../websocket";
import {BaseApiService} from '../../../api/base-api-service/base-api.service';
import {Observable} from 'rxjs';
import {BaseEntity} from "../../../domain";

export {
    StateAccess,
    StateOptions,
    StateConfig
}

class StateConfig<T extends BaseEntity> {
    constructor(
        public stateAccess: StateAccess<T>,
        public stateOptions: StateOptions<T>
    ) {
    }
}

class StateAccess<T extends BaseEntity> {
    constructor(
        public type: Type<T>,
        public primaryKeys: KeyPropertyNames<T>,
        public api?: BaseApiService<T> | Type<BaseApiService<T>> | null,
        public webSocket?: ISignalRService<T> | Type<SignalRService<T>> | string | null
    ) {
    }
}

class StateOptions<T> {

    prefetching?: boolean | Observable<T[]> = false;
    eagerLoading?: boolean = true;

    constructor(options?: Partial<StateOptions<T>>) {

        if (options?.prefetching !== undefined) {
            this.prefetching = options.prefetching;
        }

        if (options?.eagerLoading !== undefined) {
            this.eagerLoading = options.eagerLoading;
        }
    }

}
