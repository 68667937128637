import {Component, Inject, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {filterObjectQuery} from 'src/app/domain/functions';
import {LoggingService} from '../logging-service/logging.service';
import {LogEntry, LogEntryQuery} from '../model/logg-entry.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SharedModule} from '../../shared';
import {LogFilterComponent} from '../log-filter/log-filter.component';
import {LocalizedDatePipe} from '../../pipes';
import {LogLevelComponent} from '../log-level/log-level.component';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-log-terminal',
    templateUrl: './log-terminal.component.html',
    styleUrls: ['./log-terminal.component.scss'],
    imports: [
        SharedModule,
        LogFilterComponent,
        LocalizedDatePipe,
        LogLevelComponent,
        TranslateModule
    ],
    standalone: true
})
export class LogTerminalComponent implements OnInit, OnDestroy {

    /**
     * The source of logs the user shall see
     */
    @Input('logSource') logSourceInput?: string;

    /**
     * Filter-predicate for the logs that get displayed to the user
     */
    @Input() filter?: Partial<LogEntry>;

    /**
     * the currently in this view-model saved logs that are displayed to the user
     */
    logs: LogEntry[] = [];

    loading = true;

    protected filtersActive = false;

    private _logSource?: string;
    // handle for destruction
    private _subscriptions: Subject<void> = new Subject();

    constructor(
        private logger: LoggingService,
        @Optional() public dialogRef?: MatDialogRef<LogTerminalComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data?: { logSource: string, filter: Partial<LogEntry> }
    ) {
    }


    ngOnInit(): void {

        // determining the scope of the logs to be shown
        this._logSource = this.data?.logSource ?? this.logSourceInput ?? 'GLOBAL';

        this.filtersActive = this._logSource === 'GLOBAL';

        // selecting the correct source from the service to the scope
        const source = this._logSource === 'GLOBAL' ? this.logger.getGlobalLogs() : this.logger.getCurrentLogs(this._logSource);

        // subscribing to logs of given source
        source.pipe(takeUntil(this._subscriptions)) // destruction handle
            .subscribe((logs: LogEntry[]) => {
                this.loading = false;
                if (logs) {
                    // filtering logs if filter is present
                    if (this.data?.filter) {
                        this.logs = filterObjectQuery(logs, this.data?.filter ?? this.filter);
                    } else {
                        this.logs = logs;
                    }
                }
            });
    }

    /**
     * Searches logs based on user input
     * @param {LogEntryQuery} query for matching log content
     */
    searchLogs(query: LogEntryQuery): void {
        this.logger.search(query, this._logSource ? {source: this._logSource} : undefined);
    }

    /**
     * Ends all subscriptions
     */
    ngOnDestroy(): void {
        this._subscriptions.next();
        this._subscriptions.complete();
    }
}
