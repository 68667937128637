import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
  QueryList,
  Self,
  ViewChildren
} from '@angular/core';
import {CustomFormFieldComponent} from '../custom-form-field.component';
import {ControlValueAccessor, FormArray, FormControl, NgControl, UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {FocusMonitor} from '@angular/cdk/a11y';
import {insertAtIndex, isArray} from '../../domain/functions';
import {takeUntil} from 'rxjs/operators';
import {CustomControl} from "../model/custom-control.model";

@Component({
  selector: 'app-array-form-field',
  templateUrl: './array-form-field.component.html',
  styleUrls: ['./array-form-field.component.scss'],
  providers: [
    {provide: CustomFormFieldComponent, useExisting: forwardRef(() => ArrayFormFieldComponent)}
  ]
})
export class ArrayFormFieldComponent extends CustomControl<Array<string> | Array<number>> implements AfterViewInit, OnDestroy, ControlValueAccessor {

  @ViewChildren('formArrayInput') inputs!: QueryList<ElementRef>;

  control: FormArray<FormControl<string | number>>;

  stateChanges = new Subject<void>();

  private _subscriptions: Subject<void> = new Subject();

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super();

    this.control = new FormArray([new FormControl<string | number>('', {nonNullable: true})]);

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _maxValues = 5;

  /**
   * Defines the maximum amount of values possible in the array
   */
  @Input()
  get maxValues(): number {
    return this._maxValues;
  }

  set maxValues(value: number) {
    this._maxValues = value;
  }

  private _minValues = 1;

  /**
   * Defines the minimum amount of values possible in the array
   */
  @Input()
  get minValues(): number {
    return this._minValues;
  }

  set minValues(value: number) {
    this._minValues = value;
  }

  private _layout: 'col' | 'row' = 'row';

  get layout(): 'col' | 'row' {
    return this._layout;
  }

  @Input()
  set layout(value: 'col' | 'row') {
    this._layout = value;
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.control.disable();
    }
  }

  @Input()
  get value(): (string | number)[] {
    return this.control.value;
  }

  set value(values: (string | number)[] | undefined) {
    if (isArray(values)) {
      values?.forEach((val, index) => {
        this.control.at(index) ? this.control.at(index).patchValue(val) : this.control.push(new FormControl(val, {nonNullable: true}));
      });
    }
    this.stateChanges.next();
  }

  get notHasMaximumValues(): boolean {
    return this.control.length < this._maxValues;
  }

  get notHasMinimumValues(): boolean {
    return this.control.length > this._minValues;
  }

  onChange = (_: any) => {
  };

  onTouched = () => {
  };

  ngAfterViewInit(): void {
    this.inputs?.changes
      .pipe(takeUntil(this._subscriptions))
      .subscribe((val: QueryList<ElementRef>) => val.last.nativeElement.focus());
  }

  writeValue(value: string[] | number[]): void {
    this.value = value;
    this._handleInput();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  addControl(index?: number): void {
    if (this.notHasMaximumValues) {
      const ctr = new FormControl('', {nonNullable: true});
      index ? insertAtIndex(this.control.controls, index, ctr) : this.control.push(ctr);
      this.stateChanges.next();
    }
  }

  removeControl(index?: number): void {
    if (this.notHasMinimumValues) {
      this.control.removeAt(index ?? this.control.controls.length - 1);
      this.stateChanges.next();
    }
  }

  controlAtIndex(index: number): UntypedFormControl {
    return this.control.at(index) as UntypedFormControl;
  }

  ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  updateArrayCount(event: KeyboardEvent, i: number, action: 'add' | 'remove') {
    event.preventDefault();
    event.stopPropagation();

    if (action === 'add') {
      this.addControl(i);
      return;
    }

    this.removeControl(i);
    return;
  }
}
