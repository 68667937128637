<div class="vehicletype-input-container" [attr.aria-labelledby]="_formField?.getLabelId()" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
  <mat-form-field [appearance]="appearance" [color]="color" [style]="style">
    <mat-label>{{ label ?? "Fahrzeugtyp" }}</mat-label>
    <input
      [required]="required"
      matInput
      [formControl]="control"
      class="vehicletype-input-element"
      aria-label="Fahrzeugtyp"
      (input)="_handleInput()"
      [matAutocomplete]="vehicletypeComplete"
      [tabindex]="tabindex"
      #vehicletypeInput
    />
    <cdk-virtual-scroll-viewport [itemSize]="10" [minBufferPx]="200" [maxBufferPx]="400">
      <mat-autocomplete autoActiveFirstOption (optionSelected)="_handleInput()" [displayWith]="displayWithDescription.bind(this)" #vehicletypeComplete>
        <mat-option *cdkVirtualFor="let vehicletype of filteredVehicletypeOptions | async" [value]="vehicletype.vehicletypeId">
          <span>{{ vehicletype.vehicletypeId }}</span> | <small>{{ vehicletype.description }}</small>
        </mat-option>
      </mat-autocomplete>
    </cdk-virtual-scroll-viewport>
    <mat-progress-spinner mode="indeterminate" *ngIf="optionsLoading" color="accent" diameter="16" matSuffix></mat-progress-spinner>
    <mat-error>
      <app-form-errors [control]="control" [entityName]="label ?? 'Fahrzeugtyp'"></app-form-errors>
    </mat-error>
  </mat-form-field>
</div>
