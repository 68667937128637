import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {DateTimeService} from 'src/app/core';
import {LogEntry, LogEntryQuery, LogLevel} from 'src/app/logging/model/logg-entry.model';
import {filterObjectQuery, isArray} from 'src/app/domain/functions';
import {ImportLoggingProvider} from 'src/app/import';
import dayjs from 'dayjs';
import {BaseApiService} from '../../api/base-api-service/base-api.service';

type SourcedLogEntry = { [key: string]: LogEntry[] };

@Injectable({
    providedIn: 'root'
})
export class LoggingService implements ImportLoggingProvider<LogEntry> {

    // exposed states that serve data to components
    private _localLogs: BehaviorSubject<SourcedLogEntry> = new BehaviorSubject<SourcedLogEntry>({});
    private _globalLogs: BehaviorSubject<LogEntry[]> = new BehaviorSubject<LogEntry[]>([]);

    // both local states that are used for a backup on filtering
    private _latestLogState: SourcedLogEntry = {};
    private _latestGlobalState: LogEntry[] = [];

    // common for all logs coming from this app
    private readonly _logType = 'WEB';

    // fallback logging source
    private readonly _unknownSource = 'UNKOWN';

    constructor(
        private _dateTime: DateTimeService,
        @Inject(LogEntry) private _loggingApi: BaseApiService<LogEntry>
    ) {
    }

    /**
     * Grants access to the local logs of the given source
     * @param source
     * @returns
     */
    getCurrentLogs(source: string): Observable<LogEntry[]> {
        // using map to access only the logs of the given source
        return this._localLogs.asObservable().pipe(map(l => l[source]));
    }

    getGlobalLogs(): Observable<LogEntry[]> {
        return this._globalLogs.asObservable();
    }

    fetchGlobalLogs(): void {
        this._loggingApi.getAll()
            .pipe(
                // parsing collected logs into all logs
                map((logs) => {
                    // reducing collected logs into the main state to avoid parsing and strange lookin logs for user
                    return logs.reduce((acc, curr, index) => {
                        if (curr.content.startsWith('[') && curr.content.endsWith(']')) {
                            // therefor every [] of logs is parsed and destructured into to the collected state and the sorrounding main entry gets removed from the array
                            // with a slice to the current index
                            return [...acc.slice(0, index), ...JSON.parse(curr.content)];
                        }
                        // adding the current "simple" entry to the result
                        return [...acc, curr];
                    }, new Array<LogEntry>())
                }),
                tap((logs) => this._latestGlobalState = logs),
                map((logs: LogEntry[]) => logs.sort((la, lb) => dayjs(la.createdAt).isBefore(dayjs(lb.createdAt)) ? 1 : -1))
            )
            .subscribe((logs) => this._globalLogs.next(logs));
    }

    /**
     * Filters all exisitng sources in the given scope.
     * @param {{options: global}} options
     * @returns A connection to the state specified by options.global that is mapped to all logging sources found in the logs
     */
    getLoggingSources(options?: { global: boolean }): Observable<string[]> {
        if (options?.global) {
            return this._globalLogs.asObservable()
                .pipe(
                    map(() => this._latestGlobalState.reduce((acc, curr) => acc.includes(curr.source) ? acc : [...acc, curr.source], new Array<string>()))
                );
        }
        return this._localLogs.asObservable()
            .pipe(
                map(() => Object.keys(this._latestLogState))
            );
    }

    /**
     * Filters all exisitng types in the given scope.
     * @param {{options: global}} options
     * @returns A connection to the state specified by options.global that is mapped to all logging types found in the logs
     */
    getLoggingTypes(options?: { global: boolean }): Observable<Array<string>> {
        if (options?.global) {
            return this._globalLogs.asObservable()
                .pipe(
                    map(() => this._latestGlobalState.reduce((acc, curr) => acc.includes(curr.logType) ? acc : [...acc, curr.logType], new Array<string>()))
                );
        }
        return this._localLogs.asObservable()
            .pipe(
                map(() => {
                        return Object.values(this._latestLogState).map((v) => {
                            return v.reduce((acc, curr) => acc.includes(curr.logType) ? acc : [...acc, curr.logType], new Array<string>())
                        }).reduce((acc, curr) => acc.concat(curr), new Array<string>())
                    }
                )
            );
    }

    /**
     * Creates self-build log locally.
     * @param content
     * @param source
     * @param {LogLevel} level default is {@link LogLevel.Information}
     */
    log(content: string, source: string, level: LogLevel = LogLevel.Information): void {
        this.updateLogs({
            content,
            logType: this._logType,
            source: source ?? this._unknownSource,
            logLevel: level
        });
    }

    /**
     * Creates a trace log entry locally
     * @param content
     * @param source
     */
    trace(content: string, source: string): void {
        this.updateLogs({
            content,
            source: source ?? this._unknownSource,
            logLevel: LogLevel.Trace,
            logType: this._logType
        });
    }

    /**
     * Creates a debug log entry locally
     * @param content
     * @param source
     */
    debug(content: string, source: string): void {
        this.updateLogs({
            content,
            source: source ?? this._unknownSource,
            logLevel: LogLevel.Debug,
            logType: this._logType
        });
    }

    /**
     * Creates a info-level log entry locally
     * @param content
     * @param source
     */
    info(content: string, source: string): void {
        this.updateLogs({
            content,
            source: source ?? this._unknownSource,
            logLevel: LogLevel.Information,
            logType: this._logType
        });
    }

    /**
     * creates a critical log entry locally
     * @param content
     * @param source
     */
    critical(content: string, source: string): void {
        this.updateLogs({
            content,
            source: source ?? this._unknownSource,
            logLevel: LogLevel.Critical,
            logType: this._logType
        });
    }

    /**
     * Creates a warning log entry locally
     * @param content
     * @param source
     */
    warn(content: string, source: string): void {
        this.updateLogs({
            content,
            source: source ?? this._unknownSource,
            logLevel: LogLevel.Warning,
            logType: this._logType
        });
    }

    error(content: string, source: string): void {
        this.updateLogs({
            content,
            source: source ?? this._unknownSource,
            logLevel: LogLevel.Error,
            logType: this._logType
        });
    }

    /**
     * Searches the local logs based on a string query {@see filterObjectQuery}
     * @param query
     */
    search(query: LogEntryQuery, options?: { source: string }): void {
        const sourceString = options?.source ?? 'GLOBAL';
        let filterBase = sourceString === 'GLOBAL' ? this._latestGlobalState.slice() : this._latestLogState[sourceString].slice();

        if (typeof query === 'object') {
            filterBase = this.preFilterFromObjectQuery(query, filterBase);
        }

        if (sourceString === 'GLOBAL') {
            this._globalLogs.next(filterObjectQuery(filterBase, query));
        } else {
            const filteredLogs = {...this._latestLogState, [sourceString]: filterObjectQuery(filterBase, query)};
            this._localLogs.next(filteredLogs);
        }
    }

    /**
     * commits the localy sotred log of one source to the server.
     * Creates a collected entry of all local logs of that source as stores that as JSON.
     * The highest priority level from collected entries is used for main-log.
     *
     * This has no effect if the logging-module is used with the setting {autoCommit: true}
     *
     * @param {string} source the name of the logging source whichs logs shall get saved
     */
    commit(source: string): void {
        if (this._latestLogState?.[source]) {
            const collectedLogs: LogEntry[] = this._latestLogState[source];
            if (collectedLogs && collectedLogs.length > 0) {
                const mainLog: LogEntry = {
                    source: source,
                    logLevel: collectedLogs.sort((a, b) => a.logLevel > b.logLevel ? 1 : -1)?.[0].logLevel,
                    logType: this._logType,
                    content: JSON.stringify(collectedLogs)
                };
                this._loggingApi.create(mainLog, false).subscribe();
            }
        }
    }

    /**
     * Updates the locally existing logs first for the current entry.
     * This does not automatically commit to the server. Commiting is
     * done by the commit function of this service.
     * @param entry
     * @private
     */
    private updateLogs(entry: LogEntry | null): void {
        if (entry) {
            const current = this._localLogs.value;

            // storing new entry in local logs first for faster appearance in UI
            if (current[entry.source]) {
                // adding to exisiting log
                current[entry.source].push({...entry, createdAt: this._dateTime.getCurrentDate() as string});
            } else {
                // creating new log source entry
                current[entry.source] = [{...entry, createdAt: this._dateTime.getCurrentDate() as string}];
            }

            // updating local logs
            this._localLogs.next(current);

            // updating fallback storage for local logs (needed to search)
            this._latestLogState = current;
        }
    }

    /**
     * Filters a list of Logentries by a given Query. Does not mutate the list.
     * Removes the used entries from the filterquery.
     * @param {LogEntryQuery} query defines the properties of the remaining entries
     * @param {LogEntry[]} values  the entries to be filtered
     * @returns The remaining entries that match the given Query
     */
    private preFilterFromObjectQuery(query: LogEntryQuery, values: LogEntry[]): LogEntry[] {
        let result: LogEntry[] = values.slice();
        if (typeof query === 'object') {
            if (query.logLevel && isArray(query.logLevel)) {
                result = result.filter(e => (query.logLevel as LogLevel[]).includes(e.logLevel));
                delete query.logLevel;
            }

            if (query.source && isArray(query.source)) {
                result = result.filter(e => (query.source as string[]).includes(e.source));
                delete query.source;
            }

            if (query.logType && isArray(query.logType)) {
                result = result.filter(e => (query.logType as string[]).includes(e.logType));
                delete query.logType;
            }
        }

        return result;
    }
}
