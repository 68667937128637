import {Component, Input} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'app-form-column',
  templateUrl: './form-column.component.html',
  styleUrls: ['./form-column.component.scss']
})
export class FormColumnComponent {

  @Input()
  set spaceEvenly(value: any) {
    this._spaceEvenly = coerceBooleanProperty(value);
  }
  get spaceEvenly(): boolean {
    return this._spaceEvenly;
  }

  private _spaceEvenly = false;
}
