import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IDependencyConfig, IPropertyView} from '../../domain/interfaces';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {isArray} from '../../domain/functions';
import {DialogPurpose, InputTypeEnum} from '../../domain/enums';
import {BehaviorSubject, Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {MatFormFieldAppearance} from '@angular/material/form-field';

type Appearance = 'legacy' | 'fill' | 'outline' | 'standard';
type Layout = 'row' | 'column';
type PropertyFilter = 'search' | 'set';
type Color = 'primary' | 'accent';

@Component({
    selector: 'app-generic-form',
    templateUrl: './generic-form.component.html',
    styleUrls: ['./generic-form.component.scss']
})
export class GenericFormComponent implements OnInit, OnDestroy {

    @Input() purpose: DialogPurpose = DialogPurpose.CREATE;
    @Input() entity: any;
    @Input() disabled = false;
    @Output() value: EventEmitter<any> = new EventEmitter();

    form!: UntypedFormGroup;
    valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    valueChanges: Subject<any> = new Subject<any>();

    private _unSubscriber: Subject<any> = new Subject();
    private _propFilter!: PropertyFilter;

    constructor(private _fb: UntypedFormBuilder) {
    }

    private _appearance: MatFormFieldAppearance = 'fill';

    @Input()
    get appearance(): MatFormFieldAppearance {
        return this._appearance;
    }

    set appearance(val: MatFormFieldAppearance) {
        this._appearance = val;
    }

    private _ignoreValidation = false;

    get ignoreValidation(): boolean {
        return this._ignoreValidation;
    }

    @Input()
    set ignoreValidation(val: boolean) {
        this._ignoreValidation = val;
    }

    private _layout: Layout = 'column'

    get layout(): Layout {
        return this._layout;
    }

    @Input()
    set layout(val: Layout) {
        this._layout = val;
    }

    private _properties!: IPropertyView[];

    @Input()
    get properties(): IPropertyView[] {
        return this._properties;
    }

    set properties(val: IPropertyView[]) {
        this._properties = val.filter(p => {
            if (this._propFilter === 'set') {
                return p.input?.isSettable !== false;
            } else {
                return p.input?.isSearchable !== false;
            }
        });
    }

    private _color: Color = 'primary';

    get color(): Color {
        return this._color;
    }

    @Input()
    set color(color: Color) {
        this._color = color;
    }

    get propertyFilter(): PropertyFilter {
        return this._propFilter;
    }

    @Input()
    set propertyFilter(val: PropertyFilter) {
        this._propFilter = val;
    }

    ngOnInit(): void {
        this.filterProperties();
        this.buildForm();
        this.setUpChangeTracking();

        // only set up if form is not used for searching
        if (!this.propertyFilter || this.propertyFilter === 'set') {
            this.setUpValidation();
            this.checkDependencies();
        }
    }

    isArray(val: any) {
        return isArray(val);
    }

    getControl(prop: string): UntypedFormControl {
        return this.form.get(prop) as UntypedFormControl;
    }

    submitForm(): void {
        this.value.next(this.cleanUpForm());
    }

    clearForm(): void {
        this.form.reset({});
    }

    ngOnDestroy(): void {
        this._unSubscriber.unsubscribe();
    }

    private checkDependencies(): void {
        // this shall be a creation/update form
        if (!this._propFilter || this._propFilter === 'set') {
            for (const prop of this.properties.filter(p => p.dependency)) {
                this.setUpDependencyTracking(prop.dependency!, this.form.get(prop.key) as UntypedFormControl);
            }
        }
    }

    /**
     * This method is needed for generic form to emit something
     * when the inner form changes
     * strongly needed with array-inputs
     */
    private setUpChangeTracking(): void {
        this.form.valueChanges.subscribe(() => this.valueChanges.next(this.cleanUpForm()));
    }

    private filterProperties(): void {
        this._properties = this._properties.filter(p => !this._propFilter || this._propFilter === 'set' ? p.input?.isSettable : p.input?.isSearchable !== false);
    }

    private buildForm() {
        let valid = true;
        const group: any = {};

        for (const prop of this.properties) {
            let control: UntypedFormControl | UntypedFormArray;
            // init empty value
            let value = prop.input?.inputType === InputTypeEnum.Array ? [null] : null;
            const validators = this.ignoreValidation ? [] : collectValidators(!!prop.input?.required, prop.input?.inputType ?? InputTypeEnum.Text);

            if (this.entity && this.entity[prop.key] !== undefined) {
                value = this.entity[prop.key];
            }

            // eslint-disable-next-line prefer-const
            control = this._fb.control(value, validators);

            control.updateValueAndValidity();

            if (isNotEditable(prop, this.purpose)) {
                control.disable();
            }

            if (control.invalid) {
                valid = false;
            }

            group[prop.key] = control;
        }
        this.form = this._fb.group(group);
        this.valid.next(valid && this.purpose === DialogPurpose.EDIT);
    }

    private setUpDependencyTracking(dependency: IDependencyConfig, control: UntypedFormControl | AbstractControl): void {
        if (dependency) {
            const dependingControl = this.form.get(dependency.dependsOn);
            control.valueChanges
                .pipe(takeUntil(this._unSubscriber))
                .subscribe((controlValue) => {
                    if (controlValue) {
                        dependency.resolver!.getById(controlValue as number)
                            .pipe(
                                map(v => v[dependency.dependencyDifferName!]),
                                tap(v => {
                                    dependingControl?.setValue(v);
                                })
                            )
                            // calling subscribe to get the value start the observable
                            .subscribe();
                    }
                });
        }
    }

    private setUpValidation(): void {
        this.form.statusChanges
            .pipe(takeUntil(this._unSubscriber))
            .subscribe(s => {
                this.valid.next(s === 'VALID');
            });
    }

    private cleanUpForm(): any {
        const result: any = {};
        for (const controlName of Object.keys(this.form.controls)) {
            const control = this.form.controls[controlName];
            const prop = this.properties.find(p => p.key === controlName);
            switch (prop?.input?.inputType) {
                case InputTypeEnum.Number:
                    // eslint-disable-next-line no-case-declarations
                    const userInput = typeof control.value === 'string' ? control.value.replace(/,/g, '.') : control.value;
                    result[controlName] = userInput ? Number(userInput) : userInput;
                    break;
                case InputTypeEnum.Array:
                    // eslint-disable-next-line no-case-declarations
                    const v: any[] = control.value as [];
                    if (v && v.find(s => typeof s === 'string' ? s !== '' : !!s)) {
                        result[controlName] = control.value;
                    }
                    break;
                default:
                    result[controlName] = control.value;
                    break;
            }
        }
        return result;
    }

}

function collectValidators(required: boolean, type: InputTypeEnum): ValidatorFn[] {
    const validators = required ? [Validators.required] : [];
    switch (type) {
        case InputTypeEnum.Number:
            return [...validators, Validators.min(0)];
        case InputTypeEnum.Text:
            return [...validators, Validators.maxLength(50), Validators.minLength(1)];
        default:
            return validators;
    }
}

function isNotEditable(prop: IPropertyView, purpose: DialogPurpose) {
    return ((prop.keyProperty || prop.input?.isEditable === false) && purpose === DialogPurpose.EDIT) || purpose === DialogPurpose.DISPLAY;
}
