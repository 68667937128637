import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, noop, Observable, of, switchMap} from 'rxjs';
import {Setting} from '../../domain/core-model';
import {delay, map, mergeMap, takeWhile, tap} from 'rxjs/operators';
import {SignalRSettingService} from '../../websocket';
import {ImportDefinitionsProvider, NamedColumns} from 'src/app/import';
import {ImportSettings, WebAppSettings} from '../model/settings.enum';
import {LoggingService} from 'src/app/logging/logging-service/logging.service';
import {IcsIdentityAuthenticationService} from 'ics-identity-authentication';
import {SettingsMap, SettingsMapSearchResult} from "../model/settings-map.model";
import {SettingsInitializerService} from '../settings-initializer/settings-initializer.service';
import {Constructable} from '../../domain/utility-types';
import {HandlingUnitApiService} from '../../api/handling-unit-api/handling-unit-api.service';
import {BaseApiService} from '../../api/base-api-service/base-api.service';
import {IcsConfirmationService, IcsUserFeedbackService} from 'ics-core';

@Injectable({
    providedIn: 'root',
})
export class SettingsService implements ImportDefinitionsProvider {

    // current state of all global setting for webapp
    private _settingsMap: BehaviorSubject<SettingsMap> = new BehaviorSubject<SettingsMap>({} as any);
    private _huIdPatternState: BehaviorSubject<RegExp | null> = new BehaviorSubject<RegExp | null>(null);
    // flag for authentication subscription
    private _stateInitialized = false;
    // key for logging stuff
    private _loggingSource = 'SETTINGS';

    constructor(
        private _confirm: IcsConfirmationService,
        private _huApiService: HandlingUnitApiService,
        private _userFeedback: IcsUserFeedbackService,
        private _logger: LoggingService,
        private _auth: IcsIdentityAuthenticationService,
        private _signalRSettingService: SignalRSettingService,
        private _settingsInitializer: SettingsInitializerService,
        @Inject(Setting) private _settingsApi: BaseApiService<Setting>,
    ) {
        this._initState();
    }

    get state(): Observable<SettingsMap> {
        return this._settingsMap.asObservable();
    }

    get handlingUnitIdPattern(): Observable<RegExp | null> {
        return this._huIdPatternState.asObservable();
    }

    getEntitySettings<X>(entitySettingsType: Constructable<X, SettingsMap>): Observable<X> {
        return this.state.pipe(
            map((state) => new entitySettingsType(state))
        );
    }

    /**
     * Fetches a new set of settings-service from the server and updates local model
     */
    refresh(): void {
        this._buildNewState().subscribe();
    }

    /**
     * Returns the current state of the searched setting object
     * @param name
     */
    getSettingObject(name: keyof SettingsMap): Observable<Setting | null> {
        return this.state.pipe(
            map((s) => s[name] ? new Setting(name, s[name]) : null)
        )
    }


    /**
     * an Object containing the searched settings id a key and the values as property
     * @param name
     */
    getSetting<X extends keyof SettingsMap>(name: X): Observable<SettingsMapSearchResult<X>> {
        return this.state.pipe(
            map((s) => {
                return {[name]: s[name]} as SettingsMapSearchResult<X>;
            })
        );
    }

    /**
     * Reads single setting from the current local state and sommunicates updates to
     * subscribers
     * @param {WebAppSettings | ImportSettings} names
     */
    getSettings<X extends keyof SettingsMap>(names: X[]): Observable<SettingsMapSearchResult<X>> {
        return this.state
            .pipe(
                map((s) => names?.reduce((acc, curr) => {
                    acc[curr] = s[curr];
                    return acc;
                }, {} as SettingsMapSearchResult<X>))
            )
    }

    /**
     * Updates the setting on server to the given value
     * runs an automatic  update of the internal state and communicates to using components
     * @param {Setting} setting
     */
    updateSetting(setting: Setting): void {
        if (setting && setting.values) {
            this._settingsApi.update(setting).subscribe((res) => {
                if (res.responseValue) {
                    this._settingsMap.next(this._updateSettingsMap(res.responseValue));
                }
            });
        } else {
            this._logger.warn(
                `Setting ${setting.settingId} was canceled from receiving NULL as VALUES.`,
                this._loggingSource
            );
            this._logger.commit(this._loggingSource);
        }
    }

    /**
     * Allows to update multiple settings-service at the same time
     * Gives simple user feedback and fetches new state from server
     * @param settings
     */
    updateSettings(...settings: Setting[]): void {
        const inValidSettings = settings.filter((s) => !s || !s.values);
        if (inValidSettings) {
            inValidSettings.forEach((s) =>
                this._logger.warn(`Setting ${s.settingId} was canceled from receiving NULL as VALUES.`, this._loggingSource)
            );
        }
        this._settingsApi.updateEntities(settings.filter((s) => s && s.values)).pipe(
            this._userFeedback.insert(res => {
                const type = res.isError ? 'error' : 'success';
                return {
                    type: type,
                    message: `ui.user.feedback.${type}.system.settings.update.multiple`
                }
            })
        ).subscribe((res) => {
            if (res?.responseValue) {
                this._settingsMap.next(this._updateSettingsMap(...settings));
            }
        });
    }

    /**
     * Allows to create a new setting on the server
     * !!! DOES NOT UPDATE STATE
     * @param setting
     */
    createSetting(setting: Setting): void {
        if (!setting || !setting.values) {
            this._logger.warn(
                `Setting ${setting.settingId ?? 'UNKNOWN'} was canceled from creation without VALUES`,
                this._loggingSource
            );
            this._logger.commit(this._loggingSource);
        }

        this._settingsApi.create(setting)
            .subscribe((res) => {
                if (res.responseValue) {
                    this._settingsMap.next(this._updateSettingsMap(res.responseValue));
                }
            });
    }

    /**
     * Allows to delete a setting on the server
     * @param settingId of the setting to be deleted
     * @param dialogMessage a warning message for the user. Default will ask user if sure to delete
     */
    deleteSetting(settingId: string, dialogMessage?: string): Observable<void> {
        const toDelete: any = {key: settingId};
        return this._confirm.askForConfirmation({
            title: 'ui.common.confirmations.delete.title',
            message: 'ui.common.confirmations.delete.message'
        }).pipe(
            switchMap((confirm) => confirm ? this._settingsApi.delete(toDelete) : of(confirm)),
            delay(500),
            switchMap((deleted) => {
                if (deleted) {
                    delete (this._settingsMap.getValue() as any)[settingId];
                    return this._buildNewState();
                }
                return EMPTY;
            }),
            map(() => noop())
        );
    }

    /**
     * Grants access to the column order of a selected class associated with the given ID
     * @param columnsId
     */
    getColumnsInOrder(columnsId: string): Observable<string[]> {
        return this._settingsMap
            .asObservable()
            .pipe(
                map((settings) => {
                    if (settings) {
                        const columns: string[] = (settings as any)[columnsId];
                        return [...new Set(columns)];
                    }
                    return [];
                }));
    }

    /**
     * Grants access to the column names of a selected class that is associated with the given ID
     * @param columnsId
     */
    getColumnNames(columnsId: string): Observable<NamedColumns> {
        return this._settingsMap.asObservable()
            .pipe(
                map((settings) => {
                    // column names are stored as single object / JSON in an array
                    const namedColumnJSON = (settings as any)?.[columnsId]?.[0];
                    // parsing single object stored as JSON
                    return namedColumnJSON ? JSON.parse(namedColumnJSON) : {};
                }),
            );
    }

    /**
     * Grants access to the common import-range-symbol setting for imports.
     * @returns {[string, string]} a tuple of [start, end]
     */
    getImportRangeSymbols(): Observable<[string, string]> {
        return this._settingsMap
            .asObservable()
            .pipe(map((settings) => settings[ImportSettings.importRangeSymbols] as [string, string]));
    }

    private _buildNewState(): Observable<SettingsMap> {
        return this._settingsApi.getAll()
            .pipe(
                map((settings) => this._updateSettingsMap(...settings)),
                tap((settings) => this._settingsMap.next(settings)));
    }

    private _updateSettingsMap(...settings: Setting[]): SettingsMap {
        return settings.reduce((acc: SettingsMap, curr) => {
            return {...acc, [curr.settingId]: curr.values}
        }, this._settingsMap.getValue());
    }

    private _initState(): void {
        this._auth.isAuthenticated
            .pipe(takeWhile(() => !this._stateInitialized))
            .subscribe((isAuthenticated) => {
                if (isAuthenticated) {
                    this._stateInitialized = true;
                    this._watchHandlingUnitPatternUpdate();
                    this._buildNewState()
                        .pipe(mergeMap((state) => this._settingsInitializer.checkInitialState(state)))
                        .subscribe(() => this.refresh());
                }
            });
    }


    private _watchHandlingUnitPatternUpdate() {
        this._fetchHuIdPattern();
        // creating auto-update mechanism for hu-id-regex changes
        this._signalRSettingService.huIdPatternChange?.pipe(takeWhile(() => this._signalRSettingService.isConnected))?.subscribe((pattern) => this._huIdPatternState.next(pattern));
    }

    /**
     * Fetches the latest state of Hu-Id-Regex from server
     */
    private _fetchHuIdPattern(): void {
        this._huApiService.getHandlingUnitIdPattern().subscribe((regexString: string) => {
            this._huIdPatternState.next(new RegExp(regexString));
        });
    }
}


