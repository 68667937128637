export {IndexedSelectionModel, IndexedSelection, SelectionChangeEvent}

/**
 * Event emitted after the selection changed. Emits the nes set of selected values.
 */
 type SelectionChangeEvent<T = any> = { selected: IndexedSelection<T>[] };


/**
 * A selected value mapped to its index in its dataset
 */
 type IndexedSelection<T = any> = { index: number, selected: T };

/**
 * Allows to store selected values mapped by their index.
 * This can be used for implementations where data has no unique identifier and has to be identified by index.
 * Uses an map for internal implementation.
 */
 class IndexedSelectionModel<T = any> {
    // storage of selected items
    private _selection: Map<number, T> = new Map();

    /**
     * @returns all selected items as array
     */
    get selected(): IndexedSelection<T>[] {
        return Array.from(this._selection).map(([key, value]) => {
            return { index: key, selected: value };
        });
    }

    /**
     * @returns the amount of selected items
     */
    get size(): number {
        return this._selection.size;
    }

    get lastIndex(): number {
        const maxIndex = Math.max(...this._selection.keys());
        if (Number.isFinite(maxIndex)) {
            return maxIndex;
        }
        return 0;
    }

    /**
     * Checks if values are selected
     * @returns {boolean} wheter the selection holds values or not
     */
    hasValue(): boolean {
        return this._selection.size > 0;
    }

    /**
     * Checks if there is a value for the given selection index
     * @param {number} index the identifiying index of the value
     * @returns {boolean} wheter there was a value for this index or not
     */
    isSelected(index: number): boolean {
        return !!this._selection.get(index);
    }

    /**
     * Removes the value of the given index from the selection
     * @param {number} index to remove from selection 
     * @returns {IndexedSelection<T>[]} the resulting selected values
     */
    deselect(index: number): IndexedSelection<T>[] {
        this._selection.delete(index);
        return this.selected;
    }

    /***
     * adds the value at its index to the position
     * @param {IndexedSelection<T>} value the value to be marked as selected
     * @return {IndexedSelection<T>[]} the resulting selected values
     */
    select(value: IndexedSelection<T>): IndexedSelection<T>[] {
        this._selection.set(value.index, value.selected);
        return this.selected;
    }

    /**
     * removes all selected values from selection
     * @returns {IndexedSelection<T>[]} the resulting empty selected values
     */
    clear(): IndexedSelection<T>[] {
        this._selection.clear();
        return this.selected;
    }
}