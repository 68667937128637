import {IExportColumn} from '../interfaces/export-column.interface';
import {FlattenedStringMap, StringMap} from './object-parser.interface';

export {
    DestructuringParser
}

class DestructuringParser {

    private readonly _emptySign = '--';

    constructor(private _destructColumns: Array<IExportColumn>) {
    }

    /**
     * Builds empty template objects for destructuring columns. This is needed in cases where destructuring
     * columns don't share the same amount of properties. In this case the created templates collect ALL properties
     * of ALL destruct values as unique set and sets them to the empty sign.
     *
     * Afterward these templates can be used to add properties to objects that shall be destructured.
     *
     * @example
     * // you can simply do
     * const a = {a: 'myValue'};
     * console.log({result: {...template, ...a}} => {a: 'myValue', fromTemplateA: '--', fromTemplateB: '--'}
     * @param values
     */
    buildDestructuringTemplates(values: StringMap[]): Map<string, FlattenedStringMap> | undefined {

        if (!this._destructColumns || this._destructColumns.length === 0) {
            return undefined;
        }

        return this._destructColumns.reduce((acc, column) => {
            const targets = this._getAllDestructuringObjects(values, column.propertyKey);

            return acc.set(column.propertyKey, this._collectAllDestructuredKeys(targets));
        }, new Map<string, FlattenedStringMap>());
    }

    /**
     * Collect all keys from all destructuring object of a column a builds a set from it. Return this set of keys
     * mapped to the column name.
     * @param forValues
     */
    buildDestructuringHeaders(forValues: StringMap[]): Map<string, string[]> {
        return this._destructColumns.reduce((acc, col) => {
            const targets = this._getAllDestructuringObjects(forValues, col.propertyKey);
            return acc.set(col.propertyKey, Object.keys(this._collectAllDestructuredKeys(targets)));
        }, new Map<string, string[]>())
    }


    private _getAllDestructuringObjects(from: StringMap[], forProperty: string) {
        return from.map(v => v?.[forProperty])
            .filter(v => v && typeof v === 'object')
            .map(v => v as object);
    }

    private _collectAllDestructuredKeys(values: object[]): FlattenedStringMap {
        const collection = values.reduce((acc: any, curr) => {
            return {...acc, ...curr}
        }, {});

        return this._nullAllValues(collection);
    }

    private _nullAllValues(value: object) {
        return Object.keys(value).reduce((acc: FlattenedStringMap, curr) => {
            return {...acc, [curr]: this._emptySign};
        }, {});
    }

}
