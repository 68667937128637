import {ExportableConfig} from '../decorators/exportable.decorator';
import {EXPORTABLE_DECORATOR_KEY} from '../decorators/key';

export {
    ExportableReader
}

class ExportableReader {

    private readonly _config: ExportableConfig;

    constructor(private _class: Function) {
        this._config = Reflect.getMetadata(EXPORTABLE_DECORATOR_KEY, this._class);

    }

    isExportable(): boolean {
        return !!this._config;
    }

    getExportableConfig(): ExportableConfig | null {
        return this._config;
    }

    mainWritingProperty(): string | null {
        return this._config?.writingProperty ?? null;
    }

}
