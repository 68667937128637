import {Injectable} from '@angular/core';
import {BaseApiService} from '../base-api-service/base-api.service';
import {Article} from '../../domain/core-model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {IcsUserFeedbackService} from 'ics-core';

@Injectable({
    providedIn: 'root',
})
export class ArticleApiService extends BaseApiService<Article> {

    constructor(http: HttpClient, userFeedback: IcsUserFeedbackService) {
        super('article', 'core', http, userFeedback);
    }

    getArticleSmall(): Observable<Article[]> {
        return this._http.get<Article[]>(this._apiRoute + '/GetArticleSmall')
            .pipe(
                catchError(err => this.handleHttpGetError(err) as Observable<Article[]>)
            );
    }
}
