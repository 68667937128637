export {
  Headers
}

const importHeaders = {
  import: `Excel Import`,
  fileLayout: `Excel Import Layout`,
  importRangeSymbols: `Start- und Endzeile des Imports aus Dateien`,
  preferredTableHeaders: `Excel Import Benennungen in Tabellen verwenden`,
};

const materialRequest = {
  materialRequestGroupSuffix: `Automatischer Gruppen-Suffix für Nachforderungen`,
  materialRequestEmailList: `E-Mail Benachrichtigungen über neue Materialnachforderungen`,
  materialRequestArticleFilters: `Zusätzliche Artikelfilter für das Erstellen von Materialnachforderungen`
}


const Headers = {
  importHeaders,
  materialRequest
};
