import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
} from '@angular/core';
import {CustomFormFieldComponent} from '../custom-form-field.component';
import {ControlValueAccessor, FormControl, NgControl, UntypedFormBuilder} from '@angular/forms';
import {TransportPositionState, TransportPositionStateDescription} from '../../domain/tms-model';
import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {isArray, isEmpty} from '../../domain/functions';
import {Subject} from 'rxjs';
import {UserRole} from '../../roles';
import {take, takeUntil} from 'rxjs/operators';
import {MAT_FORM_FIELD, MatFormField} from '@angular/material/form-field';
import {IcsRolesService} from 'ics-core';

@Component({
    selector: 'app-transport-position-state-form-field',
    templateUrl: './transport-position-state-form-field.component.html',
    styleUrls: ['./transport-position-state-form-field.component.scss'],
    providers: [{
        provide: CustomFormFieldComponent,
        useExisting: forwardRef(() => TransportPositionStateFormFieldComponent)
    }]
})
export class TransportPositionStateFormFieldComponent extends CustomFormFieldComponent<TransportPositionState> implements OnInit, OnDestroy, ControlValueAccessor {
    static nextId = 0;
    @Output() clearUser: EventEmitter<TransportPositionState> = new EventEmitter();
    @Output() requireUser: EventEmitter<boolean> = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('aria-describedby') userAriaDescribedBy = '';
    @Input() hasHuAssigned = false;
    possibleStates = TransportPositionState;
    stateDescriptions = TransportPositionStateDescription;
    stateChanges: Subject<void> = new Subject();
    touched = false;
    focused = false;
    userAccessLoaded = false;
    controlType = 'transport-position-state-selection';
    id = `${this.controlType}-${TransportPositionStateFormFieldComponent.nextId++}`;
    private _subscriptions: Subject<void> = new Subject();

    constructor(
        private _roleService: IcsRolesService,
        private _fb: UntypedFormBuilder,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        super(_fb.control(null));
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    private _multiple = false;

    get multiple(): boolean {
        return this._multiple;
    }

    @Input()
    set multiple(value: any) {
        this._multiple = coerceBooleanProperty(value);
    }

    private _orderManagementAllowed = false;

    set orderManagementAllowed(value: boolean) {
        if (this.disableRoleManagement) {
            this._orderManagementAllowed = true;
        } else {
            this._orderManagementAllowed = value;
        }
        this.checkDisabledState();
    }

    private _disabled = false;

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set disabled(value: any) {
        this._disabled = coerceBooleanProperty(value);
        this.checkDisabledState();
    }

    get value(): TransportPositionState {
        return this.control.value;
    }

    @Input()
    set value(value: TransportPositionState | TransportPositionState[] | number | null | undefined) {
        let writingValue: TransportPositionState | TransportPositionState[] = value as any;
        if (!value) {
            writingValue = TransportPositionState.Created;
        } else {
            if (this.multiple && !isArray(value)) {
                writingValue = new Array<TransportPositionState>(value as TransportPositionState);
            }
        }
        this.control.patchValue(writingValue);
        this.stateChanges.next();
    }

    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    get empty(): boolean {
        return this.control.value === '';
    }

    get errorState(): boolean {
        return this.control.invalid && this.touched || !isEmpty(this.control.errors);
    }

    get selectedEntity(): TransportPositionState {
        return this.control.value;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = (_: any) => {
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {
    };

    ngOnInit(): void {
        if (this.ngControl?.control) {
            this.control = this.ngControl.control as FormControl;
        }
        this.userAccessLoaded = false;
        this._roleService.hasPrivilege("OrderManagement")
            .pipe(takeUntil(this._subscriptions))
            .subscribe((orderManagementAllowed: boolean) => {
                this.userAccessLoaded = true;
                this.orderManagementAllowed = orderManagementAllowed;
            });
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    setDescribedByIds(ids: string[]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const controlElement = this._elementRef.nativeElement.querySelector(
            '.transport-position-state-select-container',
        )!;
        controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent) {
        // fill
    }

    writeValue(value: TransportPositionState | number): void {
        if (value === this.value) {
            return
        }
        this.value = value;
        this._handleInput();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    _handleInput(): void {
        this.onChange(this.value);
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
        this._subscriptions.next();
        this._subscriptions.complete();
    }

    checkUserReset(event: TransportPositionState) {
        const currentValue = this.control.value;
        const nextValue = event;
        if (currentValue >= TransportPositionState.InProgress && nextValue <= TransportPositionState.Released) {
            this.clearUser.emit(nextValue);
        }

        this._roleService.hasRoles(UserRole.tmsAdmin).pipe(
            take(1)
        ).subscribe((isAdmin) => this.requireUser.emit(!isAdmin && nextValue === TransportPositionState.InProgress));
    }

    private checkDisabledState() {
        if (!this._orderManagementAllowed || this.disabled) {
            if (this.control.enabled) {
                this.control.disable({emitEvent: false, onlySelf: true});
            }

        } else {
            if (this.control.disabled) {
                this.control.enable({emitEvent: false, onlySelf: true});
            }
        }
    }
}
