import {BaseEntity} from '../base-entity.model';
import { ArticleGroup } from '../tms-model';
import {Article} from './article.model';
import {HandlingUnit} from './handling-unit.model';

export {HandlingUnitArticle};

class HandlingUnitArticle extends BaseEntity {
  static buildFromEagerLoad(handlingUnitArticle: HandlingUnitArticle): HandlingUnitArticle {
    handlingUnitArticle.articleDescription = handlingUnitArticle?.articleObj?.description ?? undefined;
    handlingUnitArticle.articleAdditionalFields = handlingUnitArticle?.articleObj?.additionalFields;
    return handlingUnitArticle;
  }

  // for displaying
  public articleDescription?: string;
  public articleAdditionalFields?: { [key: string]: string };

  public articleGroupName?: string;

  // for eager loading
  public articleObj?: Article;
  public handlingUnitObj?: HandlingUnit;

  // for inventory
  public articleGroupObj?: ArticleGroup | null

  constructor(
    public articleId: string,
    public articleCount: number,
    public handlingUnitId: string,
  ) {
    super();
  }
}
