import {TransportPosition} from '.';
import {Warehouse} from '../core-model';
import {BaseEntity} from '../base-entity.model';
import {DeepPartial} from '../../search/model';
import {TransportTaskState} from '../../modules/tms/transport-task';

export {SuperTask, SuperTaskQuery, SubTaskCreate, SuperTaskId};

type SuperTaskId = SuperTask['superTaskId'];

class SuperTask extends BaseEntity {
    public static buildFromEagerLoad(superTask: SuperTask): SuperTask {
        // setting first booking number that is found
        superTask.bookingNumber = superTask?.transportPositions?.find(p => p.bookingNumber)?.bookingNumber ?? undefined;
        return superTask;
    }

    public superTaskId?: number | null = null;
    public state?: TransportTaskState = TransportTaskState.Created;
    public destinationWarehouseObj?: Warehouse | null = null;
    public bookingNumber?: string | null = null;

    constructor(
        public destinationWarehouseId: number,
        //reference object
        public transportPositions?: TransportPosition[],
    ) {
        super();
    }
}

type SuperTaskQuery = DeepPartial<SuperTask> & DeepPartial<{
    destinationWarehouseName?: string,
    articleSourceId?: number,
    articleSourceName?: string,
    articleId: string,
    articleGroupId: number | string,
}>;

type SubTaskCreate = {
    superTaskId: number,
    transportPositionIds: number[],
    destinationWarehouseId?: number,
    toBeDoneBy?: string
};
