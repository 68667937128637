import {Component, Input} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'app-clickable-field',
  templateUrl: './clickable-field.component.html',
  styleUrls: ['./clickable-field.component.scss']
})
export class ClickableFieldComponent {

  @Input()
  set active(value: any) {
    this._active = coerceBooleanProperty(value);
  }
  get active(): boolean {
    return this._active;
  }

  private _active = false;
}
