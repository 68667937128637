import {
    TransportTaskState,
    TransportTaskStateSelections
} from '../../modules/tms/transport-task/model/transport-task-state.model';
import {Validators} from '@angular/forms';
import {InputTypeEnum, readySelection} from '../enums';
import {ITypeConfig} from '../interfaces';

export class TransportList {
    constructor(
        public readonly transportListId?: number,
        public isComplete?: boolean,
        public state?: TransportTaskState,
        public dateFinished?: Date | string,
        public destinationInformation?: string[]
    ) {
    }
}

export const TransportListConfig: ITypeConfig<TransportList> = {
    properties: [
        {
            key: 'transportListId',
            view: 'ID',
            keyProperty: true,
            writeFile: true,
            input: {
                required: true,
                inputType: InputTypeEnum.Number,
                isSettable: false
            }
        },
        {
            key: 'state',
            view: 'Status',
            input: {
                required: true,
                isSettable: true,
                inputType: InputTypeEnum.Selection,
                selectionValues: TransportTaskStateSelections
            }
        },
        {
            key: 'isComplete',
            view: 'Verfügbar',
            input: {
                inputType: InputTypeEnum.Selection,
                selectionValues: readySelection,
                required: false,
                isSettable: true
            }
        },
        {
            key: 'destinationInformation',
            view: 'Ziel-Informationen',
            input: {
                inputType: InputTypeEnum.Array,
                selectionInputType: InputTypeEnum.Text,
                required: false,
                isSettable: true,
                maxArrayLength: 10,
                validators: [Validators.maxLength(50)]
            }
        },
        {
            key: 'dateFinished',
            view: 'Beendet',
            input: {
                required: false,
                inputType: InputTypeEnum.Date,
                isSettable: false
            }
        },
    ],
    keyNames: [
        'transportListId', 'isComplete', 'state', 'destinationInformation', 'dateFinished'
    ]
};
