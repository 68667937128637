import { Inject, Injectable } from '@angular/core';
import { mergeMap, Observable, of, zip } from 'rxjs';
import { WarehouseService } from '../warehouse/warehouse-service/warehouse.service';
import { StateService } from '../model/state/state.service';
import { ExtendedStorageBinQuery, StorageBin, StorageBinArticle } from '../../domain/lvs-model';
import { HandlingUnitService } from '../handling-unit/handling-unit.service';
import { FileService } from '../../core';
import { BarcodeCheck, BarcodeService, BarcodeType } from '../../barcode';
import { BaseApiService } from '../../api/base-api-service/base-api.service';
import { SignalRService } from '../../websocket';
import { HandlingUnit, Warehouse, WarehouseTypes } from '../../domain/core-model';
import { StorageBinApiService } from '../../api/storage-bin/storage-bin-api.service';
import { combineLatestWith, map, tap } from 'rxjs/operators';
import { StorageBinLabelBuilder } from '../../domain/lvs-model/storage-bin-label-builder';
import { EntityKey } from '../../domain/utility-types';
import { searchAnd } from '../../search/operators';
import { EnvironmentType } from '../../../environments/environment.interface';
import { environment } from '../../../environments/environment';
import { FileType } from '../../export';
import { PropertySearcher } from '../../search/model';

@Injectable({
    providedIn: 'root'
})
export class StorageBinService extends StateService<StorageBin, ExtendedStorageBinQuery> {

    constructor(
        private _warehouses: WarehouseService,
        private _handlingUnits: HandlingUnitService,
        private _fileService: FileService,
        private _barcodeService: BarcodeService,
        @Inject(StorageBinArticle) private _storageBinArticleApi: BaseApiService<StorageBinArticle>,
        @Inject('WarehouseSignalRService') private _warehouseWebsocket: SignalRService<Warehouse>,
        @Inject('ContainerSignalRService') private _containerWebsocket: SignalRService<Warehouse>,
        protected override _api: StorageBinApiService,
    ) {
        super(
            {
                type: StorageBin,
                api: StorageBinApiService,
                webSocket: 'StorageBinSignalRService',
                primaryKeys: ['storageBinId', 'storageAreaId', 'warehouseId']
            },
            { prefetching: true, eagerLoading: false }
        );
        this.watchWarehouseWebsocket();
        this.listenToWarehouseDeletions();
    }

    override get state(): Observable<StorageBin[]> {
        return super.state.pipe(
            map(StorageBinLabelBuilder.addBarcodeDataToStorageBins),
            combineLatestWith(this._warehouses.state),
            map(([storageBins, warehouses]) => {
                storageBins.forEach((sb => {
                    sb.warehouseObj = warehouses.find(wh => wh.warehouseId === sb.warehouseId) ?? null
                }))
                return storageBins;
            })
        );
    }

    getByWarehouseId(warehouseKey: EntityKey<Warehouse>, query = '') {
        return this.state.pipe(
            map((s) => s.filter(b => b.warehouseId === warehouseKey.warehouseId)),
            mergeMap((s: StorageBin[]) => {
                if (s.length === 0) {
                    return this._api.search({ warehouseId: warehouseKey.warehouseId });
                }
                return of(s);
            }),
            searchAnd<StorageBin>(query)
        );
    }


    /**
     * Creates a data-representation of bins and barcodes for export using http and backend generation endpoint
     * @param storageBins all bins that shall be exported
     * @param codeType the code type to be generated into the pdf
     * @return Observable<boolean> returns the promise of file service whether file was created or not
     */
    exportDataMatrixCodes(storageBins: StorageBin[], codeType: BarcodeType): Observable<boolean> {
        const barcodeOptions = BarcodeCheck.isOneDimensional(codeType) ? {
            width: 1,// inches
            height: 5,
        } : undefined;


        // mapping bins and codes do dto for backend
        const storageBinDataMatrix = storageBins.map(b => {
            const codedValue = BarcodeCheck.isOneDimensional(codeType) ? b.binOnlyLocationData : b.fullLocationLabelData;
            return {
                storageBin: b,
                // using existing code or creating for non-existent
                dataMatrixUrl: this._barcodeService.generateCode(codeType, codedValue!, barcodeOptions)
            }
        });

        // left this here for performance tracking on dev
        if (environment.environmentType === EnvironmentType.DEVELOPMENT) {
            console.time("PDF-GENERATION");
        }

        // TODO: Replace with dynamic company logo
        // starting with file loading from assets
        return this._fileService.getBase64ImageFromURL("/assets/logos/pck-logo.png")
            .pipe(
                // sending storage bin data and base64 of logo to api service
                mergeMap((companyLogo) => this._api.generateDataMatrixPdf({ storageBinDataMatrix, companyLogo }))
            ).pipe(
                // downloading received file
                tap((httpResult) => {
                    if (httpResult.responseValue) {
                        this._fileService.download(httpResult.responseValue, FileType.PDF, `lagerplatz_${codeType}`)
                    }
                }),
                map(res => {
                    // left this also here for performance check on dev
                    if (environment.environmentType === EnvironmentType.DEVELOPMENT) {
                        console.timeEnd("PDF-GENERATION");
                    }
                    return !res.isError;
                })
            );
    }

    protected override searchProperty: PropertySearcher<StorageBin, ExtendedStorageBinQuery> = (searchList, key, query) => {

        if (key === 'articleId' || key === 'articleGroupId' || key === 'articleGroupName') {
            const relationQuery = { [key]: query };
            return zip([this._storageBinArticleApi.search(relationQuery), this._handlingUnits.searchStoredArticle(relationQuery)])
                .pipe(
                    map(([sbArticles, hus]) => [...sbArticles, ...hus]),
                    map((storedStuff) => {
                        return searchList.filter((bin) => {
                            return storedStuff.find((sbArticleOrHu) => isStoredIn(bin, sbArticleOrHu))
                        });
                    })
                );
        }

        if (key === 'warehouseName') {
            // TODO: MAKE description searchable
            return this._warehouses.search({ warehouseName: query })
                .pipe(
                    map(warehouses => searchList.filter(s => warehouses.some(w => w.warehouseId === s.warehouseId)))
                );
        }

        return undefined;
    };

    protected listenToWarehouseDeletions(): void {
        const removeDeletedWarehouse = (w: Warehouse) => {
            if (w.warehouseType === WarehouseTypes.Inactive) {
                this.storage.remove(w);
            }
        };
        this._warehouseWebsocket.getUpdatedNotification().subscribe(removeDeletedWarehouse);
        this._containerWebsocket.getUpdatedNotification().subscribe(removeDeletedWarehouse);
    }

    private watchWarehouseWebsocket(): void {
        this._warehouseWebsocket.getNewNotification()
            .subscribe((w) => {
                this._api.search({ warehouseId: w.warehouseId })
                    .subscribe((partialState) => this.updateState(partialState, 'create', false));
            });
    }
}

function isStoredIn(storageBin: StorageBin, inventory: StorageBinArticle | HandlingUnit): boolean {
    return storageBin.warehouseId === inventory.warehouseId && storageBin.storageAreaId === inventory.storageAreaId && storageBin.storageBinId === inventory.storageBinId;
}
