import {Component, Input, OnInit} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
    selector: 'app-icon-stack',
    templateUrl: './icon-stack.component.html',
    styleUrls: ['./icon-stack.component.scss'],
    standalone: true
})
export class IconStackComponent implements OnInit {

    protected _bolder = false;

    constructor() {
    }

    @Input()
    set bolder(value: any) {
        this._bolder = coerceBooleanProperty(value);
    }

    ngOnInit(): void {
    }


}
