import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {ArticleApiService} from './article-api/article-api.service';
import {ContainerApiService} from './container-api/container-api.service';
import {HandlingUnitApiService} from './handling-unit-api/handling-unit-api.service';
import {MobileAppApiService} from './mobileapp/mobile-app-api.service';
import {TransportPositionApiService} from "./transport-position-api/transport-position-api.service";
import {ApiConfig} from './interfaces/api-config.interface';
import {ConfigurationsApiService} from './configurations-api/configurations-api.service';
import {MaterialRequestApiService} from './material-request-api/material-request-api.service';
import {StorageBinApiService} from './storage-bin/storage-bin-api.service';
import {SuperTaskApiService} from './super-task/super-task-api.service';
import {BaseApiService} from './base-api-service/base-api.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {MaterialRequest, SuperTask, TransportPosition} from '../domain/tms-model';
import {Article, HandlingUnit, User} from '../domain/core-model';
import {UserApiService} from './user/user-api.service';
import {StorageBin} from '../domain/lvs-model';
import {IcsUserFeedbackService} from 'ics-core';
import {TransportTask, TransportTaskApiService} from '../modules/tms/transport-task';

const StaticApiProviders = [
    ConfigurationsApiService,
    MobileAppApiService,
    {
        provide: Article,
        useExisting: ArticleApiService,
        multi: false
    },
    ContainerApiService,
    {
        provide: HandlingUnit,
        useExisting: HandlingUnitApiService,
        multi: false
    },
    {
        provide: MaterialRequest,
        useExisting: MaterialRequestApiService,
        multi: false,
    },
    {
        provide: StorageBin,
        useExisting: StorageBinApiService,
        multi: false
    },
    {
        provide: SuperTask,
        useExisting: SuperTaskApiService,
        multi: false
    },
    {
        provide: TransportPosition,
        useExisting: TransportPositionApiService,
        multi: false
    },
    {
        provide: User,
        useExisting: UserApiService,
        multi: false
    },
    {
        provide: TransportTask,
        useExisting: TransportTaskApiService,
        multi: false
    }
];


@NgModule({
    imports: [
        HttpClientModule
    ]
})
export class ApiModule {
    constructor(@Optional() @SkipSelf() parentModule: ApiModule) {
        if (parentModule) {
            throw new Error(
                'ApiModule is already loaded. Import it only once in AppModule'
            );
        }
    }

    static forApis(config: ApiConfig[]): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [
                ...StaticApiProviders,
                ...config.map((c) => {
                    return {
                        provide: c.type,
                        useFactory: (h: HttpClient, s: IcsUserFeedbackService) => new BaseApiService(c.path, c.module, h, s),
                        deps: [HttpClient, IcsUserFeedbackService]
                    }
                })
            ]
        };
    }
}
