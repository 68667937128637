import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ReturnButtonComponent} from '../../../buttons';

@Component({
    selector: 'app-sub-page',
    templateUrl: './sub-page.component.html',
    styleUrls: ['./sub-page.component.scss']
})
export class SubPageComponent implements OnInit {

    @Input() defaultReturn: string | undefined;

    @ViewChild(ReturnButtonComponent, {static: true}) returnButton!: ReturnButtonComponent;

    constructor(
        private _activeRoute: ActivatedRoute,
        private _router: Router
    ) {
    }

    ngOnInit(): void {
    }

    return() {
        this.returnButton.navigateBack();
    }

}
