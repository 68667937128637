import {Directive} from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import {RouteQueryParams} from '../../constants';

@Directive({
    selector: '[appWithReturnLink]',
    standalone: true
})
export class WithReturnLinkDirective {
    constructor(
        private _router: Router,
        private _parentLink: RouterLink
    ) {
    }

    ngOnInit() {
        this._parentLink.queryParams = {
            ...this._parentLink.queryParams,
            [RouteQueryParams.ReturnTo]: this._router.url
        };
    }

}
