import {ISelectionProperty} from '../interfaces';


export enum TransportPositionState {
    Created = 10,
    ToBeChecked = 20,
    Released = 30,
    InProgress = 40,
    Picked = 50,
    ReadyForTransport = 60,
    InTransport = 70,
    Finished = 80,
}

export enum TransportPositionStateDescription {
    Created = 'Erstellt',
    ToBeChecked = 'Zu Prüfen',
    Released = 'Freigegeben',
    InProgress = 'In Bearbeitung',
    Picked = 'Kommissioniert',
    ReadyForTransport = 'Versandbereit',
    InTransport = 'Transport',
    Finished = 'Abgeschlossen',
}

export const AllTransportPositionStates: TransportPositionState[] = [
    TransportPositionState.Created,
    TransportPositionState.ToBeChecked,
    TransportPositionState.Released,
    TransportPositionState.InProgress,
    TransportPositionState.Picked,
    TransportPositionState.ReadyForTransport,
    TransportPositionState.InTransport,
    TransportPositionState.Finished
];

export const OPEN_TRANSPORT_STATES = AllTransportPositionStates.filter(s => s !== TransportPositionState.Finished);

export const TransportPositionStateSelections: ISelectionProperty[] = [
    {
        view: TransportPositionStateDescription.Created,
        value: TransportPositionState.Created
    },
    {
        view: TransportPositionStateDescription.InProgress,
        value: TransportPositionState.InProgress
    },
    {
        view: TransportPositionStateDescription.Picked,
        value: TransportPositionState.Picked
    },
    {
        view: TransportPositionStateDescription.ReadyForTransport,
        value: TransportPositionState.ReadyForTransport
    },
    {
        view: TransportPositionStateDescription.InTransport,
        value: TransportPositionState.InTransport
    },
    {
        view: TransportPositionStateDescription.Finished,
        value: TransportPositionState.Finished
    },
    {
        view: TransportPositionStateDescription.ToBeChecked,
        value: TransportPositionState.ToBeChecked
    },
    {
        view: TransportPositionStateDescription.Released,
        value: TransportPositionState.Released
    }
];
